import { useMutation } from '@tanstack/react-query'
import { Switch as BaseSwitch, Space, Spin, notification, Tooltip } from 'antd'
import React, { useRef, useState } from 'react'

enum States {
  INITIAL = 'initial',
  PROCESSING = 'processing',
}

type SwitchProps = {
  defaultChecked?: boolean
  name?: string
  prepare?: (value: boolean) => unknown
  updateEntity: (data: unknown) => Promise<unknown>
  onSuccess?: (data: unknown) => void
  disabled?: boolean
  tooltip?: string
}

export function Switch({
  defaultChecked = false,
  name,
  prepare,
  updateEntity,
  onSuccess,
  disabled,
  tooltip,
}: SwitchProps): JSX.Element {
  const checkedRef = useRef(defaultChecked)
  const [checked, setChecked] = useState(defaultChecked)
  const [state, setState] = useState(States.INITIAL)
  const { mutate: save } = useMutation(updateEntity, {
    onError() {
      notification.error({
        message: name ? `${name} failed to save. Please try again.` : 'Sorry, something went wrong. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
      setChecked(!checkedRef.current)
      setState(States.INITIAL)
    },
    onSuccess(data) {
      notification.success({
        message: name ? `${name} saved.` : 'Saved.',
        placement: 'bottomRight',
        duration: 2,
      })
      if (onSuccess) onSuccess(data)
      setState(States.INITIAL)
    },
  })

  function handleChange(value: boolean) {
    checkedRef.current = value
    setChecked(value)
    const data = prepare ? prepare(value) : value
    save(data)
    setState(States.PROCESSING)
  }

  return (
    <Space>
      <Tooltip title={tooltip}>
        <BaseSwitch checked={checked} disabled={disabled} onChange={handleChange} />
        {state === States.PROCESSING && <Spin size="small" />}
      </Tooltip>
    </Space>
  )
}
