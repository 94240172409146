import styled from 'styled-components'

export const StyledDetails = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  overflow: auto;
`

export const StyledSection = styled.section`
  padding: 20px 0;
  text-align: left;
`

export const StyledSpinContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 100px;
`
