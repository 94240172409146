/* eslint-disable max-len */

import * as types from './constants'

export const lookupPhoneNumbers = (payload: any) => ({
  type: types.LOOKUP_PHONE_NUMBERS,
  payload,
})
export const lookupPhoneNumbersSuccess = (payload: any) => ({
  type: types.LOOKUP_PHONE_NUMBERS_SUCCESS,
  payload,
})
export const lookupPhoneNumbersError = (payload: any) => ({
  type: types.LOOKUP_PHONE_NUMBERS_ERROR,
  payload,
})
