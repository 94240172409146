import { HTTP } from '@community_dev/requests'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { notification } from 'antd'
import { PickerFileMetadata } from 'filestack-js'
import * as filestack from 'filestack-js'

import { FanRegistrationFields } from '../SignUp/components/CustomFields'

import { getPresignedUrl, getSettings, patchSettings } from 'api/signUp'
import { QUERIES } from 'constants/queries'

const IMAGE_KEYS = ['logo', 'backgroundLandscape', 'backgroundPortrait']

// after the last '/' without the extension
const toFileNameFromUrl = (url: string) => {
  const urlSplit = url.split('/')
  const nameWithExtension = urlSplit[urlSplit.length - 1]
  const name = nameWithExtension.split('.')[0]
  return name
}

const toFileExtensionFromUrl = (url: string) => {
  // take the last extension
  const urlSplit = url.split('.')
  const extension = urlSplit[urlSplit.length - 1]
  return extension
}

const getDeletedImages = (currentSettings: any, newSettings: any) => {
  const imagesToDelete: any = []

  IMAGE_KEYS.forEach((key) => {
    if (newSettings[key] === null && currentSettings[key] && currentSettings[key] !== newSettings[key]) {
      imagesToDelete.push(currentSettings[key])
    }
  })

  return imagesToDelete
}

const getCompressedFilestackImageUrl = (file: PickerFileMetadata): string => {
  return instance.transform(file.handle, {
    compress: true,
  })
}

type NewSettings = {
  logo?: Partial<PickerFileMetadata>
  backgroundLandscape?: Partial<PickerFileMetadata>
  backgroundPortrait?: Partial<PickerFileMetadata>
}

const instance = filestack.init(import.meta.env.VITE_FILESTACK_API_KEY)

const processAddedImages = (clientId: string, newSettings: NewSettings) => {
  const settings: NewSettings = { ...newSettings }

  return Promise.all(
    IMAGE_KEYS.map((key) => {
      const file: PickerFileMetadata | undefined = newSettings[key]
      if (!file) {
        return null
      }

      if (!file.url || !file.mimetype) {
        settings[key] = null
        return null
      }

      const compressedUrl = getCompressedFilestackImageUrl(file)

      const filePromise = fetch(compressedUrl)
        .then((res) => res.blob())
        .then((blob) => new File([blob], file.uploadId))

      const signedUrlPromise = getPresignedUrl({
        operation: 'post',
        client_id: clientId,
        extension: file.mimetype.split('/').pop(),
        file_suffix: file.uploadId,
      })

      return Promise.all([filePromise, signedUrlPromise]).then(([downloadedFile, { url: signedUrl, fields }]) => {
        const body = new FormData()
        body.append('Policy', fields.policy)
        body.append('X-Amz-Algorithm', fields.xAmzAlgorithm)
        body.append('X-Amz-Credential', fields.xAmzCredential)
        body.append('X-Amz-Date', fields.xAmzDate)
        body.append('X-Amz-Signature', fields.xAmzSignature)
        if (fields.xAmzSecurityToken) {
          body.append('X-Amz-Security-Token', fields.xAmzSecurityToken)
        }
        body.append('key', fields.key)
        body.append('content-type', file.mimetype)
        body.append('acl', 'public-read')
        body.append('file', downloadedFile)
        settings[key] = `${signedUrl}/${fields.key}`
        return fetch(signedUrl, { method: HTTP.POST, body }).then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to upload image ${key}`)
          }
          return res.text()
        })
      })
    }),
  ).then(() => settings)
}

const deleteS3Image = (clientId: any, imageUrl: any) => {
  return getPresignedUrl({
    operation: 'delete',
    client_id: clientId,
    extension: toFileExtensionFromUrl(imageUrl),
    file_suffix: toFileNameFromUrl(imageUrl),
  }).then(({ url }) => fetch(url, { method: HTTP.DELETE }))
}

type UpdateSettings = {
  logo?: Partial<PickerFileMetadata>
  backgroundLandscape?: Partial<PickerFileMetadata>
  backgroundPortrait?: Partial<PickerFileMetadata>
  overlayOpacity?: any
  displayMode?: any
  fanRegistrationFields?: FanRegistrationFields
}

export const useClientSettings = (clientId: string) => {
  const queryClient = useQueryClient()
  const { data: settings, isLoading } = useQuery(
    [QUERIES.CLIENT_SETTINGS, { id: clientId }],
    () => getSettings(clientId),
    {
      onError: () => {
        notification.error({
          message: 'Could not fetch settings',
          placement: 'bottomRight',
          duration: 2,
        })
      },
    },
  )

  const { mutate: update, isLoading: isUpdating } = useMutation(
    async (newSettings: UpdateSettings) => {
      const { fanRegistrationFields, ...restOfNewSettings } = newSettings
      const fanRegistrationAppearance = await processAddedImages(clientId, restOfNewSettings)

      await patchSettings({
        params: { clientId: clientId },
        body: { fanRegistrationAppearance, fanRegistrationFields },
      })

      await Promise.all(
        getDeletedImages(settings.fanRegistrationAppearance, fanRegistrationAppearance).map((url) =>
          deleteS3Image(clientId, url),
        ),
      )
    },
    {
      onError() {
        notification.error({
          message: `Could not update fan registration appearance`,
          placement: 'bottomRight',
          duration: 2,
        })
      },
      onSuccess() {
        notification.success({
          message: `Fan registration appearance updated`,
          placement: 'bottomRight',
          duration: 2,
        })
        queryClient.invalidateQueries([QUERIES.CLIENT_SETTINGS, { id: clientId }])
      },
    },
  )

  return { settings, update, isLoading, isUpdating }
}
