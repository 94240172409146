import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { SimpleInput } from 'shared/components/input'
import validatePassword from 'utils/validatePassword'
import './PasswordInput.scss'

class PasswordInput extends React.Component {
  state = {
    show: true,
    password: '',
    validations: {},
  }

  onChange = (e: any) => {
    e.preventDefault()
    const password = e.target.value
    const validations = validatePassword(password)
    this.setState({ validations, password })
    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange(e)
  }

  renderRequirements() {
    const Requirement = ({ text, valid }: any) => (
      <div className={`PasswordInput-requirement ${valid ? 'valid' : ''}`}>
        <FontAwesomeIcon icon="check-circle" />
        <span>{text}</span>
      </div>
    )
    const {
      // @ts-expect-error TS(2339): Property 'length' does not exist on type '{}'.
      validations: { length, letter, number, symbol },
    } = this.state
    return (
      <div className="PasswordInput-requirements">
        <Requirement text="8+ characters" valid={length} />
        <Requirement text="Letter" valid={letter} />
        <Requirement text="Number" valid={number} />
        <Requirement text="Symbol (!, @, #, $)" valid={symbol} />
      </div>
    )
  }

  render() {
    // @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { error, ...rest } = this.props
    const { show, password, validations } = this.state
    return (
      <div className={`PasswordInput ${error ? 'error' : ''}`}>
        <SimpleInput
          {...rest}
          // @ts-expect-error TS(2339): Property 'space' does not exist on type '{}'.
          error={error || (password.length && !validations.space ? "can't begin or end with a space" : '')}
          onChange={this.onChange}
          type={show ? 'password' : 'text'}
        />
        <div className="PasswordInput-showHide" onClick={() => this.setState({ show: !show })}>
          {show ? 'Show' : 'Hide'}
        </div>
        {this.renderRequirements()}
      </div>
    )
  }
}

export default PasswordInput
