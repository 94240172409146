import shortId from 'shortid'

import * as types from './constants'

/*
{
  level: '',
  message: '',
  position: '',
  trigger: {
    text: '',
    color: '',
    callback: undefined
  }
}
*/

const initialState = {
  items: [],
  opened: false,
}

const snackbarReducer = (state = initialState, action: any) => {
  let pushedSnackbar
  switch (action.type) {
    case types.PUSH_SNACKBAR:
      if (!action.payload) return state
      pushedSnackbar = { id: shortId.generate(), ...action.payload }
      return { ...state, items: [pushedSnackbar, ...state.items] }

    case types.CLEAN_SNACKBAR:
      return { ...state, items: state.items.slice(0, 1) }

    case types.DISMISS_SNACKBAR:
      return { ...state, opened: false }

    case types.OPEN_SNACKBAR:
      return { ...state, opened: true }

    case types.CLOSE_SNACKBAR:
      return { ...state, opened: false }

    case types.RESET_SNACKBAR:
      return initialState

    default:
      return state
  }
}

export default snackbarReducer
