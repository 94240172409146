import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'
import { route } from 'utils/router'

export type RegistrationV2 = CamelCasedPropertiesDeep<Api.V2.TCR.Registration>

export function getTRCRegistration({
  clientId,
  seatId,
}: {
  clientId: string
  seatId: string
}): Promise<RegistrationV2> {
  return request(route(ENDPOINTS.TCR.REGISTRATION, { clientId, seatId }))
}

export function resetTCRStatus({ clientId }: { clientId: string }): Promise<''> {
  return request(route(ENDPOINTS.TCR.REGISTRATION_SUBMISSION, { clientId }), {
    method: 'DELETE',
  })
}
