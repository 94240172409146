import { Radio } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledRadioGroupContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: row;
  justify-content: space-between;
`

export const DisplayModeRadioGroup = ({ onChange, value }: any) => {
  function handleOnChange(e: any) {
    onChange(e.target.value)
  }

  // Labels for dark and light text are correct per design
  return (
    <StyledRadioGroupContainer>
      <Radio.Group defaultValue={value || 'light'} onChange={handleOnChange}>
        <Radio value="light">Dark Text</Radio>
        <Radio value="dark">Light Text</Radio>
      </Radio.Group>
    </StyledRadioGroupContainer>
  )
}
