import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import './Notif.scss'

const iconMap = {
  danger: 'exclamation-circle',
  warn: 'exclamation-triangle',
  info: 'info-circle',
  success: 'check-circle',
}

class Notif extends React.PureComponent {
  renderIcon(level: any) {
    const icon = iconMap[level]
    if (!icon) return
    return (
      <div className="Notif-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
    )
  }

  render() {
    // @ts-expect-error TS(2339): Property 'level' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { level, body } = this.props
    const cname = 'Notif-root' + (level ? ` ${level}` : '')
    return (
      <div className={cname}>
        <div className="Notif-main">
          {level && this.renderIcon(level)}
          <div className="Notif-body">{body}</div>
        </div>
      </div>
    )
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Notif.propTypes = {
  body: PropTypes.string,
  level: PropTypes.oneOf(['success', 'info', 'warn', 'danger']),
}

export default Notif
