import { FONT_WEIGHT, SPACING } from '@community_dev/pixels'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Card, Col, Row, Tooltip, Typography, Modal, Form } from 'antd'
import { PickerFileMetadata } from 'filestack-js'
import snakeCase from 'lodash/snakeCase'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { FROST_ACTIVATION_MODE, REPLY_Y_ACTIVATION_MODE } from '../constants'
import { useClientSettings } from '../hooks/useClientSettings'
import { useIsFrostOn } from '../hooks/useIsFrostOn'
import { StyledDetails } from '../styled'

import { CustomFields, FanRegistrationFields } from './components/CustomFields'
import { SIGN_UP_FORM_FIELDS } from './constants'
import { DisplayModeRadioGroup } from './DisplayModeRadioGroup'
import { OpacitySlider } from './OpacitySlider'
import { Preview } from './Preview'
import { PreviewButton } from './PreviewButton'
import { FormFieldValue } from './types'
import { StyledNoImageContainer, UploadImage } from './UploadImage'

import { patchSettings } from 'api/signUp'
import { FieldValueOption, SubscriptionDataField } from 'api/subscriptionData'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES } from 'constants/queries'
import { useHasCapability } from 'hooks/useCapabilities'
import { useToastMessage } from 'hooks/useNotification'
import { useSubscriptionDataFields, useUpdateSubscriptionDataFields } from 'hooks/useSubscriptionData'
import { Switch } from 'shared/components/Switch'

const StyledSignUpContainer = styled.div`
  height: 70px;
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 0 ${SPACING[5]};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledSignUpLiveContainer = styled.div`
  display: flex;
`

const StyledLogoUpload = styled(UploadImage)`
  img {
    width: 100px;
    height: 100px;
  }

  ${StyledNoImageContainer} {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
`

const StyledLandscapeUpload = styled(UploadImage)`
  img {
    width: 150px;
    height: 100px;
  }

  ${StyledNoImageContainer} {
    width: 150px;
    height: 100px;
  }
`

const StyledPortraitUpload = styled(UploadImage)`
  img {
    width: 100px;
    height: 150px;
  }

  ${StyledNoImageContainer} {
    width: 100px;
    height: 150px;
  }
`

const StyledStepRow = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledStepText = styled.b`
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  margin-right: ${SPACING[2]};
`

const StyledDetailsColumn = styled(StyledDetails)`
  flex-direction: column;
`
const StyledSwitchRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  padding: ${SPACING[4]};
  margin-bottom: ${SPACING[4]};
`

const StyledSwitchContainer = styled.div<{ $disabled: boolean; theme: any }>`
  display: flex;
  align-items: center;

  > p {
    ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
    font-weight: 600;
    ${({ $disabled, theme }) => ($disabled ? `color: ${theme?.COLORS?.BORDERS};` : '')};
    margin-left: ${SPACING[2]};
    margin-bottom: 0;
  }
`

const StyledFrostOffBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;

  h3 {
    font-weight: ${FONT_WEIGHT[7]};
  }

  p {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  }
`

const FrostToggle = ({ client, isFrost, label, disabled }: any) => {
  const queryClient = useQueryClient()
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <StyledSwitchRow $disabled={disabled}>
      <Tooltip
        placement="bottom"
        title={
          disabled
            ? 'You do not have the permissions to change this. If you need to switch this toggle please reach out to the Legal team.'
            : ''
        }
      >
        <StyledSwitchContainer $disabled={disabled}>
          <Switch
            defaultChecked={isFrost}
            disabled={disabled}
            name="Activation Mode"
            onSuccess={() => queryClient.invalidateQueries([QUERIES.CLIENT_SETTINGS, { id: client.id }])}
            prepare={(value) => ({
              params: { clientId: client.id },
              body: {
                fan_activation_mode: value ? FROST_ACTIVATION_MODE : REPLY_Y_ACTIVATION_MODE,
              },
            })}
            updateEntity={patchSettings}
          />
          <p>{label}</p>
        </StyledSwitchContainer>
      </Tooltip>
    </StyledSwitchRow>
  )
}

export const SignUp = ({ client }: any): JSX.Element | null => {
  const { settings, update, isUpdating, isLoading } = useClientSettings(client.id)
  const { isFrostOn } = useIsFrostOn(client.id)
  const canEditActivationMethod = useHasCapability(CAPABILITIES.ADMIN.ACTIVATION_MODE.WRITE)
  const { showNotification } = useToastMessage()

  const [logoFile, setLogoFile] = useState<Partial<PickerFileMetadata>>()
  const [landscapeFile, setLandscapeFile] = useState<Partial<PickerFileMetadata>>()
  const [portraitFile, setPortraitFile] = useState<Partial<PickerFileMetadata>>()

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [fanRegistrationOverlayOpacity, setFanRegistrationOverlayOpacity] = useState()
  const [displayMode, setDisplayMode] = useState()
  const [fanRegistrationFields, setFanRegistrationFields] = useState<FanRegistrationFields>()
  const [fanCustomRegistrationFields, setCustomFanRegistrationFields] = useState<SubscriptionDataField[]>()
  const [customFormFields, setCustomFormFields] = useState<SubscriptionDataField[]>([])
  const [form] = Form.useForm()

  const { data: customFields, isInitialLoading: isSubscriptionDataLoading } = useSubscriptionDataFields(client.id)
  const { update: updateCustomFields } = useUpdateSubscriptionDataFields(client.id)

  const customFanFields = useMemo(() => customFields?.filter((field) => field.source === 'fan'), [customFields])

  useEffect(() => {
    setCustomFanRegistrationFields(customFanFields)
    if (customFanFields) {
      // extract string fields and set those in form state
      const fieldsValue = {
        [SIGN_UP_FORM_FIELDS]: customFanFields.reduce((acc, currField) => {
          if (currField.fanEnabledStatus !== 'not_enabled' && currField.valueType === 'string') {
            return [
              ...acc,
              {
                fanEnabledStatus: currField.fanEnabledStatus,
                field: currField.name,
                // this is important because it allow us to track which form fields are new vs existing
                // existing fields will always have a fieldKey and changing the name will not update it
                fieldKey: currField.key,
                options: currField.valueOptions
                  ? (currField.valueOptions
                      .map((o) => {
                        if (o.status === 'inactive') {
                          return false
                        }
                        return o.value || false
                      })
                      .filter((x) => x) as string[])
                  : null,
              },
            ]
          }
          return [...acc]
        }, [] as FormFieldValue[]),
      }
      form.setFieldsValue(fieldsValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFanFields])

  if (isLoading || isSubscriptionDataLoading) {
    return null
  }

  const appearance = settings?.fanRegistrationAppearance || {}

  const backgroundLandscape = landscapeFile ? landscapeFile.url : appearance.backgroundLandscape
  const backgroundPortrait = portraitFile ? portraitFile.url : appearance.backgroundPortrait

  const hasInvalidBackgrounds = [backgroundLandscape, backgroundPortrait].filter(Boolean).length === 1

  const previewAppearance = {
    logo: logoFile ? logoFile.url : appearance.logo,
    backgroundLandscape: backgroundLandscape,
    backgroundPortrait: backgroundPortrait,
    // default has to be 100 initially if nothing is set
    overlayOpacity: fanRegistrationOverlayOpacity || appearance.overlayOpacity || 100,
    displayMode: displayMode || appearance.displayMode,
  }

  const onPublish = async () => {
    form
      .validateFields()
      .then(async (fieldsValue) => {
        try {
          const formFields: FormFieldValue[] = fieldsValue[SIGN_UP_FORM_FIELDS] || []
          const newFormFields: SubscriptionDataField[] = formFields
            // filter out existing fields which will all have a fieldKey
            .filter((field) => field.field && !field.fieldKey)
            .map((field: FormFieldValue) => {
              return {
                fanEnabledStatus: field.fanEnabledStatus || 'is_enabled_required',
                key: snakeCase(field.field).trim(),
                name: field.field.trim(),
                source: 'fan',
                valueOptions: field.options ? field.options.map((option) => ({ value: option?.trim?.() })) : null,
                valueType: 'string',
              }
            })
          const customFormFieldKeys = customFormFields.map((f) => f.key)
          const customFields = [
            ...(fanCustomRegistrationFields || []).map((field) => {
              // if custom field is managed by form state
              if (customFormFieldKeys.includes(field.key)) {
                const customFormField = customFormFields.find((f) => f.key === field.key)
                // override the values for fanEnabledStatus, name and valueOptions
                if (customFormField) {
                  return {
                    ...field,
                    fanEnabledStatus: customFormField.fanEnabledStatus,
                    name: customFormField.name,
                    valueOptions: customFormField.valueOptions || null,
                  }
                }
              }
              return {
                ...field,
                valueOptions: field.valueOptions
                  ? // for existing dropdown fields, filter out any inactive options and omit the status
                    (field.valueOptions
                      .map((o) => {
                        if (o.status === 'inactive') {
                          return false
                        }
                        return o.value ? { value: o.value } : false
                      })
                      .filter((x) => x) as FieldValueOption[])
                  : null,
              }
            }),
            ...newFormFields,
          ]
          await Promise.all([
            update({
              logo: logoFile,
              backgroundLandscape: landscapeFile,
              backgroundPortrait: portraitFile,
              overlayOpacity: fanRegistrationOverlayOpacity,
              displayMode,
              fanRegistrationFields,
            }),
            updateCustomFields(customFields || []),
          ])
          setFanRegistrationFields(undefined)
        } catch (e) {
          setShowErrorModal(true)
        }
      })
      .catch(() => {
        showNotification('Unable to publish. Sign up form contains errors.', false)
      })
  }

  const currentFanRegistrationFields = (fanRegistrationFields ||
    settings.fanRegistrationFields ||
    []) as FanRegistrationFields
  const currentFanCustomRegistrationFields = (fanCustomRegistrationFields ||
    customFanFields ||
    []) as SubscriptionDataField[]
  const enabledFanRegistrationFields = currentFanRegistrationFields.filter(({ enabled }) => enabled)
  const enabledFanRegistrationCustomFields = currentFanCustomRegistrationFields.filter(
    ({ fanEnabledStatus }) => fanEnabledStatus,
  )
  const currentFanCustomRegistrationFieldKeys = currentFanCustomRegistrationFields.map((f) => f?.key)
  const customFormFieldKeys = customFormFields
    // omit any new custom form fields since those field keys are determined when field name changes
    .filter((f) => currentFanCustomRegistrationFieldKeys.includes(f.key))
    .map((f) => f.key)
  // all active fan fields with string fields filtered out, since those are handled in customFormFields
  const filteredCurrentFanCustomRegistrationFields = currentFanCustomRegistrationFields.filter(
    (field) => field.valueType !== 'string' || !customFormFieldKeys.includes(field.key),
  )
  const customFieldsForPreview = [...filteredCurrentFanCustomRegistrationFields, ...customFormFields]
  // this converts all field keys to snakeCase strings used for validating that new fields won't have conflicting keys
  const allFieldKeys = [
    ...currentFanRegistrationFields.filter((f) => f?.name).map((f) => f.name),
    ...currentFanCustomRegistrationFields
      .filter((f) => f?.key)
      // omit any existing string custom fields since those will come through customFormFields
      .filter((f) => f.valueType !== 'string')
      .map((f) => f.key),
    ...customFormFieldKeys.filter((f) => f),
  ]

  if (!isFrostOn) {
    return (
      <StyledDetailsColumn>
        <FrostToggle
          client={client}
          disabled={!canEditActivationMethod}
          isFrost={isFrostOn}
          label="Frost is turned OFF. Reply Y enabled."
        />
        <StyledFrostOffBody>
          <h3>Customer sign up form is not enabled</h3>
          <p>Frost is turned off. Turn Frost on to activate the customer sign up form.</p>
        </StyledFrostOffBody>
      </StyledDetailsColumn>
    )
  }

  return (
    <StyledDetails>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FrostToggle
            client={client}
            disabled={!canEditActivationMethod}
            isFrost={isFrostOn}
            label="Frost is turned ON."
          />
          <Typography.Title level={5}>Sign Up</Typography.Title>
          <StyledSignUpContainer>
            <StyledSignUpLiveContainer>Current sign up form </StyledSignUpLiveContainer>
            <PreviewButton
              client={{
                fanRegistrationAppearance: appearance,
                fanRegistrationFields: enabledFanRegistrationFields,
                fanRegistrationCustomFields: enabledFanRegistrationCustomFields,
                ...client,
              }}
              customFields={currentFanCustomRegistrationFields}
              type="primary"
            >
              View
            </PreviewButton>
          </StyledSignUpContainer>
        </Col>
        <Col span={24}>
          <Card
            extra={
              <Button
                disabled={isUpdating || hasInvalidBackgrounds}
                loading={isUpdating}
                onClick={onPublish}
                type="primary"
              >
                Publish
              </Button>
            }
            title="Customization"
            type="inner"
          >
            <Card.Grid hoverable={false} style={{ width: '50%', padding: 0 }}>
              <CustomFields
                allFieldKeys={allFieldKeys}
                customFanFields={currentFanCustomRegistrationFields}
                customFieldCount={customFields?.length || 0}
                fields={currentFanRegistrationFields}
                form={form}
                onCustomFieldChange={setCustomFanRegistrationFields}
                onFieldChange={setFanRegistrationFields}
                setCustomFormFields={setCustomFormFields}
              />

              <StyledLogoUpload
                fileName="logo"
                image={{ file: logoFile, uploadedUrl: appearance.logo }}
                imageOptions={{
                  transformations: { force: true, circle: true, crop: false },
                }}
                onChange={setLogoFile}
                points={[
                  'Suggested dimensions: 400 x 400 pixels',
                  'The image must be a .png, or .jpeg file',
                  'Maximum file size: 1MB',
                ]}
                title={<b>Logo Image</b>}
              />
              <StyledPortraitUpload
                fileName="background portrait"
                image={{
                  file: portraitFile,
                  uploadedUrl: appearance.backgroundPortrait,
                }}
                imageOptions={{
                  transformations: {
                    // aspect ration corresponds to the suggested dimensions below of 1242 x 2688
                    crop: { aspectRatio: 9 / 19.5, force: true },
                  },
                }}
                onChange={setPortraitFile}
                points={[
                  'Upload an image and select a portrait size',
                  'This background will be what most Members will see on mobile devices',
                  'Suggested dimensions: 1242 x 2688 pixels',
                  'The image must be a .png, or .jpeg file',
                  'Maximum file size: 1MB',
                ]}
                title={
                  <>
                    <b>Background Image</b>
                    <StyledStepRow>
                      <StyledStepText>Step 1:</StyledStepText>
                      <b>Portrait (Required)</b>
                    </StyledStepRow>
                  </>
                }
              />
              <StyledLandscapeUpload
                fileName="background landscape"
                image={{
                  file: landscapeFile,
                  uploadedUrl: appearance.backgroundLandscape,
                }}
                imageOptions={{
                  transformations: {
                    // aspect ration corresponds to the suggested dimensions below of 3072 x 1920
                    crop: { aspectRatio: 16 / 10, force: true },
                  },
                }}
                onChange={setLandscapeFile}
                points={[
                  'Upload a photo and select a landscape size',
                  'This background will be what most Members will see on desktop devices',
                  'Suggested dimensions: 3072 x 1920 pixels',
                  'The image must be a .png, or .jpeg file',
                  'Maximum file size: 1MB',
                ]}
                title={
                  <StyledStepRow>
                    <StyledStepText>Step 2:</StyledStepText>
                    <b>Landscape (Required)</b>
                  </StyledStepRow>
                }
              />
              <Card.Grid hoverable={false} style={{ width: '100%', padding: 0 }}>
                <DisplayModeRadioGroup onChange={setDisplayMode} value={displayMode || appearance.displayMode} />
              </Card.Grid>
              <OpacitySlider
                onChange={setFanRegistrationOverlayOpacity}
                value={fanRegistrationOverlayOpacity || appearance.overlayOpacity}
              />
            </Card.Grid>
            <Card.Grid
              hoverable={false}
              style={{
                width: '50%',
              }}
            >
              <Preview
                client={{
                  ...client,
                  fanRegistrationAppearance: previewAppearance,
                  fanRegistrationFields: enabledFanRegistrationFields,
                  fanRegistrationCustomFields: enabledFanRegistrationCustomFields,
                  terms: [],
                }}
                customFields={customFieldsForPreview}
              />
            </Card.Grid>
          </Card>
        </Col>
      </Row>
      <Modal
        footer={
          <Button onClick={() => setShowErrorModal(false)} type="primary">
            Ok
          </Button>
        }
        onCancel={() => setShowErrorModal(false)}
        open={showErrorModal}
        title="System error"
      >
        Something went wrong; please try again.
      </Modal>
    </StyledDetails>
  )
}
