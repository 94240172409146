import { notification } from 'antd'

export function useToastMessage() {
  const showNotification = (message: any, success = true) => {
    notification[success ? 'success' : 'error']({
      message,
      placement: 'bottomRight',
      duration: 3,
    })
  }

  return { showNotification }
}
