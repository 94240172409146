import { ApiError } from '@community_dev/requests'
import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query'
import { notification } from 'antd'

import { postPresignedUrl, PostPresignedUrlResponse, putFileToS3, PutFileToS3Arguments } from 'api/numberImports'

type UseUploadNumberImport = {
  postPresignedUrl: UseMutateAsyncFunction<PostPresignedUrlResponse, ApiError, { clientId: string }, unknown>
  isLoading: boolean
  postFile: UseMutateAsyncFunction<string, ApiError, PutFileToS3Arguments, unknown>
}

export const useUploadNumberImport = (): UseUploadNumberImport => {
  const { mutateAsync: postUrl, isLoading: isPostingUrlLoading } = useMutation<
    PostPresignedUrlResponse,
    ApiError,
    { clientId: string }
  >({
    mutationFn: postPresignedUrl,
    onError: () => {
      notification.error({
        message: 'Error on presigned url POST',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })

  const { mutateAsync: postFile, isLoading: isPostingFileToS3Loading } = useMutation<
    string,
    ApiError,
    PutFileToS3Arguments,
    unknown
  >({
    mutationFn: (args: PutFileToS3Arguments) => putFileToS3(args),
    onError: () => {
      notification.error({
        message: 'Error on uploading file to S3',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })

  const isLoading = isPostingFileToS3Loading || isPostingUrlLoading
  return {
    postPresignedUrl: postUrl,
    isLoading,
    postFile: postFile,
  }
}
