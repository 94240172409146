import { BORDER_RADIUS, SPACING } from '@community_dev/pixels'
import { uuid4 } from '@sentry/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Frost } from './Frost'
import { PreviewButton } from './PreviewButton'

import { SubscriptionDataField } from 'api/subscriptionData'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 900px;
  align-items: center;
  justify-content: center;
`

const StyledPreviewButton = styled(PreviewButton)`
  align-self: flex-end;
  margin-bottom: ${SPACING[5]};
`

const StyledPortraitIframe = styled(Frost)`
  border: none;
  width: 320px;
  height: 800px;
  border-radius: ${BORDER_RADIUS[2]};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  margin: ${SPACING[2]} auto;
`

export const Preview = ({
  client,
  customFields = [],
}: {
  client: any
  customFields: SubscriptionDataField[]
}): JSX.Element => {
  const iframeRef = useRef()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const listener = (event: any) => {
      if (event.data.action === 'ready') {
        setReady(true)
        window.removeEventListener('message', listener)
      }
    }

    window.addEventListener('message', listener)
  }, [])

  useEffect(() => {
    if (ready && iframeRef.current) {
      const message = { action: 'setClient', payload: client }
      // @ts-expect-error TS(2339): Property 'contentWindow' does not exist on type 'n... Remove this comment to see the full error message
      iframeRef.current.contentWindow.postMessage(message, '*')
    }
  }, [ready, client])

  const customFieldsForPreview = useMemo(() => {
    const existingKeys: string[] = []

    return customFields.map((field: any) => {
      let fieldKey = field.key

      if (existingKeys.includes(fieldKey)) {
        fieldKey = `${fieldKey}_${uuid4()}`
      } else {
        existingKeys.push(fieldKey)
      }

      return {
        ...field,
        key: fieldKey,
      }
    })
  }, [customFields])

  return (
    <>
      <StyledContainer>
        <StyledPreviewButton client={client}>View Landscape</StyledPreviewButton>
        <StyledPortraitIframe client={client} customFields={customFieldsForPreview} />
      </StyledContainer>
    </>
  )
}
