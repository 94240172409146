import { Button as PixelsButton, composeValidators, email, required } from '@community_dev/pixels'
import { RoleGroup } from '@community_dev/types/lib/api/v1/Roles'
import { Input, Space, Typography } from 'antd'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { RoleDetails } from './RoleDetails'

import { Role } from 'api/clients'
import { StyledModal } from 'shared/components/Modal'
import { RoleSelect } from 'shared/components/RoleSelect'

const StyledRoleDetails = styled(RoleDetails)`
  margin-top: 35px;
`

const StyledInput = styled(Input)`
  width: 275px;
`

type InviteFormProps = {
  onSubmit({ email }: { email: string }): any
  roles: Role[]
  isVisible: boolean
  setIsVisible(arg: boolean): any
  title: string
  buttonName: string
  hasDropdown?: boolean
  isButtonDisabled?: boolean
  buttonDisabledMessage?: string
}

export function InviteForm({
  onSubmit,
  roles,
  isVisible,
  setIsVisible,
  title,
  buttonName = 'Invite',
  hasDropdown = true,
  isButtonDisabled = false,
  buttonDisabledMessage = '',
}: InviteFormProps): JSX.Element {
  const primaryRoles = roles.filter((role) => role.group === RoleGroup.PRIMARY)
  const defaultRole = primaryRoles[0]
  return (
    <Form<{ email: string; role?: Role }>
      initialValues={{
        role: defaultRole,
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <StyledModal centered footer={null} onCancel={() => setIsVisible(false)} open={isVisible} title={title}>
          <form onSubmit={handleSubmit}>
            <Space
              css={`
                width: 100%;
              `}
              direction="vertical"
            >
              <Space
                align="end"
                css={`
                  width: 100%;
                  .ant-space-item:first-child {
                    flex: 1;
                  }
                `}
                direction="horizontal"
              >
                <Field name="email" validate={composeValidators(required, email)}>
                  {({ input, meta }) => (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="email">Email Address</label>
                      <StyledInput id="email" placeholder="Invite someone..." size="large" type="email" {...input} />
                      {!meta.active && meta.touched && meta.modified && meta.error && (
                        <div>
                          <Typography.Text aria-live="polite" type="danger">
                            Email {meta.error}
                          </Typography.Text>
                        </div>
                      )}
                      {hasDropdown && (
                        <Field name="role">
                          {({ input }) => (
                            <label>
                              Role
                              <RoleSelect
                                onChange={input.onChange}
                                outline={true}
                                roles={primaryRoles}
                                value={input.value}
                              />
                            </label>
                          )}
                        </Field>
                      )}
                    </>
                  )}
                </Field>
                <PixelsButton
                  css={`
                    width: auto !important;
                  `}
                  disabled={isButtonDisabled}
                  type="submit"
                >
                  {buttonName}
                </PixelsButton>
              </Space>
              {isButtonDisabled && <Typography.Text type="danger">{buttonDisabledMessage}</Typography.Text>}
              <StyledRoleDetails roles={values?.role ? [values?.role] : []} />
            </Space>
          </form>
        </StyledModal>
      )}
    />
  )
}
