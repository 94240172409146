import { Api } from '@community_dev/types'
import { useQueryClient } from '@tanstack/react-query'
import { Typography, Spin } from 'antd'
import styled from 'styled-components'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { useIsFrostOn } from '../../hooks/useIsFrostOn'
import { ONBOARDING_MESSAGE_TITLES, REPLY_Y_ONBOARDING_LEGAL_LABEL } from '../constants'

import { OnboardingMessages } from './EditSubscriptionMessage'

import { putFanOnboardingMessage } from 'api/clients'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES } from 'constants/queries'
import { useHasCapability } from 'hooks/useCapabilities'
import { InlineEditMessage } from 'shared/components/InlineEditMessage'
const StyledSection = styled.div`
  margin-bottom: 10px;
`

export type OnboardingWelcomeMessage = CamelCasedPropertiesDeep<Api.OnboardingWelcomeMessage>
type EditLegalOnboardingMessageProps = {
  client: any
  message: OnboardingWelcomeMessage
  messages: OnboardingMessages
}
export const EditLegalOnboardingMessage = ({
  client,
  message,
  messages,
}: EditLegalOnboardingMessageProps): JSX.Element => {
  const queryClient = useQueryClient()
  const { id, label, text, maxLength, maxSegmentCount } = message
  const { isFrostOn, isLoading } = useIsFrostOn(client.id)

  const replyYLegalMessage = messages.find(({ label }: any) => label === REPLY_Y_ONBOARDING_LEGAL_LABEL)

  const canUpdateLegal = useHasCapability(CAPABILITIES.ADMIN.ACTIVATION_MODE.WRITE)

  const invalidateClient = (id: any) => {
    queryClient.invalidateQueries([QUERIES.CLIENT, { id }])
  }

  if (isLoading) return <Spin />

  const isReplyYOn = !isFrostOn && replyYLegalMessage
  return (
    <>
      <StyledSection>
        {replyYLegalMessage && (
          <Typography.Title
            level={5}
            style={{
              marginBottom: 5,
            }}
          >
            Message
          </Typography.Title>
        )}
        <InlineEditMessage
          defaultValue={text}
          maxLength={maxLength}
          maxSegment={maxSegmentCount}
          name={ONBOARDING_MESSAGE_TITLES[label]}
          onSuccess={invalidateClient}
          prepare={(value) => ({
            id: client.id,
            messageId: id,
            body: { text: value },
          })}
          requiredSuffix={isReplyYOn ? replyYLegalMessage.text : ''}
          updateEntity={putFanOnboardingMessage}
        />
      </StyledSection>
      {isReplyYOn && (
        <StyledSection>
          <Typography.Title
            level={5}
            style={{
              marginBottom: 5,
            }}
          >
            Legal Language
          </Typography.Title>
          <InlineEditMessage
            defaultValue={replyYLegalMessage.text}
            disabled={!canUpdateLegal}
            disabledTooltip="You do not have the permissions to change this. If you need to update this please reach out to the Legal team."
            name={ONBOARDING_MESSAGE_TITLES[replyYLegalMessage.label]}
            onSuccess={invalidateClient}
            prepare={(value) => ({
              id: client.id,
              messageId: replyYLegalMessage.id,
              body: { text: value },
            })}
            updateEntity={putFanOnboardingMessage}
          />
        </StyledSection>
      )}
    </>
  )
}
