import { Api } from '@community_dev/types'
import { useQueryClient } from '@tanstack/react-query'
import { Typography } from 'antd'
import styled from 'styled-components'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ONBOARDING_MESSAGE_TITLES } from '../constants'

import { putFanOnboardingMessage } from 'api/clients'
import { QUERIES } from 'constants/queries'
import { InlineEditMessage } from 'shared/components/InlineEditMessage'

const StyledSection = styled.div`
  margin-bottom: 10px;
`

type SubscriptionConfirmationMessage = CamelCasedPropertiesDeep<Api.OnboardingSubscriptionConfirmationMessage>

export type OnboardingMessages = CamelCasedPropertiesDeep<Api.OnboardingMessage[]>

type EditLegalOnboardingMessageProps = {
  message: SubscriptionConfirmationMessage
}

export const EditSubscriptionMessage = ({ message }: EditLegalOnboardingMessageProps): JSX.Element => {
  const queryClient = useQueryClient()
  const { clientId, id, label, text, maxLength, maxSegmentCount, tcpa } = message

  const invalidateClient = (id: any) => {
    queryClient.invalidateQueries([QUERIES.CLIENT, { id }])
  }

  return (
    <>
      <StyledSection>
        <Typography.Title
          level={5}
          style={{
            marginBottom: 5,
          }}
        >
          Message
        </Typography.Title>

        <InlineEditMessage
          defaultValue={text}
          maxLength={maxLength}
          maxSegment={maxSegmentCount}
          name={ONBOARDING_MESSAGE_TITLES[label]}
          onSuccess={invalidateClient}
          prepare={(value) => ({
            id: clientId,
            messageId: id,
            body: { text: value },
          })}
          requiredSuffix={tcpa}
          updateEntity={putFanOnboardingMessage}
        />
      </StyledSection>
    </>
  )
}
