export const FROST_ACTIVATION_MODE = 'web'
export const REPLY_Y_ACTIVATION_MODE = 'text'

// Custom Registration Field types
export const TEXT_TYPE = 'text'
export const SELECT_TYPE = 'select'
export const DATE_TYPE = 'date'
export const EMAIL_TYPE = 'email'

// Core field names
export const FIRST_NAME = 'first_name'
export const LAST_NAME = 'last_name'
export const GENDER_NAME = 'gender'
export const BIRTHDAY_NAME = 'dob'
export const CITY_NAME = 'location'
export const EMAIL_NAME = 'email'

export const CUSTOM_FIELD_LIMIT = 50

export const TYPE_LABELS = {
  [TEXT_TYPE]: 'Text Input',
  [SELECT_TYPE]: 'Dropdown',
  [DATE_TYPE]: 'Date',
  [EMAIL_TYPE]: 'Text Input',
}

export const CUSTOM_FIELD_TYPES = {
  STRING: 'string',
  DATE: 'date',
  DATETIME: 'datetime',
  INTEGER: 'integer',
  DECIMAL2: 'decimal2',
  DECIMAL4: 'decimal4',
  BOOLEAN: 'boolean',
  SELECT: 'select',
}

export const CUSTOM_FIELD_TYPE_LABELS = {
  [CUSTOM_FIELD_TYPES.STRING]: 'Text Input',
  [CUSTOM_FIELD_TYPES.DATE]: 'Date',
  [CUSTOM_FIELD_TYPES.DATETIME]: 'Date/time',
  [CUSTOM_FIELD_TYPES.INTEGER]: 'Numeric Value',
  [CUSTOM_FIELD_TYPES.DECIMAL2]: 'Numeric Value',
  [CUSTOM_FIELD_TYPES.DECIMAL4]: 'Numeric Value',
  [CUSTOM_FIELD_TYPES.BOOLEAN]: 'True / False',
  [CUSTOM_FIELD_TYPES.SELECT]: 'Dropdown',
}

export const SIGN_UP_FORM = 'sign-up-form'
export const SIGN_UP_FORM_FIELDS = 'sign-up-form-fields'
