import { FROST_ACTIVATION_MODE, REPLY_Y_ACTIVATION_MODE } from '../constants'

import { useClientSettings } from './useClientSettings'

type IsFrostOn = {
  isFrostOn: boolean
  isLoading: boolean
}

export const useIsFrostOn = (clientId: string): IsFrostOn => {
  const { settings, isLoading } = useClientSettings(clientId)
  // FROST_ACTIVATION_MODE = 'web'
  // REPLY_Y_ACTIVATION_MODE = 'text'
  // if settings.fanActivationMode is any other value we set it to FROST_ACTIVATION_MODE/'web'
  const fanActivationMode =
    settings?.fanActivationMode === REPLY_Y_ACTIVATION_MODE ? REPLY_Y_ACTIVATION_MODE : FROST_ACTIVATION_MODE

  const isFrostOn = fanActivationMode === FROST_ACTIVATION_MODE

  return { isFrostOn, isLoading }
}
