const namespace = 'snackbar'

export const PUSH_SNACK = `${namespace}/PUSH_SNACK`
export const DISMISS_SNACK = `${namespace}/DISMISS_SNACK`
export const TRIGGER_SNACK = `${namespace}/TRIGGER_SNACK`

export const PUSH_SNACKBAR = `${namespace}/PUSH_SNACKBAR`
export const DISMISS_SNACKBAR = `${namespace}/DISMISS_SNACKBAR`
export const TRIGGER_SNACKBAR = `${namespace}/TRIGGER_SNACKBAR`

export const OPEN_SNACKBAR = `${namespace}/OPEN_SNACKBAR`
export const CLOSE_SNACKBAR = `${namespace}/CLOSE_SNACKBAR`

export const CLEAN_SNACKBAR = `${namespace}/CLEAN_SNACKBAR`
export const RESET_SNACKBAR = `${namespace}/RESET_SNACKBAR`
