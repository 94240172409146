import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'
// QUERIES.LEADER

export function updateClient({ clientId, body }: any) {
  return request(ENDPOINTS.CLIENTS.INDIVIDUAL.replace(':id', clientId), {
    method: HTTP.PATCH,
    body,
  })
}

export function getClientTerms(clientId: string): Promise<any> {
  return request(ENDPOINTS.CLIENTS.TERMS.replace(':client_id', clientId), {
    method: HTTP.GET,
    // Terms will return 404 when they do not exist
    reportErrors: false,
  })
    .then((resp) => resp.data.terms)
    .catch(() => [])
}

export function postOrPatchClientTerms({ clientId, body, hasTerms }: any) {
  return request(ENDPOINTS.CLIENTS.TERMS.replace(':client_id', clientId), {
    method: hasTerms ? HTTP.PUT : HTTP.POST,
    body,
  })
}

export function deleteClientTerms({ clientId, body }: any) {
  return request(ENDPOINTS.CLIENTS.TERMS.replace(':client_id', clientId), {
    method: HTTP.DELETE,
    body,
  })
}
