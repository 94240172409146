import { Role } from './clients'

import { ENDPOINTS } from 'constants/endpoints'
import { Admin, normalizeAdmins } from 'screens/AdminsScreen/Admins/queries'
import { request } from 'utils/request'
import { route } from 'utils/router'

export function getAdminRoles(): Promise<Role[]> {
  return request(route(ENDPOINTS.CAPABILITIES.ROLES_ADMINS))
}

export async function getAdmins(): Promise<Admin[]> {
  const { data } = await request(route(ENDPOINTS.ADMINS.LIST))
  return normalizeAdmins(data)
}

export function deleteAdmin({ seatMappingId }: { seatMappingId: string }): Promise<any> {
  return request(route(ENDPOINTS.CAPABILITIES.SEAT_MAPPINGS_ADMINS, { seatMappingId }), {
    method: 'DELETE',
  })
}

export function addAdmin(a: any) {
  return request(ENDPOINTS.ADMINS.LIST, {
    method: 'POST',
    body: {
      admin: {
        ...a,
        role_id: a.role.id,
      },
    },
  })
}
