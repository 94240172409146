import { ToolOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-components'
import { COLORS } from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import { Input, notification, Typography } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

import { deleteMemberByFanId, deleteMemberByPhoneNumber } from 'api/members'
import { ButtonModalPrompt } from 'components/ButtonModalPrompt'
import { CAPABILITIES } from 'constants/capabilities'
import { useHasCapability } from 'hooks/useCapabilities'
import { SidebarLayout } from 'shared/layouts'

const StyledPage = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const StyledPageHeader = styled(PageHeader)`
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
`
const StyledDeleteMemberContainer = styled.div`
  padding: 15px 20px 5px;
  max-width: 800px;
`
const SectionContainer = styled.div`
  display: flex;
  background-color: ${COLORS.APP_BACKGROUND_LEVEL_2};
  padding: 20px;
  flex-direction: column;
`

const InputContainer = styled.div`
  display: flex;
`

const ButtonContainer = styled.div`
  margin-left: 20px;
`

const StyledUnorderedList = styled.ul`
  margin: 0;
`
export const MemberScreen = (): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [fanId, setFanId] = useState<string>('')
  const { mutate: deleteByPhoneNumber } = useMutation(deleteMemberByPhoneNumber, {
    onError: () => {
      notification.error({
        message: 'Could not delete member. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
    onSuccess: () => {
      notification.success({
        message: 'Member deleted successfully.',
        placement: 'bottomRight',
        duration: 2,
      })
      setPhoneNumber('')
    },
  })

  const { mutate: deleteByFanId } = useMutation(deleteMemberByFanId, {
    onError: () => {
      notification.error({
        message: 'Could not delete subscription. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
    onSuccess: () => {
      notification.success({
        message: 'Subscription deleted successfully.',
        placement: 'bottomRight',
        duration: 2,
      })
      setFanId('')
    },
  })

  const canDeleteMember = useHasCapability(CAPABILITIES.ADMIN.FANS.WRITE)

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target
    const phoneNumberPattern = /^[0-9\b]+$/ // Allows numbers and backspace
    if (value === '' || phoneNumberPattern.test(value)) {
      setPhoneNumber(value)
    }
  }

  return (
    <SidebarLayout>
      <StyledPage>
        <StyledPageHeader
          style={{ background: COLORS.APP_BACKGROUND_LEVEL_3, position: 'sticky', top: 0, zIndex: 1 }}
          title={
            <>
              Member Utilities <ToolOutlined />
            </>
          }
        />
        <StyledDeleteMemberContainer>
          <SectionContainer>
            <Typography.Title level={5}>Delete Member from all Leaders using Member's phone number</Typography.Title>
            <StyledUnorderedList>
              <li>
                <Typography.Paragraph>
                  Phone number should be entered without any special characters. EX: 12223334444
                </Typography.Paragraph>
              </li>
              <li>
                <Typography.Paragraph>Only deletes SMS subscriptions</Typography.Paragraph>
              </li>
            </StyledUnorderedList>
            <InputContainer>
              <Input
                aria-label="member phone number"
                onChange={handlePhoneNumberChange}
                placeholder="12223334444"
                value={phoneNumber}
              />
              <ButtonContainer>
                <ButtonModalPrompt
                  buttonText="Delete"
                  danger
                  disabled={!phoneNumber}
                  modalText={`This action cannot be undone. Are you sure you want to delete "${phoneNumber}" from ALL Leaders?`}
                  modalTitle="WARNING"
                  onClick={() => deleteByPhoneNumber(phoneNumber)}
                  validationText={phoneNumber}
                />
              </ButtonContainer>
            </InputContainer>
          </SectionContainer>
        </StyledDeleteMemberContainer>
        <StyledDeleteMemberContainer>
          <SectionContainer>
            <Typography.Title level={5}>Delete Member using its fan id</Typography.Title>
            <StyledUnorderedList>
              <li>
                <Typography.Paragraph>
                  Fan id should include dashes. EX: 5e8223cc-3cfc-4da1-8a3b-1d203c256c01
                </Typography.Paragraph>
              </li>
            </StyledUnorderedList>
            <InputContainer>
              <Input
                aria-label="member fan id"
                onChange={(e) => setFanId(e.target.value)}
                placeholder="5e8223cc-3cfc-4da1-8a3b-1d203c256c01"
                value={fanId}
              />
              <ButtonContainer>
                <ButtonModalPrompt
                  buttonText="Delete"
                  danger
                  disabled={!canDeleteMember || !fanId}
                  modalText={`This action cannot be undone. Are you sure you want to delete the "${fanId}" subscription?`}
                  modalTitle="WARNING"
                  onClick={() => deleteByFanId(fanId)}
                  validationText={fanId}
                />
              </ButtonContainer>
            </InputContainer>
          </SectionContainer>
        </StyledDeleteMemberContainer>
      </StyledPage>
    </SidebarLayout>
  )
}
