import * as types from './constants'

export const pushSnackbar = (payload: any) => ({
  type: types.PUSH_SNACKBAR,
  payload,
})

export const dismissSnackbar = () => ({ type: types.DISMISS_SNACKBAR })

export const triggerSnackbar = () => ({ type: types.TRIGGER_SNACKBAR })

export const cleanSnackbar = () => ({ type: types.CLEAN_SNACKBAR })

export const openSnackbar = () => ({ type: types.OPEN_SNACKBAR })

export const closeSnackbar = () => ({ type: types.CLOSE_SNACKBAR })

export const resetSnackbar = () => ({ type: types.RESET_SNACKBAR })
