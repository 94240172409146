import * as types from './constants'

export const login = (payload: any) => ({
  type: types.LOGIN,
  payload,
})

export const loginSuccess = (payload: any) => ({
  type: types.LOGIN_SUCCESS,
  payload,
})

export const loginError = () => ({ type: types.LOGIN_ERROR })

export const resetLogin = () => ({ type: types.RESET_LOGIN })
