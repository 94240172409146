import * as types from './constants'

const initialState = {
  token: '',
}

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_AUTH:
      return {
        ...state,
        ...action.payload,
      }
    case types.RESET_AUTH:
      return initialState
    default:
      return state
  }
}

export default authReducer
