import React from 'react'
import { connect } from 'react-redux'

import { dismissSnackbar, resetSnackbar, triggerSnackbar } from 'redux/snackbar/actions'
import { Snackbar } from 'shared/components/base'
import './AppSnackbar.scss'

class AppSnackbar extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'activeId' does not exist on type 'Readon... Remove this comment to see the full error message
    const { activeId, items, opened } = this.props
    if (items.length === 0) return null
    return (
      <div className="AppSnackbar-root">
        {items.map((item: any) => {
          const { id, level, message, position, trigger } = item
          return (
            <Snackbar
              // @ts-expect-error TS(2769): No overload matches this call.
              active={opened && id === activeId}
              key={id}
              // @ts-expect-error TS(2339): Property 'dismissSnackbar' does not exist on type ... Remove this comment to see the full error message
              onDismiss={this.props.dismissSnackbar}
              // @ts-expect-error TS(2339): Property 'triggerSnackbar' does not exist on type ... Remove this comment to see the full error message
              onTrigger={this.props.triggerSnackbar}
              {...{ level, message, position, trigger }}
            />
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  items: state.snackbar.items,
  opened: state.snackbar.opened,
  activeId: (state.snackbar.items[0] || {}).id,
  location: state.router.location,
})

export default connect(mapStateToProps, {
  triggerSnackbar,
  dismissSnackbar,
  resetSnackbar,
})(AppSnackbar)
