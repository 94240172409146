import { DropdownArrowIcon, Menu, InfoIconFilled, COLORS, SPACING } from '@community_dev/pixels'
import { Tooltip } from 'antd'
import styled, { css, useTheme } from 'styled-components'

import {
  CUSTOM_FIELD_LIMIT,
  CUSTOM_FIELD_TYPES,
  CUSTOM_FIELD_TYPE_LABELS,
  SELECT_TYPE,
  TEXT_TYPE,
  TYPE_LABELS,
} from '../constants'
import { FormFieldValue } from '../types'

import AddIcon from './icons/add.svg?react'
import CalendarIcon from './icons/calendar.svg?react'
import CheckmarkIcon from './icons/checkmark.svg?react'
import DropdownIcon from './icons/dropdown.svg?react'
import HashIcon from './icons/hash.svg?react'
import InfoIcon from './icons/info.svg?react'

import { SubscriptionDataField } from 'api/subscriptionData'

const StyledTrigger = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  svg {
    margin-left: 8px;
    width: 12px;
  }
`

const DisabledMenu = styled.div`
  border: 1px solid rgb(218, 218, 218);
  border-radius: 4px;
  display: inline-block;
  padding: 8px;
  opacity: 0.7;
`

const FieldType = styled.div`
  color: ${COLORS.SUBTEXT};
  font-size: 12px;
  line-height: 19px;
`
const FieldNameWrap = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.TEXT};
  font-size: 14px;
  line-height: 20px;

  svg {
    margin-right: 8px;
    width: 12px;
    flex-shrink: 0;
  }
`

const MenuItem = styled(Menu.Item)`
  :hover ${FieldType} {
    color: ${COLORS.TEXT};
  }

  [role='menuitem'] {
    padding-top: ${SPACING[2]};
    padding-bottom: ${SPACING[2]};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`

export const FIELD_TYPE_ICON_MAP = {
  [CUSTOM_FIELD_TYPES.STRING]: InfoIcon,
  [CUSTOM_FIELD_TYPES.INTEGER]: HashIcon,
  [CUSTOM_FIELD_TYPES.DECIMAL2]: HashIcon,
  [CUSTOM_FIELD_TYPES.DECIMAL4]: HashIcon,
  [CUSTOM_FIELD_TYPES.DATE]: CalendarIcon,
  [CUSTOM_FIELD_TYPES.DATETIME]: CalendarIcon,
  [CUSTOM_FIELD_TYPES.BOOLEAN]: CheckmarkIcon,
  [CUSTOM_FIELD_TYPES.SELECT]: DropdownIcon,
}

type FieldSelectProps = {
  customFanFields: SubscriptionDataField[]
  customFieldCount: number
  newFieldCount: number
  maxEnabled?: number
  onCreate(defaultValue?: FormFieldValue, insertIndex?: number | undefined): void
  onSelect(field: SubscriptionDataField): void
  onCustomFieldChange: (customFields?: SubscriptionDataField[]) => void
}

export function FieldSelect({
  customFanFields,
  customFieldCount,
  newFieldCount,
  maxEnabled = 3,
  onCreate,
  onSelect,
  onCustomFieldChange,
}: FieldSelectProps): JSX.Element {
  const { COLORS } = useTheme() || {}

  const outlineCss = {
    border: `1px solid ${COLORS?.BORDERS}`,
    borderRadius: '4px',
    display: 'inline-block',
    padding: '8px',
  }

  const enabled =
    customFanFields.filter(
      (field) =>
        // filter out string fields since those come through newFieldCount
        field.valueType !== 'string' && field.fanEnabledStatus !== 'not_enabled',
    ).length +
      newFieldCount <
    maxEnabled

  if (!enabled) {
    return (
      <DisabledMenu>
        <StyledTrigger>
          <span>Add Field </span>
          <Tooltip
            align={{ offset: [0, 10] }}
            placement="top"
            title={<span>Only {maxEnabled} additional fields max</span>}
          >
            <span style={{ display: 'flex', alignItems: 'center', height: '22px' }}>
              <InfoIconFilled />
            </span>
          </Tooltip>
        </StyledTrigger>
      </DisabledMenu>
    )
  }

  return (
    <div data-testid="field-select">
      <div style={outlineCss}>
        <Menu
          style={{ left: '-9px', top: '125%' }}
          trigger={
            <StyledTrigger>
              Add Field
              <DropdownArrowIcon />
            </StyledTrigger>
          }
        >
          {customFanFields
            .sort((field1, field2) => field1.name.localeCompare(field2.name))
            .map((field) => {
              const isDropdownField = field.valueType === 'string' && field.valueOptions
              const Icon = isDropdownField ? FIELD_TYPE_ICON_MAP[SELECT_TYPE] : FIELD_TYPE_ICON_MAP[field.valueType]
              const content = (
                <div>
                  <FieldType>
                    {isDropdownField ? TYPE_LABELS[SELECT_TYPE] : CUSTOM_FIELD_TYPE_LABELS[field.valueType]}
                  </FieldType>
                  <FieldNameWrap>
                    {Icon && <Icon />} {field.name}
                  </FieldNameWrap>
                </div>
              )
              if (field.fanEnabledStatus === 'is_enabled_required') {
                return (
                  <MenuItem disabled={true} divider key={field.key}>
                    {content}
                  </MenuItem>
                )
              }
              return (
                <MenuItem
                  divider
                  key={field.key}
                  onClick={() => {
                    // special case for string fields
                    if (field.valueType === 'string') {
                      // change fanEnabledStatus of matching field
                      const newCustomFanFields = customFanFields.map((f) => {
                        if (f.key === field.key) {
                          return {
                            ...f,
                            fanEnabledStatus: 'is_enabled_required' as const,
                          }
                        }
                        return f
                      })
                      onCustomFieldChange(newCustomFanFields)
                      // add field to form as FormFieldValue
                      onCreate({
                        fanEnabledStatus: 'is_enabled_required',
                        field: field.name,
                        fieldKey: field.key,
                        options: field.valueOptions?.length
                          ? (field.valueOptions
                              .map((o) => {
                                if (o.status === 'inactive') {
                                  return false
                                }
                                return o.value || false
                              })
                              .filter((x) => x) as string[])
                          : null,
                      })
                    } else {
                      onSelect({
                        ...field,
                        fanEnabledStatus: 'is_enabled_required',
                      })
                    }
                  }}
                >
                  {content}
                </MenuItem>
              )
            })}
          <MenuItem
            disabled={customFieldCount >= CUSTOM_FIELD_LIMIT}
            divider
            key="dropdown-field"
            onClick={() => onCreate({ field: '', fanEnabledStatus: 'is_enabled_required', options: [] })}
          >
            <div>
              <FieldType>{TYPE_LABELS[SELECT_TYPE]}</FieldType>
              <FieldNameWrap>
                <AddIcon /> Create new field
              </FieldNameWrap>
            </div>
          </MenuItem>
          <MenuItem
            disabled={customFieldCount >= CUSTOM_FIELD_LIMIT}
            divider
            key="text-field"
            onClick={() => onCreate({ field: '', fanEnabledStatus: 'is_enabled_required', options: null })}
          >
            <div>
              <FieldType>{TYPE_LABELS[TEXT_TYPE]}</FieldType>
              <FieldNameWrap>
                <AddIcon /> Create new field
              </FieldNameWrap>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}
