import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { verify2FA } from 'redux/auth/actions'
import { Button } from 'shared/components/base'
import { SimpleInput } from 'shared/components/input'
import { parseAuthToken } from 'utils/api'

import './TokenRequest.scss'

class TokenRequest extends React.Component {
  static propTypes = {
    verify2FA: PropTypes.func,
  }

  input: any
  totpType: any

  state = {
    verificationCode: '',
  }

  constructor(props: any) {
    super(props)
    this.input = React.createRef()

    const token = parseAuthToken()
    this.totpType = token.okta_totp_type?.toLowerCase() === 'google' ? 'authentication application' : 'Okta Verify'
  }

  componentDidMount() {
    if (this.input && this.input.current) {
      this.input.current.input.current.focus()
    }
  }

  onChange = (e: any) => {
    this.setState({ verificationCode: e.target.value })
  }

  onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      this.submit()
    }
  }

  submit = () => {
    const { verificationCode } = this.state
    if (verificationCode.length === 0) return false

    // @ts-expect-error TS(2339): Property 'verify2FA' does not exist on type 'Reado... Remove this comment to see the full error message
    this.props.verify2FA({ verificationCode })
  }

  render() {
    return (
      <div className="TokenRequest">
        <div className="TokenRequest-title">Verification Code</div>
        <div className="TokenRequest-field">
          <div>
            <SimpleInput
              // @ts-expect-error TS(2322): Type '{ label: string; ref: any; onChange: (e: any... Remove this comment to see the full error message
              label={`Please enter your ${this.totpType} code`}
              maxLength={6}
              onChange={this.onChange}
              onKeyUp={this.onKeyUp}
              ref={this.input}
            />
          </div>
          <div className="TokenRequest-button">
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Button onClick={this.submit}>Submit</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { verify2FA })(TokenRequest)
