import { sortBy } from './sort'

import { SORT_ORDER } from 'constants/sort'

const toTrimmedPhoneNumber = ({ phoneNumber }: any) => phoneNumber.substring(1)

export function sortPhones(phoneNumbers: any, options = {}) {
  // @ts-expect-error TS(2339): Property order does not exist on type {}.
  const { order = SORT_ORDER.ASC } = options
  return sortBy(phoneNumbers, toTrimmedPhoneNumber, order)
}
