import { UserAddOutlined, UserOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useDebouncedValue } from '@community_dev/hooks'
import { required } from '@community_dev/pixels'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, Descriptions, Input, Modal, Row, Select, Space, Tabs, Typography, notification } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import day from 'dayjs'
import React, { useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import Measure from 'react-measure'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import { getClients, postCreateClient } from 'api/clients'
import { getAvailableNumbers } from 'api/numbers'
import { ASSIGNMENT } from 'constants/numbers'
import { QUERIES } from 'constants/queries'
import { ROUTES } from 'constants/routes'
import { DEFAULT_PAGE_SIZE } from 'constants/table'
import { DataProvider } from 'shared/components/DataProvider'
import { Table } from 'shared/components/v2Table'
import { SidebarLayout } from 'shared/layouts'
import { formatPhone } from 'utils/formatters'
import { formatName } from 'utils/name'
import { sortPhones } from 'utils/phone'
import { route } from 'utils/router'

const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'firstName',
    key: 'firstName',
    fixed: 'left',
    render: (text: any, client: any) => (
      <Space>
        <Avatar alt={text} icon={<UserOutlined />} src={client?.profileImageSmall?.url} />
        {formatName(client)}
      </Space>
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'masterPhoneNumber',
    key: 'masterPhoneNumber',
    render: formatPhone,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (text: any) => text && <a href={`mailto:${text}`}>{text}</a>,
  },
  {
    title: 'Account Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: any) => text && day(text).format('MM/DD/YYYY'),
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: any) => {
      return (
        <Typography.Text code copyable onClick={(e: any) => e.stopPropagation()}>
          {id}
        </Typography.Text>
      )
    },
  },
]

const paginationOptions = {
  position: ['none', 'bottomCenter'],
  pageSizeOptions: [50, 100],
  hideOnSinglePage: false,
  showSizeChanger: true,
}

const StyledContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .ant-table-row {
    cursor: pointer;
  }
`

const StyledNav = styled.div`
  margin: 0 -10px;

  .ant-tabs-nav {
    margin: 0;
  }
`

const StyledStatistics = styled.div`
  margin-top: -32px;
`

const AREA_CODE_DELAY = 500

export function ClientList() {
  const queryClient = useQueryClient()

  const history = useHistory()
  const [settings, setSettings] = useLocalStorageState('clients-list-settings', {
    assigned: ASSIGNMENT.ASSIGNED,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    filters: null,
  })
  const [height, setHeight] = useState(0)
  const [create, setCreate] = useState(false)
  const query = [QUERIES.CLIENTS, settings]
  const [areaCode, setAreaCode] = useState('')
  const debouncedAreaCode = useDebouncedValue(areaCode, AREA_CODE_DELAY, true)
  const { isInitialLoading, data: numbers } = useQuery(
    [QUERIES, { areaCode: debouncedAreaCode }],
    () => getAvailableNumbers(debouncedAreaCode),
    {
      enabled: debouncedAreaCode.length === 3,
    },
  )

  const sortedPhoneNumbers = useMemo(() => sortPhones(numbers), [numbers])

  const { mutate: createClient } = useMutation(postCreateClient, {
    onSuccess() {
      notification.success({
        message: 'Client created.',
        placement: 'bottomRight',
        duration: 2,
      })
      queryClient.invalidateQueries(query)
      setCreate(false)
    },
    onError() {
      notification.error({
        message: 'Could create client. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })

  function onTableSettingsChange(value: any) {
    setSettings({ ...value, assigned: settings.assigned })
  }

  function onSearch(value: any) {
    // @ts-expect-error TS(2345): Argument of type '{ search: any; page: number; pag... Remove this comment to see the full error message
    setSettings({ ...settings, search: value || '', page: 1, pageSize: DEFAULT_PAGE_SIZE })
  }

  function handleRowClick(record: any) {
    history.push(route(ROUTES.CLIENT.GENERAL, { id: record.id }))
  }

  function handleAssigned(value: any) {
    setSettings({ ...settings, assigned: value })
  }

  function handleCreateClient(values: any) {
    const body = Object.fromEntries(Object.keys(values).map((key) => [key, values[key].trim()]))
    return createClient({ body })
  }

  function handleAreaCode(evt: any) {
    setAreaCode(evt.target.value)
  }

  return (
    <SidebarLayout>
      {/* @ts-expect-error TS(2322): Type children: (isLoading, data: any) El... Remove this comment to see the full error message */}
      <DataProvider name={query} query={getClients(settings)}>
        {({ isLoading, data }: any) => {
          let pagination: any
          let clients: any = []
          if (data) {
            const [
              items,
              {
                paginationData: { pageNumber, pageSize, totalEntries },
              },
            ] = data
            clients = items
            pagination = {
              ...paginationOptions,
              total: totalEntries,
              current: pageNumber,
              pageSize,
            }
          }

          return (
            <>
              <PageHeader
                extra={
                  <Space align="start">
                    <Button
                      icon={<UserAddOutlined />}
                      onClick={() => setCreate((previous) => !previous)}
                      size="large"
                      type="primary"
                    >
                      New Client
                    </Button>
                    <div>
                      <Input.Search
                        allowClear
                        onChange={({ target: { value } }) => onSearch(value)}
                        onSearch={onSearch}
                        placeholder="Search"
                        size="large"
                        style={{ width: 300 }}
                        // @ts-expect-error TS(2339): Property 'search' does not exist on type '{ assign... Remove this comment to see the full error message
                        value={settings.search}
                      />
                    </div>
                  </Space>
                }
                title="Clients"
              >
                <StyledStatistics>
                  <Row>
                    <Descriptions column={3} size="small">
                      <Descriptions.Item label="Total">{pagination?.total || '-'}</Descriptions.Item>
                    </Descriptions>
                  </Row>
                </StyledStatistics>
                <StyledNav>
                  <Tabs activeKey={settings.assigned} onChange={handleAssigned}>
                    <Tabs.TabPane key={ASSIGNMENT.ASSIGNED} tab="Assigned Numbers" />
                    <Tabs.TabPane key={ASSIGNMENT.UNASSIGNED} tab="Unassigned Numbers" />
                  </Tabs>
                </StyledNav>
              </PageHeader>
              <Measure
                bounds
                onResize={(contentRect: any) => {
                  setHeight(contentRect.bounds.height)
                }}
              >
                {({ measureRef }: any) => (
                  <StyledContainer ref={measureRef}>
                    <Table
                      columns={COLUMNS}
                      // @ts-expect-error TS(2322): Type '{ settings: { assigned: string; page: number... Remove this comment to see the full error message
                      dataSource={clients}
                      loading={isLoading}
                      onRow={(record: any) => ({
                        onClick: () => handleRowClick(record),
                      })}
                      onSettingsChange={onTableSettingsChange}
                      pagination={pagination}
                      rowKey="id"
                      scroll={{ x: '100%', y: `${height - 140}px` }}
                      settings={settings}
                    />
                  </StyledContainer>
                )}
              </Measure>
            </>
          )
        }}
      </DataProvider>
      {create && (
        <Form
          onSubmit={handleCreateClient}
          render={({ handleSubmit, submitting, valid }) => (
            <Modal
              confirmLoading={submitting}
              okButtonProps={{ disabled: !valid }}
              okText="Invite"
              onCancel={() => {
                setCreate(false)
              }}
              onOk={handleSubmit}
              open={create}
              title="Create New Client"
            >
              <form onSubmit={handleSubmit}>
                <Field name="first_name" validate={required}>
                  {({ input, meta }) => (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="first_name">
                        First name
                        <Input id="first_name" type="text" {...input} />
                      </label>
                      {!meta.active && meta.touched && meta.modified && meta.error && (
                        <div>
                          <Typography.Text type="danger">First name {meta.error}</Typography.Text>
                        </div>
                      )}
                    </>
                  )}
                </Field>

                <Field name="last_name">
                  {({ input, meta }) => (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="last_name">
                        Last name
                        <Input id="last_name" type="text" {...input} />
                      </label>
                      {!meta.active && meta.touched && meta.modified && meta.error && (
                        <div>
                          <Typography.Text type="danger">Last name {meta.error}</Typography.Text>
                        </div>
                      )}
                    </>
                  )}
                </Field>

                <>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="area_code">
                    Area Code
                    <Input id="area_code" maxLength={3} onChange={handleAreaCode} type="number" />
                  </label>
                </>

                <Field name="master_number_sid" validate={required}>
                  {({ input, meta }) => (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="master_number_sid">
                        Phone number
                        <Select
                          id="master_number_sid"
                          {...input}
                          loading={isInitialLoading}
                          style={{ display: 'block', width: '100%' }}
                        >
                          {/* @ts-expect-error TS(2339): Property 'map' does not */}
                          {sortedPhoneNumbers?.map((i: any) => (
                            <Select.Option key={i.sid} value={i.sid}>
                              {formatPhone(i.phoneNumber)}
                            </Select.Option>
                          ))}
                        </Select>
                      </label>
                      {!meta.active && meta.touched && meta.modified && meta.error && (
                        <div>
                          <Typography.Text type="danger">Phone number {meta.error}</Typography.Text>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </form>
            </Modal>
          )}
        />
      )}
    </SidebarLayout>
  )
}
