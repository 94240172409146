/* eslint-disable */
import { call, cancel, fork, race, take } from 'redux-saga/effects'

export const takeEverytUntil = (pattern: any, until: any, saga: any, ...args: any[]) => fork(function* () {
  while (true) {
    const action = yield take(pattern)
    yield fork(function* () {
      // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
      yield race({ main: call(saga, ...args.concat(action)), ...until })
    })
  }
})

export const takeLatestUntil = (pattern: any, until: any, saga: any, ...args: any[]) => fork(function* () {
  let task
  while (true) {
    const action = yield take(pattern)
    if (task) yield cancel(task)
    task = yield fork(function* () {
      // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
      yield race({ main: call(saga, ...args.concat(action)), ...until })
    })
  }
})
