import { QueryObserverResult } from '@tanstack/react-query'
import isObject from 'lodash/isObject'
import snakeCase from 'lodash/snakeCase'

function arrayToString(values: any) {
  return Object.keys(values).reduce((acc, key) => {
    const name = snakeCase(key)
    if (!values[key]) return acc
    return acc.concat(values[key].map((value: any) => `${name}=${snakeCase(value)}`))
  }, [])
}

export function createDigitsCountResponse(values: any) {
  return Object.keys(values)
    .reduce((acc, name) => {
      if (!values[name]) return acc
      let value = values[name]
      value = isObject(value) ? arrayToString(value) : value
      // @ts-expect-error TS(2769): No overload matches this call.
      if (isObject(value)) return acc.concat(value)
      // Some fields contain [text], ignore those till we need to
      // snake case those too
      const key = name.includes('[') ? name : snakeCase(name)
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      acc.push(`${key}=${value}`)

      return acc
    }, [])
    .join('&')
}

export interface CombinedQueries {
  isInitialLoading: boolean
  isLoading: boolean
  errors: any[]
  isError: boolean
  queries: QueryObserverResult<any>[]
  [id: string]: any
}

export function combinedQueryState(queries: any): CombinedQueries {
  return Object.keys(queries).reduce(
    function reduceQueries(acc: CombinedQueries, key: string) {
      const query = queries[key]
      acc.isInitialLoading = acc.isInitialLoading || query.isInitialLoading
      acc.isLoading = acc.isLoading || query.isLoading
      acc.isError = acc.isError || query.isError
      acc[key] = query.data
      if (query.error) {
        acc.errors.push(query.error)
      }

      return acc
    },
    { isInitialLoading: false, isLoading: false, errors: [], isError: false, queries },
  )
}
