import { DownArrowIcon, Menu } from '@community_dev/pixels'
import { useMemo } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { Role } from 'api/clients'
import { CAPABILITIES } from 'constants/capabilities'
import { useHasCapability } from 'hooks/useCapabilities'
import { formatRoleName, isRoleDisabled } from 'utils/roles'

const StyledTrigger = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  svg {
    margin-left: 8px;
    width: 12px;
  }
`

const disabledStyle = css`
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
`

type RoleSelectProps = {
  value?: Role
  onChange(r: Role): any
  roles: Role[]
  outline?: boolean
  disabled?: boolean
}

export function RoleSelect({ value, onChange, roles = [], outline = false, disabled }: RoleSelectProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const userCanSelectProtectedRoles = useHasCapability(CAPABILITIES.ADMIN.PROTECTED_ROLE_SEAT.WRITE)
  const selectedName = useMemo(() => {
    if (value) {
      return value?.displayName || formatRoleName(value?.name)
    } else if (roles.length > 0) {
      return roles[0].displayName || formatRoleName(roles[0].name)
    } else {
      return 'Select a Role'
    }
  }, [roles, value])
  const outlineCss = {
    border: `1px solid ${COLORS?.BORDERS}`,
    borderRadius: '4px',
    display: 'inline-block',
    padding: '8px',
  }

  return (
    <div data-testid="role-select">
      <div style={outline === true ? outlineCss : {}}>
        <Menu
          css={disabled && disabledStyle}
          // @ts-expect-error TS(2322): Type '{ children: any; id: string; trigger: Elemen... Remove this comment to see the full error message
          id="role"
          trigger={
            <StyledTrigger>
              {selectedName}
              <DownArrowIcon />
            </StyledTrigger>
          }
        >
          {roles.map((role) => {
            if (isRoleDisabled(role) || (role.protected && !userCanSelectProtectedRoles)) {
              // disabled/legacy roles, or protected roles
              return (
                <Menu.Item disabled={true} onClick={() => void 0}>
                  {role.displayName || role.name}
                </Menu.Item>
              )
            } else {
              // regular, selectable roles
              return (
                <Menu.Item
                  key={role.id}
                  onClick={(e) => {
                    e.preventDefault()
                    onChange(role)
                  }}
                >
                  {role.displayName || role.name}
                </Menu.Item>
              )
            }
          })}
        </Menu>
      </div>
    </div>
  )
}
