import * as types from './constants'

const initialState = {
  submitting: false,
  successful: false,
}

const loginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.LOGIN:
      return { ...initialState, submitting: true }

    case types.LOGIN_SUCCESS:
      return { ...state, submitting: false, successful: true }

    case types.LOGIN_ERROR:
      return { ...state, submitting: false, successful: false }

    case types.RESET_LOGIN:
      return initialState

    default:
      return state
  }
}

export default loginReducer
