import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { connect } from 'react-redux'

import { openSidebar } from 'redux/sidebar/actions'
import './Navbar.scss'

class Navbar extends React.PureComponent {
  render() {
    return (
      <div className="Navbar-root">
        <div className="Navbar-main">
          {/* @ts-expect-error TS(2339): Property 'openSidebar' does not exist on type */}
          <div className="Navbar-left" onClick={this.props.openSidebar}>
            <div className="Navbar-open-sidebar">
              <FontAwesomeIcon icon="bars" />
            </div>
          </div>
          <div className="Navbar-right">
            <span>Admin</span>
            <FontAwesomeIcon icon="shield-alt" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, { openSidebar })(Navbar)
