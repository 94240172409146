import React from 'react'

import TokenRequest from 'containers/TokenRequest'
import BoxFormLayout from 'shared/layouts/BoxFormLayout'

class TokenScreen extends React.PureComponent {
  render() {
    return (
      <BoxFormLayout>
        <TokenRequest />
      </BoxFormLayout>
    )
  }
}

export default TokenScreen
