import { Table as BaseTable } from 'antd'
import camelCase from 'lodash/camelCase'
import noop from 'lodash/noop'
import snakeCase from 'lodash/snakeCase'
import T from 'prop-types'
import React, { useMemo } from 'react'

import { SORT_ORDER } from 'constants/sort'
import { DEFAULT_PAGE_SIZE } from 'constants/table'
import { withActions } from 'utils/table'

export function Table({ settings, columns, onAction, onSelect, onSettingsChange, ...rest }: any) {
  const renderWithAction = useMemo(() => withActions(onAction, onSelect), [onAction, onSelect])
  const columnWithActions = useMemo(() => {
    const sort = settings.sort ? settings.sort.split(':').map(camelCase) : null
    return columns.map((column: any) => {
      // eslint-disable-next-line no-param-reassign
      column.filteredValue = settings.filters?.[column.key]
      if (sort?.[0] === column.key) {
        // eslint-disable-next-line no-param-reassign
        column.sortOrder = sort[1] === SORT_ORDER.ASC ? SORT_ORDER.ASCEND : SORT_ORDER.DESCEND
      } else {
        // eslint-disable-next-line no-param-reassign
        column.sortOrder = false
      }
      return renderWithAction(column)
    })
  }, [columns, settings.filters, settings.sort, renderWithAction])

  function onSort({ field, order }: any) {
    if (!order) {
      onSettingsChange({
        ...settings,
        sort: undefined,
      })
    } else {
      const direction = order === SORT_ORDER.DESCEND ? SORT_ORDER.DESC : SORT_ORDER.ASC
      onSettingsChange({
        ...settings,
        sort: `${snakeCase(field)}:${direction}`,
      })
    }
  }

  function onPaginate({ current, pageSize }: any) {
    onSettingsChange({ ...settings, page: current, pageSize })
  }

  function onFilter(filters: any) {
    onSettingsChange({ ...settings, filters })
  }

  function onChange(pagination: any, filters: any, sorter: any, { action }: any) {
    switch (action) {
      case 'sort':
        onSort(sorter)
        break
      case 'paginate':
        onPaginate(pagination)
        break
      case 'filter':
        onFilter(filters)
        break
      default:
    }
  }

  return <BaseTable onChange={onChange} {...rest} columns={columnWithActions} />
}

Table.defaultProps = {
  settings: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
    filters: null,
    sort: null,
  },
  persistedName: undefined,
  onAction: noop,
  onSelect: noop,
  onSettingsChange: noop,
}

Table.propTypes = {
  settings: T.shape({
    page: T.number,
    pageSize: T.oneOfType([T.string, T.number]),
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    filters: T.shape(),
    sort: T.string,
  }),
  columns: T.arrayOf(T.shape({})).isRequired,
  persistedName: T.string,
  onAction: T.func,
  onSelect: T.func,
  onSettingsChange: T.func,
}
