import { CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Alert, Col, Row, Spin, Timeline } from 'antd'
import React from 'react'

import { StyledDetails, StyledSection, StyledSpinContainer } from '../styled'

import { Subscription } from './components'
import { Account } from './components/Account'
import { Twilio } from './components/Twilio'
import { useSubscription } from './queries'
import { TCRData } from './TCRData'

import { getClient } from 'api/clients'
import { QUERIES } from 'constants/queries'

export const ONBOARDING_TASKS = Object.freeze({
  WELCOME_MESSAGE: 'Welcome Message',
  CONTACT_PHOTO: 'Contact Photo',
  CONFIRMATION_MESSAGE: 'Confirmation Message',
  VOICEMAIL: 'Voicemail',
})

export function Billing({ client }: any) {
  const { isInitialLoading, isError, error, data: subscription } = useSubscription(client.id)
  const individualClient = useQuery([QUERIES.CLIENT, { id: client.id }], () => getClient(client.id))

  if (isInitialLoading || individualClient.isLoading) {
    return (
      <StyledSpinContainer>
        <Spin size="large" />
      </StyledSpinContainer>
    )
  }

  return (
    <>
      {isError && error?.status !== 404 && (
        <Alert message="Sorry could not load billing subscription. Please try again." showIcon type="error" />
      )}
      <StyledDetails>
        <Row>
          <Col span={24}>
            <Account client={client} />
            <Subscription client={client} subscription={subscription} />
            <Twilio clientId={client.id} />
            <TCRData client={client} individualClient={individualClient} />
            <div className="ant-descriptions-title">Onboarding Steps Completed</div>
            <StyledSection>
              <Timeline>
                {Object.values(ONBOARDING_TASKS)
                  .map((id) => {
                    const task = client.onboardingTasks.tasks[id]
                    if (!task) return null
                    return task.status === 'complete' ? (
                      <Timeline.Item color="green" dot={<CheckCircleFilled style={{ fontSize: '16px' }} />} key={id}>
                        {task.title}
                      </Timeline.Item>
                    ) : (
                      <Timeline.Item color="red" dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />} key={id}>
                        {task.title}
                      </Timeline.Item>
                    )
                  })
                  .filter(Boolean)}
              </Timeline>
            </StyledSection>
          </Col>
        </Row>
      </StyledDetails>
    </>
  )
}
