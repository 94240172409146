import { all, call, cancelled, put, takeLatest } from 'redux-saga/effects'

import * as creators from './actions'
import * as types from './constants'

import { callApi } from 'utils/api'

function* lookupPhoneNumbers({ payload = {} }) {
  // @ts-expect-error TS(2339): Property 'numberSearch' does not exist on type {}... Remove this comment to see the full error message
  const { numberSearch } = payload
  const endpoint1 = `/batcave/available_numbers?area_code=${numberSearch}`
  const controller = new window.AbortController()
  try {
    const { signal } = controller
    const resp = yield call(callApi, endpoint1, { signal })
    yield put(creators.lookupPhoneNumbersSuccess({ data: resp.data }))
  } catch (error) {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    yield put(creators.lookupPhoneNumbersError())
  } finally {
    if (yield cancelled()) controller.abort()
  }
}
function* phoneNumbersWatcher() {
  // @ts-expect-error TS(2769): No overload matches this call.
  yield all([takeLatest(types.LOOKUP_PHONE_NUMBERS, lookupPhoneNumbers)])
}

export default phoneNumbersWatcher
