/* eslint-disable no-console */

import { delay } from 'redux-saga'
import { all, call, put, race, take } from 'redux-saga/effects'

import * as creators from './actions'
import * as types from './constants'

import store from 'store'
import { takeLatestUntil } from 'utils/saga-helpers'

const checkSnackbarOpened = () => {
  try {
    // @ts-expect-error TS(2339): Property 'opened' does not exist on type 'never'.
    return store.getState().snackbar.opened
  } catch (e) {
    return false
  }
}

function* pushSnackbarFlow(action: any) {
  try {
    const snackbarOpened = checkSnackbarOpened()
    if (snackbarOpened) yield put(creators.closeSnackbar())
    yield delay(snackbarOpened ? 100 : 50)
    yield put(creators.openSnackbar())
    const { timeout = 5000, trigger } = action.payload
    const raceTasks = {
      dismiss: take(types.DISMISS_SNACKBAR),
      trigger: take(types.TRIGGER_SNACKBAR),
    }
    // @ts-expect-error TS(2339): Property 'timeout' does not exist on type '{ dismi... Remove this comment to see the full error message
    if (parseInt(timeout, 10) > 0) raceTasks.timeout = delay(timeout)
    const task = yield race(raceTasks)
    if (task.trigger && trigger.callback) yield call(trigger.callback)
    if (task.trigger || task.timeout) yield put(creators.dismissSnackbar())
  } catch (e) {
    console.log('Snackbar Error:', e)
  }
}

function* cleanSnackbarFlow() {
  yield delay(400)
  yield put(creators.cleanSnackbar())
}

function* dismissSnackbarFlow() {
  yield delay(400)
  yield put(creators.resetSnackbar())
}

const dismissSnackbarUntil = {
  pushSnackbar: take(types.PUSH_SNACKBAR),
  reset: take(types.RESET_SNACKBAR),
}

const pushSnackbarUntil = {
  reset: take(types.RESET_SNACKBAR),
}

function* snackbarWatcher() {
  yield all([
    takeLatestUntil(types.PUSH_SNACKBAR, pushSnackbarUntil, pushSnackbarFlow),
    takeLatestUntil(types.PUSH_SNACKBAR, pushSnackbarUntil, cleanSnackbarFlow),
    takeLatestUntil(types.DISMISS_SNACKBAR, dismissSnackbarUntil, dismissSnackbarFlow),
  ])
}

export default snackbarWatcher
