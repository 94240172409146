import { ApiError } from '@community_dev/requests'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getSystemicOnboarding, SystemicOnboardingResponse } from 'api/systemicOnboarding'
import { QUERIES } from 'constants/queries'

type UseSystemicOnboardingProps<SelectData = SystemicOnboardingResponse> = {
  clientId: string
  options?: UseQueryOptions<SystemicOnboardingResponse, ApiError, SelectData>
}
export const useSystemicOnboardingQuery = <SelectData = SystemicOnboardingResponse>({
  clientId,
  options,
}: UseSystemicOnboardingProps<SelectData>) => {
  return useQuery<SystemicOnboardingResponse, ApiError, SelectData>({
    queryKey: [QUERIES.SYSTEMIC_ONBOARDING, clientId],
    queryFn: () => getSystemicOnboarding(clientId),
    enabled: Boolean(clientId),
    ...options,
  })
}
