import { ApiError } from '@community_dev/requests'
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { getNumberImports, NumberImportsResponse } from 'api/numberImports'
import { QUERIES } from 'constants/queries'

type UseNumberImportsProps<SelectData = NumberImportsResponse> = {
  clientId: string
  options?: UseQueryOptions<NumberImportsResponse, ApiError, SelectData>
}
export const useNumberImportsQuery = <SelectData = NumberImportsResponse>({
  clientId,
  options,
}: UseNumberImportsProps<SelectData>): UseQueryResult<SelectData, ApiError> => {
  return useQuery<NumberImportsResponse, ApiError, SelectData>({
    queryKey: [QUERIES.NUMBER_IMPORTS, clientId],
    queryFn: () => getNumberImports({ clientId }),
    enabled: Boolean(clientId),
    ...options,
  })
}
