import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import compact from 'lodash/compact'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import { useHasCapability } from 'hooks/useCapabilities'
import { resetAuth } from 'redux/auth/actions'
import { closeSidebar, collapseSidebar, uncollapseSidebar } from 'redux/sidebar/actions'
import { resetSnackbar } from 'redux/snackbar/actions'
import { Dialog } from 'shared/components/base'
import { AdminGlyph, CommunityLogo } from 'shared/components/glyph'
import './Sidebar.scss'

export type LinkType = {
  icon: IconDefinition | IconName
  text: string
  to: string
  onClick?(e: React.MouseEvent): any
}

export const Sidebar = (): JSX.Element => {
  const dispatch = useDispatch()
  const canDeleteMember = useHasCapability(CAPABILITIES.ADMIN.FANS.WRITE)
  // @ts-expect-error TS(2339): Property 'snackbar' does not exist on type 'Defaul... Remove this comment to see the full error message
  const hasSnackbar = useSelector((s) => s.snackbar.items.length > 0)
  // @ts-expect-error TS(2339): Property 'sidebar' does not exist on type 'Default... Remove this comment to see the full error message
  const collapsed = useSelector((s) => s.sidebar.collapsed)
  // @ts-expect-error TS(2339): Property 'sidebar' does not exist on type 'Default... Remove this comment to see the full error message
  const opened = useSelector((s) => s.sidebar.opened)
  const [loggingOut, setLoggingOut] = useState(false)

  const links: LinkType[] = compact([
    { icon: 'users', text: 'Clients', to: ROUTES.CLIENTS },
    { icon: 'user-shield', text: 'Admins', to: ROUTES.ADMINS },
    { icon: 'check-circle', text: 'Capabilities', to: ROUTES.CAPABILITIES.ROOT },
    canDeleteMember ? { icon: 'user', text: 'Member', to: ROUTES.MEMBER } : null,
    {
      icon: 'sign-out-alt',
      text: 'Logout',
      to: '/login',
      onClick: (e: any) => {
        e.preventDefault()
        setLoggingOut(true)
      },
    },
  ])

  const onClose = () => {
    if (opened) dispatch(closeSidebar())
  }

  const onLinkClick = () => {
    if (hasSnackbar) dispatch(resetSnackbar())
    onClose()
  }

  const toggleCollapse = () => {
    if (collapsed) {
      dispatch(uncollapseSidebar())
    } else {
      dispatch(collapseSidebar())
    }
  }

  const cname = 'Sidebar-root' + (collapsed ? ' collapsed' : '')

  return (
    <div className={cname}>
      <div className="Sidebar-main">
        <div className="Sidebar-header">
          <Link className="Sidebar-header-logo" onClick={onLinkClick} to="/">
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <CommunityLogo color="#ffffff" height="18px" />
          </Link>
          <AdminGlyph />
          <div className="Sidebar-header-close" onClick={onClose}>
            <div className="Sidebar-header-close-icon">
              <FontAwesomeIcon icon="times" />
            </div>
          </div>
        </div>
        <div className="Sidebar-menu">
          <div className="Sidebar-menu-slide">
            {links.map((item) => {
              const { icon, text, onClick, ...rest } = item
              return (
                <NavLink
                  className="Sidebar-menu-item"
                  key={item.to}
                  onClick={(e) => (onClick ? onClick(e) : onClose())}
                  {...rest}
                >
                  <div className="Sidebar-menu-item-icon">
                    <FontAwesomeIcon icon={icon} />
                  </div>
                  <div className="Sidebar-menu-item-text">{text}</div>
                </NavLink>
              )
            })}
          </div>
        </div>
        <div className="Sidebar-footer">
          <div className="Sidebar-collapse" onClick={toggleCollapse}>
            <FontAwesomeIcon icon={`angle-double-${collapsed ? 'right' : 'left'}`} />
          </div>
        </div>
        {loggingOut && (
          <Dialog
            actions={[
              {
                text: 'Cancel',
                onClick: () => setLoggingOut(false),
              },
              {
                text: 'Logout',
                solid: true,
                onClick: () => dispatch(resetAuth()),
              },
            ]}
            onOuterClick={() => setLoggingOut(false)}
          >
            Are you sure you want to logout?
          </Dialog>
        )}
      </div>
    </div>
  )
}

export default Sidebar
