import { SPACING, COLORS, Layout, Typography, Badge } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { useMutation } from '@tanstack/react-query'
import { Button, Col, Divider, Row } from 'antd'
import partition from 'lodash/partition'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { CreateFeatureGroupFormResult, CreateFeatureGroupModal } from './CreateFeatureGroupModal'
import { FeatureGroupKind } from './FeatureGroup'
import { PlanList } from './PlanList'

import { postCapability, postCapabilityToFeatureGroup, postFeatureGroup } from 'api/capabilities'
import { ROUTES } from 'constants/routes'
import { useFeatureGroups } from 'hooks/useFeatureGroups'

const StyledBadge = styled(Badge)`
  left: ${SPACING[2]};
  top: -${SPACING[1]};
  position: relative;
`

export function Plans(): JSX.Element {
  const [isCreatePlanModalOpen, setIsCreatePlanModalOpen] = useState(false)

  const { plans, allFeatureGroupsAndPlans, isLoading, refetch } = useFeatureGroups()
  const { mutateAsync: createPlan } = useMutation({
    mutationFn: async (values: CreateFeatureGroupFormResult) => {
      // create plan
      await postFeatureGroup(values.name)
      // create new capabilities
      await Promise.all(values.newCapabilities.map(postCapability))
      // add capabilities to plan
      await Promise.all(
        [...values.capabilities, ...values.newCapabilities.map((c) => c.name)].map((name) => {
          return postCapabilityToFeatureGroup({ featureGroupName: values.name, capabilityName: name })
        }),
      )
    },
    onSuccess() {
      setIsCreatePlanModalOpen(false)
      refetch()
    },
  })

  const [deprecatedPlans, activePlans] = useMemo(() => partition(plans, (plan) => plan.deprecated), [plans])

  return (
    <Layout padding={SPACING[8]}>
      {isCreatePlanModalOpen && (
        <CreateFeatureGroupModal
          featureGroups={allFeatureGroupsAndPlans}
          kind={FeatureGroupKind.PLAN}
          onClose={() => setIsCreatePlanModalOpen(false)}
          onSubmit={createPlan}
        />
      )}
      <Row justify="space-between">
        <Col>
          <h1 style={{ display: 'inline-block' }}>Plans</h1>
          <StyledBadge>{(plans || []).length}</StyledBadge>
        </Col>
        <Col>
          <Button onClick={() => setIsCreatePlanModalOpen(true)} shape="round" size="small" type="primary">
            Create Plan
          </Button>
        </Col>
      </Row>
      <Typography color={COLORS.SUBTEXT} marginBottom={SPACING[5]} variant="body2">
        A Plan is the basic set of Capabilities a Client has by default. Any Client should have exactly one plan.
      </Typography>
      <PlanList
        actions={(plan) => [<Link to={route(ROUTES.CAPABILITIES.PLAN, { name: plan.name })}>View Details</Link>]}
        loading={isLoading}
        plans={activePlans}
      />
      <Divider style={{ color: COLORS.SUBTEXT }}>Deprecated Plans</Divider>
      <PlanList
        actions={(plan) => [<Link to={route(ROUTES.CAPABILITIES.PLAN, { name: plan.name })}>View Details</Link>]}
        loading={isLoading}
        plans={deprecatedPlans}
      />
    </Layout>
  )
}
