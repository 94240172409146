/* eslint-disable no-plusplus */

export default function validatePassword(password: any) {
  const validations = {}

  // password length 8+
  // @ts-expect-error TS(2339): Property 'length' does not exist on type '{}'.
  if (password.length >= 8) validations.length = true

  // password must not start or end with a space
  // @ts-expect-error TS(2339): Property 'space' does not exist on type '{}'.
  if (password[0] !== ' ' && password[password.length - 1] !== ' ') validations.space = true

  // password must have two types of characters (letters, numbers, symbols)
  let characterTypes = 0
  const letters = /[a-zA-Z]+/
  const numbers = /[0-9]+/
  const symbols = /[^a-zA-Z0-9\s]+/
  if (password.match(letters)) {
    // @ts-expect-error TS(2339): Property 'letter' does not exist on type '{}'.
    validations.letter = true
    characterTypes++
  }
  if (password.match(numbers)) {
    // @ts-expect-error TS(2339): Property 'number' does not exist on type '{}'.
    validations.number = true
    characterTypes++
  }
  if (password.match(symbols)) {
    // @ts-expect-error TS(2339): Property 'symbol' does not exist on type '{}'.
    validations.symbol = true
    characterTypes++
  }

  // password is overall valid if all above are true
  // @ts-expect-error TS(2339): Property 'length' does not exist on type '{}'.
  if (characterTypes >= 2 && validations.length && validations.space) validations.valid = true

  return validations
}
