import { StarOutlined } from '@ant-design/icons'
import { Typography } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { List, Popover, Skeleton, Tag } from 'antd'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { FeatureGroup } from 'api/capabilities'
import { ROUTES } from 'constants/routes'

export type FeatureGroupListProps = {
  featureGroups?: FeatureGroup[]
  actions?: (fg: FeatureGroup) => ReactNode[]
  loading?: boolean
  showCapabilities?: boolean
}

export function FeatureGroupList({
  featureGroups = [],
  showCapabilities = true,
  actions = () => [],
  loading = false,
}: FeatureGroupListProps): JSX.Element {
  return (
    <List
      dataSource={featureGroups}
      itemLayout="horizontal"
      loading={loading}
      renderItem={(item) => (
        <List.Item actions={actions ? actions(item) : []} key={item.id}>
          <Skeleton active avatar loading={loading} title={false}>
            <List.Item.Meta
              avatar={<StarOutlined />}
              description={item.description || '(no description)'}
              title={<Link to={route(ROUTES.CAPABILITIES.FEATURE_GROUP, { name: item.name })}>{item.name}</Link>}
            />
            {item.deprecated === true && <Tag>Deprecated</Tag>}
            <Popover
              content={
                <Typography variant="caption1">
                  <b>{item.name}</b> Capabilities:
                  <hr />
                  {Object.values(item.capabilities).map((capability) => (
                    <Link key={capability.id} to={route(ROUTES.CAPABILITIES.CAPABILITY, { name: capability.name })}>
                      {capability.name}
                    </Link>
                  ))}
                </Typography>
              }
            >
              {showCapabilities && <Tag>Capabilities: {Object.values(item.capabilities).length}</Tag>}
            </Popover>
          </Skeleton>
        </List.Item>
      )}
    />
  )
}
