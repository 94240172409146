import { CloseOutlined, FileImageOutlined, UploadOutlined } from '@ant-design/icons'
import { notification, Button } from 'antd'
import * as filestack from 'filestack-js'
import { PickerFileMetadata, PickerInstance, PickerOptions } from 'filestack-js'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const MAX_UPLOAD_SIZE_BYTES = 1024 * 1024

const StyledUploadImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'image buttons'
    'text buttons';
  border-bottom: 1px solid #f0f0f0;
  padding: 24px;
`

export const StyledImageContainer = styled.div`
  grid-area: image;
  padding-bottom: 14px;
`

const StyledImage = styled.img`
  object-fit: cover;
`

const StyledTextContainer = styled.div`
  grid-area: text;
`

const StyledButtonContainer = styled.div`
  grid-area: buttons;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  button {
    width: 200px;
    margin-bottom: 16px;
  }
`

export const StyledNoImageContainer = styled.div`
  background: #f4f4f4;
  min-height: 100px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dadada;
`

type UseFilestackOptions = Partial<PickerOptions>

const useFilestack = (options: UseFilestackOptions = {}) => {
  const picker = useRef<PickerInstance>()
  const [pickerFileMetadata, setPickerFileMetadata] = useState<PickerFileMetadata>()

  useEffect(() => {
    const instance = filestack.init(import.meta.env.VITE_FILESTACK_API_KEY)
    const mergedOptions: PickerOptions = {
      maxFiles: 1,
      accept: ['image/jpg', 'image/jpeg', 'image/png'],
      fromSources: ['local_file_system'],
      uploadInBackground: false,
      onFileUploadFinished: setPickerFileMetadata,
      onFileSelected: validateImage,
      ...options,
    }
    picker.current = instance.picker(mergedOptions)
  }, [])

  const openPicker = useCallback(() => {
    picker?.current?.open()
  }, [])

  const validateImage = useCallback((file) => {
    if (file.size > MAX_UPLOAD_SIZE_BYTES) {
      picker?.current?.cancel()
      picker?.current?.close()
      notification.error({
        message: 'Images must be less than 1MB',
        placement: 'bottomRight',
        duration: 5,
      })
    }
  }, [])

  return {
    openPicker,
    pickerFileMetadata,
  }
}

export type UploadImageProps = {
  title: React.ReactNode
  points: React.ReactNode[]
  onChange: (file?: Partial<PickerFileMetadata>) => void
  image: {
    file?: Partial<PickerFileMetadata>
    uploadedUrl?: string
  }
  imageOptions: Partial<PickerOptions>
  fileName: string
  className?: string
}

export const UploadImage = ({
  title,
  points,
  image,
  imageOptions,
  onChange,
  fileName,
  className,
}: UploadImageProps) => {
  const { openPicker, pickerFileMetadata } = useFilestack(imageOptions)

  useEffect(() => {
    if (!pickerFileMetadata) return
    onChange(pickerFileMetadata)
  }, [onChange, pickerFileMetadata])

  const currentImage = image?.file ? image?.file?.url : image?.uploadedUrl

  return (
    <StyledUploadImageContainer className={className}>
      <StyledImageContainer>
        {currentImage ? (
          <StyledImage alt={fileName} src={currentImage} />
        ) : (
          <StyledNoImageContainer>
            <FileImageOutlined style={{ fontSize: '40px', color: '#888888' }} />
          </StyledNoImageContainer>
        )}
      </StyledImageContainer>
      <StyledTextContainer>
        {title}
        <ul>
          {points?.map((point: any, index: any) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </StyledTextContainer>
      <StyledButtonContainer>
        <Button disabled={Boolean(currentImage)} icon={<UploadOutlined />} onClick={openPicker}>
          Click to Upload
        </Button>
        {currentImage && (
          <Button icon={<CloseOutlined />} onClick={() => onChange({})}>
            Remove
          </Button>
        )}
      </StyledButtonContainer>
    </StyledUploadImageContainer>
  )
}
