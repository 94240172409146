import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'

export const STATUS = Object.freeze({
  DENIED: 'denied',
  PENDING_REVIEW: 'pending_review',
  APPROVED: 'approved',
  PIC_REVIEW: 'pic_review',
  HIGH_TOUCH: 'high_touch',
  // unknown is purely a frontend concept
  // we use it as a default for any new or unhandled statuses
  UNKNOWN: 'unknown',
  PENDING_DEPLOYMENT: 'pending_deployment',
})

export const STATUS_LABELS_AT_REST = Object.freeze({
  [STATUS.DENIED]: 'Denied',
  [STATUS.PENDING_REVIEW]: 'Pending Review',
  [STATUS.APPROVED]: 'Approved',
  [STATUS.PIC_REVIEW]: 'PIC Review',
  [STATUS.PENDING_DEPLOYMENT]: 'Pending Carrier Approval',
  [STATUS.HIGH_TOUCH]: 'High Touch',
  [STATUS.UNKNOWN]: 'Unknown',
})

export const STATUS_LABELS_ON_ACTION = Object.freeze({
  [STATUS.DENIED]: 'Deny',
  [STATUS.PENDING_REVIEW]: 'Pending Review',
  [STATUS.HIGH_TOUCH]: 'High Touch',
  [STATUS.PIC_REVIEW]: 'PIC Review',
  [STATUS.PENDING_DEPLOYMENT]: 'Submit for Carrier Approval',
})

export const ALLOWED_STATUS = Object.freeze({
  [STATUS.DENIED]: [STATUS.PENDING_REVIEW],
  [STATUS.PENDING_REVIEW]: [STATUS.PENDING_DEPLOYMENT, STATUS.DENIED, STATUS.PIC_REVIEW, STATUS.HIGH_TOUCH],
  [STATUS.APPROVED]: [],
  [STATUS.PIC_REVIEW]: [STATUS.PENDING_DEPLOYMENT, STATUS.HIGH_TOUCH, STATUS.DENIED],
  [STATUS.HIGH_TOUCH]: [],
  [STATUS.PENDING_DEPLOYMENT]: [],
  [STATUS.UNKNOWN]: [],
})

export const SOCIAL_PLATFORMS = Object.freeze({
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  LINKEDIN: 'linkedin',
})

export const SOCIAL_PLATFORM_LABELS = Object.freeze({
  [SOCIAL_PLATFORMS.TWITTER]: 'Twitter',
  [SOCIAL_PLATFORMS.INSTAGRAM]: 'Instagram',
  [SOCIAL_PLATFORMS.YOUTUBE]: 'YouTube',
  [SOCIAL_PLATFORMS.LINKEDIN]: 'LinkedIn',
})

export const INVITE_STATUS = Object.freeze({
  AREA_CODE_UNAVAILABLE: 'area_code_unavailable',
  LAST_ASSIGNMENT_RESPONSE: 'last_assignment_response',
  INVITE_ERROR: 'last_assign_and_invite_error',
  SENDING_INVITE_TOKEN_FAILED: 'sending_invite_token_failed',
})

export const GENDER = Object.freeze({
  MALE: 'Male',
  FEMALE: 'Female',
  NON_BINARY: 'Non-Binary',
  PREFER_NOT_TO_ANSWER: 'Prefer not to answer',
})

export const COMMUNICATION_CHANNEL_LABEL = {
  [CommunicationChannel.SMS]: 'SMS',
  [CommunicationChannel.WHATS_APP]: 'WhatsApp',
  [CommunicationChannel.AMB]: 'AMB',
}
