/* eslint-disable max-len */

import PropTypes from 'prop-types'
import React from 'react'

class ClientIcon extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'bgColor' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { bgColor, size, ...rest } = this.props
    if (size) {
      const sz = `${parseInt(size, 10) || 20}px`
      // @ts-expect-error TS(2339): Property 'style' does not exist on type '{ childre... Remove this comment to see the full error message
      rest.style = { width: sz, height: sz, ...rest.sytle }
    }
    return (
      <svg {...rest} viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
        <circle cx="64" cy="64" fill={bgColor} r="64" />
        <path
          d="M64,128a63.81,63.81,0,0,0,46.75-20.31c-5.53-4.42-16.22-7.73-23.84-10.87-9.57-3.94-8.27-6.33-8.61-9.6-.05-.45-.08-.93-.1-1.41a27.09,27.09,0,0,0,7.71-10.71A31,31,0,0,0,87,71.91c2.2-.41,3.47-2.64,4-4.7A9,9,0,0,0,92.25,62c-.3-3-1.6-4.39-3.06-5,0-.18,0-.35,0-.52a73.47,73.47,0,0,0-1.13-12.93,21,21,0,0,0-.72-3A22.13,22.13,0,0,0,79,29.37a27.59,27.59,0,0,0-29.94,0,22.13,22.13,0,0,0-8.32,11.2,20.52,20.52,0,0,0-.72,3A73.43,73.43,0,0,0,38.92,56.5c0,.16,0,.31,0,.48-1.5.56-2.85,1.94-3.17,5A9,9,0,0,0,37,67.21c.5,2.09,1.81,4.36,4.07,4.72.28,1,.61,2,1,2.91A27.34,27.34,0,0,0,50.1,86c0,.44-.05.86-.09,1.26-.34,3.27.66,5.66-8.91,9.61-7.63,3.14-18.33,6.45-23.85,10.87A63.81,63.81,0,0,0,64,128Z"
          fill="currentColor"
        />
      </svg>
    )
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ClientIcon.defaultProps = {
  size: 20,
  bgColor: '#E4E9F1',
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ClientIcon.propTypes = {
  bgColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default ClientIcon
