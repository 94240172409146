/* eslint-disable max-len */

import PropTypes from 'prop-types'
import React from 'react'

class GalleryIcon extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'size' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { size, ...rest } = this.props
    if (['string', 'number'].includes(typeof size)) {
      const sz = typeof size === 'number' ? `${size}px` : size
      // @ts-expect-error TS(2339): Property 'height' does not exist on type '{ childr... Remove this comment to see the full error message
      rest.height = sz
      // @ts-expect-error TS(2339): Property 'width' does not exist on type '{ childre... Remove this comment to see the full error message
      rest.width = sz
    }
    return (
      <svg {...rest} version="1.1" viewBox="0 0 21 14" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" id="01a.-client-web" stroke="none" strokeWidth="1">
          <g fill="currentColor" id="schedule-0" transform="translate(-292.000000, -457.000000)">
            <g id="Group-2" transform="translate(260.000000, 153.000000)">
              <g id="compose-icon-gallery" transform="translate(32.000000, 304.000000)">
                <path d="M19.5416565,0 L1.45834346,0 C0.652900367,0 0,0.62692 0,1.4 L0,12.6 C0,13.37322 0.652900367,14 1.45834346,14 L19.5416565,14 C20.3470996,14 21,13.37322 21,12.6 L21,1.4 C21,0.62692 20.3470996,0 19.5416565,0 L19.5416565,0 Z M13.3840929,6.36342 C14.2938076,6.36342 15.027646,5.65572 15.027646,4.78534 C15.027646,3.91524 14.2938076,3.20796 13.3840929,3.20796 C12.4745241,3.20796 11.7409774,3.91524 11.7409774,4.78534 C11.7409774,5.656 12.4745241,6.36342 13.3840929,6.36342 M19.153883,1.84982 L19.153883,11.3155 L16.0855284,8.31362 L13.7416788,10.60654 L7.4743019,5.52342 L1.84611699,10.08812 L1.84611699,1.84982 L19.153883,1.84982 L19.153883,1.84982 Z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
GalleryIcon.defaultProps = {
  size: 20,
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
GalleryIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default GalleryIcon
