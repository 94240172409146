import { Button } from 'antd'

export const DownloadTemplate = (): JSX.Element => {
  const downloadTemplate = () => {
    const headers = [
      'phone_number',
      'opt_in_date',
      'given_name',
      'surname',
      'date_of_birth',
      'gender_identity',
      'city',
      'postal_code',
      'state_or_province',
      'state_or_province_abbreviation',
      'country',
      'country_code',
      'email',
    ]

    // Sample row with example data
    const sampleRow = [
      '12223334444',
      'YYYY-MM-DD',
      'John',
      'Doe',
      'YYYY-MM-DD',
      '"male, female, non-binary, no-answer"',
      'Chicago',
      '60642',
      'Illinois',
      'IL',
      '"United States, Canada"',
      '"US, CA"',
      'hello@community.com',
    ]

    // Create CSV content
    const csvContent = [headers.join(','), sampleRow.join(',')].join('\n')

    // Create blob and download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', 'ImportTemplate.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }
  return <Button onClick={downloadTemplate}>Download Template</Button>
}
