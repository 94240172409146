import { UserAddOutlined } from '@ant-design/icons'
import { SPACING } from '@community_dev/pixels'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Alert, Button, Col, Row, notification, Table } from 'antd'
import isEqual from 'lodash/isEqual'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { DataExportToggle } from './DataExportToggle'
import { InviteForm } from './InviteForm'
import { Mfa } from './Mfa'
import { useTeamMembers, useDataDownloadAccess } from './queries'
import { TeamMemberEditModal, TeamMemberValue } from './TeamMemberEditModal'
import { useTeamColumnDef } from './useTeamsColumnDef'

import {
  Client,
  ClientSeat,
  deleteRemoveInvite,
  deleteTeamMember,
  getRoles,
  postSendInvite,
  putEditTeamMember,
  Role,
  transferProtectedRole,
  updateSeatRoles,
} from 'api/clients'
import { QUERIES } from 'constants/queries'
import { SeatRoles } from 'constants/roles'

const StyledFooter = styled.footer`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

const StyledButtons = styled(Button)`
  margin: ${SPACING[2]};
`

function showError() {
  notification.error({
    message: 'Sorry, something went wrong. Please try again.',
    placement: 'bottomRight',
    duration: 2,
  })
}

function showSuccess(message: string) {
  notification.success({
    message,
    placement: 'bottomRight',
    duration: 2,
  })
}

export function Team({ client }: { client: Client }): JSX.Element {
  const queryClient = useQueryClient()
  const [editMember, setEditMember] = useState<ClientSeat>()
  const [showAdd, setShowAdd] = useState(false)
  const teamMembersObj = useTeamMembers(client.id)

  const { teamMembers, members, pendingMembers, isLoading, isMembersError, isPendingMembersError } = teamMembersObj

  const { data: roles = [] } = useQuery([QUERIES.ROLES], getRoles, { staleTime: Infinity })

  // an owner exists or has been invited.
  const ownerRoleExists = useMemo(() => {
    return (
      members.some((member) => member.roles.some((role) => role.name === SeatRoles.OWNER)) ||
      pendingMembers.some((member) => member.role.name === SeatRoles.OWNER)
    )
  }, [members, pendingMembers])

  const invitableRoles = useMemo(() => {
    if (ownerRoleExists) {
      // if there is an owner, we cannot invite a new owner
      return roles.filter((role) => role.name !== SeatRoles.OWNER)
    } else {
      // if there isn’t an owner, we can only invite owners
      return roles.filter((role) => role.name === SeatRoles.OWNER)
    }
  }, [ownerRoleExists, roles])

  const { isDownloadEnabled, isDownloadEnabledLoading, enableClientDownload, disableClientDownload } =
    useDataDownloadAccess({ clientId: client.id })

  const { mutate: sendInvite } = useMutation(postSendInvite, {
    onError: showError,
    onSuccess() {
      showSuccess('Invite sent.')
      queryClient.invalidateQueries([QUERIES.PENDING_INVITES, { id: client.id }])
      setShowAdd(false)
    },
  })

  const { mutate: deleteInvite } = useMutation(deleteRemoveInvite, {
    onError: showError,
    onSuccess() {
      showSuccess('Invite deleted.')
      queryClient.invalidateQueries([QUERIES.PENDING_INVITES, { id: client.id }])
    },
  })
  const { mutate: saveTeamMember } = useMutation(putEditTeamMember, {
    onError: showError,
    onSuccess() {
      showSuccess('Team member saved.')
      queryClient.invalidateQueries([QUERIES.TEAM_MEMBERS, { id: client.id }])
      setEditMember(undefined)
    },
  })
  const { mutate: saveTeamMemberRoles } = useMutation(
    async ({
      seatMappingId,
      roles,
      isOwnerTransfer,
    }: {
      seatMappingId: string
      roles: Role[]
      isOwnerTransfer: boolean
    }) => {
      const ownerRole = roles.find((role) => role.name === SeatRoles.OWNER)
      const nonOwnerRoles = roles.filter((role) => role.name !== SeatRoles.OWNER)
      // owner roles must be transferred from one seat to another:
      if (isOwnerTransfer && ownerRole) {
        await transferProtectedRole({ seatMappingId, role: ownerRole, clientId: client.id })
      }
      if (nonOwnerRoles.length > 0) {
        // regular roles are updated
        await updateSeatRoles({ seatMappingId, roles: roles })
      }
    },
    {
      onError: showError,
      onSuccess() {
        queryClient.invalidateQueries([QUERIES.TEAM_MEMBERS, { id: client.id }])
      },
    },
  )
  const { mutate: deleteMember } = useMutation(deleteTeamMember, {
    onError: showError,
    onSuccess() {
      showSuccess('Team member deleted.')
      queryClient.invalidateQueries([QUERIES.TEAM_MEMBERS, { id: client.id }])
    },
  })

  function handleRemove(record: ClientSeat) {
    return deleteMember({ memberId: record.id, id: client.id })
  }

  function handleSendInvite({ email, role, roles }: { email: string; role?: Role; roles?: Role[] }) {
    const body = { email, role: role?.name ?? roles?.[0]?.name }
    return sendInvite({ id: client.id, body })
  }

  function handleEditSave(value: TeamMemberValue) {
    if (editMember !== undefined) {
      if (!isEqual(value.roles, editMember.roles)) {
        saveTeamMemberRoles({
          seatMappingId: editMember.seatMappingId,
          roles: value.roles,
          isOwnerTransfer:
            editMember.roles.every((role) => role.name !== SeatRoles.OWNER) &&
            value.roles.some((role) => role.name === SeatRoles.OWNER),
        })
      }
      return saveTeamMember({ memberId: editMember.id, body: value })
    }
  }

  const columnDefs = useTeamColumnDef(
    client.id,
    teamMembers,
    isLoading,
    setEditMember,
    handleSendInvite,
    handleRemove,
    deleteInvite,
  )
  return (
    <>
      {(isMembersError || isPendingMembersError) && (
        <Alert message="Sorry could not load team members. Please try again." showIcon type="error" />
      )}
      <Row>
        <Col span={8}>
          <Mfa clientId={client.id} />
        </Col>
        <Col span={16}>
          {!isDownloadEnabledLoading && (
            <DataExportToggle
              client={client}
              disable={disableClientDownload}
              enable={enableClientDownload}
              isDataDownloadEnabled={isDownloadEnabled}
            />
          )}
        </Col>
      </Row>
      <Table
        columns={columnDefs}
        dataSource={teamMembers}
        loading={isLoading}
        pagination={{ hideOnSinglePage: true }}
        rowKey="id"
        scroll={{ y: 'calc(100vh - 360px)' }}
      />
      <StyledFooter>
        <div>
          <StyledButtons
            icon={<UserAddOutlined />}
            onClick={() => setShowAdd((previous) => !previous)}
            size="large"
            type="primary"
          >
            {ownerRoleExists ? 'Invite team member' : 'Invite account owner'}
          </StyledButtons>
        </div>
      </StyledFooter>
      <InviteForm
        buttonName="Invite"
        isVisible={showAdd}
        onSubmit={handleSendInvite}
        roles={invitableRoles}
        setIsVisible={setShowAdd}
        title="Invite Team Member"
      />
      {editMember && (
        <TeamMemberEditModal
          client={client}
          isDownloadEnabled={isDownloadEnabled || false}
          onCancel={() => setEditMember(undefined)}
          onSubmit={handleEditSave}
          roles={roles}
          value={editMember}
        />
      )}
    </>
  )
}
