/* eslint-disable no-console */
import { all, call, cancelled, put, takeLatest } from 'redux-saga/effects'

import * as creators from './actions'
import * as types from './constants'

import { AUTH_TOKENS } from 'constants/web-storage'
import { callApi, logout } from 'utils/api'

const verify2FaAPI = ({ data, signal }: any) => {
  const path = '/admins/login/callback/2fa'
  const body = JSON.stringify({ totp: data })
  return callApi(path, { body, signal, method: 'POST', noAuth: false })
}

function* resetAuthFlow() {
  try {
    yield call(logout)
  } catch (error) {
    // it is OK if logout API returns a non 204. Still proceeding to '/login'
    console.error('Logout error: ', error, "routing to '/login'")
  }

  yield call([localStorage, 'removeItem'], AUTH_TOKENS)
  yield call([localStorage, 'removeItem'], 'persist:root')

  try {
    yield call([window.location, 'assign'], '/login')
  } catch (e) {
    console.log('Reset Auth Error:', e)
  }
}

function* verify2FaFlow(action: any) {
  const controller = new window.AbortController()
  try {
    const { signal } = controller
    const resp = yield call(verify2FaAPI, {
      data: action.payload.verificationCode,
      signal,
    })
    yield put(creators.setAuth({ token: resp.data.token }))
    window.location.assign('/clients')
  } catch (e) {
    yield put(creators.resetAuth())
  } finally {
    if (yield cancelled()) controller.abort()
  }
}

function* authWatcher() {
  yield all([takeLatest(types.RESET_AUTH, resetAuthFlow), takeLatest(types.VERIFY_2FA, verify2FaFlow)])
}

export default authWatcher
