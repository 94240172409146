import { SPACING } from '@community_dev/pixels'
import { Button, Image, Modal, Spin, Switch } from 'antd'
import isEmpty from 'lodash/isEmpty'
import T from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { StyledSpinContainer } from '../styled'

import addYourContact from './addYourContact.png'
import { useClientTerms } from './queries'
import { RichTextEditor } from './RichTextEditor'
import { COPY_TYPES, getCopyData, getHtmlContentTextLength } from './utils'

const StyledTextAndPictureRow = styled.div`
  display: flex;
  margin-top: 14px;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`

const StyledHeader = styled.h3`
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 126%;
`

const regularText = css`
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.08px;
  color: #333333;
`
const StyledSubHeader = styled.h5`
  ${regularText}
  margin-bottom: 14px;
`
const StyledList = styled.ol`
  ${regularText}
  padding-left: 20px;
  text-indent: 0;
  li {
    margin-bottom: 4px;
  }
`

const StyledImageContainer = styled.div`
  display: flex;
  flex: 1;
`

const StyledInputRow = styled.div`
  margin-top: 20px;

  span {
    margin-right: ${SPACING[2]};
  }
`
const StyledOptionalDisclaimer = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION2};
  font-style: italic;
  padding: ${SPACING[1]};
`

const CHAR_LIMIT = 1000

const isSignUpTextInvalid = (signUpText: string): boolean => {
  if (getHtmlContentTextLength(signUpText) > CHAR_LIMIT) return true
  return false
}

const isLegalTextInvalid = (legalText: string): boolean => {
  // We need to take into account pre-pending "(Optional)" (10 chars) to the message
  if (getHtmlContentTextLength(legalText) > CHAR_LIMIT - 10) return true
  return false
}

export const CustomCheckboxModal = ({ clientId, isOpen, onSave, onDelete, onCancel }: any) => {
  const { clientTerms, saveTerms, deleteTerms } = useClientTerms(clientId)
  const { isLoading, data: terms } = clientTerms
  const [signUpText, setSignUpText] = useState('')
  const [legalText, setLegalText] = useState('')
  const [hasCheckBox, setHasCheckBox] = useState(false)

  useEffect(() => {
    const signData = getCopyData(terms, COPY_TYPES.COPY_ONLY)
    const legalData = getCopyData(terms, COPY_TYPES.CHECKBOX)
    setSignUpText(signData.body)
    setLegalText(legalData.body)
    setHasCheckBox(legalData.checkedByDefault)
  }, [isLoading, terms])

  const handleSignUpText = (content) => {
    if (/<br>/.test(content)) return setSignUpText('')
    setSignUpText(content)
  }

  const handLegalText = (content) => {
    if (/<br>/.test(content)) return setLegalText('')
    setLegalText(content)
  }

  const isSaveDisabled = isSignUpTextInvalid(signUpText) || isLegalTextInvalid(legalText)

  const handleOnDelete = () => {
    deleteTerms({
      clientId,
      body: {
        terms: [
          {
            body: signUpText,
            type: COPY_TYPES.COPY_ONLY,
          },
          {
            body: legalText.replace(/^<p>(\(Optional\) )?/, '<p>(Optional) '),
            type: COPY_TYPES.CHECKBOX,
            checkedByDefault: hasCheckBox,
          },
        ],
      },
    })

    onDelete()
  }

  const handleOnSave = () => {
    const filteredTerms = [
      {
        body: signUpText,
        type: COPY_TYPES.COPY_ONLY,
      },
      {
        body: legalText.replace(/^<p>(\(Optional\) )?/, '<p>(Optional) '),
        type: COPY_TYPES.CHECKBOX,
        checkedByDefault: hasCheckBox,
      },
    ].filter((term) => term.body !== '')

    saveTerms({
      clientId,
      hasTerms: !isEmpty(terms),
      body: { terms: filteredTerms },
    })
    onSave()
  }

  if (isLoading) {
    return (
      <StyledSpinContainer>
        <Spin size="large" />
      </StyledSpinContainer>
    )
  }

  return (
    <Modal
      //Doing the empty styles to get the underline
      data-testid="legal-language-modal"
      footer={[
        <Button danger key="delete" onClick={handleOnDelete} type="primary">
          Delete
        </Button>,
        <Button disabled={isSaveDisabled} key="save" onClick={handleOnSave} type="primary">
          Save
        </Button>,
      ]}
      // @ts-expect-error TS(2322): Type '{ children: Element[]; "data-testid": string... Remove this comment to see the full error message
      height={545}
      onCancel={onCancel}
      open={isOpen}
      title="   "
      type="primary"
      width={640}
    >
      <StyledTextAndPictureRow>
        <StyledTextContainer>
          <StyledHeader>Additional Sign Up Language</StyledHeader>
          <StyledSubHeader>New members will see this at sign up.</StyledSubHeader>
          <StyledList>
            <li>
              <strong>Additional Sign Up Language</strong> is placed below Community's standard text
            </li>
            <li>
              <strong>Legal Checkbox Language</strong> is placed above the "Accept" button
            </li>
          </StyledList>
        </StyledTextContainer>
        <StyledImageContainer>
          <Image src={addYourContact} />
        </StyledImageContainer>
      </StyledTextAndPictureRow>
      <StyledInputRow>
        <p>1. Additional Sign Up Language</p>
        <RichTextEditor charLimit={CHAR_LIMIT} id={'signup-tool-bar'} onChange={handleSignUpText} value={signUpText} />
      </StyledInputRow>
      <StyledInputRow>
        <p>2. Legal Checkbox Language </p>
        <RichTextEditor
          charLimit={CHAR_LIMIT}
          id={'legal-tool-bar'}
          onChange={handLegalText}
          placeholder="(Optional)"
          value={legalText}
        />
        <StyledOptionalDisclaimer>"(Optional)" will be added to the beginning of this message</StyledOptionalDisclaimer>
      </StyledInputRow>
      <StyledInputRow>
        <span>Pre-select checkbox in US</span>
        {/* @ts-expect-error TS(2322): Type ' checked: boolean; name: string; onClick: (... Remove this comment to see the full error message */}
        <Switch checked={hasCheckBox} name="Pre-select checkbox in US" onClick={() => setHasCheckBox(!hasCheckBox)} />
      </StyledInputRow>
    </Modal>
  )
}

CustomCheckboxModal.propTypes = {
  onCancel: T.func.isRequired,
  onSave: T.func.isRequired,
  isOpen: T.bool.isRequired,
}
