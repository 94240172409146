import { CheckCircleOutlined, GithubOutlined } from '@ant-design/icons'
import { SPACING, COLORS, Layout, Typography, Badge } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { useMutation } from '@tanstack/react-query'
import { List, Skeleton, Tooltip, Tag, Row, Col, Button } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { CapabilityFormValues, CreateCapabilityModal } from './CreateCapabilityModal'

import { postCapability, postCapabilityToFeatureGroup, postFeatureGroup } from 'api/capabilities'
import { ROUTES } from 'constants/routes'
import { useFeatureGroups } from 'hooks/useFeatureGroups'

const StyledBadge = styled(Badge)`
  left: ${SPACING[2]};
  top: -${SPACING[1]};
  position: relative;
`

export function Capabilities(): JSX.Element {
  const { capabilities, allFeatureGroupsAndPlans, isLoading, refetch } = useFeatureGroups()
  const [isCreateCapabilityModalOpen, setIsCreateCapabilityModalOpen] = useState(false)

  const { mutateAsync: createCapability } = useMutation({
    mutationFn: async (values: CapabilityFormValues): Promise<any> => {
      // create the capability
      await postCapability({ name: values.name, description: values.description })
      // create the new Feature Groups
      await Promise.all(values.newFeatureGroups.map(postFeatureGroup))
      // add the new Capability to Feature Groups
      await Promise.all(
        [...values.featureGroups, ...values.newFeatureGroups].map((featureGroupName) =>
          postCapabilityToFeatureGroup({ featureGroupName, capabilityName: values.name }),
        ),
      )
    },
    onSuccess() {
      setIsCreateCapabilityModalOpen(false)
      refetch()
    },
  })

  return (
    <Layout padding={SPACING[8]}>
      {isCreateCapabilityModalOpen && (
        <CreateCapabilityModal
          featureGroups={allFeatureGroupsAndPlans || []}
          onClose={() => setIsCreateCapabilityModalOpen(false)}
          onSubmit={createCapability}
        />
      )}
      <Row justify="space-between">
        <Col>
          <h1 style={{ display: 'inline-block' }}>Capabilities</h1>
          <StyledBadge>{Object.keys(capabilities || {}).length}</StyledBadge>
        </Col>
        <Col>
          <Button onClick={() => setIsCreateCapabilityModalOpen(true)} shape="round" size="small" type="primary">
            Create Capability
          </Button>
        </Col>
      </Row>
      <Typography color={COLORS.SUBTEXT} marginBottom={SPACING[5]} variant="body2">
        A Capability gates access to a feature of our Product. Capabilities can be part of Plans or Feature Groups,
        which get assigned to Clients. Developers only ever check for capabilities, never for Plans or Feature Groups.
      </Typography>
      <List
        dataSource={Object.values(capabilities || {}).sort((a: any, b: any) => a.name.localeCompare(b.name))}
        itemLayout="horizontal"
        loading={isLoading}
        renderItem={(item) => (
          <List.Item
            actions={[<Link to={route(ROUTES.CAPABILITIES.CAPABILITY, { name: item.name })}>View Details</Link>]}
            key={item.id}
          >
            <Skeleton active avatar loading={isLoading} title={false}>
              <List.Item.Meta
                avatar={<CheckCircleOutlined />}
                description={item.description || '(no description)'}
                title={
                  <>
                    <Link to={route(ROUTES.CAPABILITIES.CAPABILITY, { name: item.name })}>{item.name}</Link>
                    <Tooltip title="Search GitHub">
                      <a
                        href={`https://github.com/search?q=org%3AShimmur+${encodeURIComponent(item.name)}&type=code`}
                        rel="noreferrer"
                        style={{ color: COLORS.SUBTEXT, marginLeft: SPACING[2] }}
                        target="_blank"
                      >
                        <GithubOutlined />
                      </a>
                    </Tooltip>
                  </>
                }
              />
              <>
                <Tooltip
                  title={
                    <Typography color={COLORS.APP_BACKGROUND_LEVEL_1} variant="caption1">
                      <b>{item.name}</b> is part of these Plans:
                      <hr />
                      {Object.values(item.plans).map((plan) => (
                        <Link
                          key={plan.id}
                          style={{ color: COLORS.APP_BACKGROUND_LEVEL_1, display: 'block' }}
                          to={route(ROUTES.CAPABILITIES.PLAN, { name: plan.name })}
                        >
                          {plan.name}
                        </Link>
                      ))}
                    </Typography>
                  }
                >
                  <Tag>Plans: {Object.values(item.plans).length}</Tag>
                </Tooltip>
                <Tooltip
                  title={
                    <Typography color={COLORS.APP_BACKGROUND_LEVEL_1} variant="caption1">
                      <b>{item.name}</b> is part of these Feature Groups:
                      <hr />
                      {Object.values(item.featureGroups).map((featureGroup) => (
                        <Link
                          key={featureGroup.id}
                          style={{ color: COLORS.APP_BACKGROUND_LEVEL_1, display: 'block' }}
                          to={route(ROUTES.CAPABILITIES.FEATURE_GROUP, { name: featureGroup.name })}
                        >
                          {featureGroup.name}
                        </Link>
                      ))}
                    </Typography>
                  }
                >
                  <Tag>Feature Groups: {Object.values(item.featureGroups).length}</Tag>
                </Tooltip>
              </>
            </Skeleton>
          </List.Item>
        )}
      />
    </Layout>
  )
}
