import { all } from 'redux-saga/effects'

import authSaga from 'redux/auth/sagas'
import loginSaga from 'redux/login/sagas'
import phoneNumbersSaga from 'redux/phone_numbers/sagas'
import snackbarSaga from 'redux/snackbar/sagas'

export default function* GlobalSaga() {
  yield all([authSaga(), loginSaga(), snackbarSaga(), phoneNumbersSaga()])
}
