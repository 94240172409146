import { CheckCircleOutlined, FileTextOutlined, GithubOutlined, StarOutlined } from '@ant-design/icons'
import { SPACING, Layout, Typography, Badge, COLORS } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { useMutation } from '@tanstack/react-query'
import { Button, Col, List, Popconfirm, Row, Skeleton, Tag, Tooltip } from 'antd'
import { useMemo } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import { deleteCapability } from 'api/capabilities'
import { ROUTES } from 'constants/routes'
import { useFeatureGroups } from 'hooks/useFeatureGroups'
import { formatDate } from 'utils/formatters'

export function Capability(): JSX.Element {
  const { name: capabilityName } = useParams<{ name: string }>()
  const history = useHistory()

  const { capabilities, isLoading } = useFeatureGroups()
  const capability = useMemo(() => capabilities[capabilityName], [capabilities, capabilityName])

  const plans = useMemo(() => {
    return Object.values(capability?.plans || {}).sort((a, b) => a.name.localeCompare(b.name))
  }, [capability?.plans])

  const featureGroups = useMemo(() => {
    return Object.values(capability?.featureGroups || {}).sort((a, b) => a.name.localeCompare(b.name))
  }, [capability?.featureGroups])

  const { mutateAsync: removeCapability } = useMutation({
    mutationFn: (name: string) => {
      return deleteCapability({ name })
    },
    onSuccess() {
      history.push(ROUTES.CAPABILITIES.CAPABILITIES)
    },
  })

  return (
    <Layout padding={SPACING[8]}>
      <Typography variant="overline">
        <CheckCircleOutlined /> Capability{' '}
      </Typography>
      <h1>{capability?.name}</h1>
      <Row gutter={50}>
        <Col>
          <Typography variant="caption1">Description</Typography>
          <Typography variant="body1">{capability?.description || '(no description)'}</Typography>
        </Col>
        <Col>
          <Typography variant="caption1">Created</Typography>
          <Typography variant="body1">{formatDate(capability?.createdAt)}</Typography>
        </Col>
        <Col>
          <Typography variant="caption1">Code</Typography>
          <Typography variant="body1">
            <a
              href={`https://github.com/search?q=org%3AShimmur+${encodeURIComponent(capability?.name || '')}&type=code`}
              rel="noreferrer"
              style={{ color: COLORS.LINKS }}
              target="_blank"
            >
              <GithubOutlined /> Search GitHub Org
            </a>
          </Typography>
        </Col>
        <Col>
          <Typography variant="caption1">Delete</Typography>
          <Typography variant="body1">
            {plans.length > 0 || featureGroups.length > 0 ? (
              <Tooltip title={'Only capabilities that are not used in Plans or Feature Groups can be deleted.'}>
                <Button disabled shape="round" size="small">
                  Delete Capability
                </Button>
              </Tooltip>
            ) : (
              <Popconfirm
                cancelText="No"
                okText="Yes"
                onCancel={(e) => e?.stopPropagation()}
                onConfirm={async (e) => {
                  e?.stopPropagation()
                  removeCapability(capabilityName)
                }}
                placement="bottom"
                title={
                  <>
                    Are you sure you want to delete the Capability <b>“{capabilityName}”</b>?
                  </>
                }
              >
                <Button shape="round" size="small">
                  Delete Capability
                </Button>
              </Popconfirm>
            )}
          </Typography>
        </Col>
      </Row>
      <Row gutter={50}>
        <Col span={12}>
          <Typography display="block" marginTop={SPACING[3]} variant="caption1">
            Included in Plans <Badge>{Object.keys(capability?.plans || {}).length}</Badge>
          </Typography>
          <List loading={isLoading}>
            {plans.length === 0 && (
              <List.Item style={{ opacity: 0.5 }}>
                <i>{capabilityName}</i> is not used in any Plans
              </List.Item>
            )}
            {plans.map((plan) => {
              return (
                <List.Item key={plan.name}>
                  <Skeleton active avatar loading={isLoading} title={false}>
                    <List.Item.Meta
                      avatar={<FileTextOutlined />}
                      description="(no description)"
                      title={<Link to={route(ROUTES.CAPABILITIES.PLAN, { name: plan.name })}>{plan.name}</Link>}
                    />
                    {plan.deprecated === true && <Tag>Deprecated</Tag>}
                  </Skeleton>
                </List.Item>
              )
            })}
          </List>
        </Col>
        <Col span={12}>
          <Typography display="block" marginTop={SPACING[3]} variant="caption1">
            Included in Feature Groups <Badge>{Object.keys(capability?.featureGroups || {}).length}</Badge>
          </Typography>
          <List loading={isLoading}>
            {featureGroups.length === 0 && (
              <List.Item style={{ opacity: 0.5 }}>
                <i>{capabilityName}</i> is not used in any Feature Groups
              </List.Item>
            )}
            {featureGroups.map((featureGroup) => {
              return (
                <List.Item key={featureGroup.name}>
                  <Skeleton active avatar loading={isLoading} title={false}>
                    <List.Item.Meta
                      avatar={<StarOutlined />}
                      description="(no description)"
                      title={
                        <Link to={route(ROUTES.CAPABILITIES.FEATURE_GROUP, { name: featureGroup.name })}>
                          {featureGroup.name}
                        </Link>
                      }
                    />
                    {featureGroup.deprecated === true && <Tag>Deprecated</Tag>}
                  </Skeleton>
                </List.Item>
              )
            })}
          </List>
        </Col>
      </Row>
    </Layout>
  )
}
