import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'
import { route } from 'utils/router'

export function getPresignedUrl({ client_id, operation, extension, file_suffix }: any) {
  return request(
    route(ENDPOINTS.CLIENTS.PRESIGNED_URL, {
      client_id,
      operation,
      extension,
      file_suffix,
    }),
    {
      method: HTTP.GET,
    },
  )
}

export function patchSettings({ params: { clientId }, body }: any) {
  return request(route(ENDPOINTS.ADMINS.CLIENT_SETTINGS, { client_id: clientId }), {
    method: HTTP.PATCH,
    body: body,
  }).then(({ data }) => data)
}

export function getSettings(id: string): Promise<any> {
  return request(route(ENDPOINTS.ADMINS.CLIENT_SETTINGS, { client_id: id }), {
    method: HTTP.GET,
  }).then(({ data }) => data)
}
