import * as types from './constants'

const initialState = {
  collapsed: false,
  opened: false,
}

const sidebarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.OPEN_SIDEBAR:
      return { ...state, opened: true }

    case types.CLOSE_SIDEBAR:
      return { ...state, opened: false }

    case types.COLLAPSE_SIDEBAR:
      return { ...state, collapsed: true }

    case types.UNCOLLAPSE_SIDEBAR:
      return { ...state, collapsed: false }

    default:
      return state
  }
}

export default sidebarReducer
