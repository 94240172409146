import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Descriptions, Typography, notification, Modal } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import { MessageLimitSection } from './MessageLimit'

import { SubscriptionV2, deleteSubscription, postBillingPaywall } from 'api/billing'
import { putClient } from 'api/clients'
import { QUERIES } from 'constants/queries'
import { Switch } from 'shared/components/Switch'

const StyledSection = styled.section`
  padding: 20px 0;
`

const StyledButton = styled(Button)`
  margin: 20px 20px 0 0;
`
const shouldShowCancelSubscription = (subscription?: SubscriptionV2): boolean => {
  const { billingSubscriptionId, billingProvider } = subscription || {}
  // We need a subscription id to do the request
  if (!billingSubscriptionId) return false
  // We dont want to use this endpoint for recurly subscriptions
  // We should use the reculry portal or let them go through the dunning cycle
  if (billingProvider === 'recurly') return false
  return true
}

export function Subscription({ client, subscription }: { client: any; subscription?: SubscriptionV2 }): JSX.Element {
  const queryClient = useQueryClient()
  const recurlyAccountLink = `https://${import.meta.env.VITE_RECURLY_ACCOUNT_BASE_URL}/accounts/${client.id}`
  const [showEnforcePaywallModal, setShowEnforcePaywallModal] = useState(false)
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false)
  const { mutate: paywallClient } = useMutation(postBillingPaywall, {
    onError() {
      notification.error({
        message: 'Could not paywall client. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
    onSuccess() {
      notification.success({
        message: 'Success',
        placement: 'bottomRight',
        duration: 2,
      })
      queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])
    },
  })

  const { mutate: cancelSubscription } = useMutation(deleteSubscription, {
    onError() {
      notification.error({
        message: 'Could not cancel subscription. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
    onSuccess() {
      notification.success({
        message: 'Subscription cancelled',
        placement: 'bottomRight',
        duration: 2,
      })

      queryClient.setQueryData([QUERIES.BILLING.SUBSCRIPTION, { clientId: client.id }], undefined)
      queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])
    },
  })

  const { billingProvider, state, billingSubscriptionId } = subscription || {}
  const { planName, subtotal, collectionMethod, planId, description, planCode } = subscription?.subscription || {}

  return (
    <>
      <StyledSection>
        <Descriptions bordered column={1} title="Billing State">
          <Descriptions.Item label="Billing Required">
            <Switch
              defaultChecked={client.onboardingBillingState.billingRequired}
              name="Billing Required"
              onSuccess={() => queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])}
              prepare={(value) => ({
                id: client.id,
                body: { billing_required: value },
              })}
              updateEntity={putClient}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Billing Provided">
            {client.onboardingBillingState.billingProvided ? 'Yes' : 'No'}
          </Descriptions.Item>
        </Descriptions>
      </StyledSection>
      {billingProvider === 'recurly' && (
        <StyledSection>
          <Descriptions bordered column={1} title="Subscription">
            <Descriptions.Item label="Billing Provider">{billingProvider}</Descriptions.Item>
            <Descriptions.Item label="Plan">{planName}</Descriptions.Item>
            <Descriptions.Item label="Recurly Account">
              <a href={recurlyAccountLink} rel="noreferrer noopener" target="_blank">
                {recurlyAccountLink}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(subtotal || 0)}
            </Descriptions.Item>
            <Descriptions.Item label="Billing">
              <Typography.Text code>{collectionMethod}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Recurly ID">
              <Typography.Text code copyable>
                {planId}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Billing State">
              <Typography.Text code>{state}</Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </StyledSection>
      )}
      {billingProvider === 'stripe' && (
        <StyledSection>
          <Descriptions bordered column={1} title="Subscription">
            <Descriptions.Item label="Billing Provider">{billingProvider}</Descriptions.Item>
            <Descriptions.Item label="Plan">{planName}</Descriptions.Item>
            <Descriptions.Item label="Description">{description}</Descriptions.Item>
            <Descriptions.Item label="Plan Code">{planCode}</Descriptions.Item>
          </Descriptions>
        </StyledSection>
      )}
      {billingProvider === null && (
        <StyledSection>
          <Descriptions bordered column={1} title="Subscription">
            <Descriptions.Item label="Plan">{planName}</Descriptions.Item>
            <Descriptions.Item label="Description">{description}</Descriptions.Item>
            <Descriptions.Item label="Plan Code">{planCode}</Descriptions.Item>
          </Descriptions>
        </StyledSection>
      )}
      <StyledButton
        aria-label="Enforce paywall"
        disabled={!client.onboardingBillingState.billingRequired}
        onClick={() => setShowEnforcePaywallModal(true)}
        size="middle"
        type="primary"
      >
        Enforce Paywall
      </StyledButton>
      {shouldShowCancelSubscription(subscription) && (
        <StyledButton
          aria-label="Cancel subscription"
          onClick={() => setShowCancelSubscriptionModal(true)}
          size="middle"
          type="primary"
        >
          Cancel Subscription
        </StyledButton>
      )}
      <MessageLimitSection client={client} />

      <Modal
        onCancel={() => setShowEnforcePaywallModal(false)}
        onOk={() => {
          paywallClient({
            clientId: client.id,
          })
          setShowEnforcePaywallModal(false)
        }}
        open={showEnforcePaywallModal}
        title="Enforce Paywall"
      >
        <p>This action will lock the leader out of the app until they add a credit card.</p>
      </Modal>
      <Modal
        onCancel={() => setShowCancelSubscriptionModal(false)}
        onOk={() => {
          cancelSubscription({
            clientId: client.id,
            billingSubscriptionId: billingSubscriptionId!,
          })
          setShowCancelSubscriptionModal(false)
        }}
        open={showCancelSubscriptionModal}
        title="Cancel Subscription"
      >
        <p>
          This action will remove the leaders subscription, set the account to inactive and set the billing provided to
          false.
        </p>
      </Modal>
    </>
  )
}
