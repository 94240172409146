import { HTTP, route } from '@community_dev/requests'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'

type CommunityPlusSubscription = {
  client_id: string
  client_url_slug: string
  fan_id: string
  fan_subscription_id: string
  invite_id: string
  phone_number: string
  recurly_account_id: string | null
  recurly_subscription_id: string | null
  status: 'invite_viewed'
  tag_id: string
  updated_at: string
}

type GetCommunityPlusSubscriptions = {
  data: CamelCasedPropertiesDeep<CommunityPlusSubscription>[]
}
export const getCommunityPlusSubscriptions = (phoneNumber: string): Promise<GetCommunityPlusSubscriptions> => {
  return request(route(ENDPOINTS.CLIENTS.COMMUNITY_PLUS_SUBSCRIPTIONS, {}, { phone_number: phoneNumber }))
}

export const refreshInviteLink = (inviteId: string): Promise<{ inviteUrl: string }> => {
  return request(route(ENDPOINTS.CLIENTS.COMMUNITY_PLUS_INVITE_LINK, { inviteId }), { method: HTTP.PATCH })
}
