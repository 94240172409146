import { useQuery } from '@tanstack/react-query'
import React, { createContext, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { REHYDRATE } from 'redux-persist/lib/constants'

import { getRefreshToken } from 'api/capabilities'
import { QUERIES, STALE_TIME } from 'constants/queries'
import { setAuth } from 'redux/auth/actions'

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const CapabilitiesContext = createContext()

CapabilitiesContext.displayName = 'CapabilitiesContext'

const useCapabilities = () => {
  const dispatch = useDispatch()
  const {
    data: { capabilities = [] } = {},
    isLoading,
    isFetched,
  } = useQuery([QUERIES.TOKEN], getRefreshToken, {
    staleTime: STALE_TIME.FOREVER,
    onSuccess({ jwt }) {
      dispatch(setAuth({ token: jwt }))
      dispatch({ type: REHYDRATE })
    },
  })

  const userHasCapability = useCallback(
    (key) => {
      return capabilities.includes(key)
    },
    [capabilities],
  )

  return { userHasCapability, isLoading, isReady: isFetched }
}

export const CapabilitiesProvider = (props: any) => {
  const { userHasCapability, isReady } = useCapabilities()
  // we block rendering until capabilities have been fetched
  if (!isReady) return null
  return <CapabilitiesContext.Provider value={userHasCapability} {...props} />
}

// in your component you could use it like
// const canEditActivationMethod = useHasCapability(CAPABILITIES.ADMIN.ACTIVATION_METHOD.WRITE)
export function useHasCapability(key: string): boolean {
  const userHasCapability = React.useContext(CapabilitiesContext)
  if (!userHasCapability) {
    throw new Error('useHasCapability must be used within a CapabilitiesProvider')
  }
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  return userHasCapability(key)
}
