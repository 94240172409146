const msgs = {
  activateError: {
    level: 'danger',
    body: 'Unable to activate account',
  },
  inviteExpired: {
    level: 'danger',
    body: 'Your invitation has expired',
  },
  loginError: {
    level: 'danger',
    body: 'Invalid email or password. Please use Okta credentials.',
  },
  passwordChanged: {
    level: 'success',
    body: 'Your password has been changed',
  },
  passwordUnchanged: {
    level: 'danger',
    body: 'Unable to update password',
  },
  passwordEmailSent: {
    level: 'info',
    body: 'Email sent! Check your inbox',
  },
}

export default msgs
