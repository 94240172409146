import { MemberDataFilter } from '@community_dev/filter-dsl/src/subscription-data'
import { HTTP, route } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { OnboardingMessageTemplateType } from '@community_dev/types/lib/api/OnboardingMessages'
import identity from 'lodash/identity'
import keyBy from 'lodash/keyBy'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { FEATURE_GROUPS } from 'constants/feature-groups'
import { ASSIGNMENT } from 'constants/numbers'
import { createDigitsCountResponse } from 'utils/query'
import { request } from 'utils/request'

export type ClientSeat = CamelCasedPropertiesDeep<Api.V1.Seat>

export type Client = Omit<CamelCasedPropertiesDeep<Api.Client>, 'onboardingTasks'> & {
  onboardingTasks: {
    tasks: { [key: string]: CamelCasedPropertiesDeep<Api.OnboardingTask> }
    complete: boolean
  }
}

export type PendingInvite = {
  email: string
  id: string
  role: {
    name: string
  }
}

export function getClients(params = {}) {
  // @ts-expect-error TS(2339): Property 'page' does not exist on type {}.
  const { page, search, assigned, ...rest } = params
  const updated = {
    ...rest,
    page_number: page,
    'search[text]': search,
    isAssigned: String(assigned === ASSIGNMENT.ASSIGNED),
  }
  const query = createDigitsCountResponse(updated)
  return () => request(`${ENDPOINTS.CLIENTS.ALL}?${query}`).then(({ data: clients, ...rest }) => [clients, rest])
}

export function getClient(id: string): Promise<Client> {
  return request(route(ENDPOINTS.CLIENTS.INDIVIDUAL, { id })).then(
    ({ data }: CamelCasedPropertiesDeep<Api.ClientResponse>) => {
      const client = data
      return {
        ...client,
        onboardingTasks: {
          tasks: keyBy(client.onboardingTasks, 'title'),
          complete: client.onboardingTasks.every((task) => task.status === 'complete'),
        },
      }
    },
  )
}

export const deleteClient = (clientId: string) => {
  return request(route(ENDPOINTS.CLIENTS.DELETE, { clientId }), { method: HTTP.DELETE })
}

export function getContentReview(id: string): Promise<any> {
  return request(route(ENDPOINTS.CLIENTS.CONTENT_REVIEW, { id })).then(({ data }) => data.contentReviewRequired)
}

export function putContentReview({ id, body }: any) {
  return request(route(ENDPOINTS.CLIENTS.CONTENT_REVIEW, { id }), {
    method: HTTP.PUT,
    body,
  })
}

// This really should be a PATCH
export function putClient({ id, body }: any) {
  return request(route(ENDPOINTS.CLIENTS.INDIVIDUAL, { id }), {
    method: HTTP.PUT,
    body,
  })
}

export function patchClientSettings({ id, body }: any) {
  return request(route(ENDPOINTS.ADMINS.CLIENT_SETTINGS, { client_id: id }), {
    method: HTTP.PATCH,
    body,
  })
}

export function putProfileImage({ id, body }: any) {
  return request(route(ENDPOINTS.CLIENTS.PROFILE_IMAGE, { id }), {
    method: HTTP.PUT,
    body,
    defaultHeaders: false,
    serialize: identity,
  })
}

export function putVoicemail({ id, body }: any) {
  return request(route(ENDPOINTS.CLIENTS.VOICEMAIL, { id }), {
    method: HTTP.PUT,
    body,
    defaultHeaders: false,
    serialize: identity,
    responseMethod: 'text',
  })
}

export function postSendVcard(clientId: string): Promise<any> {
  return request(route(ENDPOINTS.CLIENTS.SEND_VCARD, { clientId }), {
    method: HTTP.POST,
  })
}

export function getTeamMembers(id: string): Promise<ClientSeat[]> {
  return request(route(ENDPOINTS.CLIENTS.TEAM_MEMBERS, { id })).then(({ data }) => data)
}

export function getPendingInvites(id: string): Promise<PendingInvite[]> {
  return request(route(ENDPOINTS.CLIENTS.PENDING_INVITES, { id })).then(({ data }) => data)
}

export function postSendInvite({ id, body }: any) {
  return request(route(ENDPOINTS.CLIENTS.SEND_INVITE, { id }), {
    method: HTTP.POST,
    body,
    responseMethod: 'text',
  })
}

export function deleteRemoveInvite({ id, inviteId }: { id: string; inviteId: string }) {
  return request(route(ENDPOINTS.CLIENTS.REMOVE_INVITE, { id, inviteId }), {
    method: HTTP.DELETE,
    responseMethod: 'text',
  })
}

export function putEditTeamMember({ memberId, body }: any) {
  return request(route(ENDPOINTS.SEATS.TEAM_MEMBER, { memberId }), {
    method: HTTP.PUT,
    body,
  })
}

export function deleteTeamMember({ id, memberId }: any) {
  return request(route(ENDPOINTS.CLIENTS.TEAM_MEMBER, { id, memberId }), {
    method: HTTP.DELETE,
    responseMethod: 'text',
  })
}

export function logOutTeamMember({ memberId }: { memberId: string }): Promise<{ msg: string }> {
  return request(route(ENDPOINTS.CLIENTS.SESSION_TEAM_MEMBER, { memberId }), { method: HTTP.DELETE })
}

export function putFanOnboardingMessage({
  id,
  messageId,
  body,
}: {
  id: string
  messageId: string
  body: { text: string; shortenLinks: boolean; campaignTemplateType?: OnboardingMessageTemplateType }
}): Promise<{ data: Api.OnboardingMessage }> {
  return request(route(ENDPOINTS.CLIENTS.FAN_ONBOARDING_MESSAGE, { id, messageId }), {
    method: HTTP.PUT,
    body,
  })
}

export function postImportOnboardingMessage({
  clientId,
  text,
  label,
  templateType,
  shortenLinks,
}: {
  clientId: string
  text: string
  label: string
  templateType?: OnboardingMessageTemplateType
  shortenLinks: boolean
}): Promise<CamelCasedPropertiesDeep<Api.OnboardingMessage>> {
  return request(route(ENDPOINTS.CLIENTS.FAN_ONBOARDING, { id: clientId }), {
    method: HTTP.POST,
    body: {
      text,
      label,
      shortenLinks,
      campaignTemplateType: templateType,
    },
  })
}

export function getOnboardingMessageLabels(clientId: string): Promise<Api.OnboardingMessageLabelsResponse> {
  return request(route(ENDPOINTS.CLIENTS.FAN_ONBOARDING_LABELS, { clientId }))
}

export function getKeywordMessages(id: string): Promise<any> {
  return request(route(ENDPOINTS.CLIENTS.KEY_WORDS, { id })).then(({ data }) => data)
}

export function putKeywordMessage({ id, messageId, body }: any) {
  return request(`${route(ENDPOINTS.CLIENTS.KEY_WORDS, { id })}/${messageId}`, {
    method: HTTP.PUT,
    body,
  })
}

export function postKeywordMessage({ id, body }: any) {
  return request(route(ENDPOINTS.CLIENTS.KEY_WORDS, { id }), {
    method: HTTP.POST,
    body,
  })
}

export function deleteKeywordMessage({ id, messageId }: any) {
  return request(`${route(ENDPOINTS.CLIENTS.KEY_WORDS, { id })}/${messageId}`, {
    method: HTTP.DELETE,
  })
}

export function postImpersonate({ body }: any) {
  return request(route(ENDPOINTS.CLIENTS.IMPERSONATE), {
    method: HTTP.POST,
    body,
  }).then(({ data }) => data)
}

export function postCreateClient({ body }: any) {
  return request(route(ENDPOINTS.CLIENTS.INVITE), {
    method: HTTP.POST,
    body,
  })
}

export function getMfaEnabled(id: string): Promise<any> {
  return request(route(ENDPOINTS.CLIENTS.REQUIRE_MFA, { id })).then((data) => {
    return {
      mfaEnabled: data['mfaEnabled'],
    }
  })
}

export function putEnableMfa({ id }: any) {
  return request(route(ENDPOINTS.CLIENTS.REQUIRE_MFA, { id }), {
    method: HTTP.PUT,
  })
}

export function deleteMfaRequired({ id }: any) {
  return request(route(ENDPOINTS.CLIENTS.REQUIRE_MFA, { id }), {
    method: HTTP.DELETE,
    serialize: identity,
    responseMethod: 'text',
  })
}

export function assignEnableDownload({ id }: { id: string }): Promise<any> {
  return request(
    route(ENDPOINTS.CLIENTS.FEATURE_GROUP, {
      clientId: id,
      featureGroupName: FEATURE_GROUPS.DATA_EXPORT_STANDARD,
    }),
    {
      method: HTTP.POST,
    },
  )
}

export function deleteEnableDownload({ id }: { id: string }): Promise<any> {
  return request(
    route(ENDPOINTS.CLIENTS.FEATURE_GROUP, {
      clientId: id,
      featureGroupName: FEATURE_GROUPS.DATA_EXPORT_STANDARD,
    }),
    {
      method: HTTP.DELETE,
    },
  )
}

export type Capability = {
  createdAt: string
  description: string | null
  id: string
  name: string
}

export type Role = CamelCasedPropertiesDeep<Api.V1.Role>
export type Community = CamelCasedPropertiesDeep<Api.Community>
export type Communities = CamelCasedPropertiesDeep<Api.Communities>
export type CommunitiesResponse = {
  data: Communities
}

export function updateClientCommunity(clientId: string, community: Community): Promise<{ data: Community }> {
  return request(route(ENDPOINTS.CLIENTS.TAG, { clientId, tagId: community.id }), {
    method: HTTP.PUT,
    body: community,
  })
}
export function createClientCommunity(
  clientId: string,
  community: Community & { tagType: string },
): Promise<{ data: Community }> {
  return request(route(ENDPOINTS.CLIENTS.TAGS, { clientId }), {
    method: HTTP.POST,
    body: community,
  })
}
export function deleteClientCommunity(clientId: string, communityId: string): Promise<Community> {
  return request(route(ENDPOINTS.CLIENTS.TAG, { clientId, tagId: communityId }), {
    method: HTTP.DELETE,
  })
}

export function getClientCommunities(clientId: string): Promise<CommunitiesResponse> {
  return request(route(ENDPOINTS.CLIENTS.TAGS, { clientId }))
}

export function getRoles(): Promise<Role[]> {
  return request(route(ENDPOINTS.CAPABILITIES.ROLES))
}

export function updateSeatRoles({ seatMappingId, roles }: { seatMappingId: string; roles: Role[] }): Promise<any> {
  return request(route(ENDPOINTS.CAPABILITIES.SEAT_MAPPINGS, { seatMappingId }), {
    method: HTTP.PUT,
    body: {
      role_ids: roles.map((role) => role.id),
    },
  })
}

export function transferProtectedRole({
  clientId,
  seatMappingId,
  role,
}: {
  clientId: string
  seatMappingId: string
  role: Role
}): Promise<unknown> {
  return request(route(ENDPOINTS.CAPABILITIES.TRANSFER_PROTECTED_ROLE, { seatMappingId, clientId, roleId: role.id }), {
    method: HTTP.PUT,
  })
}

export function getDownloadUrl({ clientId, fileName }: { clientId: string; fileName: string }): Promise<string> {
  return request(
    route(ENDPOINTS.DOWNLOAD, {
      clientId,
      fileName,
    }),
    { skipAuthentication: true },
  ).then((data) => data.url)
}

type PostCountByQueryArgs = {
  clientId: string
  communicationChannel: CommunicationChannel
  filters: MemberDataFilter
}
export const postCountByQuery = ({ clientId, communicationChannel, filters }: PostCountByQueryArgs): any => {
  return request(
    route(ENDPOINTS.SUBSCRIPTION_DATA.COUNT, { clientId }, { communication_channel: communicationChannel }),
    {
      body: {
        filters: {
          subscription_data: filters,
        },
      },
      method: HTTP.POST,
      camelCase: false,
    },
  ).then(({ data }) => data?.count || 0)
}
