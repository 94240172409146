import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './AdminGlyph.scss'

class AdminGlyph extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { className, ...rest } = this.props
    return (
      <div className={`AdminGlyph ${className}`} {...rest}>
        <span>Admin</span>
        <FontAwesomeIcon icon="shield-alt" />
      </div>
    )
  }
}

export default AdminGlyph
