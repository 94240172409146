export const COPY_TYPES = Object.freeze({
  COPY_ONLY: 'copy_only',
  CHECKBOX: 'copy_with_checkbox',
})

export function getCopyData(terms: any, copyType: any) {
  if (!terms) return { body: '', check_by_default: false }
  const term = terms.find((term: any) => term.type === copyType)
  return term ? term : { body: '', check_by_default: false }
}

export function getHtmlContentTextLength(html: string): number {
  const el = document.createElement('div')
  el.innerHTML = html.trim()
  return el.textContent?.length || 0
}
