import { BORDER_RADIUS, SPACING, Tooltip, Layout } from '@community_dev/pixels'
import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import styled from 'styled-components'

import { getHtmlContentTextLength } from './utils'

const StyledContainer = styled.div`
  border: 1px solid ${({ theme }) => theme?.COLORS?.BORDERS};
  border-radius: ${BORDER_RADIUS[1]};
`

const StyledReactQuill = styled(ReactQuill)`
  div.ql-container.ql-snow {
    border: none;
  }
`

const StyledToolbar = styled.div`
  border: none !important;
`

const CharacterLimit = styled.div`
  font-size: 14px;
  pointer-events: none;
  align-self: flex-end;
  margin: 0 ${SPACING[4]} ${SPACING[2]} ${SPACING[2]};
`

const StyledToolbarBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledTooltipTitle = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H3};
  padding-bottom: ${SPACING[2]};
`

const StyledTooltipContent = styled.div`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  padding: ${SPACING[4]};
`

const StyledTipsList = styled.ol`
  padding-left: 20px;
  text-indent: 0;
  li {
    margin: ${SPACING[4]} 0;
  }
`

const TooltipContent = () => (
  <StyledTooltipContent>
    <StyledTooltipTitle>Link Tips</StyledTooltipTitle>
    <StyledTipsList>
      <li>When adding a link you must include "https://" at the beginning of the URL</li>
    </StyledTipsList>
  </StyledTooltipContent>
)

const TooltipCharacterCount = ({ value }: { value: string }): JSX.Element | null => {
  return <StyledTooltipContent>{value}</StyledTooltipContent>
}

// We have had issues with copy and pasting(from slack into admin) adding extra HTML into the editor and inflating the character count. This is meant to strip any html thats not a link
const StripOutExtraHtml = (node, delta) => {
  const newOps = delta.ops.map((op) => {
    // Only allow links
    if (op?.attributes?.link) {
      return { attributes: { link: op.attributes.link }, insert: op.insert }
    }

    // For everything else just take the string
    return {
      insert: op.insert,
    }
  })

  delta.ops = newOps
  return delta
}

export function RichTextEditor({ placeholder, value, onChange, id = 'toolbar', charLimit }: any): JSX.Element {
  // intentionally using useState over useRef, because the element needs to be available
  // after the first render
  const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null)

  const editorModule = {
    toolbar: {
      container: `#${id}`,
    },
    clipboard: {
      matchers: [[Node.ELEMENT_NODE, StripOutExtraHtml]],
    },
  }

  return (
    <StyledContainer data-testid={`rich-text-editor-${id}`}>
      {
        // do not render react-quill until the toolbar is present, otherwise react-quill crashes the app
        toolbarRef && (
          <StyledReactQuill modules={editorModule} onChange={onChange} placeholder={placeholder} value={value} />
        )
      }
      <StyledToolbarBottom>
        <StyledToolbar id={id} ref={(ref) => setToolbarRef(ref)}>
          <Tooltip content={<TooltipContent />}>
            <button className="ql-link" data-testid={`link-button-${id}`}>
              Link
            </button>
          </Tooltip>
        </StyledToolbar>
        <Tooltip content={<TooltipCharacterCount value={value} />} disabled={!value} maxWidth={800}>
          <Layout display="flex">
            <CharacterLimit>{`${getHtmlContentTextLength(value)}/${charLimit}`}</CharacterLimit>
          </Layout>
        </Tooltip>
      </StyledToolbarBottom>
    </StyledContainer>
  )
}
