import { Picker } from 'emoji-mart'
import PropTypes from 'prop-types'
import React from 'react'
import 'emoji-mart/css/emoji-mart.css'
import './EmojiPicker.scss'

class EmojiPicker extends React.PureComponent {
  root: any
  constructor(props: any) {
    super(props)
    this.root = React.createRef()
  }

  componentDidMount() {
    // @ts-expect-error TS(2339): Property 'onOuterClick' does not exist on type 'Re... Remove this comment to see the full error message
    if (this.props.onOuterClick) {
      document.addEventListener('click', this.onOuterClick, { passive: true })
    }
  }

  componentWillUnmount() {
    // @ts-expect-error TS(2339): Property 'onOuterClick' does not exist on type 'Re... Remove this comment to see the full error message
    if (this.props.onOuterClick) {
      document.removeEventListener('click', this.onOuterClick)
    }
  }

  onOuterClick = (e: any) => {
    if (this.root.current && !this.root.current.contains(e.target)) {
      // @ts-expect-error TS(2339): Property 'onOuterClick' does not exist on type 'Re... Remove this comment to see the full error message
      this.props.onOuterClick()
    }
  }

  render() {
    // @ts-expect-error TS(2339): Property 'rootStyle' does not exist on type 'Reado... Remove this comment to see the full error message
    const { rootStyle, onOuterClick, ...rest } = this.props
    return (
      <div className="EmojiPicker-root" ref={this.root} style={rootStyle}>
        <div className="EmojiPicker-main">
          <Picker {...rest} />
        </div>
      </div>
    )
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
EmojiPicker.defaultProps = {
  title: 'Pick your emoji...',
  color: '#6e6cb2',
  emoji: 'point_up',
  native: true,
  showPreview: false,
  perLine: 8,
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
EmojiPicker.propTypes = {
  autoFocus: PropTypes.bool,
  color: PropTypes.string,
  emoji: PropTypes.string,
  emojiSize: PropTypes.string,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  onSkinChange: PropTypes.func,
  onOuterClick: PropTypes.func,
  native: PropTypes.bool,
  recent: PropTypes.array,
  set: PropTypes.string,
  showPreview: PropTypes.bool,
  showSkinTones: PropTypes.bool,
  title: PropTypes.string,
  rootStyle: PropTypes.object,
  perLine: PropTypes.number,
}

export default EmojiPicker
