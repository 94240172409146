import { Layout, SPACING, Typography } from '@community_dev/pixels'
import { Alert, Checkbox, Divider, Input, Select, Tag } from 'antd'
import partition from 'lodash/partition'
import { useMemo } from 'react'
import { Field, Form } from 'react-final-form'

import { StructuredCapabilityNameInput } from './StructuredCapabilityNameInput'

import { FeatureGroup } from 'api/capabilities'
import { useFeatureGroups } from 'hooks/useFeatureGroups'
import { StyledModal } from 'shared/components/Modal'

export type CreateCapabilityModalProps = {
  onSubmit(values: CapabilityFormValues): any
  defaultFeatureGroup?: FeatureGroup
  canAddToFeatureGroup?: boolean
  additionalCapabilities?: string[]
  featureGroups: FeatureGroup[]
  onClose(): any
}

export type CapabilityFormValues = {
  featureGroups: string[]
  newFeatureGroups: string[]
  name: string
  description: string
}

export function CreateCapabilityModal({
  onSubmit,
  defaultFeatureGroup,
  canAddToFeatureGroup = true,
  featureGroups,
  additionalCapabilities = [],
  onClose,
}: CreateCapabilityModalProps): JSX.Element {
  const { capabilities } = useFeatureGroups()
  const [featureGroupGroup, planGroup] = useMemo(
    () => partition(featureGroups, (fg) => fg.name.startsWith('feature:')),
    [featureGroups],
  )
  const featureCapabilities = useMemo(
    () => [...Object.keys(capabilities), ...additionalCapabilities],
    [capabilities, additionalCapabilities],
  )

  return (
    <Form<CapabilityFormValues>
      initialValues={{
        featureGroups: defaultFeatureGroup ? [defaultFeatureGroup.name] : [],
        name: 'feature::',
        description: '',
        newFeatureGroups: [],
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, valid, submitting }) => (
        <StyledModal
          confirmLoading={submitting}
          okButtonProps={{ disabled: !valid }}
          okText="Create"
          onCancel={onClose}
          onOk={handleSubmit}
          open={true}
          title={<>Create Capability</>}
          width={850}
        >
          <Layout paddingLeft={SPACING[5]} paddingRight={SPACING[5]}>
            <>
              <Divider>1. Capability Name *</Divider>
              <Typography component="p" textAlign="center" variant="caption1">
                Choose a fitting name for your capability, e. g. <code>feature:campaigns:schedule</code>. <br />
                Capability names <b>cannot be edited</b> once they have been created.
              </Typography>
              <StructuredCapabilityNameInput existingNames={featureCapabilities} value={values.name} />
            </>
            <Field name="description" validate={(val) => !val && 'Description is a required Field'}>
              {({ input, meta }) => (
                <Layout marginTop={SPACING[6]}>
                  <Divider>2. Capability Description *</Divider>
                  <Typography component="p" textAlign="center" variant="caption1">
                    Make sure to describe the capability sufficiently, e. g. “
                    <code>Enables a leader to schedule campaigns.</code>”. Be exact.
                  </Typography>
                  <Input
                    allowClear
                    aria-label="capability description"
                    onChange={input.onChange}
                    placeholder="e. g. Enables a leader to schedule campaigns."
                    size="large"
                    value={input.value}
                  ></Input>
                  {meta.error && !meta.pristine && (
                    <Alert
                      message={meta.error}
                      style={{ marginTop: SPACING[2], marginBottom: SPACING[2] }}
                      type="error"
                    />
                  )}
                </Layout>
              )}
            </Field>
            {canAddToFeatureGroup && (
              <Field name="featureGroups">
                {({ input }) => (
                  <Layout marginTop={SPACING[6]}>
                    <Divider>3. Optional: add Capability to Feature Groups or Plans</Divider>
                    <Typography component="p" textAlign="center" variant="caption1">
                      Add the new Capability to Feature Groups or Plans after its creation
                    </Typography>
                    <Select
                      aria-label="capability feature groups"
                      mode="multiple"
                      onChange={(values) => input.onChange({ target: { value: values } })}
                      placeholder="Add to Feature Groups or Plans (optional)"
                      size="large"
                      style={{ width: '100%' }}
                      value={values.featureGroups}
                    >
                      <Select.OptGroup key="Plans">
                        {planGroup.map((plan) => (
                          <Select.Option key={plan.name} title={plan.name} value={plan.name}>
                            {plan.name} {plan.deprecated && <Tag>Deprecated</Tag>}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup key="Feature Groups">
                        {featureGroupGroup.map((featureGroup) => (
                          <Select.Option key={featureGroup.name} title={featureGroup.name} value={featureGroup.name}>
                            {featureGroup.name} {featureGroup.deprecated && <Tag>Deprecated</Tag>}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Layout>
                )}
              </Field>
            )}
            {canAddToFeatureGroup && (
              <Field name="newFeatureGroups">
                {({ input }) => (
                  <Layout textAlign="center">
                    <Checkbox
                      checked={input.value.length === 1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          input.onChange({ target: { value: [values.name] } })
                        } else {
                          input.onChange({ target: { value: [] } })
                        }
                      }}
                    >
                      Create ad-hoc Feature Group of the same name
                    </Checkbox>
                  </Layout>
                )}
              </Field>
            )}
          </Layout>
        </StyledModal>
      )}
    </Form>
  )
}
