import { SPACING } from '@community_dev/pixels'
import { Card, Form, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'
import snakeCase from 'lodash/snakeCase'
import { useCallback, useEffect } from 'react'

import { SIGN_UP_FORM, SIGN_UP_FORM_FIELDS } from '../constants'
import { FormFieldValue } from '../types'

import { CustomFieldCard, CustomStringFieldCard, FieldCard, Fields } from './FieldCard'
import { FieldSelect } from './FieldSelect'

import { SubscriptionDataField } from 'api/subscriptionData'
import { CAPABILITIES } from 'constants/capabilities'
import { useHasCapability } from 'hooks/useCapabilities'

export type FanRegistrationFields = Fields[]

type CustomFieldsProps = {
  allFieldKeys: string[]
  form: FormInstance<any>
  fields: FanRegistrationFields
  customFanFields: SubscriptionDataField[]
  customFieldCount: number
  onFieldChange: (fields: FanRegistrationFields) => void
  onCustomFieldChange: (customFields?: SubscriptionDataField[]) => void
  setCustomFormFields: (customFormFields: SubscriptionDataField[]) => void
}

export const CustomFields = ({
  allFieldKeys,
  form,
  fields,
  customFanFields,
  customFieldCount,
  onFieldChange,
  onCustomFieldChange,
  setCustomFormFields,
}: CustomFieldsProps): JSX.Element => {
  const canCustomizeSignUpForm = useHasCapability(CAPABILITIES.ADMIN.CUSTOM_FROST.WRITE)
  const handleFieldChange = useCallback(
    (field: Fields) => {
      const newFields = fields.reduce((acc: FanRegistrationFields, currField: Fields) => {
        if (field.name === currField.name) {
          return [...acc, { ...field }] as FanRegistrationFields
        }

        return [...acc, currField]
      }, [] as FanRegistrationFields)
      onFieldChange(newFields)
    },
    [fields, onFieldChange],
  )

  const handleCustomFieldChange = useCallback(
    (field: SubscriptionDataField) => {
      const newFields = customFanFields?.reduce((acc, currField): SubscriptionDataField[] => {
        if (field.key === currField.key) {
          return [
            ...acc,
            {
              ...currField,
              ...field,
            },
          ] as SubscriptionDataField[]
        }
        return [...acc, currField] as SubscriptionDataField[]
      }, [] as SubscriptionDataField[])
      onCustomFieldChange(newFields)
    },
    [customFanFields, onCustomFieldChange],
  )

  const handleValuesChange = () => {
    const fieldsValue = form.getFieldsValue(true)
    const fields = fieldsValue[SIGN_UP_FORM_FIELDS] || []
    const newCustomFormFields: SubscriptionDataField[] = fields
      .map((field: FormFieldValue) => {
        if (field?.field) {
          return {
            fanEnabledStatus: field.fanEnabledStatus || 'is_enabled_required',
            key: (field.fieldKey || snakeCase(field.field)).trim(),
            name: field.field.trim(),
            source: 'fan',
            valueOptions: field.options ? field.options.map((option) => ({ value: option?.trim?.() })) : null,
            valueType: 'string',
          }
        }
        return false
      })
      .filter((x: SubscriptionDataField | false) => x)
      .sort((field1: SubscriptionDataField, field2: SubscriptionDataField) => field1.name.localeCompare(field2.name))
    setCustomFormFields(newCustomFormFields)
  }

  const filteredFanFields = customFanFields
    // filter out string fields since those will be handled by the form
    .filter((field) => field.valueType !== 'string')
    // filter out hidden fields
    .filter((field) => field.fanEnabledStatus !== 'not_enabled')
    .sort((field1, field2) => field1.name.localeCompare(field2.name))

  // initial trigger to setCustomFormFields
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleValuesChange(), [])

  return (
    <Card.Grid hoverable={false} style={{ width: '100%', padding: SPACING[5] }}>
      <Form form={form} layout="vertical" name={SIGN_UP_FORM} onValuesChange={handleValuesChange}>
        <Form.List name={SIGN_UP_FORM_FIELDS}>
          {(formFields, { add, remove }) => (
            <>
              <Typography.Text
                style={{
                  display: 'flex',
                  marginBottom: SPACING[4],
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Modify your form to collect Members information.{' '}
                {canCustomizeSignUpForm && customFanFields && (
                  <FieldSelect
                    customFanFields={customFanFields}
                    customFieldCount={customFieldCount}
                    newFieldCount={formFields.length}
                    onCreate={add}
                    onCustomFieldChange={onCustomFieldChange}
                    onSelect={handleCustomFieldChange}
                  />
                )}
              </Typography.Text>
              {fields.map((field) => (
                <FieldCard field={field} key={field.name} onChange={handleFieldChange} />
              ))}
              {filteredFanFields &&
                filteredFanFields.map((field) => (
                  <CustomFieldCard
                    field={field}
                    key={field.key}
                    onChange={canCustomizeSignUpForm ? handleCustomFieldChange : undefined}
                    onDelete={canCustomizeSignUpForm ? handleCustomFieldChange : undefined}
                  />
                ))}
              {formFields
                // sort form fields alphabetically to match frost preview and add field menu
                .sort((field1, field2) => {
                  const field1Value = form.getFieldValue([SIGN_UP_FORM_FIELDS, field1.name]) as FormFieldValue
                  const field2Value = form.getFieldValue([SIGN_UP_FORM_FIELDS, field2.name]) as FormFieldValue
                  // if fieldKey doesn't exist, that means this is a newly added custom field and we
                  // always want those to show below existing fields, sorted by when they were added
                  // to prevent form fields from shuffling around when user changes field names
                  const field1Name = field1Value?.fieldKey ? field1Value?.field || 'a' : `zzz-${field1.name}`
                  const field2Name = field2Value?.fieldKey ? field2Value?.field || 'b' : `zzz-${field2.name}`
                  return field1Name.localeCompare(field2Name)
                })
                .map((formField) => (
                  <CustomStringFieldCard
                    allFieldKeys={allFieldKeys}
                    field={formField}
                    fieldValue={form.getFieldValue([SIGN_UP_FORM_FIELDS, formField.name])}
                    key={`form-field-${formField.key}`}
                    onChange={(fieldValue) => {
                      // change fanEnabledStatus of matching form field
                      const fieldsValue = form.getFieldsValue(true)
                      const fields: FormFieldValue[] = fieldsValue[SIGN_UP_FORM_FIELDS] || []
                      const newSignUpFields = fields.map((f) => {
                        if (f === fieldValue) {
                          return {
                            ...fieldValue,
                            fanEnabledStatus:
                              fieldValue.fanEnabledStatus !== 'is_enabled_required'
                                ? 'is_enabled_required'
                                : 'is_enabled_optional',
                          }
                        }
                        return f
                      })
                      form.setFieldsValue({
                        [SIGN_UP_FORM_FIELDS]: newSignUpFields,
                      })
                      handleValuesChange()
                    }}
                    onRemove={
                      canCustomizeSignUpForm
                        ? (field) => {
                            const fieldValue: FormFieldValue = form.getFieldValue([
                              SIGN_UP_FORM_FIELDS,
                              field as number,
                            ])
                            // change fanEnabledStatus of matching field if it exists
                            if (fieldValue?.fieldKey) {
                              const newCustomFanFields = customFanFields.map((field) => {
                                if (field.key === fieldValue.fieldKey) {
                                  return {
                                    ...field,
                                    fanEnabledStatus: 'not_enabled' as const,
                                  }
                                }
                                return field
                              })
                              onCustomFieldChange(newCustomFanFields)
                            }
                            // remove from form
                            remove(field)
                          }
                        : undefined
                    }
                  />
                ))}
            </>
          )}
        </Form.List>
      </Form>
    </Card.Grid>
  )
}
