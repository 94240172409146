/* eslint-disable no-param-reassign */
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddlware from 'redux-saga'

// Import the global reducer and global saga
import GlobalReducer from './global-reducer'
import GlobalSaga from './global-saga'

// Setup history
export const history = createBrowserHistory({
  basename: import.meta.env.VITE_PUBLIC_URL,
})

// Setup middlwares
const sagaMiddleware = createSagaMiddlware()
const middlewares = [sagaMiddleware, routerMiddleware(history)]

// Use Redux DevTools
// @ts-expect-error TS(2339): Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// Configure redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'sidebar'],
  migrate: (state: any) => Promise.resolve(state),
}

// Setup store
const store = createStore(
  persistReducer(persistConfig, GlobalReducer(history)),
  // Allows redux devtools to watch sagas
  composeEnhancers(applyMiddleware(...middlewares)),
)

// Begin the Global Saga
sagaMiddleware.run(GlobalSaga)

export const persistor = persistStore(store)
export default store
