export const ROUTES = Object.freeze({
  ADMIN: {
    EDIT: '/admins/:id',
  },
  ADMINS: '/admins',
  CAPABILITIES: {
    CAPABILITIES: '/capabilities/capabilities',
    CAPABILITY: '/capabilities/capabilities/:name',
    FEATURE_GROUP: '/capabilities/feature-groups/:name',
    FEATURE_GROUPS: '/capabilities/feature-groups',
    PLAN: '/capabilities/plans/:name',
    PLANS: '/capabilities/plans',
    ROLE: '/capabilities/roles/:name',
    ROLES: '/capabilities/roles',
    ROOT: '/capabilities',
  },
  CLIENT: {
    AMB: '/clients/:id/amb',
    BILLING: '/clients/:id/billing',
    COMMUNITY_PLUS: '/clients/:id/community-plus',
    FEATURE_GROUPS: '/clients/:id/feature-groups',
    GENERAL: '/clients/:id',
    INFO: '/clients/:id/info',
    MESSAGES: '/clients/:id/messages',
    NUMBER_IMPORT: '/clients/:id/number-import',
    SIGN_UP: '/clients/:id/sign-up',
    TEAM: '/clients/:id/team',
  },
  CLIENTS: '/clients',
  MEMBER: '/member',
} as const)
