/* eslint-disable max-len */

import PropTypes from 'prop-types'
import React from 'react'

class CommunityLogo extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { color, ...rest } = this.props
    // @ts-expect-error TS(2339): Property 'height' does not exist on type '{ childr... Remove this comment to see the full error message
    if (!rest.height && !rest.width) rest.height = '28px'
    return (
      <svg {...rest} version="1.1" viewBox="0 0 598 110" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" id="Branding" stroke="none" strokeWidth="1">
          <g fill={color || 'currentColor'} id="00.-Title-Copy-3" transform="translate(-647.000000, -316.000000)">
            <g id="Group-3" transform="translate(219.000000, 316.000000)">
              <g id="Group-Copy" transform="translate(428.000000, 0.000000)">
                <g id="community">
                  <path
                    d="M0,56.6636504 C0,74.3074477 12.5779083,87.6532309 29.8016703,87.6532309 C48.2717931,87.6532309 56.9971828,74.6466131 58.1301069,64.5806283 L41.5863155,64.5806283 C40.3398828,69.6702682 35.5807005,73.4025269 29.6883779,73.4025269 C21.0764609,73.4025269 16.0906578,66.6167 16.0906578,56.6636504 C16.0906578,46.7106008 21.0764609,39.924774 29.6883779,39.924774 C35.5807005,39.924774 40.3398828,43.6570327 41.5863155,48.7466726 L58.1301069,48.7466726 C56.9971828,38.6806877 48.2717931,25.6739261 29.8016703,25.6739261 C12.5779083,25.6739261 0,39.0198532 0,56.6636504 Z"
                    id="Path"
                  />
                  <path
                    d="M95.2798569,87.5402956 C114.316874,87.5402956 126.441397,73.4025269 126.441397,56.5503555 C126.441397,39.6985438 114.316874,25.560847 95.2798569,25.560847 C76.2432,25.560847 64.1183172,39.6985438 64.1183172,56.5503555 C64.1183172,73.4025269 76.2432,87.5402956 95.2798569,87.5402956 Z M80.2091552,56.5503555 C80.2091552,45.918975 86.7814845,39.6985438 95.2798569,39.6985438 C103.77859,39.6985438 110.350919,45.918975 110.350919,56.5503555 C110.350919,67.1820957 103.77859,73.4025269 95.2798569,73.4025269 C86.7814845,73.4025269 80.2091552,67.1820957 80.2091552,56.5503555 Z"
                    id="Shape"
                  />
                  <path
                    d="M187.631912,52.5918666 C187.631912,44.3357233 191.597867,39.4723136 199.189972,39.4723136 C206.328764,39.4723136 208.708121,44.5619535 208.708121,51.1215501 L208.708121,86.2962093 L224.798959,86.2962093 L224.798959,49.3120682 C224.798959,35.5137168 217.546659,25.6739261 203.269076,25.6739261 C194.770703,25.6739261 188.764836,28.2752497 183.552448,34.0434003 C180.152955,28.8407532 174.374105,25.6739261 166.328686,25.6739261 C157.829953,25.6739261 151.937955,29.1800626 149.218072,34.2696305 L149.218072,27.0311634 L134.374028,27.0311634 L134.374028,86.2962093 L150.464505,86.2962093 L150.464505,52.2527011 C150.464505,44.3357233 154.543969,39.4723136 162.022566,39.4723136 C169.161717,39.4723136 171.541074,44.5619535 171.541074,51.1215501 L171.541074,86.2962093 L187.631912,86.2962093 L187.631912,52.5918666 Z"
                    id="Path"
                  />
                  <path
                    d="M291.071057,52.5918666 C291.071057,44.3357233 295.037012,39.4723136 302.629117,39.4723136 C309.767909,39.4723136 312.147626,44.5619535 312.147626,51.1215501 L312.147626,86.2962093 L328.238103,86.2962093 L328.238103,49.3120682 C328.238103,35.5137168 320.986164,25.6739261 306.708221,25.6739261 C298.209848,25.6739261 292.203981,28.2752497 286.991593,34.0434003 C283.5921,28.8407532 277.81325,25.6739261 269.767831,25.6739261 C261.269459,25.6739261 255.3771,29.1800626 252.657578,34.2696305 L252.657578,27.0311634 L237.813172,27.0311634 L237.813172,86.2962093 L253.90401,86.2962093 L253.90401,52.2527011 C253.90401,44.3357233 257.983114,39.4723136 265.462071,39.4723136 C272.600862,39.4723136 274.980219,44.5619535 274.980219,51.1215501 L274.980219,86.2962093 L291.071057,86.2962093 L291.071057,52.5918666 Z"
                    id="Path"
                  />
                  <path
                    d="M378.531431,60.7354343 C378.531431,68.9915776 374.567638,73.8549873 366.636448,73.8549873 C359.496216,73.8549873 356.776693,69.1048725 356.776693,62.2057507 L356.776693,27.0311634 L340.685855,27.0311634 L340.685855,64.0152326 C340.685855,78.1530013 348.051303,87.6532309 362.330328,87.6532309 C371.165983,87.6532309 377.061224,84.1472023 379.778224,79.0575624 L379.778224,86.2962093 L394.624431,86.2962093 L394.624431,27.0311634 L378.531431,27.0311634 L378.531431,60.7354343 Z"
                    id="Path"
                  />
                  <path
                    d="M423.019603,27.0311634 L408.173397,27.0311634 L408.173397,86.2962093 L424.266397,86.2962093 L424.266397,52.3656364 C424.266397,44.4486585 428.572517,39.4723136 436.388397,39.4723136 C443.526828,39.4723136 446.474448,44.2224284 446.474448,51.1215501 L446.474448,86.2962093 L462.563845,86.2962093 L462.563845,49.3120682 C462.563845,35.1744074 454.971379,25.6739261 440.694517,25.6739261 C431.855259,25.6739261 425.736603,29.1800626 423.019603,34.2696305 L423.019603,27.0311634 Z"
                    id="Path"
                  />
                  <path
                    d="M483.694466,19.3402719 C489.359086,19.3402719 493.43819,15.381747 493.43819,9.72667553 C493.43819,4.07163998 489.359086,0 483.694466,0 C478.029845,0 473.950741,4.07163998 473.950741,9.72667553 C473.950741,15.381747 478.029845,19.3402719 483.694466,19.3402719 Z M475.647966,86.2962093 L491.740966,86.2962093 L491.740966,27.0311634 L475.647966,27.0311634 L475.647966,86.2962093 Z"
                    id="Shape"
                  />
                  <path
                    d="M533.267103,72.0455054 C533.267103,72.0455054 531.677983,72.610901 529.526724,72.610901 C525.33231,72.610901 523.519776,70.8014191 523.519776,66.8425706 L523.519776,39.6985438 L533.37881,39.6985438 L528.561,27.0311634 L523.519776,27.0311634 L523.519776,12.3279991 L507.430379,12.3279991 L507.430379,27.0311634 L499.383879,27.0311634 L499.383879,39.6985438 L507.430379,39.6985438 L507.430379,68.5391172 C507.430379,79.9624832 512.640966,86.6353748 525.674638,86.6353748 C530.5465,86.6353748 533.267103,85.9566842 533.267103,85.9566842 L533.267103,72.0455054 Z"
                    id="Path"
                  />
                  <polygon
                    id="Path"
                    points="548.221414 109.821272 564.083793 109.821272 597.285966 27.0311634 580.515517 27.0311634 566.123345 66.6167 551.846483 27.0311634 534.73731 27.0311634 558.192155 85.2783533"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
CommunityLogo.defaultProps = {
  color: 'currentColor',
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
CommunityLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}

export default CommunityLogo
