import { COLORS, SPACING, composeValidators, email as emailRule, required as requiredRule } from '@community_dev/pixels'
import { RoleGroup } from '@community_dev/types/lib/api/v1/Roles'
import { Button, Col, Input, Modal, Row, Switch, Typography } from 'antd'
import partition from 'lodash/partition'
import { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { useAdminRoles } from './queries'

import { Role } from 'api/clients'
import { AdminRoles } from 'constants/roles'
import { ROUTES } from 'constants/routes'
import { RoleDetails } from 'screens/Clients/Client/components/Team/RoleDetails'
import { StyledModal } from 'shared/components/Modal'
import { RoleSelect } from 'shared/components/RoleSelect'
import { getPrimaryRole, isRoleDisabled, isRoleInRoles, switchPrimaryRole, toggleRole } from 'utils/roles'

const StyledRoleDetails = styled(RoleDetails)`
  margin-top: 35px;
`

const StyledHint = styled.div`
  text-align: center;
  color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
  margin-top: ${SPACING[4]};
`

function AddAdminForm({ onSubmit, onClose }: any) {
  const { data: roles = [] } = useAdminRoles()
  const primaryRoles = roles.filter((role) => role.group === RoleGroup.PRIMARY)
  return (
    <Form
      initialValues={{
        role: roles.find((role) => role.name === AdminRoles.READ_ONLY_ADMIN),
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <StyledModal
          confirmLoading={submitting}
          okButtonProps={{ disabled: !valid }}
          okText="Create"
          onCancel={onClose}
          onOk={handleSubmit}
          open={true}
          title="Create Admin"
        >
          <form data-testid="add-admin-form" onSubmit={handleSubmit}>
            <Field name="first_name" validate={requiredRule}>
              {({ input, meta }) => (
                <>
                  <label htmlFor="first_name">
                    First name
                    <Input id="first_name" type="text" {...input} />
                  </label>
                  {!meta.active && meta.touched && meta.modified && meta.error && (
                    <div>
                      <Typography.Text type="danger">First name {meta.error}</Typography.Text>
                    </div>
                  )}
                </>
              )}
            </Field>
            <Field name="last_name">
              {({ input, meta }) => (
                <>
                  <label htmlFor="last_name">
                    Last name
                    <Input id="last_name" type="text" {...input} />
                  </label>
                  {!meta.active && meta.touched && meta.modified && meta.error && (
                    <div>
                      <Typography.Text type="danger">Last name {meta.error}</Typography.Text>
                    </div>
                  )}
                </>
              )}
            </Field>
            <Field name="email" validate={composeValidators(requiredRule, emailRule)}>
              {({ input, meta }) => (
                <>
                  <label htmlFor="email">
                    Email
                    <Input id="email" type="email" {...input} />
                  </label>
                  {!meta.active && meta.touched && meta.modified && meta.error && (
                    <div>
                      <Typography.Text type="danger">Email {meta.error}</Typography.Text>
                    </div>
                  )}
                </>
              )}
            </Field>
            <StyledHint>
              Hint: New Admins should use their <b>Okta</b> password to log in.
            </StyledHint>
            <Field<Role> name="role">
              {({ input }) => (
                <>
                  <label htmlFor="role">Role</label>
                  <RoleSelect onChange={input.onChange} outline={true} roles={primaryRoles} value={input.value} />
                  <StyledRoleDetails roles={[input.value]} />
                </>
              )}
            </Field>
          </form>
        </StyledModal>
      )}
    />
  )
}

function EditAdminForm({ firstName, lastName, roles = [], email, seatMappingId, onSubmit, onClose, onDelete }: any) {
  const { data: allAdminRoles = [], isInitialLoading } = useAdminRoles()
  const history = useHistory()
  const [primaryRoles, nonPrimaryRoles] = useMemo(
    () => partition(allAdminRoles, (role) => role.group === RoleGroup.PRIMARY),
    [allAdminRoles],
  )

  const rolesWithNames = useMemo(
    () => allAdminRoles?.filter((adminRole) => roles?.some((role) => role.name === adminRole.name)),
    [allAdminRoles, roles],
  )

  // We shouldn't render the form if the roles are still loading
  // This is so the initialValues load correctly
  if (isInitialLoading) return null

  return (
    <>
      {seatMappingId === undefined ? (
        <Modal closable={false} destroyOnClose={true} onCancel={onClose} onOk={onClose} open={true} title="Error">
          Admin not found
        </Modal>
      ) : (
        <Form
          initialValues={{
            first_name: firstName,
            last_name: lastName,
            email: email,
            roles: rolesWithNames,
          }}
          onSubmit={(values) => onSubmit({ ...values, seatMappingId })}
          render={({ handleSubmit, submitting, valid }) => (
            <StyledModal
              confirmLoading={submitting}
              footer={
                <>
                  <Row>
                    <Col span={16} style={{ textAlign: 'left' }}>
                      <Button
                        danger
                        onClick={() => {
                          const confirm = Modal.confirm({
                            title: `Are you sure you want to delete Admin User ${firstName} ${lastName}?`,
                            okType: 'danger',
                            className: 'delete-user-confirm',
                            onOk: () => {
                              onDelete({ seatMappingId })
                              confirm.destroy()
                              history.push(ROUTES.ADMINS)
                            },
                          })
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                    <Col span={8}>
                      <Button onClick={onClose}>Cancel</Button>
                      <Button onClick={handleSubmit} type="primary">
                        OK
                      </Button>
                    </Col>
                  </Row>
                </>
              }
              okButtonProps={{ disabled: !valid }}
              okText="Save"
              onCancel={onClose}
              open={true}
              title="Edit Admin Member"
            >
              <form data-testid="edit-admin-form" onSubmit={handleSubmit}>
                <Field name="first_name" validate={requiredRule}>
                  {({ input }) => (
                    <>
                      <label data-testid="add-admin-first-name" htmlFor="first_name">
                        First name
                        <Input disabled id="first_name" type="text" {...input} />
                      </label>
                    </>
                  )}
                </Field>
                <Field name="last_name">
                  {({ input }) => (
                    <>
                      <label data-testid="add-admin-last-name" htmlFor="last_name">
                        Last name
                        <Input disabled id="last_name" type="text" {...input} />
                      </label>
                    </>
                  )}
                </Field>
                <Field name="email" validate={composeValidators(requiredRule, emailRule)}>
                  {({ input }) => (
                    <>
                      <label data-testid="add-admin-email" htmlFor="email">
                        Email
                        <Input disabled id="email" type="email" {...input} />
                      </label>
                    </>
                  )}
                </Field>
                <Field<Role[]> name="roles">
                  {({ input }) => (
                    <>
                      <label htmlFor="role">Role</label>
                      <RoleSelect
                        onChange={(role) => input.onChange(switchPrimaryRole(role, input.value))}
                        outline={true}
                        roles={primaryRoles}
                        value={getPrimaryRole(input.value)}
                      />
                      <label>Additional Roles</label>
                      {nonPrimaryRoles.map((role) => (
                        <Row gutter={16} key={role.id}>
                          <Col data-testid={`toggle-integration-${role.name}`} span={3}>
                            <Switch
                              defaultChecked={isRoleInRoles(role, input.value)}
                              disabled={isRoleDisabled(role)}
                              onChange={() => input.onChange(toggleRole(role, input.value))}
                              style={{ margin: '10px 0 0 5px' }}
                            />
                          </Col>
                          <Col span={20} style={{ color: COLORS?.SUBTEXT, marginTop: SPACING[3] }}>
                            {role.displayName || role.name}
                          </Col>
                        </Row>
                      ))}
                      <StyledRoleDetails roles={input.value} />
                    </>
                  )}
                </Field>
              </form>
            </StyledModal>
          )}
        />
      )}
    </>
  )
}

type AdminFormProps = {
  values?: {
    firstName?: string
    lastName?: string
    fullName?: string
    email?: string
    seat_mapping_id?: string
    roles?: Role[]
  }
  onSubmit(v: any): any
  onClose(): any
  onDelete?({ seatMappingId }: { seatMappingId: string }): any
}

export default function AdminForm({ values, onSubmit, onClose, onDelete }: AdminFormProps): JSX.Element {
  return values !== undefined ? (
    <EditAdminForm
      email={values.email}
      firstName={values.firstName}
      fullName={values.fullName}
      lastName={values.lastName}
      onClose={onClose}
      onDelete={onDelete}
      onSubmit={onSubmit}
      roles={values?.roles}
      seatMappingId={values.seat_mapping_id}
    />
  ) : (
    <AddAdminForm onClose={onClose} onSubmit={onSubmit} />
  )
}
