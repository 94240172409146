import { Layout, SPACING, Typography } from '@community_dev/pixels'
import { Alert, Button, Divider, Select } from 'antd'
import { useState } from 'react'
import { Field, Form } from 'react-final-form'

import { CapabilityFormValues, CreateCapabilityModal } from './CreateCapabilityModal'
import { FeatureGroupKind } from './FeatureGroup'
import { StructuredCapabilityNameInput } from './StructuredCapabilityNameInput'

import { FeatureGroup } from 'api/capabilities'
import { useFeatureGroups } from 'hooks/useFeatureGroups'
import { StyledModal } from 'shared/components/Modal'

export type CreateFeatureGroupFormResult = {
  capabilities: string[]
  name: string
  newCapabilities: CapabilityFormValues[]
}

export type CreateFeatureGroupModalProps = {
  onSubmit(c: CreateFeatureGroupFormResult): any
  featureGroups: FeatureGroup[]
  onClose(): any
  kind?: FeatureGroupKind
}

type CreateFeatureGroupFormValues = {
  capabilities: string[]
  name: string
  description: string
}

export function CreateFeatureGroupModal({
  onSubmit,
  kind = FeatureGroupKind.FEATURE_GROUP,
  featureGroups,
  onClose,
}: CreateFeatureGroupModalProps): JSX.Element {
  const { capabilities } = useFeatureGroups()
  const [isCreateCapabilityModalOpen, setIsCreateCapabilityModalOpen] = useState(false)
  const [newCapabilities, setNewCapabilities] = useState<CapabilityFormValues[]>([])
  const submitHandler = (values: CreateFeatureGroupFormValues) => {
    onSubmit({
      name: values.name,
      capabilities: values.capabilities,
      newCapabilities: newCapabilities,
    })
  }
  return (
    <>
      {isCreateCapabilityModalOpen && (
        <CreateCapabilityModal
          additionalCapabilities={newCapabilities.map((c) => c.name)}
          canAddToFeatureGroup={false}
          featureGroups={featureGroups}
          onClose={() => setIsCreateCapabilityModalOpen(false)}
          onSubmit={(c) => {
            setNewCapabilities((val) => [...val, c])
            setIsCreateCapabilityModalOpen(false)
          }}
        />
      )}
      <Form<CreateFeatureGroupFormValues>
        initialValues={{
          capabilities: [],
          name: kind === FeatureGroupKind.FEATURE_GROUP ? 'feature::' : 'plan:',
          description: '',
        }}
        keepDirtyOnReinitialize
        onSubmit={submitHandler}
      >
        {({ handleSubmit, submitting, values, valid }) => (
          <StyledModal
            confirmLoading={submitting}
            okButtonProps={{ disabled: !valid }}
            okText="Create"
            onCancel={onClose}
            onOk={handleSubmit}
            open={true}
            title={<>Create {kind === FeatureGroupKind.FEATURE_GROUP ? 'Feature Group' : 'Plan'}</>}
            width={850}
          >
            <Layout paddingLeft={SPACING[5]} paddingRight={SPACING[5]}>
              <>
                <Divider>1. {kind === FeatureGroupKind.FEATURE_GROUP ? 'Feature Group' : 'Plan'} Name *</Divider>
                <Typography component="p" textAlign="center" variant="caption1">
                  Choose a fitting name for your {kind === FeatureGroupKind.FEATURE_GROUP ? 'Feature Group' : 'Plan'},
                  e. g.{' '}
                  {kind === FeatureGroupKind.FEATURE_GROUP ? (
                    <code>feature:campaigns:schedule</code>
                  ) : (
                    <code>plan:growth_stage</code>
                  )}
                  .
                </Typography>
                <StructuredCapabilityNameInput
                  existingNames={featureGroups
                    .filter((featureGroup) => featureGroup.name.startsWith(kind))
                    .map((featureGroup) => featureGroup.name)}
                  fields={kind === FeatureGroupKind.PLAN ? ['kind', 'scope'] : ['kind', 'scope', 'action']}
                  value={values.name}
                />
              </>
              <Field name="capabilities">
                {({ input }) => (
                  <Layout marginTop={SPACING[6]}>
                    <Divider>
                      2. Optional: add Capabilities to your{' '}
                      {kind === FeatureGroupKind.FEATURE_GROUP ? 'Feature Group' : 'Plan'}
                    </Divider>
                    <Typography component="p" textAlign="center" variant="caption1">
                      Add Capabilities to the new Feature Group or Plan after its creation.
                      <br /> You can always do this later too.
                    </Typography>
                    <Select
                      allowClear
                      aria-label="capabilities"
                      mode="multiple"
                      onChange={(values) => input.onChange({ target: { value: values } })}
                      placeholder="Add Capabilities (optional)"
                      size="large"
                      style={{ width: '100%' }}
                      value={values.capabilities}
                    >
                      {Object.keys(capabilities).map((capabilityName) => (
                        <Select.Option key={capabilityName} value={capabilityName}>
                          {capabilityName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Layout>
                )}
              </Field>
              {newCapabilities.map((c) => (
                <Alert
                  closable
                  key={c.name}
                  message={
                    <>
                      Capability <b>{c.name}</b> will be created and added.
                    </>
                  }
                  onClose={() => setNewCapabilities((val) => val.filter((v) => v.name !== c.name))}
                  style={{ marginTop: SPACING[2], marginBottom: SPACING[2] }}
                />
              ))}
              <Layout marginTop={SPACING[2]} textAlign="center">
                … or,{' '}
                <Button onClick={() => setIsCreateCapabilityModalOpen(true)} shape="round" size="small">
                  Create a new Capability
                </Button>
              </Layout>
            </Layout>
          </StyledModal>
        )}
      </Form>
    </>
  )
}
