import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Layout, SPACING } from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { ImportActivationMode } from '@community_dev/types/lib/api/NumberImports'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form, Select, Typography, Radio, Upload, Button, Card, notification, Modal, Tooltip, Steps } from 'antd'
import { RcFile } from 'antd/es/upload'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { parse } from 'papaparse'
import { useCallback, useState } from 'react'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { useClientSettings } from '../hooks/useClientSettings'

import { IMPORT_DEFAULT_ONBOARDING_TCPA_TEXT, IMPORT_ONBOARDING_MESSAGES_LABELS, SOURCE_ACTIVATION } from './constants'
import { DownloadTemplate } from './DownloadTemplate'
import { PastImports } from './PastImports'
import { useUploadNumberImport } from './useUploadNumberImport'

import { Client, Community } from 'api/clients'
import { postNumberImports } from 'api/numberImports'
import { patchSettings } from 'api/signUp'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES } from 'constants/queries'
import { useHasCapability } from 'hooks/useCapabilities'
import { useCommunitiesQuery } from 'hooks/useCommunitiesQuery'
import { BodySpinner } from 'shared/components/BodySpinner'

dayjs.extend(customParseFormat)

const { Option } = Select
const { useForm, useWatch } = Form

type OnboardingMessage = CamelCasedPropertiesDeep<Api.OnboardingMessage>
type OnboardingMessages = OnboardingMessage[]

const toOnboardingMessages = (
  communicationChannel?: CommunicationChannel,
  importType?: ImportActivationMode,
  onboardingMessages?: OnboardingMessages,
): Array<[string, OnboardingMessage | undefined]> => {
  if (!communicationChannel || !importType || !onboardingMessages) return []

  return IMPORT_ONBOARDING_MESSAGES_LABELS[communicationChannel][importType].map((label) => {
    return [label, onboardingMessages.find((message) => message.label === label)]
  })
}

const isStringAllNumbers = (str: string) => {
  // check that the string contains only numbers
  return /^\d+$/.test(str)
}

const isPhoneNumbersValid = (data: any[]) => {
  const rowErrorIndex = data.findIndex((row) => {
    const phoneNumber = row?.phone_number
    return !phoneNumber || !isStringAllNumbers(phoneNumber)
  })

  if (rowErrorIndex !== -1) {
    notification.error({
      message: `Phone number must exist and be all numbers. Row ${rowErrorIndex + 1} is invalid.`,
      placement: 'bottomRight',
      duration: 4,
    })
    return false
  }
  return true
}

const isDateColumnsValid = (data: any[]) => {
  const dateColumnKeys = ['date_of_birth', 'consented_at', 'opt_in_date']
  const allowedDateFormats = ['YYYY-MM-DD', 'YYYY-MM-DDTHH:MM:SSZ', 'YYYY-MM-DDTHH:MM:SS.SSSSSSZ']
  const rowErrorIndex = data.findIndex((row) => {
    const hasInvalidDateFormat = dateColumnKeys.some((key) => {
      const date = row[key]

      // Skip validation if date is empty or undefined (dates are optional)
      if (!date || date.length === 0) {
        return false
      }

      // Return true if the date is invalid in all allowed formats
      return !allowedDateFormats.some((format) => dayjs(date, format, true).isValid())
    })

    return hasInvalidDateFormat
  })

  if (rowErrorIndex !== -1) {
    notification.error({
      message: `Date of birth, consented at, or opt in date must be in one of these formats: 
        YYYY-MM-DD, YYYY-MM-DDTHH:MM:SSZ, or YYYY-MM-DDTHH:MM:SS.SSSSSSZ. 
        Row ${rowErrorIndex + 1} is invalid.`,
      placement: 'bottomRight',
      duration: 4,
    })
    return false
  }
  return true
}

export const NumberImports = ({ client }: { client: Client }): JSX.Element => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  // Controls the Upload component state
  const [file, setFile] = useState<RcFile | null>(null)
  const [parsedCsv, setParsedCsv] = useState<unknown[] | null>(null)
  const queryClient = useQueryClient()
  const canImportNumbers = useHasCapability(CAPABILITIES.ADMIN.NUMBER_IMPORT.WRITE)
  const [form] = useForm()

  const { data: communities, isInitialLoading: isLoadingCommunities } = useCommunitiesQuery<Community[]>({
    clientId: client.id,
    options: {
      select: ({ data }) => {
        const { default: defaultCommunities, custom: customCommunities } = data
        return [...defaultCommunities, ...customCommunities]
      },
    },
  })
  const { settings, isLoading: isLoadingSettings } = useClientSettings(client.id)
  const { postPresignedUrl, postFile, isLoading: isProcessingUpload } = useUploadNumberImport()
  const { mutateAsync: mutateSettings, isLoading: isUpdatingSettings } = useMutation({
    mutationFn: patchSettings,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERIES.CLIENT_SETTINGS, { id: client.id }])
      notification.success({
        message: 'Source activation mode updated.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
    onError: () => {
      notification.error({
        message: 'Error updating source activation mode',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })

  const { mutate: mutateNumberImports, isLoading: isMutatingNumberImports } = useMutation({
    mutationFn: postNumberImports,
    onSuccess: () => {
      notification.success({
        message: 'Number import started',
        placement: 'bottomRight',
        duration: 2,
      })
      queryClient.invalidateQueries([QUERIES.NUMBER_IMPORTS, client.id])
    },
    onError: () => {
      notification.error({
        message: 'Error with number import',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })

  // 1. First thing we do is check if the source activation mode is already set.
  // If it is, we don't need to do anything.
  // If it isn't, we need to update the source activation mode in settings
  // 2. Then get the presigned url for the file upload
  // 3. Then upload the file to S3
  // 4. Then send the file and import data to the backend
  const onSubmit = useCallback(
    async (values: any) => {
      setIsConfirmationModalOpen(false)
      const { sourceActivationModes } = settings
      const defaultedSourceActivationModes = sourceActivationModes ?? []

      const isSourceActivationModeSet = defaultedSourceActivationModes.find(
        ({ source, fanActivationMode }) => source === SOURCE_ACTIVATION && fanActivationMode === values.importType,
      )

      if (!isSourceActivationModeSet) {
        const filteredOldSourceActivationModes = defaultedSourceActivationModes.filter(
          ({ source }) => source !== SOURCE_ACTIVATION,
        )

        try {
          await mutateSettings({
            params: { clientId: client.id },
            body: {
              sourceActivationModes: [
                ...filteredOldSourceActivationModes,
                { source: SOURCE_ACTIVATION, fanActivationMode: values.importType },
              ],
            },
          })
        } catch (error) {
          return
        }
      }

      try {
        const presignedUrlData = await postPresignedUrl({ clientId: client.id })
        const presignedUrl = presignedUrlData?.data?.uploadUrl

        const file = values.file.file
        await postFile({
          file: file,
          url: presignedUrl,
        })

        await mutateNumberImports({
          params: { clientId: client.id },
          body: {
            s3Uri: presignedUrlData?.data?.s3Uri,
            rowCount: parsedCsv?.length ?? 0,
            communicationChannel: values.communicationChannel,
            tagId: values.community,
            activationMode: values.importType,
            fileSize: file.size,
          },
        })
        form.resetFields()
        setParsedCsv(null)
        setFile(null)
      } catch (error) {
        return
      }
    },
    [client.id, form, mutateNumberImports, mutateSettings, parsedCsv?.length, postFile, postPresignedUrl, settings],
  )

  const validateCsvAndSetFile = useCallback(
    async (file) => {
      const isLt50M = file.size / 1024 / 1024 < 50
      if (!isLt50M) {
        notification.error({
          message: 'File must smaller than 50MB!',
          placement: 'bottomRight',
          duration: 2,
        })
        return false
      }

      try {
        const parsedCsv = parse(await file.text(), { header: true, skipEmptyLines: 'greedy' })
        const { errors, data } = parsedCsv
        if (errors.length > 0) {
          const [error] = errors
          notification.error({
            message: `Row ${error.row}: ${error.message}`,
            placement: 'bottomRight',
            duration: 2,
          })
          return false
        }

        // Checking to make sure all phone numbers are valid
        if (!isPhoneNumbersValid(data)) {
          return false
        }

        if (!isDateColumnsValid(data)) {
          return false
        }

        setFile(file)
        setParsedCsv(data)
        form.setFieldValue('file', file)
        return false
      } catch (error) {
        notification.error({
          message: 'Error parsing CSV file',
          placement: 'bottomRight',
          duration: 2,
        })
        return false
      }
    },
    [setFile, setParsedCsv, form],
  )

  const currentCommunicationChannel = useWatch('communicationChannel', form)
  const currentImportType = useWatch('importType', form)

  const currentOnboardingMessages = toOnboardingMessages(
    currentCommunicationChannel,
    currentImportType,
    client.onboardingMessages,
  )

  const onboardingMessagesSteps = currentOnboardingMessages.map(([label, message]) => {
    const isTCPA = label.includes('TCPA')
    if (isTCPA) {
      return {
        title: label,
        status: message?.text ? 'process' : 'wait',
        description: message?.text || IMPORT_DEFAULT_ONBOARDING_TCPA_TEXT[label],
      } as const
    }

    return {
      title: label,
      status: message?.text ? 'process' : 'error',
      description: message?.text,
    } as const
  })

  const hasRequiredFieldsFilledFn = useCallback(() => {
    const values = form.getFieldsValue()
    const hasNoOnboardingMessageErrors = onboardingMessagesSteps.every((step) => step.status !== 'error')
    return values.communicationChannel && values.importType && file && hasNoOnboardingMessageErrors
  }, [form, file, onboardingMessagesSteps])

  //Whatsappt only supports pre-opt
  const onCommunicationChannelChange = useCallback(
    (value: CommunicationChannel) => {
      if (value === CommunicationChannel.WHATS_APP) {
        form.setFieldValue('importType', ImportActivationMode.PREOPT)
      }
    },
    [form],
  )

  const whatsappSelected = currentCommunicationChannel === CommunicationChannel.WHATS_APP
  if (isLoadingCommunities || isLoadingSettings) {
    return (
      <Layout padding={`${SPACING[5]}`}>
        <BodySpinner />
      </Layout>
    )
  }

  const communicationChannels = client.communicationChannels.filter((channel) =>
    [CommunicationChannel.SMS, CommunicationChannel.WHATS_APP].includes(channel),
  )

  const isProcessing = isProcessingUpload || isUpdatingSettings || isMutatingNumberImports

  return (
    <Layout padding={`${SPACING[5]}`}>
      <Card>
        <Typography.Title level={5}>Number Import</Typography.Title>
        <Form form={form} id="importNumberForm" labelCol={{ span: 5 }} onFinish={onSubmit} wrapperCol={{ span: 8 }}>
          <Form.Item label="Channel" name="communicationChannel" rules={[{ required: true }]}>
            <Select onChange={onCommunicationChannelChange}>
              {communicationChannels.map((channel) => (
                <Option key={channel} value={channel}>
                  {channel}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Import Type" name="importType" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio disabled={whatsappSelected} value={ImportActivationMode.WEB}>
                Frost (web)
              </Radio>
              <Radio disabled={whatsappSelected} value={ImportActivationMode.TEXT}>
                Reply y
              </Radio>
              <Radio value={ImportActivationMode.PREOPT}>Pre-Opt</Radio>
            </Radio.Group>
          </Form.Item>
          {currentOnboardingMessages.length > 0 ? (
            <Form.Item wrapperCol={{ span: 17, offset: 4 }}>
              <Layout marginBottom={SPACING[4]}>
                <Typography.Text strong>
                  Onboarding Messages{' '}
                  <Tooltip
                    title={
                      'These messages will be sent out with this import. You can edit or create these messages in the Messages tab.'
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Typography.Text>
              </Layout>
              <Steps current={undefined} direction="vertical" items={onboardingMessagesSteps} size="small" />
            </Form.Item>
          ) : null}
          <Form.Item label="Add to Community" name="community" rules={[{ required: false }]}>
            <Select>
              {communities?.map((community) => (
                <Option key={community.id} value={community.id}>
                  {community.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Upload" name="file" rules={[{ required: true }]}>
            <Upload<File>
              accept=".csv"
              beforeUpload={validateCsvAndSetFile}
              fileList={file ? [file] : []}
              listType="picture-card"
              maxCount={1}
              multiple={false}
              onRemove={() => {
                setParsedCsv(null)
                setFile(null)
                form.setFieldValue('file', null)
              }}
            >
              <button style={{ border: 0, background: 'none' }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 8, offset: 4 }}>
            <Tooltip title={!canImportNumbers ? 'You do not have the capability to import numbers' : ''}>
              <Button
                disabled={!canImportNumbers || !hasRequiredFieldsFilledFn() || isProcessing}
                loading={isProcessing}
                onClick={() => setIsConfirmationModalOpen(true)}
                style={{ marginRight: SPACING[3] }}
                type="primary"
              >
                Import
              </Button>
            </Tooltip>
            <DownloadTemplate />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 8, offset: 4 }}>
            <Typography.Text type="secondary">* Only do one import per client at a time.</Typography.Text>
          </Form.Item>
          <Modal
            footer={[
              <Button form="importNumberForm" htmlType="submit">
                Submit
              </Button>,
            ]}
            okButtonProps={{ htmlType: 'submit', type: 'primary' }}
            onCancel={() => setIsConfirmationModalOpen(false)}
            open={isConfirmationModalOpen}
            title="Warning"
          >
            <p>
              Review the following onboarding messages that will be sent out with this import. You can edit or create
              these messages in the Messages tab.
            </p>
            <Layout>
              <Typography.Title level={5} style={{ marginBottom: SPACING[5], marginTop: SPACING[5] }}>
                Onboarding Messages
              </Typography.Title>
              {currentOnboardingMessages.map(([label, message]) => {
                const text = message?.text || IMPORT_DEFAULT_ONBOARDING_TCPA_TEXT[label]
                return (
                  <Layout display="flex" flexDirection="column" key={label} marginBottom={SPACING[5]}>
                    <Layout marginBottom={SPACING[4]}>
                      <Typography.Text strong>Label: </Typography.Text>
                      <Typography.Text>{label}</Typography.Text>
                    </Layout>
                    <Layout marginBottom={SPACING[4]}>
                      <Typography.Text strong>Text: </Typography.Text>
                      {text ? (
                        <Typography.Text>{text}</Typography.Text>
                      ) : (
                        <Typography.Text type="secondary">No onboarding message found</Typography.Text>
                      )}
                    </Layout>
                  </Layout>
                )
              })}
            </Layout>
          </Modal>
        </Form>
      </Card>
      <PastImports client={client} communities={communities} />
    </Layout>
  )
}
