import { ReloadOutlined } from '@ant-design/icons'
import { SPACING, Layout } from '@community_dev/pixels'
import { Api } from '@community_dev/types'
import { Typography, Card, Table, Button, Tooltip } from 'antd'
import { ColumnType } from 'antd/es/table'
import dayjs from 'dayjs'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { Client, Community } from 'api/clients'
import { useNumberImportsQuery } from 'hooks/useNumberImportsQuery'

type ColumnRecord = CamelCasedPropertiesDeep<Api.NumberImports.Record> & {
  communityName?: string
}

const formatDate = (value: string) => {
  return dayjs(value).format('MM/DD/YYYY HH:mm:ss')
}

const columns = [
  { dataIndex: 'id', title: 'Import ID' },
  { dataIndex: 'seatId', title: 'Seat ID' },
  { dataIndex: 'communicationChannel', title: 'Channel' },
  { dataIndex: 'activationMode', title: 'Mode' },
  { dataIndex: 'communityName', title: 'Community' },
  { dataIndex: 'rowCount', title: 'Row Count' },
  { dataIndex: 'processedRowCount', title: 'Processed Row Count' },
  { dataIndex: 'erroredRowCount', title: 'Errored Row Count' },
  { dataIndex: 'createdAt', title: 'Created At', render: formatDate },
  {
    dataIndex: 'updatedAt',
    title: 'Updated At',
    defaultSortOrder: 'descend',
    sorter: (a, b, sortOrder) => {
      const timeA = new Date(a.updatedAt).getTime()
      const timeB = new Date(b.updatedAt).getTime()
      return sortOrder === 'descend' ? timeA - timeB : timeB - timeA
    },
    render: formatDate,
  },
]

export const PastImports = ({ client, communities }: { client: Client; communities?: Community[] }): JSX.Element => {
  const {
    data: numberImports,
    refetch,
    isFetching,
  } = useNumberImportsQuery<ColumnRecord[]>({
    clientId: client.id,
    options: {
      select: ({ data }) => {
        return data.map((record) => {
          const community = communities?.find((community) => community.id === record.tagId)
          return { ...record, communityName: community?.title || record.tagId || '' }
        })
      },
      staleTime: 1000 * 60,
    },
  })

  return (
    <Card style={{ marginTop: SPACING[5] }}>
      <Layout alignItems="center" display="flex" justifyContent="space-between" paddingBottom={SPACING[3]}>
        <Typography.Title level={5}>Past Imports</Typography.Title>
        <Tooltip title="Refresh">
          <Button loading={isFetching} onClick={() => refetch()}>
            {isFetching ? null : <ReloadOutlined />}
          </Button>
        </Tooltip>
      </Layout>
      <Table columns={columns as ColumnType<ColumnRecord>[]} dataSource={numberImports} />
    </Card>
  )
}
