import { HTTP, route } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'

// DELETES all subscriptions for that number across all clients for SMS
export const deleteMemberByPhoneNumber = (phoneNumber: string) => {
  return request(route(ENDPOINTS.MEMBER.DELETE, {}, { phone_number: phoneNumber }), { method: HTTP.POST })
}

export const deleteMemberByFanId = (fanId: string) => {
  return request(route(ENDPOINTS.MEMBER.DELETE, {}, { id: fanId }), { method: HTTP.POST })
}
