import React from 'react'
import { connect } from 'react-redux'

import { login, resetLogin } from 'redux/login/actions'
import { Notif } from 'shared/components/base'
import { BoxForm } from 'shared/components/form'
import { SimpleInput } from 'shared/components/input'
import notifs from 'utils/notifs'
import { isEmail, isRequired, minLength } from 'utils/validators'

class LoginForm extends React.Component {
  submitButton: any
  constructor(props: any) {
    super(props)
    this.submitButton = React.createRef()
    this.state = {
      notif: {},
      email: { value: '', touched: false },
      password: { value: '', touched: false },
    }
  }

  componentDidUpdate(prevProps: any) {
    // @ts-expect-error TS(2339): Property 'submitting' does not exist on type 'Read... Remove this comment to see the full error message
    const { submitting, successful } = this.props
    if (prevProps?.submitting && !submitting && !successful) this.setState({ notif: notifs.loginError })
  }

  componentWillUnmount() {
    // @ts-expect-error TS(2339): Property 'resetLogin' does not exist on type 'Read... Remove this comment to see the full error message
    this.props.resetLogin()
  }

  emailError = () => {
    // @ts-expect-error TS(2339): Property 'email' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { value } = this.state.email
    if (isRequired.validator(value)) return isRequired.message
    if (!isEmail.validator(value)) return isEmail.message
    return ''
  }

  passwordError = () => {
    // @ts-expect-error TS(2339): Property 'password' does not exist on type 'Readon... Remove this comment to see the full error message
    const { value } = this.state.password
    if (isRequired.validator(value)) return isRequired.message
    const min = minLength(8)
    if (min.validator(value)) return min.message
    return ''
  }

  isValid = () => {
    // @ts-expect-error TS(2339): Property 'submitting' does not exist on type 'Read... Remove this comment to see the full error message
    if (this.props.submitting) return false
    if (this.emailError() || this.passwordError()) return false
    return true
  }

  touchEmail = () => {
    // @ts-expect-error TS(2339): Property 'email' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { email } = this.state
    if (email.touched) return
    email.touched = true
    this.setState({ email })
  }

  touchPassword = () => {
    // @ts-expect-error TS(2339): Property 'password' does not exist on type 'Readon... Remove this comment to see the full error message
    const { password } = this.state
    if (password.touched) return
    password.touched = true
    this.setState({ password })
  }

  onChangeEmail = (e: any) => {
    // @ts-expect-error TS(2339): Property 'email' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { email } = this.state
    email.value = e.target.value.replace(/\s+/g, '')
    this.setState({ email })
  }

  onChangePassword = (e: any) => {
    // @ts-expect-error TS(2339): Property 'password' does not exist on type 'Readon... Remove this comment to see the full error message
    const { password } = this.state
    password.value = e.target.value
    this.setState({ password })
  }

  onSubmit = (e: any) => {
    e.preventDefault()
    // @ts-expect-error TS(2339): Property 'successful' does not exist on type 'Read... Remove this comment to see the full error message
    if (this.props.successful) return
    this.touchEmail()
    this.touchPassword()
    if (!this.isValid()) return
    if (this.submitButton.current) this.submitButton.current.focus()
    // @ts-expect-error TS(2339): Property 'email' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { email, password } = this.state
    // @ts-expect-error TS(2339): Property 'login' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.login({ email: email.value, password: password.value })
  }

  renderHeader() {
    // @ts-expect-error TS(2339): Property 'notif' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { notif } = this.state
    if (!notif || !notif.body) return
    return <Notif {...notif} />
  }

  render() {
    // @ts-expect-error TS(2339): Property 'submitting' does not exist on type 'Read... Remove this comment to see the full error message
    const { submitting } = this.props
    // @ts-expect-error TS(2339): Property 'email' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { email, password } = this.state
    return (
      // @ts-expect-error TS(2769): No overload matches this call.
      <BoxForm header={this.renderHeader()} onSubmit={this.onSubmit} title="Admin Login">
        <SimpleInput
          // @ts-expect-error TS(2322): Type '{ label: string; name: string; autoComplete:... Remove this comment to see the full error message
          autoComplete="username"
          error={email.touched ? this.emailError() : ''}
          label="Email"
          name="email"
          onBlur={this.touchEmail}
          onChange={this.onChangeEmail}
          value={email.value}
        />
        <SimpleInput
          // @ts-expect-error TS(2322): Type '{ label: string; name: string; type: string;... Remove this comment to see the full error message
          autoComplete="current-password"
          error={password.touched ? this.passwordError() : ''}
          label="Okta password"
          name="password"
          onBlur={this.touchPassword}
          onChange={this.onChangePassword}
          type="password"
          value={password.value}
        />
        <input
          className={!this.isValid() ? 'is-disabled' : ''}
          ref={this.submitButton}
          type="submit"
          value={!submitting ? 'Login' : 'Loading...'}
        />
      </BoxForm>
    )
  }
}

const mapStateToProps = (state: any) => ({
  submitting: state.login.submitting,
  successful: state.login.successful,
})

export default connect(mapStateToProps, { login, resetLogin })(LoginForm)
