import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { getAdminRoles } from 'api/admins'
import { Role } from 'api/clients'
import { QUERIES, STALE_TIME } from 'constants/queries'

export function useAdminRoles(): UseQueryResult<Role[]> {
  return useQuery([QUERIES.ROLES_ADMINS], getAdminRoles, {
    staleTime: STALE_TIME.FOREVER,
  })
}
