import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Descriptions, notification, Popconfirm, Skeleton, Space } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

import { StyledSection } from '../styled'

import { putClient } from 'api/clients'
import { resetTCRStatus } from 'api/tcr'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES } from 'constants/queries'
import { useHasCapability } from 'hooks/useCapabilities'
import { useTCRRegistration } from 'hooks/useTCR'
import { Switch } from 'shared/components/Switch'
import { parseAuthToken } from 'utils/api'

const StyledTCR = styled.div`
  display: flex;
  width: 50px;
`

const StyledStatus = styled.div`
  display: inline-block;
  margin-right: 12px;
  text-transform: capitalize;
`

export function TCRData({ client, individualClient }: any) {
  const queryClient = useQueryClient()
  const canEditTCRExemption = useHasCapability(CAPABILITIES.ADMIN.BILLING.WRITE)
  const [token] = useState(parseAuthToken)
  const seatId = token?.sub
  const { data, isLoading: isRegistrationStatusLoading } = useTCRRegistration({
    clientId: client.id,
    seatId: seatId as string,
  })
  const registrationStatus = (data?.data?.status || '').replace('_', ' ')

  const { mutate: resetStatus } = useMutation(resetTCRStatus, {
    onSuccess() {
      notification.success({
        message: 'Status wipe completed',
        placement: 'bottomRight',
        duration: 2,
      })
      queryClient.invalidateQueries([QUERIES.TCR.REGISTRATION])
    },
    onError() {
      notification.error({
        message: 'Could not wipe status',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })

  function handleReset() {
    resetStatus({ clientId: client.id })
  }

  const isResetTCRDisabled =
    isRegistrationStatusLoading || !registrationStatus || registrationStatus === 'not submitted'

  return (
    <>
      <StyledSection>
        <Descriptions bordered column={1} title="TCR Data">
          <Descriptions.Item label="Exempt From TCR">
            <StyledTCR>
              <Space>
                <Switch
                  defaultChecked={individualClient?.data?.exemptFromInAppTcrSubmission}
                  disabled={!canEditTCRExemption}
                  name="TCR Exempt"
                  onSuccess={() => queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])}
                  prepare={(value) => ({
                    id: client.id,
                    body: { exempt_from_in_app_tcr_submission: value },
                  })}
                  updateEntity={putClient}
                />
              </Space>
            </StyledTCR>
          </Descriptions.Item>
          <Descriptions.Item label="TCR Status">
            <Skeleton active loading={isRegistrationStatusLoading} paragraph={false} title={{ width: 50 }} />
            {registrationStatus && (
              <div id="registrationStatus">
                <StyledStatus>{`${registrationStatus} `}</StyledStatus>
                {canEditTCRExemption && (
                  <Popconfirm
                    cancelText="No"
                    disabled={isResetTCRDisabled}
                    okText="Yes"
                    onConfirm={handleReset}
                    placement="bottomLeft"
                    title="Are you sure you want to wipe this TCR registration status?"
                  >
                    <Button disabled={isResetTCRDisabled} type="primary">
                      Reset
                    </Button>
                  </Popconfirm>
                )}
              </div>
            )}
          </Descriptions.Item>
        </Descriptions>
      </StyledSection>
    </>
  )
}
