import { FONT_SIZE, FONT_WEIGHT, Layout } from '@community_dev/pixels'
import { Typography, Tooltip } from 'antd'
import { useTheme } from 'styled-components'

import NotVisibleIcon from './icons/notVisible.svg?react'
import VisibleIcon from './icons/visible.svg?react'
import { StyledButtonContainer, StyledCardContainer, StyledIconButton } from './styled'

export const FieldCardLayout = ({
  inputLabel,
  isDisabled,
  isEnabled,
  tooltipText,
  children,
  onVisibleChange,
}: {
  inputLabel: string
  isDisabled?: boolean
  isEnabled: boolean
  tooltipText: string
  children: React.ReactNode
  onVisibleChange: () => void
}): JSX.Element => {
  const { COLORS } = useTheme() || {}

  return (
    <StyledCardContainer $enabled={isEnabled}>
      <Layout alignItems="center" display="flex" height="18px">
        <Typography.Text style={{ fontSize: FONT_SIZE[2], fontWeight: FONT_WEIGHT[5], color: COLORS?.SUBTEXT }}>
          {inputLabel}
        </Typography.Text>
        <StyledButtonContainer $isDisabled={isDisabled}>
          <Tooltip placement="top" title={<span>{tooltipText}</span>}>
            <StyledIconButton
              disabled={isDisabled}
              icon={isEnabled ? <VisibleIcon /> : <NotVisibleIcon />}
              onClick={onVisibleChange}
            />
          </Tooltip>
        </StyledButtonContainer>
      </Layout>
      {children}
    </StyledCardContainer>
  )
}
