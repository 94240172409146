import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Descriptions, notification, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useBillingClient } from '../../queries'

import { PLAN_TYPES, STATUSES } from './constants'

import { patchBillingClient } from 'api/billing'
import { putClient } from 'api/clients'
import { QUERIES } from 'constants/queries'
import { InlineEditField } from 'shared/components/InlineEditField/InlineEditField'
import { SelectInput } from 'shared/components/SelectInput'
import { isEmail } from 'utils/validators'

const StyledSection = styled.section`
  padding: 0 0 20px 0;
`

const StyledButton = styled(Button)`
  margin: 20px 0 0 0;
`

const emptyValue = { value: '', label: '' }
const getByValue = (collection: any) => (value: any) =>
  collection.find(({ value: currentValue }) => currentValue === value) || emptyValue
const toStatusByValue = getByValue(STATUSES)
const toPlanTypeByValue = getByValue(PLAN_TYPES)

export function Account({ client }: any) {
  const queryClient = useQueryClient()
  const { isInitialLoading, data, reset: refetchBillingClient, isFetching } = useBillingClient(client.id)
  const [selectedPlanType, setSelectedPlanType] = useState(emptyValue)
  const [selectedStatus, setSelectedStatus] = useState(emptyValue)

  const { mutate: save, isLoading: isSaveLoading } = useMutation(patchBillingClient, {
    onError() {
      notification.error({
        message: 'Could not update Account. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
    onSuccess() {
      notification.success({
        message: 'Account updated.',
        placement: 'bottomRight',
        duration: 2,
      })
      refetchBillingClient()
    },
  })

  const onSave = () => {
    save({
      clientId: client.id,
      body: {
        account_status: selectedStatus.value,
        plan_type: selectedPlanType.value,
      },
    })
  }

  useEffect(() => {
    const { accountStatus, planType } = data || {}
    setSelectedStatus(toStatusByValue(accountStatus))
    setSelectedPlanType(toPlanTypeByValue(planType))
  }, [isInitialLoading, data, setSelectedPlanType, setSelectedStatus])

  return (
    <StyledSection>
      <Descriptions bordered column={1} title="Account">
        <Descriptions.Item label="Account Status">
          {isFetching ? (
            <Spin />
          ) : (
            <SelectInput
              aria-label="Account status"
              onChange={(value: any) => setSelectedStatus(toStatusByValue(value))}
              options={STATUSES}
              value={selectedStatus.value}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Plan Type">
          {isFetching ? (
            <Spin />
          ) : (
            <SelectInput
              aria-label="Account plan type"
              onChange={(value: any) => setSelectedPlanType(toPlanTypeByValue(value))}
              options={PLAN_TYPES}
              value={selectedPlanType.value}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Billing Email">
          <InlineEditField
            defaultValue={client.email}
            name="email"
            onSuccess={() => queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])}
            prepare={(value) => ({
              id: client.id,
              body: { email: value },
            })}
            updateEntity={putClient}
            validation={isEmail.validator}
          />
        </Descriptions.Item>
      </Descriptions>
      <StyledButton aria-label="Save" loading={isSaveLoading} onClick={onSave} size="middle" type="primary">
        Save
      </StyledButton>
    </StyledSection>
  )
}
