import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { addAdmin as addAdminRPC, deleteAdmin as deleteAdminRPC, getAdmins } from 'api/admins'
import { Role, updateSeatRoles } from 'api/clients'
import { QUERIES } from 'constants/queries'
import { useToastMessage } from 'hooks/useNotification'

export type ApiAdmin = {
  email: string
  firstName: string
  id: null
  lastName: string
  roles: Role[]
  seatMappingId: string
}

export type Admin = {
  email: string
  firstName: string
  lastName: string
  fullName: string
  roles: Role[]
  seat_mapping_id: string
}

// This is migrated from redux
export function normalizeAdmins(admins: ApiAdmin[]): Admin[] {
  return admins.map((item: any) => {
    return {
      email: item.email,
      firstName: item.firstName,
      lastName: item.lastName,
      fullName: item.firstName + ' ' + item.lastName,
      roles: item.roles || [],
      seat_mapping_id: item.seatMappingId || '',
    }
  })
}

export function useAdmins() {
  const queryClient = useQueryClient()
  const { showNotification } = useToastMessage()
  const { isLoading: isLoadingAdmins, data: admins = [] } = useQuery([QUERIES.ADMINS], getAdmins, {
    placeholderData: [],
  })
  const { mutate: updateAdminRole } = useMutation(updateSeatRoles, {
    onSuccess() {
      queryClient.invalidateQueries([QUERIES.ADMINS])
      showNotification('Admin updated.', true)
    },
    onError() {
      showNotification('Could not update admin.', false)
    },
  })
  const { mutate: deleteAdmin } = useMutation(deleteAdminRPC, {
    onSuccess() {
      queryClient.invalidateQueries([QUERIES.ADMINS])
      showNotification('Admin deleted.')
    },
    onError() {
      showNotification('Could not delete admin.', false)
    },
  })
  const { mutate: addAdmin } = useMutation(addAdminRPC, {
    onSuccess() {
      queryClient.invalidateQueries([QUERIES.ADMINS])
      showNotification('Admin added.')
    },
    onError() {
      showNotification('Could not add admin.', false)
    },
  })

  return { admins, updateAdminRole, isLoadingAdmins, deleteAdmin, addAdmin }
}
