import { SPACING, FONT_SIZE } from '@community_dev/pixels'
import { Button } from 'antd'
import styled from 'styled-components'

export const StyledCardContainer = styled.div<{ $enabled?: boolean }>`
  border: 1px ${({ $enabled }) => ($enabled ? 'solid' : 'dashed')} ${({ theme }) => theme?.COLORS?.BORDERS};
  margin-bottom: ${SPACING[2]};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: ${SPACING[2]} ${SPACING[3]};

  .ant-btn::after {
    content: '';
    display: none;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    font-size: ${FONT_SIZE[1]};
    min-height: 16px;
  }
`

export const StyledButtonContainer = styled.div<{ $isDisabled?: boolean; $iconColor?: string }>`
  margin-left: auto;
  margin-right: 0;

  button,
  button[disabled] {
    border: none;
    box-shadow: none;
    background: white;
    border-color: white;
  }

  svg {
    path {
      fill: ${({ theme, $isDisabled, $iconColor }) =>
        $isDisabled ? theme?.COLORS?.BUTTON_DISABLED : $iconColor || theme?.COLORS?.BUTTON_PRIMARY};
    }
  }
`

export const StyledIconButton = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 14px;

  svg {
    flex-shrink: 0;
  }
`
