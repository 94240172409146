import PropTypes from 'prop-types'
import React from 'react'

import { Button } from 'shared/components/base'
import './Dialog.scss'

type DialogProps = {
  children?: React.ReactNode
  message?: React.ReactNode
  onOuterClick?: (...args: any[]) => void
  actions: {
    text?: string
    color?: string
    solid?: boolean
    onClick?: (...args: any[]) => void
  }[]
}

class Dialog extends React.Component<DialogProps> {
  onOuterClick = (e: any) => {
    const { onOuterClick } = this.props
    if (onOuterClick && e.target === e.currentTarget) onOuterClick()
  }

  renderActions() {
    const { actions } = this.props
    if (!actions || !actions.length) return
    return (
      <div className="Dialog-actions">
        {actions.map((action: any, idx: any) => {
          const { color, solid, text, onClick } = action
          let cname = 'Dialog-action'
          if (solid) cname += ' solid'
          if (color) cname += ` ${color}`
          const key = `action-${idx}`
          return (
            <div className={cname} key={key}>
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <Button onClick={onClick} text={text} />
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const { children, message } = this.props
    return (
      <div className="Dialog-root" onClick={this.onOuterClick}>
        <div className="Dialog-main">
          <div className="Dialog-content">{children || message}</div>
          {this.renderActions()}
        </div>
      </div>
    )
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Dialog.defaultProps = {
  actions: [],
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Dialog.propTypes = {
  children: PropTypes.node,
  message: PropTypes.node,
  onOuterClick: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      color: PropTypes.string,
      solid: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ),
}
export default Dialog
