import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { Layout, SPACING } from '@community_dev/pixels'
import { useQuery } from '@tanstack/react-query'
import { Button, Dropdown, Input, Popconfirm, Typography } from 'antd'
import differenceBy from 'lodash/differenceBy'
import partition from 'lodash/partition'
import { useMemo, useState } from 'react'

import { FeatureGroup, getClientFeatureGroups } from 'api/capabilities'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES } from 'constants/queries'
import { useHasCapability } from 'hooks/useCapabilities'
import { useFeatureGroups } from 'hooks/useFeatureGroups'
import { FeatureGroupList } from 'screens/CapabilitiesScreen/components/FeatureGroupList'
import { PlanList } from 'screens/CapabilitiesScreen/components/PlanList'
import { PlanMenu } from 'screens/CapabilitiesScreen/components/PlanMenu'
import { StyledModal } from 'shared/components/Modal'

export type ClientFeatureGroupsProps = {
  client: any
}

export function ClientFeatureGroups({ client }: ClientFeatureGroupsProps): JSX.Element {
  const canEditClientFeatureGroups = useHasCapability(CAPABILITIES.ADMIN.CLIENT_FEATURE_GROUP.WRITE)
  const { removeFeatureGroupFromClient: removeFeatureGroup, addClientToFeatureGroup } = useFeatureGroups()
  const { data: clientFeatureGroupsAndPlans, refetch: refetchClientFeatureGroups } = useQuery(
    [QUERIES.FEATURE_GROUPS],
    () => getClientFeatureGroups(client.id),
  )

  const { allFeatureGroupsAndPlans } = useFeatureGroups()

  const [isAddFeatureGroupModalOpen, setIsAddFeatureGroupModalOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [selectedPlans, selectedFeatureGroups] = useMemo(
    () =>
      partition(
        clientFeatureGroupsAndPlans?.sort((a, b) => a.name.localeCompare(b.name)),
        (fg) => fg.name.startsWith('plan:'),
      ),
    [clientFeatureGroupsAndPlans],
  )

  const [allPlans, allFeatureGroups] = useMemo(
    () => partition(allFeatureGroupsAndPlans, (fg) => fg.name.startsWith('plan:')),
    [allFeatureGroupsAndPlans],
  )

  const filteredSelectableFeatureGroups = useMemo(() => {
    return differenceBy(allFeatureGroups, selectedFeatureGroups, (a) => a.id).filter(
      (featureGroup) =>
        featureGroup.name.toLowerCase().includes(searchValue.toLowerCase()) && featureGroup.deprecated !== true,
    )
  }, [allFeatureGroups, selectedFeatureGroups, searchValue])

  async function selectPlan(plan: FeatureGroup) {
    // remove all plans
    await Promise.all(selectedPlans.map((plan) => removeFeatureGroup({ id: client.id, featureGroupName: plan.name })))
    // add the new plan
    await addClientToFeatureGroup({ client, featureGroup: plan })
    // reload the list
    await refetchClientFeatureGroups()
  }

  return (
    <div style={{ padding: `0 ${SPACING[8]} ${SPACING[8]} ${SPACING[8]}` }}>
      <StyledModal
        closable={false}
        footer={null}
        onCancel={() => {
          setIsAddFeatureGroupModalOpen(false)
          setSearchValue('')
        }}
        open={isAddFeatureGroupModalOpen}
        style={{ paddingTop: SPACING[5] }}
        title={
          <Input
            allowClear={true}
            autoFocus
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search Feature Groups …"
            prefix={<SearchOutlined />}
            value={searchValue}
          />
        }
      >
        <FeatureGroupList
          actions={(featureGroup) => [
            <Button
              color="blue"
              onClick={async () => {
                await addClientToFeatureGroup({ client, featureGroup })
                setIsAddFeatureGroupModalOpen(false)
                setSearchValue('')
                await refetchClientFeatureGroups()
              }}
              shape="round"
              size="small"
            >
              Add
            </Button>,
          ]}
          featureGroups={filteredSelectableFeatureGroups}
          showCapabilities={false}
        />
      </StyledModal>
      <h2 style={{ display: 'flex', justifyContent: 'space-between', marginTop: SPACING[5] }}>
        Plan
        {canEditClientFeatureGroups && (
          <Dropdown
            overlay={<PlanMenu onChange={selectPlan} plans={allPlans} value={selectedPlans} />}
            overlayStyle={{
              boxShadow: '0 0 10px rgba(0,0,0,.1)',
            }}
            trigger={['click']}
          >
            <Button>
              Change Plan <DownOutlined />
            </Button>
          </Dropdown>
        )}
      </h2>
      <Typography style={{ paddingBottom: SPACING[5] }}>
        By default, a leader should only have a single plan.
      </Typography>
      <Layout padding={SPACING[3]}>
        <PlanList plans={selectedPlans} />
      </Layout>
      <h2 style={{ display: 'flex', justifyContent: 'space-between', marginTop: SPACING[5] }}>
        Feature Groups
        {canEditClientFeatureGroups && (
          <Button onClick={() => setIsAddFeatureGroupModalOpen(true)}>Add Feature Group</Button>
        )}
      </h2>
      <Typography style={{ paddingBottom: SPACING[5] }}>
        Feature Groups are collections of capabilities that can be assigned to leader accounts in addition to the
        capabilities included in their plan.
      </Typography>
      <Layout padding={SPACING[3]}>
        <FeatureGroupList
          actions={(featureGroup) => [
            canEditClientFeatureGroups && (
              <Popconfirm
                cancelText="No"
                okText="Yes"
                onCancel={(e) => e?.stopPropagation()}
                onConfirm={async (e) => {
                  e?.stopPropagation()
                  await removeFeatureGroup({ id: client.id, featureGroupName: featureGroup.name })
                  await refetchClientFeatureGroups()
                }}
                placement="bottomLeft"
                title={
                  <>
                    Are you sure you want to remove the Feature Group <b>“{featureGroup.name}”</b> from{' '}
                    <b>
                      “{client.firstName} {client.lastName}”
                    </b>
                    ?
                  </>
                }
              >
                <Button
                  aria-label="delete feature group"
                  onClick={(e) => e.stopPropagation()}
                  shape="round"
                  size="small"
                >
                  Remove
                </Button>
              </Popconfirm>
            ),
          ]}
          featureGroups={selectedFeatureGroups}
        />
      </Layout>
    </div>
  )
}
