import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Navbar from 'containers/Navbar'
import Sidebar from 'containers/Sidebar'
import { closeSidebar } from 'redux/sidebar/actions'
import './SidebarLayout.scss'

class SidebarLayout extends React.PureComponent {
  dimmer: any
  constructor(props: any) {
    super(props)
    this.dimmer = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('touchstart', this.onDimmerTouch, {
      passive: false,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('touchstart', this.onDimmerTouch)
  }

  onDimmerTouch = (e: any) => {
    if (e.target === this.dimmer.current) {
      e.preventDefault()
      // @ts-expect-error TS(2339): Property 'closeSidebar' does not exist on type 'Re... Remove this comment to see the full error message
      this.props.closeSidebar()
      return false
    }
  }

  onResize = () => {
    // @ts-expect-error TS(2339): Property 'opened' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.props.opened && window.innerWidth >= 1080) {
      // @ts-expect-error TS(2339): Property 'closeSidebar' does not exist on type 'Re... Remove this comment to see the full error message
      this.props.closeSidebar()
    }
  }

  render() {
    // @ts-expect-error TS(2339): Property 'collapsed' does not exist on type 'Reado... Remove this comment to see the full error message
    const { children, collapsed, opened, hideNavbar, closeSidebar } = this.props
    let cname = 'SidebarLayout-root'
    if (collapsed) cname += ' sidebar-collapsed'
    if (opened) cname += ' sidebar-opened'
    return (
      <div className={cname}>
        <Sidebar />
        <div className="SidebarLayout-main">
          {!hideNavbar && <Navbar />}
          {children}
          {opened && <div className="SidebarLayout-dimmer" onClick={closeSidebar} ref={this.dimmer} />}
        </div>
      </div>
    )
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
SidebarLayout.propTypes = {
  children: PropTypes.node,
  hideNavbar: PropTypes.bool,
  closeSidebar: PropTypes.func,
  collapsed: PropTypes.bool,
  opened: PropTypes.bool,
}

const mapStateToProps = (state: any) => ({
  collapsed: state.sidebar.collapsed,
  opened: state.sidebar.opened,
})

export default connect(mapStateToProps, { closeSidebar })(SidebarLayout)
