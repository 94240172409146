import * as types from './constants'

export const setAuth = (payload: any) => ({
  type: types.SET_AUTH,
  payload,
})
export const verify2FA = (payload: any) => ({
  type: types.VERIFY_2FA,
  payload,
})
export const resetAuth = () => ({ type: types.RESET_AUTH })
