import { RoleGroup } from '@community_dev/types/lib/api/v1/Roles'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'

import { Role } from 'api/clients'
import { DISABLED_ROLES } from 'constants/roles'

/** Is a role applied? */
export function isRoleInRoles(role: Role, roles: Role[]): boolean {
  return roles.some((userRole) => role.id === userRole.id)
}
/** Add or remove a role */
export function toggleRole(role: Role, roles: Role[]): Role[] {
  return isRoleInRoles(role, roles) ? roles.filter((r) => r.name !== role.name) : roles.concat(role)
}

export function getPrimaryRole(roles: Role[]): Role | undefined {
  return roles.find((role) => role.group === RoleGroup.PRIMARY)
}
/** switch out the "primary" role — there can only be one */
export function switchPrimaryRole(newPrimaryRole: Role, roles: Role[]): Role[] {
  return roles.filter((role) => role.group !== RoleGroup.PRIMARY).concat(newPrimaryRole)
}

/** Format a plain role name, in case there is no Display Name available
 * @example formatRoleName('role_name') => 'Role Name'
 */
export function formatRoleName(name: string): string {
  return startCase(camelCase(name))
}

export function isRoleDisabled(role: Role): boolean {
  return Object.values(DISABLED_ROLES).some((name) => role.name === name)
}

export function getReadableRoles(roles: Role[]): string {
  return roles
    .sort((role) => (role.group === RoleGroup.PRIMARY ? -1 : 1))
    .map((role) => role.displayName || formatRoleName(role.name))
    .join(', ')
}
