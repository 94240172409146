import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'
import { route } from 'utils/router'

export type PostPresignedUrlResponse = Promise<CamelCasedPropertiesDeep<Api.NumberImports.PostPresignedUrlResponse>>
export const postPresignedUrl = ({ clientId }: { clientId: string }): Promise<PostPresignedUrlResponse> => {
  return request(
    route(ENDPOINTS.CLIENTS.NUMBER_IMPORTS.PRESIGNED_URL, {
      clientId,
    }),
    {
      method: HTTP.POST,
    },
  )
}

export type PutFileToS3Arguments = {
  file: File
  url: string
}

export const putFileToS3 = ({ file, url }: PutFileToS3Arguments): Promise<string> => {
  return fetch(url, {
    method: HTTP.PUT,
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  }).then(async (res) => {
    if (!res.ok) {
      throw new Error(`Failed to upload file to S3`)
    }
    const text = await res.text()
    return text
  })
}

export type PostNumberImportsArguments = {
  body: {
    s3Uri: string
    rowCount: number
    communicationChannel: string
    tagId: string
    activationMode: string
    fileSize: number
  }
  params: {
    clientId: string
  }
}

export const postNumberImports = ({
  params,
  body,
}: PostNumberImportsArguments): Promise<Api.NumberImports.PostNumberImportsResponse> => {
  const { clientId } = params
  return request(route(ENDPOINTS.CLIENTS.NUMBER_IMPORTS.IMPORT, { clientId }), {
    method: HTTP.POST,
    body: {
      s3_uri: body.s3Uri,
      row_count: body.rowCount,
      communication_channel: body.communicationChannel,
      tag_id: body.tagId,
      activation_mode: body.activationMode,
      file_size: body.fileSize,
    },
    serialize: JSON.stringify,
  })
}

export type NumberImportsResponse = CamelCasedPropertiesDeep<Api.NumberImports.GetNumberImportsResponse>

export const getNumberImports = ({ clientId }: { clientId: string }): Promise<NumberImportsResponse> => {
  return request(route(ENDPOINTS.CLIENTS.NUMBER_IMPORTS.IMPORT, { clientId }), {
    method: HTTP.GET,
  })
}
