import PropTypes from 'prop-types'
import React from 'react'

export function SelectInput({ options, onChange, value, ...rest }: any) {
  const hasEmptyOption = !value
  return (
    <select onChange={({ target: { value } }) => onChange(value)} style={{ width: 200 }} value={value} {...rest}>
      {hasEmptyOption && <option value={value}>{value}</option>}
      {options.map(({ value: currentValue, label }: any) => (
        <option key={currentValue} value={currentValue}>
          {label}
        </option>
      ))}
    </select>
  )
}

SelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
}
