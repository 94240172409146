import React, { PureComponent } from 'react'
import AutosizeTextarea from 'react-textarea-autosize'

class ElasticInput extends PureComponent {
  render() {
    return <AutosizeTextarea {...this.props} />
  }
}

export default ElasticInput
