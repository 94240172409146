import { Layout, SPACING, FONT_SIZE, FONT_WEIGHT } from '@community_dev/pixels'
import { Input, Typography } from 'antd'
import styled, { useTheme } from 'styled-components'

import { TYPE_LABELS } from '../constants'

import { Fields, SelectField } from './FieldCard'
import { FieldCardLayout } from './FieldCardLayout'
import DropdownIcon from './icons/dropdown.svg?react'
import NotVisibleIcon from './icons/notVisible.svg?react'
import VisibleIcon from './icons/visible.svg?react'
import { StyledIconButton, StyledIconWrapper } from './styled'

const StyledInput = styled(Input)`
  font-size: ${FONT_SIZE[4]};
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  margin-left: ${SPACING[3]};

  :hover,
  :focus {
    box-shadow: none !important;
  }
`

export const GENDER_OPTIONS = ['Male', 'Female', 'Non-binary', 'Prefer not to answer']

export const ALLOWABLE_HIDDEN_GENDERS = ['Non-binary', 'Prefer not to answer']
const StyledButtonContainer = styled.div<{ $isDisabled?: boolean; $iconColor?: string }>`
  button,
  button[disabled] {
    border: none;
    box-shadow: none;
    background: white;
    border-color: white;
  }

  svg {
    path {
      fill: ${({ theme, $isDisabled, $iconColor }) =>
        $isDisabled ? theme?.COLORS?.BUTTON_DISABLED : $iconColor || theme?.COLORS?.BUTTON_PRIMARY};
    }
  }
`
export const GenderSelect = ({
  field,
  onChange,
}: {
  field: SelectField
  onChange: (field: Fields) => void
}): JSX.Element => {
  const { COLORS } = useTheme() || {}
  const { enabled, type, label } = field
  const tooltipText = enabled ? 'Hide field' : 'Show field'

  return (
    <FieldCardLayout
      inputLabel={TYPE_LABELS[type]}
      isEnabled={enabled}
      onVisibleChange={() => onChange({ ...field, enabled: !enabled, options: GENDER_OPTIONS })}
      tooltipText={tooltipText}
    >
      <Layout>
        <Layout alignItems="center" display="flex">
          <StyledIconWrapper>
            <DropdownIcon />
          </StyledIconWrapper>
          {enabled ? (
            <StyledInput
              onChange={(e) => {
                onChange({ ...field, label: e.target.value })
              }}
              value={label}
            />
          ) : (
            <Typography.Text
              style={{
                fontSize: FONT_SIZE[4],
                fontWeight: FONT_WEIGHT[5],
                marginLeft: SPACING[3],
                color: enabled ? COLORS?.TEXT : COLORS?.SUBTEXT,
              }}
            >
              {label}
              {' (Hidden)'}
            </Typography.Text>
          )}
        </Layout>

        {enabled
          ? GENDER_OPTIONS.map((option, index) => {
              const fieldEnabled = field.options.includes(option)

              const onOptionsChange = () => {
                if (fieldEnabled) {
                  const newOptions = field.options.filter((o) => o !== option)

                  onChange({ ...field, options: newOptions })
                  return
                }

                //Enable the option in the same place it was before
                onChange({
                  ...field,
                  options: [...field.options, option].sort(
                    (a, b) => GENDER_OPTIONS.indexOf(a) - GENDER_OPTIONS.indexOf(b),
                  ),
                })
              }
              return (
                <Layout
                  alignItems="center"
                  data-testid={`${option}-row`}
                  display="flex"
                  key={option}
                  marginTop={SPACING[3]}
                >
                  <Layout
                    color={COLORS.SUBTEXT}
                    flexShrink={0}
                    fontSize={FONT_SIZE[2]}
                    textAlign="right"
                    width="14px"
                  >{`${index + 1}.`}</Layout>
                  <Layout
                    alignItems="center"
                    borderBottom={`1px solid ${COLORS.BORDERS}`}
                    display="flex"
                    flex="1"
                    padding={`${SPACING[1]} 0 ${SPACING[1]} 0`}
                  >
                    <Typography.Text
                      style={{
                        fontSize: FONT_SIZE[4],
                        marginLeft: SPACING[3],
                        color: fieldEnabled ? COLORS?.TEXT : COLORS?.SUBTEXT,
                      }}
                    >
                      {option}
                      {fieldEnabled ? '' : ' (Hidden)'}
                    </Typography.Text>
                  </Layout>
                  {ALLOWABLE_HIDDEN_GENDERS.includes(option) ? (
                    <StyledButtonContainer>
                      <StyledIconButton
                        icon={field.options.includes(option) ? <VisibleIcon /> : <NotVisibleIcon />}
                        onClick={onOptionsChange}
                      />
                    </StyledButtonContainer>
                  ) : (
                    <Layout width="32px"></Layout>
                  )}
                </Layout>
              )
            })
          : null}
      </Layout>
    </FieldCardLayout>
  )
}
