import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import auth from 'redux/auth/reducer'
import login from 'redux/login/reducer'
import phoneNumbers from 'redux/phone_numbers/reducer'
import sidebar from 'redux/sidebar/reducer'
import snackbar from 'redux/snackbar/reducer'

const GlobalReducer = (history: any) =>
  combineReducers({
    auth,
    login,
    sidebar,
    snackbar,
    phoneNumbers,
    router: connectRouter(history),
  })

export default GlobalReducer
