import { ApiError } from '@community_dev/requests'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { RegistrationV2 } from 'api/tcr'
import { getTRCRegistration } from 'api/tcr'
import { QUERIES, STALE_TIME } from 'constants/queries'

export function useTCRRegistration({
  clientId,
  seatId,
}: {
  clientId: string
  seatId: string
}): UseQueryResult<RegistrationV2, ApiError> {
  return useQuery(
    [QUERIES.TCR.REGISTRATION, clientId, seatId],
    () => getTRCRegistration({ clientId, seatId: seatId }),
    {
      refetchOnWindowFocus: 'always',
      staleTime: STALE_TIME.ONE_MINUTE,
    },
  )
}
