import { Api } from '@community_dev/types'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ONBOARDING_MESSAGE_TITLES } from '../constants'

import { putFanOnboardingMessage } from 'api/clients'
import { Client } from 'api/clients'
import { QUERIES } from 'constants/queries'
import { InlineEditMessage } from 'shared/components/InlineEditMessage'

type EditLegalOnboardingMessageProps = {
  client: Client
  message: CamelCasedPropertiesDeep<Api.OnboardingMessage>
}

export const EditOnboardingMessage = ({ client, message }: EditLegalOnboardingMessageProps): JSX.Element => {
  const queryClient = useQueryClient()
  const isWhatsAppMessageLabel = useMemo(
    () => message.label?.toLowerCase().includes('whatsapp') && message.label?.toLowerCase().includes('import'),
    [message.label],
  )
  return (
    <InlineEditMessage
      canShortenLinks={true}
      defaultValue={message.text}
      maxLength={('maxLength' in message && message.maxLength) || undefined}
      maxSegment={('maxSegmentCount' in message && message.maxSegmentCount) || undefined}
      name={ONBOARDING_MESSAGE_TITLES[message.label]}
      onSuccess={() => queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])}
      prepare={(value, shortenLinks, campaignTemplateType) => ({
        id: client.id,
        messageId: message.id,
        body: { text: value, shortenLinks, campaignTemplateType },
      })}
      showTemplateTypeSelect={isWhatsAppMessageLabel}
      templateType={message.campaignTemplateType || undefined}
      updateEntity={putFanOnboardingMessage}
    />
  )
}
