/* eslint-disable camelcase */
let gaTrackingId = ''

function init(id: any, history: any) {
  try {
    gaTrackingId = id

    if (!gaTrackingId || !history) return

    const script = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gaTrackingId}');
    `
    const script1 = document.createElement('script')
    script1.type = 'text/javascript'
    script1.appendChild(document.createTextNode(script))
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    document.documentElement.firstChild.appendChild(script1)

    const script2 = document.createElement('script')
    script2.type = 'text/javascript'
    script2.setAttribute('async', 'true')
    script2.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    document.documentElement.firstChild.appendChild(script2)

    history.listen((location: any) => {
      // @ts-expect-error TS(2339): Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
      window.gtag('config', gaTrackingId, { page_path: location.pathname })
    })
  } catch (ex) {
    /* noop */
  }
}

function page(page_path: any, page_title: any) {
  try {
    if (!gaTrackingId) return
    // @ts-expect-error TS(2339): Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
    window.gtag('config', gaTrackingId, { page_path, page_title })
  } catch (ex) {
    /* noop */
  }
}

function event(event_name: any, event_category: any, event_label: any) {
  try {
    if (!gaTrackingId) return
    // @ts-expect-error TS(2339): Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
    window.gtag('event', event_name, { event_category, event_label })
  } catch (ex) {
    /* noop */
  }
}

const _ga = { event, init, page }
export default _ga
