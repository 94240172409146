import { CheckCircleOutlined, ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { Layout, SPACING } from '@community_dev/pixels'
import { OnboardingMessageTemplateStatus } from '@community_dev/types/lib/api/OnboardingMessages'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Collapse, Tag, Tooltip } from 'antd'
import lowerCase from 'lodash/lowerCase'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  ONBOARDING_MESSAGE_TITLES,
  INTRO_WITH_FAN_REGISTRATION_URL_LABEL,
  SUBSCRIPTION_CONFIRMATION_LABEL,
} from '../constants'

import { EditLegalOnboardingMessage } from './EditLegalOnboardingMessage'
import { EditOnboardingMessage } from './EditOnboardingMessage'
import { EditSubscriptionMessage } from './EditSubscriptionMessage'

import { Client } from 'api/clients'
import { QUERIES } from 'constants/queries'

const RenderDictionary = {
  [INTRO_WITH_FAN_REGISTRATION_URL_LABEL]: EditLegalOnboardingMessage,
  [SUBSCRIPTION_CONFIRMATION_LABEL]: EditSubscriptionMessage,
  Default: EditOnboardingMessage,
}

const StyledMessages = styled.section`
  padding: 20px 0;
`

export const EditOnboardingMessagesList = ({
  title,
  client,
  messageLabels,
}: {
  title: string
  client: Client
  messageLabels: string[]
}): JSX.Element => {
  const queryClient = useQueryClient()
  const messages = useMemo(
    () =>
      sortBy(
        client.onboardingMessages.filter((message) => messageLabels.includes(message.label)),
        'label',
      ),
    [messageLabels, client],
  )

  const [isLoadingClient, setIsLoadingClient] = useState(false)
  const reloadClient = useCallback(async () => {
    setIsLoadingClient(true)
    await queryClient.refetchQueries([QUERIES.CLIENT, { id: client.id }])
    setIsLoadingClient(false)
  }, [queryClient, client])

  return (
    <StyledMessages>
      <Layout display="flex" justifyContent="space-between" marginBottom={SPACING[2]}>
        <h2 className="ant-descriptions-title">{title}</h2>
        <Tooltip title="Reload messages and statuses">
          <Button
            aria-label="Reload Messages"
            icon={<ReloadOutlined style={{ marginTop: '3px' }} />}
            loading={isLoadingClient}
            onClick={() => !isLoadingClient && reloadClient()}
            shape="circle"
          ></Button>
        </Tooltip>
      </Layout>
      <Collapse>
        {messages.map((message) => {
          const Render = RenderDictionary[message.label] || RenderDictionary.Default
          return (
            <Collapse.Panel
              extra={
                <>
                  {message.campaignTemplateStatus === OnboardingMessageTemplateStatus.APPROVED && (
                    <Tag color="success" icon={<CheckCircleOutlined />}>
                      Template Approved
                    </Tag>
                  )}
                  {message.campaignTemplateStatus === OnboardingMessageTemplateStatus.FAILED && (
                    <Tooltip title="To re-submit, edit the message and click ”Save”">
                      <Tag color="error" icon={<ExclamationCircleOutlined />}>
                        Template Failed
                      </Tag>
                    </Tooltip>
                  )}
                  {message.campaignTemplateStatus === OnboardingMessageTemplateStatus.PENDING && (
                    <Tooltip title="Template is pending approval by Meta/WhatsApp. This can take up to several minutes.">
                      <Tag color="warning" icon={<ExclamationCircleOutlined />}>
                        Template Pending
                      </Tag>
                    </Tooltip>
                  )}
                  {message.campaignTemplateStatus === OnboardingMessageTemplateStatus.REJECTED && (
                    <Tooltip title="To re-submit, edit the message and click ”Save”">
                      <Tag color="error" icon={<ExclamationCircleOutlined />}>
                        Template Rejected
                      </Tag>
                    </Tooltip>
                  )}
                </>
              }
              header={ONBOARDING_MESSAGE_TITLES[message.label] || startCase(lowerCase(message.label))}
              key={message.label}
            >
              <Render client={client} message={message} messages={client.onboardingMessages} />
            </Collapse.Panel>
          )
        })}
      </Collapse>
    </StyledMessages>
  )
}
