import { UploadOutlined, UserOutlined } from '@ant-design/icons'
import { Dropzone, Layout, SPACING } from '@community_dev/pixels'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { SystemicOnboardingState } from '@community_dev/types/lib/api/SystemicOnboarding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Avatar, Button, Col, Descriptions, Row, Skeleton, Space, Tooltip, Typography, notification } from 'antd'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { COMMUNICATION_CHANNEL_LABEL } from './constants'
import { CustomCheckboxModal } from './CustomCheckboxModal'
import { useGeneralDetails, useMemberCount } from './queries'

import {
  Client,
  deleteClient,
  getDownloadUrl,
  patchClientSettings,
  postImpersonate,
  postSendVcard,
  putClient,
  putContentReview,
  putProfileImage,
} from 'api/clients'
import { ButtonModalPrompt } from 'components/ButtonModalPrompt'
import { AGE } from 'constants/age'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES } from 'constants/queries'
import { ROUTES } from 'constants/routes'
import { useHasCapability } from 'hooks/useCapabilities'
import { useSystemicOnboardingQuery } from 'hooks/useSystemicOnboardingQuery'
import Sentry from 'integrations/Sentry'
import { InlineEditField } from 'shared/components/InlineEditField/InlineEditField'
import { Switch } from 'shared/components/Switch'
import { formatPhone } from 'utils/formatters'
import { getAuthToken } from 'utils/request'
import { isPresent } from 'utils/validators'

const StyledSimpleTable = styled.table`
  width: unset !important;
  tr {
    td:nth-child(2) {
      text-align: right;
      padding-left: 20px;
    }
  }
  // Last row if there is more than one
  tr:not(:first-of-type):last-child {
    font-weight: bold;
    border-top: 1px solid ${({ theme }) => theme.COLORS.BORDERS};
  }
`

const StyledDetails = styled.div`
  padding: 20px 20px 16px;
`

const StyledImageColumn = styled.div`
  display: flex;
  justify-content: center;
  .ant-avatar {
    margin-bottom: 15px;
  }
`

const StyledFooter = styled.footer`
  display: flex;
  padding: 0 20px 32px;
  button:not(:last-child) {
    margin-right: 10px;
  }
`

const StyledButton = styled(Button)`
  border: none;
  box-shadow: none;
`

const StyledModalDeleteButton = styled(ButtonModalPrompt)`
  margin-left: auto;
`

export type FileDetails = {
  label: string
}

const dataExportFiles: Record<string, FileDetails> = {
  'campaigns.csv.gz': {
    label: 'Campaign Performance',
  },
  'outbound_message_type_usage.csv.gz/segment-based-subscription': {
    label: 'Message Segment Usage',
  },
  'campaign_links.csv.gz': {
    label: 'Campaign Link Performance',
  },
  'members.csv.gz': {
    label: 'Member Details',
  },
  'member_state_changes.csv.gz': {
    label: 'Member Subscription Status',
  },
  'custom_member_data.csv.gz': {
    label: 'Custom Member Data',
  },
  'communities.csv.gz': {
    label: 'Communities',
  },
  'member_communities.csv.gz': {
    label: 'Community Membership',
  },
}

const SYSTEMIC_ONBOARDING_STATE_LABELS = {
  [SystemicOnboardingState.APPROVED]: 'Approved',
  [SystemicOnboardingState.DENIED]: 'Denied',
  [SystemicOnboardingState.AWAITING_APPROVAL]: 'Awaiting Approval',
}
const DEFAULT_TAG_LIMIT = 200
const MAX_TAG_LIMIT = 2000

export function General({ client }: { client: Client }) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [openLegalModal, setOpenLegalModal] = useState(false)
  const [exportFile, setExportFile] = useState('campaigns.csv.gz')
  const canUpdateTagLimit = useHasCapability(CAPABILITIES.ADMIN.TAG_LIMIT.WRITE)

  // We always want to fetch for SMS, even if its not part of the clients communication channels. We do this, so we display member count in cases that TCR has not been approved yet.
  const communicationChannels = useMemo(
    () => [...new Set([...client.communicationChannels, CommunicationChannel.SMS])].sort(),
    [client.communicationChannels],
  )
  const memberCount = useMemberCount({ clientId: client.id, communicationChannels: communicationChannels })

  const { isLoading, isError, contentReview } = useGeneralDetails(client.id)

  const { mutate: saveProfileImage } = useMutation(putProfileImage, {
    onSuccess() {
      notification.success({
        message: ' Profile Image saved.',
        placement: 'bottomRight',
        duration: 2,
      })
      queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])
    },
    onError() {
      notification.error({
        message: 'Profile Image failed to save. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })
  const { mutateAsync: loginAs } = useMutation(postImpersonate, {
    onError() {
      notification.error({
        message: 'Unable to impersonate user',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })

  const { mutate: deleteClientId } = useMutation(deleteClient, {
    onError: () => {
      notification.error({
        message: 'Could not delete client. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
    onSuccess: () => {
      notification.success({
        message: 'Client deleted successfully.',
        placement: 'bottomRight',
        duration: 2,
      })
      queryClient.invalidateQueries([QUERIES.CLIENTS])
      history.push(ROUTES.CLIENTS)
    },
  })
  const { mutate: sendVcard } = useMutation(postSendVcard, {
    onSuccess: () => {
      notification.success({
        message: 'Virtual cards sent successfully.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })

  const hasDeleteClientCapability = useHasCapability(CAPABILITIES.ADMIN.CLIENTS.DELETE)
  const hasSendVcardCapability = useHasCapability(CAPABILITIES.ADMIN.CLIENT_VCARD.SEND)

  const { data: systemicOnboarding, isLoading: isSystemicOnboardingLoading } = useSystemicOnboardingQuery({
    clientId: client.id,
  })

  function invalidateClient() {
    queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])
  }

  function invalidateClientLedger() {
    queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])
  }

  function onFileAccepted(file: File) {
    const body = new FormData()
    body.append('profile_image', file)
    saveProfileImage({ id: client.id, body })
  }

  function handleDownload() {
    getDownloadUrl({ fileName: exportFile, clientId: client.id })
      .then((url) => {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.target = '_blank'
        link.href = url
        let event
        try {
          event = new MouseEvent('click')
        } catch (e) {
          event = document.createEvent('MouseEvent')
          event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
        }

        link.dispatchEvent(event)
      })
      .catch((err) => {
        let errorText = `Could not download: ${exportFile}`
        if (err.name === 'ApiError' && err.status === 404) {
          errorText = `No data found: ${exportFile}`
        }
        if (err.name === 'ApiError' && err.status === 401) {
          errorText = `Permission denied: ${exportFile}`
        }
        notification.error({
          message: errorText,
          placement: 'bottomRight',
          duration: 2,
        })

        Sentry.captureException(new Error(errorText))
      })
  }

  async function handleImpersonate(_: any, mobile = false) {
    const authToken = getAuthToken()
    const { token } = await loginAs({
      body: { client_id: client.id, token: authToken },
    })
    const target = mobile ? '_self' : '_blank'
    const url = mobile
      ? `community-app-admin://payload?${token}`
      : `${import.meta.env.VITE_DASHBOARD_URL}/impersonate?token=${token}&clientId=${client.id}`
    window.open(url, target)
  }

  const validationText = `${client.firstName} ${client.lastName}`.trim()
  return (
    <>
      <StyledDetails>
        <Row>
          <Col span={16}>
            <Descriptions bordered column={1} title="Client Info">
              <Descriptions.Item label="First Name">
                <InlineEditField
                  defaultValue={client.firstName}
                  name="First Name"
                  onSuccess={invalidateClient}
                  prepare={(value) => ({
                    id: client.id,
                    body: { first_name: value },
                  })}
                  updateEntity={putClient}
                  validation={(value) => !!value.match(/^.{3,}$/)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Last Name">
                <InlineEditField
                  defaultValue={client.lastName}
                  name="Last Name"
                  onSuccess={invalidateClient}
                  prepare={(value) => ({
                    id: client.id,
                    body: { last_name: value },
                  })}
                  updateEntity={putClient}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Client ID">
                <Typography.Text code copyable>
                  {client?.id}
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Member Count">
                {memberCount.isLoading ? (
                  <Skeleton active loading={memberCount.isLoading} paragraph={false} title={{ width: 50 }} />
                ) : (
                  <StyledSimpleTable>
                    <tbody>
                      {communicationChannels.map((channel: CommunicationChannel) => (
                        <tr key={channel}>
                          <td>{COMMUNICATION_CHANNEL_LABEL[channel]}:</td>
                          <td>{memberCount[channel]}</td>
                        </tr>
                      ))}
                      {communicationChannels?.length > 1 && (
                        <tr>
                          <td>Total:</td>
                          <td>{memberCount.total}</td>
                        </tr>
                      )}
                    </tbody>
                  </StyledSimpleTable>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">
                <Typography.Text
                  copyable={{
                    text: formatPhone(client?.masterPhoneNumber, {
                      intl: false,
                    }),
                  }}
                >
                  {formatPhone(client?.masterPhoneNumber)}
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Twilio AccountSID">
                <Typography.Text code copyable>
                  {client?.twilioAccountSid}
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="URL Reference">
                {isLoading ? (
                  <Skeleton active loading={isLoading} paragraph={false} title={{ width: 50 }} />
                ) : (
                  <InlineEditField
                    defaultValue={client?.referenceUrl}
                    name="URL Reference"
                    onSuccess={invalidateClientLedger}
                    prepare={(value) => ({
                      id: client.id,
                      body: { reference_url: value },
                    })}
                    updateEntity={putClient}
                    validation={isPresent.validator}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="URL Slug">
                <InlineEditField
                  defaultValue={client.urlSlug}
                  name="URL Slug"
                  onSuccess={invalidateClient}
                  prepare={(value) => ({
                    id: client.id,
                    body: { url_slug: value },
                  })}
                  updateEntity={putClient}
                  validation={(value) => !!value.match(/^.{3,}$/)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Swipe up link">
                <Switch
                  defaultChecked={client.swipeUpEnabled}
                  name="Swipe up link"
                  onSuccess={invalidateClient}
                  prepare={(value) => ({
                    id: client.id,
                    body: { swipe_up_enabled: value },
                  })}
                  updateEntity={putClient}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Campaigns Require Review">
                <Skeleton active loading={isLoading} paragraph={false} title={{ width: 50 }} />
                {!isLoading && !isError && (
                  <Switch
                    defaultChecked={contentReview}
                    name="Campaigns Review Required"
                    onSuccess={invalidateClient}
                    prepare={(value) => ({
                      id: client.id,
                      body: { content_review_required: value },
                    })}
                    updateEntity={putContentReview}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Minimum Fan Age">
                <InlineEditField
                  defaultValue={client.minimumFanAge || AGE.MIN_AGE}
                  name="Minimum Fan Age"
                  onSuccess={invalidateClient}
                  pattern={/^\d{0,3}$/}
                  placeholder="13"
                  prepare={(value) => ({
                    id: client.id,
                    body: { minimum_fan_age: parseInt(value) },
                  })}
                  updateEntity={putClient}
                  validation={(value) => value >= AGE.MIN_AGE && value <= AGE.MAX_AGE}
                  warningModalText="Changing the minimum fan age could trigger subscription deletions for members who fall below the new minimum age. Would you like to proceed?"
                />
              </Descriptions.Item>
              <Descriptions.Item label="Max Sub-Community Limit">
                <InlineEditField
                  defaultValue={client.tagLimit || DEFAULT_TAG_LIMIT}
                  disabled={!canUpdateTagLimit}
                  name="Max Sub-Community Limit"
                  onSuccess={invalidateClient}
                  pattern={/^\d{0,4}$/}
                  placeholder={DEFAULT_TAG_LIMIT.toString()}
                  prepare={(value) => ({
                    id: client.id,
                    body: { client_allotted_tag_limit: parseInt(value) },
                  })}
                  updateEntity={patchClientSettings}
                  useErrorMessage={true}
                  validation={(value) => value >= 1 && value <= MAX_TAG_LIMIT}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Additional legal language">
                <StyledButton onClick={() => setOpenLegalModal(true)}>
                  <FontAwesomeIcon icon="edit" />
                </StyledButton>
              </Descriptions.Item>
              <Descriptions.Item label="Data Exports">
                <select onChange={(e) => setExportFile(e.target.value)} value={exportFile}>
                  {Object.entries(dataExportFiles).map(([exportOption, fileDetails]) => (
                    <option key={exportOption} value={exportOption}>
                      {fileDetails.label}
                    </option>
                  ))}
                </select>{' '}
                <Button onClick={handleDownload} type="primary">
                  Download
                </Button>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <>
                    Systemic Onboarding Status
                    <Layout display="inline-block" marginLeft={SPACING[2]}>
                      <Tooltip
                        title={
                          <Layout display="flex" flexDirection="column">
                            <Layout marginBottom={SPACING[3]}>
                              Controls how many subscriptions can be created via the api.
                            </Layout>
                            <Layout marginBottom={SPACING[3]}>
                              <Typography.Text style={{ fontWeight: 'bold', color: 'white', marginBottom: SPACING[3] }}>
                                Approved:{' '}
                              </Typography.Text>
                              <Typography.Text style={{ color: 'white' }}>
                                Allows creating unlimited subscriptions via the api.
                              </Typography.Text>
                            </Layout>
                            <Layout marginBottom={SPACING[3]}>
                              <Typography.Text style={{ fontWeight: 'bold', color: 'white' }}>Denied: </Typography.Text>
                              <Typography.Text style={{ color: 'white' }}>
                                No subscriptions can be created via the api.
                              </Typography.Text>
                            </Layout>
                            <Layout>
                              <Typography.Text style={{ fontWeight: 'bold', color: 'white' }}>
                                Awaiting Approval or Unset:{' '}
                              </Typography.Text>
                              <Typography.Text style={{ color: 'white' }}>
                                20 subscriptions per day and 60 total subscriptions.
                              </Typography.Text>
                            </Layout>
                          </Layout>
                        }
                      >
                        <FontAwesomeIcon icon="info-circle" />
                      </Tooltip>
                    </Layout>
                  </>
                }
              >
                {isSystemicOnboardingLoading ? (
                  <Skeleton active loading={isSystemicOnboardingLoading} paragraph={false} title={{ width: 50 }} />
                ) : (
                  <Typography.Text>
                    {SYSTEMIC_ONBOARDING_STATE_LABELS[systemicOnboarding?.data?.state] || ''}
                  </Typography.Text>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={8}>
            <StyledImageColumn>
              <Space align="center" direction="vertical">
                <Typography.Title level={5}>Contact Card Photo</Typography.Title>
                <Avatar
                  alt={[client.firstName, client.lastName].join(' ')}
                  aria-label="User profile image"
                  icon={<UserOutlined />}
                  size={200}
                  src={client?.profileImageSmall?.url}
                />
                <Dropzone accept={{ 'image/*': [] }} noDrag onFileAccepted={onFileAccepted}>
                  <Button block icon={<UploadOutlined />}>
                    Upload
                  </Button>
                </Dropzone>
                <ButtonModalPrompt
                  buttonText="Send vCard"
                  buttonType="primary"
                  disabled={!hasSendVcardCapability}
                  modalText="This will send a vCard to all SMS members."
                  modalTitle="Are you sure?"
                  onClick={() => sendVcard(client.id)}
                  validationText={validationText}
                />
              </Space>
            </StyledImageColumn>
          </Col>
        </Row>
      </StyledDetails>
      <Row>
        <Col span={16}>
          <StyledFooter>
            <Button onClick={handleImpersonate} size="large" type="primary">
              Login As
            </Button>
            <Button
              onClick={(evt) => {
                handleImpersonate(evt, true)
              }}
              size="large"
              type="primary"
            >
              Mobile Login As
            </Button>
            {hasDeleteClientCapability && (
              <StyledModalDeleteButton
                buttonText="Delete"
                danger
                modalText={`This action cannot be undone. Are you sure you want to delete the "${validationText}" client?`}
                modalTitle="WARNING"
                onClick={() => deleteClientId(client.id)}
                size="large"
                validationText={validationText}
              />
            )}
          </StyledFooter>
        </Col>
      </Row>

      <CustomCheckboxModal
        // @ts-expect-error TS(2322): Type '{ clientId: any; isOpen: boolean; onSave: ()... Remove this comment to see the full error message
        clientId={client.id}
        isOpen={openLegalModal}
        onCancel={() => setOpenLegalModal(false)}
        onDelete={() => setOpenLegalModal(false)}
        onSave={() => setOpenLegalModal(false)}
      />
    </>
  )
}
