export enum OnboardingMessagesLabels {
  INTRO_WITH_FAN_REGISTRATION_URL = 'intro_with_fan_registration_url',
  SUBSCRIPTION_CONFIRMATION = 'subscription_confirmation',
  REPLY_Y_ONBOARDING_LEGAL = 'reply_y_onboarding_legal',
}

export enum OnboardingMessageTemplateStatus {
  PENDING = 'template_pending',
  REJECTED = 'template_rejected',
  FAILED = 'template_failed',
  APPROVED = 'template_approved',
}

export enum OnboardingMessageTemplateType {
  MARKETING = 'marketing',
  UTILITY = 'utility',
}
