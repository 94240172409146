export const CAPABILITIES = Object.freeze({
  ADMIN: {
    ACTIVATION_MODE: {
      WRITE: 'admin:activation_mode:write',
    },
    BILLING: {
      WRITE: 'admin:billing:write',
    },
    CLIENT: {
      LOGOUT: 'admin:client:logout',
    },
    CLIENTS: {
      DELETE: 'admin:clients:delete',
      READ: 'admin:clients:read',
      WRITE: 'admin:clients:write',
    },
    CLIENT_FEATURE_GROUP: {
      READ: 'admin:client_feature_group:read',
      WRITE: 'admin:client_feature_group:write',
    },
    CLIENT_PROVIDER: {
      WRITE: 'admin:client_provider:write',
    },
    CLIENT_VCARD: {
      SEND: 'admin:client_vcards:send',
    },
    CUSTOM_FROST: {
      WRITE: 'admin:custom_frost:write',
    },
    FANS: {
      WRITE: 'admin:fans:write',
    },
    FEATURE_GROUP: {
      READ: 'admin:feature_group:read',
      WRITE: 'admin:feature_group:write',
    },
    NUMBER_IMPORT: {
      WRITE: 'admin:number_import:write',
    },
    PROTECTED_ROLE_SEAT: {
      WRITE: 'admin:protected_seats:write',
    },
    PROTECTED_TAGS: {
      WRITE: 'admin:protected_tags:write',
    },
    RESTRICTED_REGISTRATION_FIELD: {
      WRITE: 'admin:restricted_registration_field:write',
    },
    SEGMENT_BILLING: {
      WRITE: 'admin:segment_billing:write',
    },
    TAG_LIMIT: {
      WRITE: 'admin:tag_limit:write',
    },
  },
  FEATURE: {
    AMB: {
      ALL: 'feature:amb:all',
    },
  },
} as const)
