export const TERMED_INVOICE = 'termed_invoice'
export const UNLIMITED_FREE_TRIAL = 'unlimited_free_trial'
export const COMMUNITY_PLUS = 'community_plus'
export const MARKETING_PARTNERSHIP = 'marketing_partnership'
export const CREDIT_CARD = 'credit_card'

export const PLAN_TYPES = [
  { value: TERMED_INVOICE, label: 'Termed Invoice' },
  { value: UNLIMITED_FREE_TRIAL, label: 'Unlimited Free Trial' },
  { value: COMMUNITY_PLUS, label: 'Community+' },
  { value: MARKETING_PARTNERSHIP, label: 'Marketing Partnership' },
  { value: CREDIT_CARD, label: 'Credit Card' },
]

export const STATUSES = [
  { value: 'active', label: 'active' },
  { value: 'inactive', label: 'inactive' },
]
