import {
  CheckCircleOutlined,
  CheckOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
  LoadingOutlined,
  SearchOutlined,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { SPACING, Layout, Typography, Badge } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { useQuery } from '@tanstack/react-query'
import { Avatar, Button, Col, Dropdown, Input, List, Popconfirm, Popover, Row, Skeleton, Switch, Tag } from 'antd'
import differenceBy from 'lodash/differenceBy'
import indexBy from 'lodash/fp/indexBy'
import VirtualList from 'rc-virtual-list'
import { ReactNode, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import Measure from 'react-measure'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { CreateCapabilityModal } from './CreateCapabilityModal'
import { PlanMenu } from './PlanMenu'
import { StructuredCapabilityNameInput } from './StructuredCapabilityNameInput'

import { FeatureGroup as FeatureGroupType, getClientsByFeatureGroup } from 'api/capabilities'
import { Capability, getClients } from 'api/clients'
import { CsvDownloader } from 'components/CsvDownloader'
import { QUERIES } from 'constants/queries'
import { ROUTES } from 'constants/routes'
import { useFeatureGroups } from 'hooks/useFeatureGroups'
import { StyledModal } from 'shared/components/Modal'
import { formatDate } from 'utils/formatters'
import { formatName } from 'utils/name'

export enum FeatureGroupKind {
  PLAN = 'plan',
  FEATURE_GROUP = 'feature',
}

type FeatureGroupProps = {
  kind: FeatureGroupKind
}

const StyledHeader = styled.div`
  position: sticky;
  top: 0;
`

type UpdateFeatureGroupModalProps = {
  onClose: () => any
  featureGroup: FeatureGroupType
  featureGroups: FeatureGroupType[]
  onSubmit: (values: UpdateFeatureGroupFormValues) => any
  kind?: FeatureGroupKind
}

type UpdateFeatureGroupFormValues = {
  name: string
}

function UpdateFeatureGroupModal({
  onClose,
  featureGroup,
  featureGroups,
  onSubmit,
  kind = FeatureGroupKind.FEATURE_GROUP,
}: UpdateFeatureGroupModalProps): JSX.Element {
  const submitHandler = (values: UpdateFeatureGroupFormValues) => {
    onSubmit({
      name: values.name,
    })
  }
  return (
    <Form<UpdateFeatureGroupFormValues>
      initialValues={{
        name: featureGroup.name,
      }}
      keepDirtyOnReinitialize
      onSubmit={submitHandler}
    >
      {({ handleSubmit, submitting, values, valid }) => (
        <StyledModal
          confirmLoading={submitting}
          okButtonProps={{ disabled: !valid }}
          okText="Update"
          onCancel={onClose}
          onOk={handleSubmit}
          open={true}
          title={<>Update {kind === FeatureGroupKind.FEATURE_GROUP ? 'Feature Group' : 'Plan'}</>}
          width={850}
        >
          <Layout paddingLeft={SPACING[5]} paddingRight={SPACING[5]}>
            <StructuredCapabilityNameInput
              existingNames={featureGroups
                .filter((featureGroup) => featureGroup.name.startsWith(kind))
                .map((featureGroup) => featureGroup.name)}
              fields={kind === FeatureGroupKind.PLAN ? ['kind', 'scope'] : ['kind', 'scope', 'action']}
              value={values.name}
            />
          </Layout>
        </StyledModal>
      )}
    </Form>
  )
}

type AddCapabilityModalProps = {
  onClose: () => any
  existingCapabilities: Capability[]
  featureGroup: FeatureGroupType
  featureGroups: FeatureGroupType[]
  onAdd: (cap: Capability) => any
  allCapabilities: Capability[]
  children?: ReactNode
}

function AddCapabilityModal({
  onClose,
  existingCapabilities,
  onAdd,
  allCapabilities,
  featureGroups,
  featureGroup,
  children = null,
}: AddCapabilityModalProps): JSX.Element {
  const [isCreateCapabilityModalOpen, setIsCreateCapabilityModalOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const { createCapability } = useFeatureGroups()
  const filteredSelectableCapabilities = useMemo(() => {
    return differenceBy(allCapabilities, existingCapabilities, (a) => a.id).filter((capability) =>
      (capability.name.toLowerCase() + '|||' + (capability.description || '').toLowerCase()).includes(
        searchValue.toLowerCase(),
      ),
    )
  }, [allCapabilities, existingCapabilities, searchValue])
  return (
    <>
      {isCreateCapabilityModalOpen && (
        <CreateCapabilityModal
          canAddToFeatureGroup={false}
          defaultFeatureGroup={featureGroup}
          featureGroups={featureGroups}
          onClose={() => setIsCreateCapabilityModalOpen(false)}
          onSubmit={async (values) => {
            const capability = await createCapability(values)
            onAdd(capability)
          }}
        />
      )}
      <StyledModal
        closable={false}
        footer={
          <>
            … or,{' '}
            <Button onClick={() => setIsCreateCapabilityModalOpen(true)} shape="round" size="small">
              Create a new Capability
            </Button>
          </>
        }
        onCancel={onClose}
        open={true}
        style={{ paddingTop: SPACING[5] }}
        title={
          <div>
            {children}
            <Input
              allowClear={true}
              autoFocus
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search Capabilities …"
              prefix={<SearchOutlined />}
              value={searchValue}
            />
          </div>
        }
      >
        <List>
          {filteredSelectableCapabilities?.length === 0 && searchValue && (
            <List.Item>
              <Typography variant="caption1">No Match Found</Typography>
            </List.Item>
          )}
          {filteredSelectableCapabilities?.map((cap) => {
            return (
              <List.Item
                actions={[
                  <Button
                    onClick={async () => {
                      await onAdd(cap)
                    }}
                    shape="round"
                    size="small"
                    type="primary"
                  >
                    Add
                  </Button>,
                ]}
                key={cap.id}
              >
                <List.Item.Meta
                  avatar={<CheckCircleOutlined />}
                  description={cap.description || '(no description)'}
                  title={<Link to={route(ROUTES.CAPABILITIES.CAPABILITY, { name: cap.name })}>{cap.name}</Link>}
                />
              </List.Item>
            )
          })}
        </List>
      </StyledModal>
    </>
  )
}

function AddClientModal({ onClose, existingClients, onAdd, children = null }): JSX.Element {
  const [searchValue, setSearchValue] = useState('')
  const { COLORS } = useTheme()
  const { data: filteredClients, isLoading: isLoadingClients } = useQuery([QUERIES.CLIENTS, searchValue], () =>
    getClients({ search: searchValue, assigned: 'assigned' })(),
  )
  const [addedClients, setAddedClients] = useState<{ [clientId: string]: any }>({})
  const existingClientsById = useMemo(() => indexBy('id', existingClients), [existingClients])
  return (
    <StyledModal
      closable={false}
      footer={null}
      onCancel={onClose}
      open={true}
      style={{ paddingTop: SPACING[5] }}
      title={
        <div>
          {children}
          <Input
            allowClear={true}
            autoFocus
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search Clients …"
            prefix={isLoadingClients ? <LoadingOutlined /> : <SearchOutlined />}
            value={searchValue}
          />
        </div>
      }
    >
      <List>
        {filteredClients?.length === 0 && searchValue && (
          <List.Item>
            <Typography variant="caption1">No Match Found</Typography>
          </List.Item>
        )}
        {filteredClients?.[0].map((client) => {
          return (
            <List.Item
              actions={[
                addedClients[client.id] === undefined && existingClientsById[client.id] === undefined ? (
                  <Button
                    onClick={async () => {
                      await onAdd(client)
                      setAddedClients({ ...addedClients, [client.id]: client })
                    }}
                    shape="round"
                    size="small"
                    type="primary"
                  >
                    Add
                  </Button>
                ) : (
                  <CheckOutlined color="green" />
                ),
              ]}
              key={client.id}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    alt={formatName(client)}
                    icon={<UserOutlined />}
                    src={client?.profileImageSmall?.url}
                    style={{ background: COLORS.APP_BACKGROUND_LEVEL_1 }}
                  />
                }
                description={`created at ${formatDate(client.createdAt)}`}
                title={<Link to={route(ROUTES.CLIENT.FEATURE_GROUPS, { id: client.id })}>{formatName(client)}</Link>}
              />
            </List.Item>
          )
        })}
      </List>
    </StyledModal>
  )
}

export function FeatureGroup({ kind }: FeatureGroupProps): JSX.Element {
  const { COLORS } = useTheme()
  const history = useHistory()
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false)
  const [isAddCapabilityModalOpen, setIsAddCapabilityModalOpen] = useState(false)
  const [isUpdateFeatureGroupModalOpen, setIsUpdateFeatureGroupModalOpen] = useState(false)
  const { name: featureGroupName } = useParams<{ name: string }>()
  const {
    allFeatureGroupsAndPlans,
    plans,
    capabilities,
    isLoading,
    changeClientPlan,
    addCapabilityToFeatureGroup,
    removeCapabilityFromFeatureGroup,
    updateFeatureGroup,
    removeFeatureGroupFromClient: removeFeatureGroup,
    addClientToFeatureGroup,
    deleteFeatureGroup,
  } = useFeatureGroups()
  const { data: featureGroupClients, refetch: refetchFeatureGroupClients } = useQuery(
    [QUERIES.FEATURE_GROUP_CLIENTS, featureGroupName],
    () => getClientsByFeatureGroup(featureGroupName),
  )
  const [clientSearchQuery, setClientSearchQuery] = useState('')
  const [capabilitySearchQuery, setCapabilitySearchQuery] = useState('')

  const filteredClients = useMemo(() => {
    return (featureGroupClients || []).filter((c) =>
      formatName(c).toLocaleLowerCase().includes(clientSearchQuery.toLocaleLowerCase()),
    )
  }, [clientSearchQuery, featureGroupClients])

  const featureGroup = useMemo(() => {
    return allFeatureGroupsAndPlans?.find((featureGroup) => featureGroup.name === featureGroupName)
  }, [allFeatureGroupsAndPlans, featureGroupName])

  const filteredCapabilities = useMemo(() => {
    return featureGroup?.capabilities.filter((c) =>
      (c.name.toLocaleLowerCase() + '|||' + (c.description || '').toLocaleLowerCase()).includes(
        capabilitySearchQuery.toLocaleLowerCase(),
      ),
    )
  }, [capabilitySearchQuery, featureGroup?.capabilities])

  return (
    <Layout padding={SPACING[8]}>
      {isUpdateFeatureGroupModalOpen && featureGroup && (
        <UpdateFeatureGroupModal
          featureGroup={featureGroup}
          featureGroups={allFeatureGroupsAndPlans || []}
          kind={kind}
          onClose={() => setIsUpdateFeatureGroupModalOpen(false)}
          onSubmit={async (values) => {
            await updateFeatureGroup({ featureGroup, updatedName: values.name })
            history.push(route(ROUTES.CAPABILITIES.FEATURE_GROUP, { name: values.name }))
          }}
        />
      )}
      {isAddCapabilityModalOpen && featureGroup && (
        <AddCapabilityModal
          allCapabilities={Object.values(capabilities)}
          existingCapabilities={featureGroup.capabilities}
          featureGroup={featureGroup}
          featureGroups={allFeatureGroupsAndPlans || []}
          onAdd={async (capability) => {
            await addCapabilityToFeatureGroup({ featureGroup, capability })
            setIsAddCapabilityModalOpen(false)
          }}
          onClose={() => setIsAddCapabilityModalOpen(false)}
        />
      )}
      {isAddClientModalOpen && featureGroup && (
        <AddClientModal
          existingClients={featureGroupClients}
          onAdd={(client) => addClientToFeatureGroup({ client, featureGroup })}
          onClose={() => {
            setIsAddClientModalOpen(false)
            refetchFeatureGroupClients()
          }}
        />
      )}
      <Typography variant="overline">
        {kind === FeatureGroupKind.PLAN ? (
          <>
            <FileTextOutlined /> Plan
          </>
        ) : (
          <>
            <StarOutlined /> Feature Group
          </>
        )}
      </Typography>
      <h1>
        {featureGroup?.name}{' '}
        <EditOutlined
          aria-label="Edit feature group"
          onClick={() => setIsUpdateFeatureGroupModalOpen(true)}
          role="button"
        />
        {featureGroup?.deprecated && (
          <>
            {' '}
            <Tag style={{ position: 'relative', top: '-5px' }}>Deprecated</Tag>
          </>
        )}
      </h1>
      <Row gutter={100} style={{ marginBottom: SPACING[5] }}>
        <Col>
          <Typography variant="caption1">Description</Typography>
          <Typography variant="body1">{featureGroup?.description || '(no description)'}</Typography>
        </Col>
        <Col>
          <Typography variant="caption1">Created</Typography>
          <Typography variant="body1">{formatDate(featureGroup?.createdAt)}</Typography>
        </Col>
        <Col>
          <Typography variant="caption1">Deprecated</Typography>
          <Switch checked={featureGroup?.deprecated === true} checkedChildren="Yes" unCheckedChildren="No" />
        </Col>
        <Col span={6}>
          <Typography variant="caption1">Delete</Typography>
          <Typography display="block" paddingTop={0} variant="caption1">
            {(featureGroup !== undefined && featureGroup?.capabilities.length > 0) ||
            (featureGroupClients && featureGroupClients?.length > 0) ? (
              `(Only ${
                kind === FeatureGroupKind.FEATURE_GROUP ? 'Feature Groups' : 'Plans'
              } without Capabilities or Clients can be deleted.)`
            ) : (
              <Button
                onClick={async () => {
                  if (featureGroup) {
                    await deleteFeatureGroup(featureGroup.name)
                    if (kind === FeatureGroupKind.FEATURE_GROUP) {
                      history.push(ROUTES.CAPABILITIES.FEATURE_GROUPS)
                    } else {
                      history.push(ROUTES.CAPABILITIES.PLANS)
                    }
                  }
                }}
                shape="round"
                size="small"
              >
                Delete
              </Button>
            )}
          </Typography>
        </Col>
      </Row>
      <Measure bounds>
        {({ contentRect, measureRef }) => (
          <Row gutter={50}>
            <Col span={12}>
              <List
                footer={
                  <Layout display="flex" justifyContent="end" width="100%">
                    <Popover
                      content={
                        featureGroupClients && (
                          <CsvDownloader
                            data={featureGroupClients}
                            name={`clients_${featureGroup?.name.replaceAll(':', '_')}.csv`}
                          />
                        )
                      }
                      trigger="click"
                    >
                      <Button icon={<DownloadOutlined />} shape="round" size="small">
                        Download CSV
                      </Button>
                    </Popover>
                  </Layout>
                }
                header={
                  <StyledHeader>
                    <Row>
                      <Col flex={1}>
                        <Typography display="block" variant="caption1">
                          Clients <Badge>{filteredClients.length}</Badge>
                        </Typography>
                      </Col>
                      <Col>
                        {kind === FeatureGroupKind.FEATURE_GROUP && (
                          <Button
                            onClick={() => setIsAddClientModalOpen(true)}
                            shape="round"
                            size="small"
                            style={{ marginTop: SPACING[1] }}
                            type="primary"
                          >
                            Add Client
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Input
                      allowClear
                      onChange={(e) => setClientSearchQuery(e.target.value)}
                      placeholder="Filter Clients …"
                      prefix={<SearchOutlined />}
                      style={{ marginTop: SPACING[3], marginBottom: SPACING[3] }}
                      value={clientSearchQuery}
                    />
                  </StyledHeader>
                }
                loading={isLoading}
                rowKey="id"
                size="small"
              >
                {!featureGroupClients && (
                  <List.Item style={{ opacity: 0.5 }}>
                    <i>{featureGroup?.name}</i> has no clients Assigned
                  </List.Item>
                )}
                {!filteredClients && clientSearchQuery && (
                  <List.Item>
                    <Typography variant="caption1">No Match Found</Typography>
                  </List.Item>
                )}
                <div ref={measureRef}>
                  <VirtualList
                    data={filteredClients}
                    height={window.innerHeight - (contentRect.bounds?.top || 0) - 100}
                    itemHeight={64}
                    itemKey="id"
                  >
                    {(client) => (
                      <List.Item
                        actions={[
                          // clients can be removed from feature groups
                          kind === FeatureGroupKind.FEATURE_GROUP && featureGroup && (
                            <Popconfirm
                              cancelText="No"
                              okText="Yes"
                              onCancel={(e) => e?.stopPropagation()}
                              onConfirm={async (e) => {
                                e?.stopPropagation()
                                await removeFeatureGroup({ id: client.id, featureGroupName: featureGroup.name })
                                await refetchFeatureGroupClients()
                              }}
                              placement="bottom"
                              title={
                                <>
                                  Are you sure you want to remove the Feature Group <b>“{featureGroupName}”</b> from
                                  <b>
                                    “{client.firstName} {client.lastName}”
                                  </b>
                                  ?
                                </>
                              }
                            >
                              <Button aria-label="remove client" shape="round" size="small">
                                Remove
                              </Button>
                            </Popconfirm>
                          ),
                          // but clients can only change their plan, not remove it.
                          kind === FeatureGroupKind.PLAN && featureGroup && (
                            <Dropdown
                              overlay={
                                <PlanMenu
                                  onChange={async (newFeatureGroup) => {
                                    await changeClientPlan({ client, newFeatureGroup, oldFeatureGroup: featureGroup })
                                    refetchFeatureGroupClients()
                                  }}
                                  plans={plans || []}
                                  value={[featureGroup]}
                                />
                              }
                              overlayStyle={{
                                boxShadow: '0 0 10px rgba(0,0,0,.1)',
                              }}
                              trigger={['click']}
                            >
                              <Button shape="round" size="small">
                                Change
                              </Button>
                            </Dropdown>
                          ),
                        ]}
                      >
                        <Skeleton active avatar loading={isLoading} title={false}>
                          <List.Item.Meta
                            avatar={
                              <Avatar
                                alt={formatName(client)}
                                icon={<UserOutlined />}
                                src={client.clientImage}
                                style={{ background: COLORS.APP_BACKGROUND_LEVEL_1 }}
                              />
                            }
                            description={client.phoneNumber}
                            title={
                              <Link to={route(ROUTES.CLIENT.FEATURE_GROUPS, { id: client.id })}>
                                {formatName(client)}
                              </Link>
                            }
                          />
                        </Skeleton>
                      </List.Item>
                    )}
                  </VirtualList>
                </div>
              </List>
            </Col>
            <Col span={12}>
              <List
                footer={
                  featureGroup && (
                    <Layout display="flex" justifyContent="end" width="100%">
                      <Popover
                        content={
                          <CsvDownloader
                            data={featureGroup?.capabilities}
                            name={`capabilities_${featureGroup?.name.replaceAll(':', '_')}.csv`}
                          />
                        }
                        trigger="click"
                      >
                        <Button icon={<DownloadOutlined />} shape="round" size="small">
                          Download CSV
                        </Button>
                      </Popover>
                    </Layout>
                  )
                }
                header={
                  <StyledHeader>
                    <Row>
                      <Col flex={1}>
                        <Typography display="block" variant="caption1">
                          Capabilities <Badge>{filteredCapabilities?.length || 0}</Badge>
                        </Typography>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => setIsAddCapabilityModalOpen(true)}
                          shape="round"
                          size="small"
                          style={{ marginTop: SPACING[1] }}
                          type="primary"
                        >
                          Add Capability
                        </Button>
                      </Col>
                    </Row>
                    <Input
                      allowClear
                      onChange={(e) => setCapabilitySearchQuery(e.target.value)}
                      placeholder="Filter Capabilities …"
                      prefix={<SearchOutlined />}
                      style={{ marginTop: SPACING[3], marginBottom: SPACING[3] }}
                      value={capabilitySearchQuery}
                    />
                  </StyledHeader>
                }
                loading={isLoading}
              >
                {featureGroup?.capabilities.length === 0 && (
                  <List.Item style={{ opacity: 0.5 }}>
                    <i>{featureGroup.name}</i> has no capabilities assigned.
                  </List.Item>
                )}
                <VirtualList
                  data={filteredCapabilities || []}
                  height={window.innerHeight - (contentRect.bounds?.top || 0) - 100}
                  itemHeight={64}
                  itemKey="id"
                >
                  {(capability) => (
                    <List.Item
                      actions={[
                        featureGroup && (
                          <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            onCancel={(e) => e?.stopPropagation()}
                            onConfirm={async (e) => {
                              e?.stopPropagation()
                              await removeCapabilityFromFeatureGroup({ featureGroup, capability })
                            }}
                            placement="bottomLeft"
                            title={
                              <>
                                Are you sure you want to remove the Capability <b>“{capability.name}”</b> from the
                                Feature Group <b>“{featureGroup?.name}”</b>?
                              </>
                            }
                          >
                            <Button
                              aria-label="remove capability"
                              onClick={(e) => e.stopPropagation()}
                              shape="round"
                              size="small"
                            >
                              Remove
                            </Button>
                          </Popconfirm>
                        ),
                      ]}
                      key={capability.id}
                    >
                      <Skeleton active avatar loading={isLoading} title={false}>
                        <List.Item.Meta
                          avatar={<CheckCircleOutlined />}
                          description={capability.description || '(no description)'}
                          title={
                            <Link to={route(ROUTES.CAPABILITIES.CAPABILITY, { name: capability.name })}>
                              {capability.name}
                            </Link>
                          }
                        />
                      </Skeleton>
                    </List.Item>
                  )}
                </VirtualList>
              </List>
            </Col>
          </Row>
        )}
      </Measure>
    </Layout>
  )
}
