import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { SubscriptionDataField } from 'api/subscriptionData'

const StyledIframe = styled.iframe`
  border: 0;
`

export const Frost = ({
  client,
  customFields = [],
  ...rest
}: {
  client: any
  customFields: SubscriptionDataField[]
}): JSX.Element => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const listener = (event: any) => {
      if (event.data.action === 'ready') {
        setReady(true)
        window.removeEventListener('message', listener)
      }
    }

    window.addEventListener('message', listener)
  }, [])

  useEffect(() => {
    if (ready && iframeRef.current) {
      const message = { action: 'setClient', payload: client }
      // @ts-expect-error TS(2339): Property 'contentWindow' does not exist on type 'n... Remove this comment to see the full error message
      iframeRef.current.contentWindow.postMessage(message, '*')
    }
  }, [ready, client])

  useEffect(() => {
    if (ready && iframeRef.current) {
      const payload = customFields
        .filter((field) => field.fanEnabledStatus !== 'not_enabled')
        .map((field) => {
          // special case for dropdown fields
          if (field.valueType === 'string' && field.valueOptions?.length) {
            return {
              required: field.fanEnabledStatus === 'is_enabled_required',
              enabled: true,
              name: field.key,
              label: field.name,
              type: 'select',
              options: field.valueOptions
                .map((o) => {
                  if (o.status === 'inactive') {
                    return false
                  }
                  return o.value || false
                })
                .filter((x) => x) as string[],
            }
          }
          return {
            required: field.fanEnabledStatus === 'is_enabled_required',
            enabled: true,
            name: field.key,
            label: field.name,
            type: field.valueType === 'string' ? 'text' : field.valueType,
          }
        })
      const message = { action: 'setCustomFields', payload }
      // @ts-expect-error TS(2339): Property 'contentWindow' does not exist on type 'n... Remove this comment to see the full error message
      iframeRef.current.contentWindow.postMessage(message, '*')
    }
  }, [ready, customFields])

  return (
    <StyledIframe
      {...rest}
      allowFullScreen
      ref={iframeRef}
      src={import.meta.env.VITE_FROST_PREVIEW_URL}
      title="Leader signup preview"
    ></StyledIframe>
  )
}
