import { useQueryClient } from '@tanstack/react-query'
import { Descriptions, Space, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { putClient } from 'api/clients'
import { QUERIES } from 'constants/queries'
import { InlineEditField } from 'shared/components/InlineEditField/InlineEditField'
import { Switch } from 'shared/components/Switch'

const StyledThrottling = styled.div`
  display: flex;
`

const StyledSection = styled.section`
  padding: 20px 0;
`

const formatNumber = (value: any) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const validateLimit = (value: any) => Boolean(value.match(/^\d{4,}$/)) && Number(value) > 1000

export function MessageLimitSection({ client }: any) {
  const queryClient = useQueryClient()
  return (
    <StyledSection>
      <Descriptions bordered column={1} title="Message Limit">
        <Descriptions.Item label="Throttling Active">
          <StyledThrottling>
            <Space>
              <Switch
                defaultChecked={client.campaignMessagesMonthlyThreshold > 1000}
                name="Throttling Active"
                onSuccess={() => queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])}
                prepare={(value) => ({
                  id: client.id,
                  body: {
                    campaign_messages_monthly_threshold: value ? 25001 : 0,
                  },
                })}
                updateEntity={putClient}
              />
              {client.campaignMessagesMonthlyThreshold > 1000 && (
                <InlineEditField
                  defaultValue={formatNumber(client.campaignMessagesMonthlyThreshold)}
                  name="Monthly Limit"
                  onSuccess={() => queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])}
                  prepare={(value) => ({
                    id: client.id,
                    body: { campaign_messages_monthly_threshold: value },
                  })}
                  updateEntity={putClient}
                  validation={validateLimit}
                >
                  {({ valid }: { valid: any }) =>
                    client.campaignMessagesMonthlyThreshold > 1000 && (
                      <Typography.Text type={valid ? 'secondary' : 'danger'}>
                        Provide a value greater than 1,000.
                      </Typography.Text>
                    )
                  }
                </InlineEditField>
              )}
            </Space>
          </StyledThrottling>
        </Descriptions.Item>
      </Descriptions>
    </StyledSection>
  )
}
