import { useMutation, useQuery } from '@tanstack/react-query'
import indexBy from 'lodash/fp/indexBy'
import { useMemo } from 'react'

import { useToastMessage } from './useNotification'

import {
  FeatureGroup,
  FeatureGroupClient,
  deleteCapabilityFromFeatureGroup,
  deleteClientFeatureGroup,
  getCapabilities,
  getFeatureGroups,
  patchFeatureGroup,
  postCapabilityToFeatureGroup,
  postClientFeatureGroup,
  deleteFeatureGroup as deleteFeatureGroupApi,
  postCapability,
  PostClientFeatureGroupResponse,
  PatchFeatureGroupResponse,
} from 'api/capabilities'
import { Capability } from 'api/clients'
import { QUERIES } from 'constants/queries'
import { CapabilityFormValues } from 'screens/CapabilitiesScreen/components/CreateCapabilityModal'
import { CapabilityWithFeatureGroupsAndPlans, groupFeatureGroupsByCapability } from 'screens/CapabilitiesScreen/util'
import { formatName } from 'utils/name'

type RemoveCapabilityFromFeatureGroup = (props: { featureGroup: FeatureGroup; capability: Capability }) => Promise<void>

type AddCapabilityToFeatureGroup = (props: {
  featureGroup: FeatureGroup
  capability: Capability
}) => Promise<FeatureGroup>

type RemoveFeatureGroup = ({ id, featureGroupName }: { id: string; featureGroupName: string }) => Promise<void>

type DeleteFeatureGroup = (name: string) => Promise<void>

type AddClientToFeatureGroup = (values: {
  client: FeatureGroupClient
  featureGroup: FeatureGroup
}) => Promise<PostClientFeatureGroupResponse>

type UpdateFeatureGroup = (values: {
  featureGroup: FeatureGroup
  updatedName: string
}) => Promise<PatchFeatureGroupResponse>

type ChangeClientPlan = (values: {
  client: FeatureGroupClient
  newFeatureGroup: FeatureGroup
  oldFeatureGroup: FeatureGroup
}) => Promise<any>

export function useFeatureGroups(): {
  isLoading: boolean
  capabilities: Record<string, CapabilityWithFeatureGroupsAndPlans>
  allFeatureGroupsAndPlans: FeatureGroup[]
  featureGroups: FeatureGroup[]
  plans: FeatureGroup[]
  removeFeatureGroupFromClient: RemoveFeatureGroup
  deleteFeatureGroup: DeleteFeatureGroup
  createCapability(values: CapabilityFormValues): Promise<Capability>
  addCapabilityToFeatureGroup: AddCapabilityToFeatureGroup
  removeCapabilityFromFeatureGroup: RemoveCapabilityFromFeatureGroup
  updateFeatureGroup: UpdateFeatureGroup
  addClientToFeatureGroup: AddClientToFeatureGroup
  changeClientPlan: ChangeClientPlan
  refetch(): void
} {
  const { showNotification } = useToastMessage()
  const { data: capabilities, refetch: refetchCapabilities } = useQuery([QUERIES.CAPABILITIES], {
    queryFn: () => getCapabilities({ prefix: 'feature' }),
    staleTime: 5 * 60 * 1000,
    initialData: [],
  })
  const {
    data: allFeatureGroupsAndPlans,
    isLoading: isLoadingFeatureGroups,
    refetch: refetchClientFeatureGroups,
  } = useQuery([QUERIES.FEATURE_GROUPS_ALL], {
    queryFn: () => getFeatureGroups(),
    staleTime: 5 * 60 * 1000,
  })
  const allPlans = useMemo(() => {
    return allFeatureGroupsAndPlans
      ?.filter((p) => p.name.startsWith('plan:'))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [allFeatureGroupsAndPlans])
  const allFeatureGroups = useMemo(() => {
    return allFeatureGroupsAndPlans
      ?.filter((p) => p.name.startsWith('feature:'))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [allFeatureGroupsAndPlans])
  const allCapabilities = useMemo(() => {
    const capabilitiesInFeatureGroups = groupFeatureGroupsByCapability(allFeatureGroupsAndPlans || [])
    const allCapabilities = indexBy(
      'name',
      capabilities.map((c) => ({ ...c, featureGroups: {}, plans: {} })),
    )
    return {
      ...allCapabilities,
      ...capabilitiesInFeatureGroups,
    }
  }, [allFeatureGroupsAndPlans, capabilities])
  const refetch = () => {
    refetchCapabilities()
    refetchClientFeatureGroups()
  }
  const { mutateAsync: removeFeatureGroupFromClient } = useMutation({
    mutationFn: deleteClientFeatureGroup,
    onSuccess(_, { featureGroupName }) {
      showNotification(`removed Feature Group ${featureGroupName} from client`)
    },
    onError() {
      showNotification('Could not remove feature group')
    },
  })
  const { mutateAsync: addClientToFeatureGroup } = useMutation({
    mutationFn: async ({ client, featureGroup }: { client: FeatureGroupClient; featureGroup: FeatureGroup }) => {
      return postClientFeatureGroup({ id: client.id, featureGroupName: featureGroup.name })
    },
    onSuccess(_, { featureGroup, client }) {
      showNotification(`Added ${featureGroup?.name} to ${formatName(client)}`)
    },
    onError() {
      showNotification(`Could not add Feature Group`, false)
    },
  })

  const { mutateAsync: addCapabilityToFeatureGroup } = useMutation({
    mutationFn: ({ featureGroup, capability }: { featureGroup: FeatureGroup; capability: Capability }) => {
      return postCapabilityToFeatureGroup({ featureGroupName: featureGroup.name, capabilityName: capability.name })
    },
    onSuccess(_, { capability, featureGroup }) {
      showNotification(`Added ${capability?.name} to ${featureGroup?.name}`)
      refetch()
    },
    onError() {
      showNotification(`Could not add Capability`, false)
    },
  })

  const { mutateAsync: removeCapabilityFromFeatureGroup } = useMutation({
    mutationFn: async ({ featureGroup, capability }: { featureGroup: FeatureGroup; capability: Capability }) => {
      return deleteCapabilityFromFeatureGroup({ featureGroupName: featureGroup.name, capabilityName: capability.name })
    },
    onSuccess(_, { capability, featureGroup }) {
      showNotification(`Removed ${capability.name} from ${featureGroup.name}`)
      refetch()
    },
    onError() {
      showNotification(`Could not remove Capability`, false)
    },
  })

  const { mutateAsync: updateFeatureGroup } = useMutation({
    mutationFn: ({ featureGroup, updatedName }: { featureGroup: FeatureGroup; updatedName: string }) => {
      return patchFeatureGroup(featureGroup.name, { name: updatedName })
    },
    onSuccess(_, { featureGroup, updatedName }) {
      showNotification(`Renamed ${featureGroup.name} to ${updatedName}`)
      refetch()
    },
    onError(_, { featureGroup }) {
      showNotification(`Failed to rename ${featureGroup.name}`, false)
    },
  })

  const { mutateAsync: changeClientPlan } = useMutation({
    mutationFn: async ({
      client,
      newFeatureGroup,
      oldFeatureGroup,
    }: {
      client: FeatureGroupClient
      newFeatureGroup: FeatureGroup
      oldFeatureGroup: FeatureGroup
    }) => {
      await removeFeatureGroupFromClient({ id: client.id, featureGroupName: oldFeatureGroup.name })
      await addClientToFeatureGroup({ client, featureGroup: newFeatureGroup })
    },
    onError() {
      showNotification(`Plan could not be changed`, false)
    },
  })

  const { mutateAsync: deleteFeatureGroup } = useMutation({
    mutationFn: async (name: string) => {
      await deleteFeatureGroupApi({ name })
    },
    onSuccess(_, name) {
      showNotification(`${name} has been deleted`)
      refetch()
    },
    onError(_, name) {
      showNotification(`${name} could not be deleted.`, false)
    },
  })

  const { mutateAsync: createCapability } = useMutation({
    mutationFn: async (values: CapabilityFormValues): Promise<Capability> => {
      return await postCapability({ name: values.name, description: values.description })
    },
    onError(_, vars) {
      showNotification(`Capability “${vars.name}” could not be created`, false)
    },
    onSuccess() {
      refetch()
    },
  })

  return {
    isLoading: isLoadingFeatureGroups,
    capabilities: allCapabilities,
    allFeatureGroupsAndPlans: allFeatureGroupsAndPlans || [],
    featureGroups: allFeatureGroups || [],
    plans: allPlans || [],
    createCapability,
    deleteFeatureGroup,
    changeClientPlan,
    addCapabilityToFeatureGroup,
    removeCapabilityFromFeatureGroup,
    updateFeatureGroup,
    removeFeatureGroupFromClient,
    addClientToFeatureGroup,
    refetch,
  }
}
