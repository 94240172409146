import { CheckOutlined } from '@ant-design/icons'
import { ApiError } from '@community_dev/requests'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@tanstack/react-query'
import { Button, Input, Space, Spin, notification } from 'antd'
import noop from 'lodash/noop'
import T from 'prop-types'
import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { ButtonModalPrompt } from 'components/ButtonModalPrompt'

enum InlineEditFieldMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

const StyledEditButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme?.COLORS?.LINKS};
  }
`

const StyledProcessing = styled.span`
  margin-right: 8px;
`

const StyledInputWrapper = styled.div<{ $valid: boolean }>`
  display: flex;
  ${({ $valid }) =>
    !$valid &&
    css`
      input[type='text']:focus,
      input[type='text'] {
        border: 1px solid red;
      }
    `}
`

export function InlineEditField({
  children = noop,
  defaultValue = '',
  disabled = false,
  name,
  onSuccess,
  pattern,
  placeholder = 315,
  prepare,
  updateEntity,
  useErrorMessage = false,
  validation,
  warningModalText,
}: any) {
  const inputRef = useRef()
  const [state, setState] = useState<InlineEditFieldMode>(InlineEditFieldMode.VIEW)
  const [value, setValue] = useState(defaultValue?.toString())
  const [valid, setValid] = useState(true)
  const { mutate: save, isLoading } = useMutation(updateEntity, {
    onError(e: ApiError) {
      const suffix = useErrorMessage && e.errors?.[0]?.message ? `: ${e.errors?.[0]?.message}` : '. Please try again.'
      notification.error({
        message: name ? `${name} failed to save${suffix}` : `Sorry, something went wrong${suffix}`,
        placement: 'bottomRight',
        duration: 2,
      })
      setValue(defaultValue.toString())
      setState(InlineEditFieldMode.VIEW)
    },
    onSuccess(data) {
      notification.success({
        message: name ? `${name} saved.` : 'Saved.',
        placement: 'bottomRight',
        duration: 2,
      })
      if (onSuccess) onSuccess(data)
      setState(InlineEditFieldMode.VIEW)
    },
  })

  function handleChange(evt: any) {
    const current = evt.target.value
    if (pattern && !current.match(pattern)) return
    setValue(current)
  }

  function handleKeyDown(evt: any) {
    // enter is handled by onPressEnter from andt Input
    if (evt.key === 'Enter') {
      return
    }

    if (evt.key !== 'Escape') return
    setValue(defaultValue.toString())
    setState(InlineEditFieldMode.VIEW)
  }

  function handleSave() {
    if (validation && !validation(value)) {
      setValid(false)
      return
    }
    setValid(true)
    const data = prepare ? prepare(value) : value
    save(data)
  }

  if (isLoading) {
    return (
      <div aria-busy aria-label="Saving" aria-live="polite">
        <StyledProcessing>{value}</StyledProcessing> <Spin size="small" />
      </div>
    )
  }

  switch (state) {
    case InlineEditFieldMode.EDIT:
      return (
        <>
          <div>
            <Space>
              <StyledInputWrapper $valid={valid}>
                <Input
                  autoFocus
                  disabled={disabled}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onPressEnter={handleSave}
                  placeholder={placeholder}
                  // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
                  ref={inputRef}
                  value={value?.replace(/,/g, '')}
                />
                {warningModalText ? (
                  <ButtonModalPrompt
                    buttonText={<CheckOutlined />}
                    buttonType="primary"
                    modalText={warningModalText}
                    modalTitle="Warning"
                    onClick={handleSave}
                    size="middle"
                  />
                ) : (
                  <Button aria-label="Save" onClick={handleSave} size="middle" type="primary">
                    <CheckOutlined />
                  </Button>
                )}
              </StyledInputWrapper>
            </Space>
          </div>
          {children({ valid })}
        </>
      )
    default:
      return (
        <>
          <div>
            {value}{' '}
            {!disabled && (
              <StyledEditButton aria-label="Edit" onClick={() => setState(InlineEditFieldMode.EDIT)}>
                <FontAwesomeIcon icon="edit" />
              </StyledEditButton>
            )}
          </div>
          {children({ valid })}
        </>
      )
  }
}

InlineEditField.propTypes = {
  children: T.node,
  disabled: T.bool,
  defaultValue: T.oneOfType([T.string, T.number]),
  name: T.string,
  placeholder: T.string,
  prepare: T.func,
  pattern: T.instanceOf(RegExp),
  onSuccess: T.func,
  updateEntity: T.func.isRequired,
  useErrorMessage: T.bool,
  validation: T.func,
  warningModalText: T.string,
}
