import { BORDER_RADIUS } from '@community_dev/pixels'
import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Frost } from './Frost'

const StyledModal = styled(Modal)`
  border-radius: ${BORDER_RADIUS[2]};

  .ant-modal-content {
    border-radius: ${BORDER_RADIUS[2]};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }

  .ant-modal-body {
    height: 80vh;
    position: relative;
  }
`

const StyledLandscapeIframe = styled(Frost)`
  border: none;
  width: 100%;
  height: 100%;
  border-radius: ${BORDER_RADIUS[2]};
`

export const PreviewButton = ({ client, customFields, ...rest }: any): JSX.Element => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(true)} {...rest} />
      <StyledModal bodyStyle={{ padding: 0 }} footer={null} onCancel={() => setOpen(false)} open={open} width="90vw">
        <StyledLandscapeIframe client={client} customFields={customFields} />
      </StyledModal>
    </>
  )
}
