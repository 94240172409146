export const ENDPOINTS = Object.freeze({
  ADMINS: {
    CLIENT_SETTINGS: `/batcave/v1/clients/:client_id/client_settings`,
    INDIVIDUAL: `/batcave/admins/:adminId`,
    LIST: `/batcave/admins`,
  },
  AVAILABLE_NUMBERS: '/batcave/available_numbers',
  BILLING: {
    BILLING_PAYWALL: '/billing/v1/clients/:clientId/billing_paywall',
    CANCEL_SUBSCRIPTION: '/billing/v2/clients/:clientId/cancel_subscription/:subscriptionId',
    CLIENT: '/billing/v1/clients/:clientId',
    SUBSCRIPTION: '/billing/v2/clients/:clientId/subscription',
    TWILIO_SUBACCOUNT_STATUS: '/batcave/v1/clients/:clientId/twilio_subaccount_status',
  },
  CAPABILITIES: {
    CAPABILITIES: '/capabilities/v1/capabilities?prefix=:prefix',
    CAPABILITY: '/capabilities/v1/capabilities/:capabilityName',
    FEATURE_GROUP: '/capabilities/v1/feature-groups/:featureGroupName',
    FEATURE_GROUPS: '/capabilities/v1/feature-groups',
    FEATURE_GROUP_CAPABILITIES: '/capabilities/v1/feature-groups/:featureGroupName/capabilities ',
    FEATURE_GROUP_CAPABILITY: '/capabilities/v1/feature-groups/:featureGroupName/capabilities/:capabilityName',
    FEATURE_GROUP_CLIENTS: '/capabilities/v1/feature-groups/:featureGroupName/clients',
    ROLES: '/capabilities/v1/roles',
    ROLES_ADMINS: '/capabilities/v1/roles?visibility=admin',
    SEAT_MAPPINGS: '/capabilities/v1/seat-mappings/:seatMappingId/roles',
    SEAT_MAPPINGS_ADMINS: '/capabilities/v1/admins/seat-mappings/:seatMappingId',
    TRANSFER_PROTECTED_ROLE: '/capabilities/v2/clients/:clientId/seat-mappings/:seatMappingId/roles/:roleId',
  },
  CLIENTS: {
    ALL: '/batcave/clients',
    CLIENT_SETTINGS: '/client-dashboard/v3/:client_id/client_settings',
    COMMUNITY_PLUS_INVITE_LINK: '/paid_communities/v1/admin/billing_subscriptions/:inviteId',
    COMMUNITY_PLUS_SUBSCRIPTIONS: '/paid_communities/v1/admin/billing_subscriptions',
    CONTENT_REVIEW: '/content-review/v1/clients/:id',
    DELETE: '/batcave/clients/:clientId',
    FAN_ONBOARDING: '/batcave/clients/:id/fan-onboarding-messages',
    FAN_ONBOARDING_LABELS: '/batcave/clients/:clientId/import-onboarding-messages-labels',
    FAN_ONBOARDING_MESSAGE: '/batcave/clients/:id/fan-onboarding-messages/:messageId',
    FEATURE_GROUP: '/capabilities/v1/clients/:clientId/feature-groups/:featureGroupName',
    FEATURE_GROUPS: '/capabilities/v1/feature-groups/clients/:clientId',
    IMPERSONATE: '/sessions/v1/impersonate',
    INDIVIDUAL: '/batcave/v1/clients/:id',
    INVITE: '/batcave/clients',
    KEY_WORDS: '/batcave/clients/:id/keyword-messages',
    NUMBER_IMPORTS: {
      IMPORT: '/batcave/clients/:clientId/number-imports',
      PRESIGNED_URL: '/batcave/clients/:clientId/number-imports/presigned-url',
    },
    ONBOARDING_TASKS: '/batcave/onboarding-tasks?client_id=:id',
    PENDING_INVITES: '/clients/v1/:id/invitations',
    PRESIGNED_URL: `/batcave/v1/clients/:client_id/presigned-url?operation=:operation&extension=:extension&file_suffix=:file_suffix`,
    PROFILE_IMAGE: '/batcave/clients/:id/profile-image',
    REMOVE_INVITE: '/clients/v1/:id/invitations/:inviteId',
    REQUIRE_MFA: `/batcave/v1/clients/:id/require_mfa`,
    ROLES: `/capabilities/v1/roles`,
    SEND_INVITE: '/clients/v1/:id/invitations',
    SEND_VCARD: '/batcave/clients/:clientId/send_vcards',
    SESSIONS: '/auth/v1/clients/:clientId/sessions',
    SESSION_TEAM_MEMBER: '/auth/v1/seats/:memberId/sessions',
    SYSTEMIC_ONBOARDING: '/batcave/clients/:clientId/systematic-onboarding',
    TAG: '/clients/v1/:clientId/tags/:tagId',
    TAGS: '/clients/v1/:clientId/tags',
    TEAM_MEMBER: '/clients/v1/:id/seats/:memberId',
    TEAM_MEMBERS: '/batcave/clients/:id/team-members',
    TERMS: `/client_terms/v1/clients/:client_id/terms`,
    VOICEMAIL: '/batcave/clients/:id/voicemail',
  },
  DOWNLOAD: '/download/v1/files/:clientId/:fileName?noredirect=true',
  LOGIN: {
    REFRESH_TOKEN: '/auth/v1/refresh?force_recreate=true',
  },
  LOGOUT: '/auth/v1/logout',
  MEMBER: {
    DELETE: '/batcave/fan/delete',
  },
  SEATS: {
    TEAM_MEMBER: '/batcave/v1/seats/:memberId',
  },
  SUBSCRIPTION_DATA: {
    COUNT: '/subscription-data/v1/clients/:clientId/subscriptions/count',
    FIELDS: '/subscription-data/v1/clients/:clientId/fields',
    SAVE_FIELD: '/subscription-data/v1/clients/:clientId/fields',
  },
  TCR: {
    REGISTRATION: '/twilio_onboarding/v2/clients/:clientId/seats/:seatId/registration',
    REGISTRATION_SUBMISSION: '/twilio_onboarding/v1/clients/:clientId/registration_submission',
  },
} as const)
