import React from 'react'

import SimpleLayout from 'shared/layouts/SimpleLayout'
import './BoxFormLayout.scss'

type BoxFormLayoutProps = {
  children?: React.ReactNode
}

class BoxFormLayout extends React.PureComponent<BoxFormLayoutProps> {
  render() {
    return (
      <SimpleLayout>
        <div className="BoxFormLayout-root">
          <div className="BoxFormLayout-main">{this.props.children}</div>
        </div>
      </SimpleLayout>
    )
  }
}

export default BoxFormLayout
