export const baseConfig = {
  apiUrl: import.meta.env.VITE_API_URL,
  authToken: import.meta.env.VITE_AUTH_TOKEN,
  dashboardUrl: import.meta.env.VITE_DASHBOARD_URL,
  deployEnv: import.meta.env.VITE_DEPLOY_ENV || 'dev',
  nodeEnv: import.meta.env.NODE_ENV,
}

export const analyticsConfig = {
  gaTrackingId: import.meta.env.VITE_GA_TRACKING_ID,
}
