import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledSpinner = styled.div`
  width: 100%;
  height: 200px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

export function BodySpinner() {
  return (
    <StyledSpinner>
      <Spin size="large" />
    </StyledSpinner>
  )
}
