import { ThemeProvider } from '@community_dev/pixels'
import LIGHT from '@community_dev/pixels/dist/js/themes/presets/light'
import { library } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from 'containers/App'
import store, { persistor } from 'store'
import * as faIcons from 'utils/fa-icons'
import 'normalize.css'
import 'styled-components'

// window.AbortController polyfill
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

// window.fetch polyfill
import 'whatwg-fetch'

import 'integrations/Sentry'
declare global {
  interface Window {
    Cypress: unknown
  }
}

if ((import.meta.env.DEV && !(window as any).Cypress) || import.meta.env.VITE_INCLUDE_MOCKS === 'true') {
  import('./mocks/browser').then(({ worker }) => {
    worker.start({ onUnhandledRequest: 'bypass' })
  })
}

// Add font awesome icons globally
// @ts-expect-error TS(2345): Argument of type 'typeof import("...' Remove this comment to see the full error message
library.add(faIcons)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ThemeProvider theme={LIGHT}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>,
)
