import {
  BuiltInFields,
  FieldSources,
  FieldTypes,
  MemberDataFilter,
  RelationshipOperators,
  SelectorOperators,
  fieldLabelFor,
} from '@community_dev/filter-dsl/lib/subscription-data'
import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { getClient, getContentReview, postCountByQuery } from 'api/clients'
import { deleteClientTerms, getClientTerms, postOrPatchClientTerms } from 'api/leader'
import { QUERIES, STALE_TIME } from 'constants/queries'
import { useToastMessage } from 'hooks/useNotification'
import { combinedQueryState } from 'utils/query'

export function useGeneralDetails(id: any) {
  const contentReview = useQuery([QUERIES.CONTENT_REVIEW, { id }], () => getContentReview(id))
  const client = useQuery([QUERIES.CLIENT, { id }], () => getClient(id))

  return combinedQueryState({ contentReview, client })
}

export function useClientTerms(clientId: any) {
  const { showNotification } = useToastMessage()
  const clientTerms = useQuery([QUERIES.TERMS, { clientId }], () => getClientTerms(clientId))
  const { refetch: refetchClientTerms } = clientTerms

  const { mutate: saveTerms } = useMutation(postOrPatchClientTerms, {
    onError() {
      showNotification('Could not update Client Terms. Please try again.', false)
    },
    onSuccess() {
      showNotification('Client Terms Updated.')
      refetchClientTerms()
    },
  })

  const { mutate: deleteTerms } = useMutation(deleteClientTerms, {
    onError() {
      showNotification('Could not delete Client Terms. Please try again.', false)
    },
    onSuccess() {
      showNotification('Client Terms Deleted.')
      refetchClientTerms()
    },
  })

  return { clientTerms, saveTerms, deleteTerms }
}

export type MemberCountChannel = {
  [key in CommunicationChannel | 'unknown']?: number
}

type MemberCountChannelWithTotal = MemberCountChannel & {
  total: number
  isLoading: boolean
}

const everyoneFilter: MemberDataFilter = {
  operator: RelationshipOperators.AND,
  operands: [
    {
      operator: SelectorOperators.EQUALS,
      operand: {
        field_key: BuiltInFields.EVERYONE,
        field_label: fieldLabelFor(BuiltInFields.EVERYONE),
        source: FieldSources.BUILT_IN,
        value: '*',
        type: FieldTypes.STRING,
      },
    },
  ],
}

export const useMemberCount = ({
  clientId,
  communicationChannels = [],
}: {
  clientId: string
  communicationChannels: CommunicationChannel[]
}): MemberCountChannelWithTotal => {
  const results = useQueries({
    queries: communicationChannels.map((channel) => ({
      queryKey: [QUERIES.SUBSCRIPTION_DATA.COUNT, { clientId, filters: everyoneFilter, communicationChannel: channel }],
      queryFn: async () => {
        return postCountByQuery({
          filters: everyoneFilter,
          communicationChannel: channel,
          clientId,
        })
      },
      staleTime: STALE_TIME.FIVE_MINUTES,
      enabled: Boolean(clientId && channel),
      refetchOnWindowFocus: false,
      select: (data) => ({ count: data ?? 0, communicationChannel: channel }),
    })),
  })

  return useMemo(
    () => ({
      total: results.reduce((acc, { data }) => acc + (data?.count ?? 0), 0),
      sms: results.find(({ data }) => data?.communicationChannel === CommunicationChannel.SMS)?.data?.count,
      whatsapp: results.find(({ data }) => data?.communicationChannel === CommunicationChannel.WHATS_APP)?.data?.count,
      amb: results.find(({ data }) => data?.communicationChannel === CommunicationChannel.AMB)?.data?.count,
      isLoading: results.some(({ isInitialLoading }) => isInitialLoading),
    }),
    [results],
  )
}
