import { HTTP } from '@community_dev/requests'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'
import { route } from 'utils/router'

export type FieldValueOption = {
  value: string
  status?: 'active' | 'inactive'
}

export type SubscriptionDataField = {
  key: string
  name: string
  valueOptions: FieldValueOption[] | null
  valueType: 'string' | 'integer' | 'decimal2' | 'decimal4' | 'date' | 'boolean'
  fanEnabledStatus: 'is_enabled_required' | 'is_enabled_optional' | 'not_enabled'
  source: string
}

export async function getSubscriptionDataFields(clientId: string): Promise<SubscriptionDataField[]> {
  const resp = await request(route(ENDPOINTS.SUBSCRIPTION_DATA.FIELDS, { clientId }))
  return resp?.data
}

export async function putSubscriptionDataFields(clientId: string, fields: SubscriptionDataField[]): Promise<any> {
  const resp = await request(route(ENDPOINTS.SUBSCRIPTION_DATA.SAVE_FIELD, { clientId }), {
    method: HTTP.PUT,
    body: fields,
  })
  return resp
}
