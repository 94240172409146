import { WarningFilled } from '@ant-design/icons'
import { required as requiredRule, composeValidators, email as emailRule, SPACING } from '@community_dev/pixels'
import { RoleGroup } from '@community_dev/types/lib/api/v1/Roles'
import { Button, Col, Input, Modal, Row, Switch, Tooltip, Typography, notification } from 'antd'
import { Field, Form } from 'react-final-form'
import styled, { useTheme } from 'styled-components'

import { RoleDetails } from './RoleDetails'

import { Client, Role, logOutTeamMember } from 'api/clients'
import { CAPABILITIES } from 'constants/capabilities'
import { SeatRoles } from 'constants/roles'
import { useHasCapability } from 'hooks/useCapabilities'
import { StyledModal } from 'shared/components/Modal'
import { RoleSelect } from 'shared/components/RoleSelect'
import {
  formatRoleName,
  getPrimaryRole,
  isRoleDisabled,
  isRoleInRoles,
  switchPrimaryRole,
  toggleRole,
} from 'utils/roles'

const StyledRoleDetails = styled(RoleDetails)`
  margin-top: 15px;
`

export type TeamMemberValue = {
  firstName: string
  lastName: string
  email: string
  roles: Role[]
  id: string
  seatMappingId: string
}

export type TeamMemberEditModalProps = {
  onSubmit(value: TeamMemberValue): any
  onCancel(): any
  roles: Role[]
  isDownloadEnabled: boolean
  client: Client
  value: TeamMemberValue
}

export function TeamMemberEditModal({
  onSubmit,
  onCancel,
  value,
  isDownloadEnabled,
  client,
  roles,
}: TeamMemberEditModalProps): JSX.Element {
  const { COLORS } = useTheme() || {}
  const canLogOutUser = useHasCapability(CAPABILITIES.ADMIN.CLIENT.LOGOUT)
  const dataDownloaderRole = roles.find((role) => role.name === SeatRoles.DATA_DOWNLOADER)
  const isOwner = value.roles.some((role) => role.name === SeatRoles.OWNER)
  async function onLogout(member: TeamMemberValue) {
    try {
      await logOutTeamMember({ memberId: member.id })
      notification.success({ message: `${member.firstName} ${member.lastName} is now logged out.` })
    } catch (e) {
      notification.error({ message: 'There was an error logging out the seat.' })
    }
  }
  return (
    <Form
      initialValues={value}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, submitting, valid }) => (
        <StyledModal
          cancelText={null}
          confirmLoading={submitting}
          footer={
            <Row justify="space-between">
              <Tooltip title={!canLogOutUser && 'Your account doesn’t have permission to log out a member'}>
                <Button
                  danger
                  disabled={!canLogOutUser}
                  onClick={() => {
                    const confirm = Modal.confirm({
                      type: 'error',
                      icon: <WarningFilled color="red" />,
                      title: `Are you sure you want to log ${values.firstName} ${values.lastName}?`,
                      content: (
                        <>
                          <p>
                            The Team Member will be logged out of all their accounts on Community, not just this
                            specific one.
                          </p>
                          <p>
                            Any API Tokens created by this user will also be invalidated (including on other clients
                            they have access to).
                          </p>
                        </>
                      ),
                      okType: 'danger',
                      okButtonProps: { danger: true, content: 'Yes, log out all members.' },
                      onOk: () => {
                        onLogout(value)
                        confirm.destroy()
                      },
                    })
                  }}
                  size="large"
                >
                  Log out {value.firstName}
                </Button>
              </Tooltip>
              <Button
                data-testid="team-save-button"
                disabled={!valid}
                key="submit"
                loading={submitting}
                onClick={handleSubmit}
                size="large"
                type="primary"
              >
                Save
              </Button>
            </Row>
          }
          onCancel={onCancel}
          open={true}
          title="Edit Team Member"
        >
          <form onSubmit={handleSubmit}>
            <Field<string> name="firstName" validate={requiredRule}>
              {({ input, meta }) => (
                <>
                  <label htmlFor="firstName">First name</label>
                  <Input id="firstName" size="large" type="text" {...input} />
                  {!meta.active && meta.touched && meta.modified && meta.error && (
                    <Typography.Text type="danger">First name {meta.error}</Typography.Text>
                  )}
                </>
              )}
            </Field>

            <Field<string> name="lastName">
              {({ input, meta }) => (
                <>
                  <label htmlFor="lastName">Last name</label>
                  <Input id="lastName" size="large" type="text" {...input} />
                  {!meta.active && meta.touched && meta.modified && meta.error && (
                    <Typography.Text type="danger">Last name {meta.error}</Typography.Text>
                  )}
                </>
              )}
            </Field>

            <Field<string> name="email" validate={composeValidators(requiredRule, emailRule)}>
              {({ input, meta }) => (
                <>
                  <label htmlFor="email">Email Address</label>
                  <Input id="email" size="large" type="email" {...input} />
                  {!meta.active && meta.touched && meta.modified && meta.error && (
                    <Typography.Text type="danger">Email {meta.error}</Typography.Text>
                  )}
                </>
              )}
            </Field>
            <Field<Role[]> name="roles">
              {({ input }) => (
                <>
                  <label htmlFor="canExport">Data Export</label>
                  {isDownloadEnabled && dataDownloaderRole ? (
                    <Row gutter={16}>
                      <Col data-testid={`toggle-${SeatRoles.DATA_DOWNLOADER}`} span={3}>
                        <Switch
                          defaultChecked={isRoleInRoles(dataDownloaderRole, values.roles)}
                          id="canExport"
                          // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
                          name="Export Settings"
                          onChange={() => input.onChange(toggleRole(dataDownloaderRole, values.roles))}
                          style={{ margin: '10px 0 0 5px' }}
                        />
                      </Col>
                      <Col span={20} style={{ color: COLORS?.SUBTEXT }}>
                        This Team Member {isRoleInRoles(dataDownloaderRole, values.roles) ? 'can' : 'can’t'} download
                        data from the{' '}
                        <a href="https://data-export.community.com" rel="noopener noreferrer" target="_blank">
                          Data Exporter Tool
                        </a>
                        .
                      </Col>
                    </Row>
                  ) : (
                    <Row style={{ color: COLORS?.SUBTEXT }}>
                      Data Exports are disabled for ”{client.firstName} {client.lastName}”
                    </Row>
                  )}
                  <label>Integrations</label>
                  {roles
                    .filter((role) => role.group !== RoleGroup.PRIMARY && role.name !== SeatRoles.DATA_DOWNLOADER)
                    .map((role) => {
                      return (
                        <Row gutter={16} key={role.id}>
                          <Col data-testid={`toggle-integration-${role.name}`} span={3}>
                            <Switch
                              defaultChecked={isRoleInRoles(role, values.roles)}
                              disabled={isRoleDisabled(role)}
                              onChange={() => input.onChange(toggleRole(role, values.roles))}
                              style={{ margin: '10px 0 0 5px' }}
                            />
                          </Col>
                          <Col span={20} style={{ color: COLORS?.SUBTEXT, marginTop: SPACING[3] }}>
                            {role.displayName || formatRoleName(role.name)}
                          </Col>
                        </Row>
                      )
                    })}
                  <label htmlFor="role">Role</label>
                  {isOwner && (
                    <Typography.Text type="secondary">
                      Ownership cannot be removed, only transferred to another account.
                    </Typography.Text>
                  )}
                  <RoleSelect
                    disabled={isOwner}
                    onChange={(primaryRole) => input.onChange(switchPrimaryRole(primaryRole, values.roles))}
                    outline={true}
                    roles={roles.filter((role) => role.group === RoleGroup.PRIMARY)}
                    value={getPrimaryRole(values.roles)}
                  />
                  <StyledRoleDetails roles={values.roles} />
                </>
              )}
            </Field>
          </form>
        </StyledModal>
      )}
    />
  )
}
