import { TwilioSubaccountStatus } from '@community_dev/types/lib/api/Twilio'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Descriptions, notification, Tooltip, Select } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

import { StyledSection } from '../../../styled'

import { getTwilioSubaccountStatus, putTwilioSubaccountStatus } from 'api/billing'
import { ButtonModalPrompt } from 'components/ButtonModalPrompt'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES, STALE_TIME } from 'constants/queries'
import { useHasCapability } from 'hooks/useCapabilities'

const options = [
  { value: TwilioSubaccountStatus.ACTIVE, label: 'Active' },
  { value: TwilioSubaccountStatus.SUSPENDED, label: 'Suspended' },
]

const StyledButtonWithModal = styled(ButtonModalPrompt)`
  margin-left: 12px;
`
export const Twilio = ({ clientId }: { clientId: string }): JSX.Element => {
  const canUpdateTwilioSubaccountStatus = useHasCapability(CAPABILITIES.ADMIN.CLIENT_PROVIDER.WRITE)
  const [selectedStatus, setSelectedStatus] = useState(undefined)
  const queryClient = useQueryClient()
  const { data, isInitialLoading } = useQuery({
    queryKey: [QUERIES.BILLING.TWILIO_SUBACCOUNT_STATUS, clientId],
    queryFn: () => getTwilioSubaccountStatus(clientId),
    staleTime: STALE_TIME.FIVE_MINUTES,
  })

  const { mutate: updateTwilioSubaccountStatus } = useMutation({
    mutationFn: (status: string) => putTwilioSubaccountStatus({ clientId, status }),
    onSuccess: (data) => {
      notification.success({
        message: 'Twilio Subaccount status updated.',
        placement: 'bottomRight',
        duration: 2,
      })
      setSelectedStatus(undefined)
      queryClient.setQueryData([QUERIES.BILLING.TWILIO_SUBACCOUNT_STATUS, clientId], () => data)
      queryClient.invalidateQueries([QUERIES.BILLING.TWILIO_SUBACCOUNT_STATUS, clientId])
    },
    onError: () => {
      notification.error({
        message: 'Could not update Twilio Subaccount status. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
    },
  })

  const twilioStatus = selectedStatus || data?.data?.status

  return (
    <StyledSection>
      <Descriptions bordered column={1} title="Twilio">
        <Descriptions.Item label="Subaccount status">
          <Tooltip title={canUpdateTwilioSubaccountStatus ? '' : 'Permission required'}>
            <span>
              <Select
                aria-label="twilio subaccount status"
                disabled={!canUpdateTwilioSubaccountStatus}
                loading={isInitialLoading}
                onChange={(value: any) => setSelectedStatus(value)}
                options={options}
                value={twilioStatus}
              />
              <StyledButtonWithModal
                buttonText="Update"
                danger
                disabled={!canUpdateTwilioSubaccountStatus}
                modalText={
                  selectedStatus === TwilioSubaccountStatus.ACTIVE
                    ? 'Are you sure you want to activate this account? Activating this account will enable the sending and receiving of calls and SMS messages.'
                    : 'Are you sure you want to suspend this account? Suspending this account will disable the sending and receiving of calls and SMS messages.'
                }
                modalTitle="WARNING"
                onClick={() => {
                  if (!twilioStatus) return
                  updateTwilioSubaccountStatus(twilioStatus)
                }}
              />
            </span>
          </Tooltip>
        </Descriptions.Item>
      </Descriptions>
    </StyledSection>
  )
}
