import PropTypes from 'prop-types'
import React from 'react'
import './SelectInput.scss'

class SelectInput extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { options: opts, value, label, title, error = '', className = '', ...rest } = this.props
    const options = opts.map((opt: any) => (opt.value ? opt : { value: opt, text: opt }))
    const cname = `SimpleInput-root ${error ? 'error' : ''} ${className} ${value ? 'active' : ''}`
    return (
      <div className={cname}>
        <div className="SimpleInput-main">
          <select value={value} {...rest} className="SimpleInput-input">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            {label && <option />}
            {options.map((option: any, idx: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <option key={`${option.value}${idx}`} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          {label && <div className="SimpleInput-label">{label}</div>}
          <div className="SimpleInput-line">
            <div className="SimpleInput-activeline" />
          </div>
          {error && (
            <div className="SimpleInput-error">
              {title || label} {error}
            </div>
          )}
        </div>
      </div>
    )
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
SelectInput.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.string,
}

export default SelectInput
