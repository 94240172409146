import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import './Snackbar.scss'

class Snackbar extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { active, position, level, message, trigger, onDismiss, onTrigger } = this.props
    let cname = 'Snackbar-root'
    if (level) cname += ` ${level}`
    if (position) cname += ` ${position}`
    if (active) cname += ' active'
    const hasTrigger = trigger && trigger.text
    return (
      <div className={cname}>
        <div className="Snackbar-main">
          <div className="Snackbar-message">{message}</div>
          {hasTrigger && (
            <div className="Snackbar-trigger" onClick={onTrigger}>
              {trigger.text}
            </div>
          )}
          {onDismiss && (
            <div className="Snackbar-dismiss" onClick={onDismiss}>
              <FontAwesomeIcon icon="times" />
            </div>
          )}
        </div>
      </div>
    )
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Snackbar.propTypes = {
  active: PropTypes.bool,
  level: PropTypes.oneOf(['success', 'info', 'warn', 'danger']),
  message: PropTypes.string,
  position: PropTypes.string,
  onDismiss: PropTypes.func,
  onTrigger: PropTypes.func,
  trigger: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
  }),
}

export default Snackbar
