import { UserAddOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Descriptions, Input, Row, Space } from 'antd'
import React, { useState } from 'react'
import Measure from 'react-measure'
import { useHistory, Route } from 'react-router'
import styled from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

import { useAdmins } from './queries'

import { Role } from 'api/clients'
import AdminForm from 'components/AdminForm'
import AdminTable from 'components/AdminTable'
import { ROUTES } from 'constants/routes'
import { route } from 'utils/router'

const StyledStatistics = styled.div`
  margin-top: -32px;
`

const StyledContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  .ant-table-row {
    cursor: pointer;
  }
`

const StyledSearchHelp = styled.p`
  font-size: 12px;
  font-style: italic;
  text-align: right;
`

function Admins() {
  const [tableHeight, setTableHeight] = useState(0)
  const [isAddingAdmin, setIsAddingAdmin] = useState(false)
  const [settings, setSettings] = useLocalStorageState('admin-list-settings', {
    searchQuery: '',
  })
  const history = useHistory()
  const { addAdmin, updateAdminRole, deleteAdmin, admins, isLoadingAdmins } = useAdmins()

  const filteredAdmins = admins
    .filter(
      (a: any) => `${a.fullName} ${a.email}`.toLocaleLowerCase().indexOf(settings.searchQuery.toLocaleLowerCase()) > -1,
    )
    .sort((a: any, b: any) => a.fullName.localeCompare(b.fullName))

  function createAdmin(a: any) {
    addAdmin(a)
    setIsAddingAdmin(false)
  }

  function updateAdmin(admin: { roles: Role[]; seatMappingId: string }) {
    const oldRoles = admins.find((a: any) => a.seat_mapping_id === admin.seatMappingId)?.roles
    if (oldRoles !== undefined) {
      updateAdminRole({
        roles: admin.roles,
        seatMappingId: admin.seatMappingId,
      })
      history.push(route(ROUTES.ADMINS))
    }
  }

  function onDelete({ seatMappingId }: { seatMappingId: string }) {
    deleteAdmin({ seatMappingId })
  }

  function getAdminBySeatMappingId(seatMappingId: any) {
    return admins.find((a: any) => a.seat_mapping_id === seatMappingId)
  }

  return (
    <>
      <PageHeader
        extra={
          <Space align="start">
            <Button icon={<UserAddOutlined />} onClick={() => setIsAddingAdmin(true)} size="large" type="primary">
              Add new Admin
            </Button>
            <div>
              <Input.Search
                allowClear
                data-testid="admin-search-field"
                onChange={({ target: { value } }) => setSettings({ searchQuery: value })}
                placeholder="Search"
                size="large"
                style={{ width: 300 }}
                value={settings.searchQuery}
              />
              <StyledSearchHelp>Search by name or by E-Mail.</StyledSearchHelp>
            </div>
          </Space>
        }
        title="Admins"
      >
        <StyledStatistics>
          <Row>
            <Descriptions column={3} size="small">
              <Descriptions.Item label="Total">{isLoadingAdmins ? '/' : admins.length}</Descriptions.Item>
            </Descriptions>
          </Row>
        </StyledStatistics>
      </PageHeader>
      {isAddingAdmin && (
        <AdminForm onClose={() => setIsAddingAdmin(false)} onSubmit={(admin: any) => createAdmin(admin)} />
      )}
      <Route
        exact
        path={route(ROUTES.ADMIN.EDIT)}
        render={({ match }) => (
          <AdminForm
            onClose={() => history.push(route(ROUTES.ADMINS))}
            onDelete={({ seatMappingId }: any) => onDelete({ seatMappingId })}
            onSubmit={(a) => updateAdmin(a)}
            values={getAdminBySeatMappingId(match.params.id)}
          />
        )}
      />
      <Measure bounds onResize={(contentRect: any) => setTableHeight(contentRect.bounds.height)}>
        {({ measureRef }: any) => (
          <StyledContainer ref={measureRef}>
            <AdminTable
              data={filteredAdmins}
              loading={isLoadingAdmins}
              onDelete={({ seatMappingId }: { seatMappingId: string }) => onDelete({ seatMappingId })}
              scroll={{ x: '100%', y: `${tableHeight - 140}px` }}
              showRoles={true}
            />
          </StyledContainer>
        )}
      </Measure>
    </>
  )
}

export default Admins
