import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import './ButtonLink.scss'

class ButtonLink extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'icon' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { icon, href, text, children, ...rest } = this.props
    return (
      <Link className="ButtonLink" to={href} {...rest}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {text || children}
      </Link>
    )
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ButtonLink.propTypes = {
  icon: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ButtonLink.defaultProps = {
  icon: '',
  href: '',
  text: '',
}

export default ButtonLink
