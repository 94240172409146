import { ApiError } from '@community_dev/requests'
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { CommunitiesResponse, getClientCommunities } from 'api/clients'
import { QUERIES } from 'constants/queries'

type UseCommunitiesProps<SelectData = CommunitiesResponse> = {
  clientId: string
  options?: UseQueryOptions<CommunitiesResponse, ApiError, SelectData>
}
export const useCommunitiesQuery = <SelectData = CommunitiesResponse>({
  clientId,
  options,
}: UseCommunitiesProps<SelectData>): UseQueryResult<SelectData, ApiError> => {
  return useQuery<CommunitiesResponse, ApiError, SelectData>({
    queryKey: [QUERIES.TAGS, clientId],
    queryFn: () => getClientCommunities(clientId),
    enabled: Boolean(clientId),
    ...options,
  })
}
