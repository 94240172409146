import { FeatureGroup } from 'api/capabilities'

export type CapabilityWithFeatureGroupsAndPlans = {
  createdAt: string
  description: string | null
  id: string
  name: string
  featureGroups: {
    [name: string]: Omit<FeatureGroup, 'capabilities'>
  }
  plans: {
    [name: string]: Omit<FeatureGroup, 'capabilities'>
  }
}

// reverses the list of _capabilities by feature group_ to _feature groups by capability_
export function groupFeatureGroupsByCapability(featureGroups: FeatureGroup[]): {
  [capabilityName: string]: CapabilityWithFeatureGroupsAndPlans
} {
  return featureGroups?.reduce((m, featureGroupOrPlan) => {
    featureGroupOrPlan.capabilities.forEach((capability) => {
      m[capability.name] = {
        ...capability,
        plans: {
          ...m[capability.name]?.plans,
          ...(featureGroupOrPlan.name.startsWith('plan')
            ? {
                [featureGroupOrPlan.name]: {
                  deprecated: featureGroupOrPlan.deprecated,
                  id: featureGroupOrPlan.id,
                  name: featureGroupOrPlan.name,
                  createdAt: featureGroupOrPlan.createdAt,
                },
              }
            : undefined),
        },
        featureGroups: {
          ...m[capability.name]?.featureGroups,
          ...(featureGroupOrPlan.name.startsWith('feature')
            ? {
                [featureGroupOrPlan.name]: {
                  deprecated: featureGroupOrPlan.deprecated,
                  id: featureGroupOrPlan.id,
                  name: featureGroupOrPlan.name,
                  createdAt: featureGroupOrPlan.createdAt,
                },
              }
            : undefined),
        },
      }
    })
    return m
  }, {} as { [name: string]: CapabilityWithFeatureGroupsAndPlans })
}
