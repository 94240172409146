import { InfoCircleOutlined } from '@ant-design/icons'
import { Layout, SPACING } from '@community_dev/pixels'
import { OnboardingMessageTemplateType } from '@community_dev/types/lib/api/OnboardingMessages'
import { Select, SelectProps, Tooltip } from 'antd'

export function TemplateTypeSelect({
  value,
  onChange,
  ...rest
}: Omit<SelectProps, 'value'> & {
  value?: OnboardingMessageTemplateType
  onChange?: (value: OnboardingMessageTemplateType) => any
}): JSX.Element {
  return (
    <Layout alignItems="center" display="flex" flexDirection="row" marginBottom={SPACING[2]}>
      <Select
        {...rest}
        aria-label="Select Template Type"
        onChange={onChange}
        options={[
          { label: 'Marketing Template', value: OnboardingMessageTemplateType.MARKETING },
          { label: 'Utility Template', value: OnboardingMessageTemplateType.UTILITY },
        ]}
        placeholder="Select Template Type"
        // the placeholder only shows up if the value is undefined, not if it’s
        // an empty string.
        style={{ width: '100%', marginRight: SPACING[2] }}
        value={value || undefined}
        virtual={false}
      />
      <Tooltip title="Click here to see Meta’s Documentation">
        <a
          href="https://go.atmeta.com/rs/267-PVB-941/images/WhatsApp-Business-Platform-Reference-guide-for-updates-to-template-category-guidelines.pdf"
          rel="noreferrer"
          target="_blank"
        >
          <InfoCircleOutlined style={{ marginRight: SPACING[2] }} />
        </a>
      </Tooltip>
    </Layout>
  )
}
