export const QUERIES = Object.freeze({
  ADMIN: 'ADMIN',
  ADMINS: 'ADMINS',
  BILLING: {
    CLIENT: 'BILLING_CLIENT',
    CLIENT_PLANS: 'CLIENT_PLANS',
    PLANS: 'PLANS',
    SUBSCRIPTION: 'SUBSCRIPTION',
    TWILIO_SUBACCOUNT_STATUS: 'TWILIO_SUBACCOUNT_STATUS',
  },
  CAPABILITIES: 'CAPABILITIES',
  CLIENT: 'CLIENT',
  CLIENTS: 'CLIENTS',
  CLIENT_DELETE: 'CLIENT_DELETE',
  CLIENT_ONBOARDING_LABELS: 'CLIENT_ONBOARDING_LABELS',
  CLIENT_SETTINGS: 'CLIENT_SETTINGS',
  COMMUNITY_PLUS_SUBSCRIPTIONS: 'COMMUNITY_PLUS_SUBSCRIPTIONS',
  CONTENT_REVIEW: 'CONTENT_REVIEW',
  DATA_DOWNLOAD_ENABLED: 'DATA_DOWNLOAD_ENABLED',
  FAN_COUNT: 'FAN_COUNT',
  FEATURE_GROUPS: 'FEATURE_GROUPS',
  FEATURE_GROUPS_ALL: 'FEATURE_GROUPS_ALL',
  FEATURE_GROUP_CLIENTS: 'FEATURE_GROUP_CLIENTS',
  KEYWORDS: 'KEYWORDS',
  LEADER: 'LEADER',
  LEADERS: 'LEADERS',
  NUMBER_IMPORTS: 'NUMBER_IMPORTS',
  ONBOARDING_TASKS: 'ONBOARDING_TASKS',
  PENDING_INVITES: 'PENDING_INVITES',
  REQUIRE_MFA: 'REQUIRE_MFA',
  ROLES: 'ROLES',
  ROLES_ADMINS: 'ROLES_ADMINS',
  SUBSCRIPTION_DATA: {
    COUNT: 'SUBSCRIPTION_DATA_COUNT',
    FIELDS: 'SUBSCRIPTION_DATA_FIELDS',
  },
  SYSTEMIC_ONBOARDING: 'SYSTEMIC_ONBOARDING',
  TAGS: 'TAGS',
  TCR: {
    REGISTRATION: 'TCR_REGISTRATION',
  },
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  TERMS: 'TERMS',
  TOKEN: 'TOKEN',
})

export const STALE_TIME = Object.freeze({
  FIVE_MINUTES: 5 * 60 * 1000,
  FOREVER: Infinity,
  ONE_MINUTE: 60 * 1000,
})
