import PropTypes from 'prop-types'
import React from 'react'
import './SimpleInput.scss'

class SimpleInput extends React.PureComponent {
  input: any
  constructor(props: any) {
    super(props)
    this.input = React.createRef()
  }

  render() {
    // @ts-expect-error TS(2339): Property 'label' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { label, title, error, ...rest } = this.props
    const cname = 'SimpleInput-root' + (error ? ' error' : '')
    return (
      <div className={cname}>
        <div className="SimpleInput-main">
          <input ref={this.input} {...rest} className="SimpleInput-input" />
          {label && <div className="SimpleInput-label">{label}</div>}
          <div className="SimpleInput-line">
            <div className="SimpleInput-activeline" />
          </div>
          {error && (
            <div className="SimpleInput-error">
              {title || label} {error}
            </div>
          )}
        </div>
      </div>
    )
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
SimpleInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.string,
}

export default SimpleInput
