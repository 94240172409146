import PropTypes from 'prop-types'
import React from 'react'
import './Button.scss'

class Button extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { children, color, disabled, solid, text, ...rest } = this.props
    let cname = 'Button-root'
    if (solid) cname += ' solid'
    if (color) cname += ` ${color}`
    if (disabled) cname += ' disabled'
    return (
      <button className={cname} {...rest}>
        <div className="Button-main">{!text ? children : text}</div>
      </button>
    )
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  solid: PropTypes.bool,
  text: PropTypes.string,
}

export default Button
