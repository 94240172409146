import { REHYDRATE } from 'redux-persist/lib/constants'
import { delay } from 'redux-saga'
import { all, call, cancelled, put, take } from 'redux-saga/effects'

import * as creators from './actions'
import * as types from './constants'

import { setAuth } from 'redux/auth/actions'
import { callApi } from 'utils/api'
import { takeLatestUntil } from 'utils/saga-helpers'

const loginApi = ({ data, signal }: any) => {
  const path = '/admins/login'
  const body = JSON.stringify({ auth: data })
  return callApi(path, { body, signal, method: 'POST', noAuth: true })
}

function* loginFlow(action: any) {
  const controller = new window.AbortController()
  try {
    const signal = controller.signal
    const { email, password } = action.payload
    const data = { email, password }
    const resp = yield call(loginApi, { data, signal })
    yield put(setAuth({ token: resp.data.token }))
    yield put({ type: REHYDRATE })
    yield delay(10)
    window.location.assign('/login/token')
  } catch (e) {
    yield put(creators.loginError())
  } finally {
    if (yield cancelled()) controller.abort()
  }
}

const loginUntil = {
  reset: take(types.RESET_LOGIN),
}

function* loginWatcher() {
  yield all([takeLatestUntil(types.LOGIN, loginUntil, loginFlow)])
}

export default loginWatcher
