import { UserOutlined, DownOutlined } from '@ant-design/icons'
import { Avatar, Modal, Space, Dropdown } from 'antd'
import noop from 'lodash/noop'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Admin } from 'screens/AdminsScreen/Admins/queries'
import { Table } from 'shared/components/v2Table'
import { getReadableRoles } from 'utils/roles'

export const StyledTrigger = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  svg {
    margin-left: 8px;
    width: 12px;
  }
`
type AdminTableProps = {
  data: Admin[]
  loading: boolean
  showRoles?: boolean
  onDelete({ seatMappingId }: { seatMappingId: string }): any
  scroll: { x: string; y: string }
}

export default function AdminTable({
  data,
  loading,
  showRoles = true,
  onDelete,
  scroll,
}: AdminTableProps): JSX.Element {
  const history = useHistory()

  const columns = [
    {
      title: 'Name',
      key: 'firstName',
      dataIndex: 'firstName',
      render: (text: any, admin: any) => (
        <div onClick={() => history.push(`/admins/${admin.seat_mapping_id}`)}>
          <Space>
            <Avatar alt={text} icon={<UserOutlined />} />
            {admin.fullName || '–'}
          </Space>
        </div>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      render: (text: any, admin: any) => <a href={`mailto:${admin.email}`}>{admin.email}</a>,
    },
    {
      title: 'Roles',
      key: 'roles',
      render: (text: any, admin: any) => (
        <Dropdown
          menu={{
            items: [
              {
                label: 'Edit',
                onClick: () => history.push(`/admins/${admin.seat_mapping_id}`),
                key: '0',
              },
              {
                type: 'divider',
              },
              {
                danger: true,
                label: 'Remove',
                onClick: () => {
                  const confirm = Modal.confirm({
                    title: `Are you sure you want to delete Admin User ${admin.fullName}?`,
                    okType: 'danger',
                    className: 'delete-user-confirm',
                    onOk: () => {
                      onDelete({ seatMappingId: admin.seat_mapping_id })
                      confirm.destroy()
                    },
                  })
                },
                key: '1',
              },
            ],
          }}
          trigger={['click']}
        >
          <Space>
            {getReadableRoles(admin.roles)}
            <DownOutlined />
          </Space>
        </Dropdown>
      ),
    },
  ]
  return (
    <Table
      columns={columns.filter((c) => showRoles === true || c.key !== 'role')}
      // @ts-expect-error TS(2322): Type '{ dataSource: any; columns: ({ title: string... Remove this comment to see the full error message
      dataSource={data}
      loading={loading}
      onSettingsChange={noop}
      pagination={{
        defaultPageSize: 50,
        hideOnSinglePage: true,
        position: ['none', 'bottomCenter'],
        pageSizeOptions: [50, 100],
      }}
      rowKey="seat_mapping_id"
      scroll={scroll}
    />
  )
}
