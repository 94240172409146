/* eslint-disable camelcase */
import { formatPhone } from 'utils/formatters'

export const normalizePhoneNumbers = (phone_numbers = []) => {
  const isArray = Array.isArray(phone_numbers)
  const data = isArray ? phone_numbers : [phone_numbers]

  const mappedAndPortedData = data
    .sort((a, b) => {
      let compare = 0

      // @ts-expect-error TS(2339): Property 'phone_number' does not exist on type 'ne... Remove this comment to see the full error message
      if (a.phone_number > b.phone_number) compare = 1
      // @ts-expect-error TS(2339): Property 'phone_number' does not exist on type 'ne... Remove this comment to see the full error message
      if (a.phone_number < b.phone_number) compare = -1

      return compare
    })
    .map((item) => {
      const { sid = '', phone_number = '' } = item
      return {
        text: formatPhone(phone_number),
        value: sid,
      }
    })
  return mappedAndPortedData
}
