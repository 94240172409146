export const AUTOMATED_DRIP_MESSAGE_LABEL = 'automated_drip_message'
export const SUBSCRIPTION_CONFIRMATION_LABEL = 'subscription_confirmation'
export const INTRO_WITH_FAN_REGISTRATION_URL_LABEL = 'intro_with_fan_registration_url'
export const UNSUPPORTED_COUNTRY_FAN_REGISTRATION_LABEL = 'unsupported_country_fan_registration'
export const IMPORT_WELCOME_MESSAGE_LABEL = 'import_welcome_message'
export const REPLY_Y_ONBOARDING_LEGAL_LABEL = 'reply_y_onboarding_legal'

export const EDITABLE_MESSAGES = [
  AUTOMATED_DRIP_MESSAGE_LABEL,
  SUBSCRIPTION_CONFIRMATION_LABEL,
  INTRO_WITH_FAN_REGISTRATION_URL_LABEL,
  UNSUPPORTED_COUNTRY_FAN_REGISTRATION_LABEL,
]

export const ONBOARDING_MESSAGE_TITLES = Object.freeze({
  [INTRO_WITH_FAN_REGISTRATION_URL_LABEL]: 'Welcome Message (long)',
  [AUTOMATED_DRIP_MESSAGE_LABEL]: 'Contact Info Reminder Text',
  [SUBSCRIPTION_CONFIRMATION_LABEL]: 'Sign Up Confirmation',
  [UNSUPPORTED_COUNTRY_FAN_REGISTRATION_LABEL]: 'International Text Response',
  [IMPORT_WELCOME_MESSAGE_LABEL]: 'Shopify Abandoned Cart Message',
  [REPLY_Y_ONBOARDING_LEGAL_LABEL]: 'Reply Y Onboarding Legal',
})
