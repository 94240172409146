import { request as baseRequest } from '@community_dev/requests'
import { Modal } from 'antd'

import Sentry from 'integrations/Sentry'
import { resetAuth } from 'redux/auth/actions'
import store from 'store'

export function getBaseUrl() {
  const url = import.meta.env.VITE_API_URL

  return url === 'undefined' || !url ? '' : url
}

export function getAuthToken() {
  let token
  try {
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    const { auth } = JSON.parse(localStorage.getItem('persist:root'))
    token = JSON.parse(auth).token
    return token || ''
  } catch (e) {
    return ''
  }
}

function onError({ skipAuthentication, reportErrors = true }: any) {
  return (message: any, errors: any, response: any) => {
    if (!skipAuthentication && response.status === 401) {
      store.dispatch(resetAuth())
    }
    if (response.status === 403 && Array.isArray(errors) && errors.some((e) => e.type === 'capability_missing')) {
      Modal.error({
        title: `Your account is not allowed to perform this request.`,
        content: `Please contact amber@community.com for access to this operation`,
        className: 'capability-missing-modal',
      })
    } else if (response.status >= 400 && reportErrors) {
      Sentry.captureException(new Error(message))
    }
  }
}

export function request(path: any, options = {}) {
  const token = getAuthToken()
  // @ts-expect-error TS(2339): Property 'headers' does not exist on type {}.
  const headers = { Authorization: `Bearer ${token}`, ...options.headers }
  return baseRequest(`${getBaseUrl()}${path}`, {
    ...options,
    headers,
    onError: onError(options),
  })
}
