// Some special roles that we need for defaults, and to disable on the Front End

export enum SeatRoles {
  OWNER = 'owner',
  VIEWER = 'viewer',
  DATA_DOWNLOADER = 'data_downloader',
}

export enum AdminRoles {
  READ_ONLY_ADMIN = 'read_only_admin',
}

export enum DISABLED_ROLES {
  LEGACY_COMMUNITY_ADMIN = 'community_admin',
}
