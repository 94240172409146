import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { notification } from 'antd'
import orderBy from 'lodash/orderBy'
import { useEffect, useState } from 'react'

import { getClientFeatureGroups } from 'api/capabilities'
import {
  getPendingInvites,
  getTeamMembers,
  assignEnableDownload,
  deleteEnableDownload,
  ClientSeat,
  PendingInvite,
} from 'api/clients'
import { FEATURE_GROUPS } from 'constants/feature-groups'
import { QUERIES } from 'constants/queries'

function showError() {
  notification.error({
    message: 'Sorry, something went wrong. Please try again.',
    placement: 'bottomRight',
    duration: 2,
  })
}

type PrimaryContact = {
  email: string
  id: string
  isPendingDeployment: boolean
}

export type TeamMember = ClientSeat | PendingInvite | PrimaryContact

export type UseTeamMembersValue = {
  teamMembers: TeamMember[]
  members: ClientSeat[]
  pendingMembers: PendingInvite[]
  [x: string]: any
}

export function useTeamMembers(id: string): UseTeamMembersValue {
  const [allMembers, setAllMembers] = useState<TeamMember[]>([])

  const {
    data: members = [],
    isError: isMembersError,
    isLoading: isMembersLoading,
  } = useQuery([QUERIES.TEAM_MEMBERS, { id }], () => getTeamMembers(id))
  const {
    data: pendingMembers = [],
    isError: isPendingMembersError,
    isLoading: isPendingMembersLoading,
  } = useQuery([QUERIES.PENDING_INVITES, { id }], () => getPendingInvites(id))

  useEffect(() => {
    setAllMembers([...pendingMembers, ...orderBy(members, 'firstName')])
  }, [members, pendingMembers, setAllMembers])

  const isLoading = isMembersLoading || isPendingMembersLoading
  const isError = isMembersError || isPendingMembersError

  return {
    teamMembers: allMembers,
    members,
    pendingMembers,
    isLoading,
    isMembersLoading,
    isPendingMembersLoading,
    isError,
    isMembersError,
    isPendingMembersError,
  }
}

export function useDataDownloadAccess({ clientId }: { clientId: string }): {
  isDownloadEnabled?: boolean
  isDownloadEnabledLoading: boolean
  enableClientDownload: () => void
  disableClientDownload: () => void
} {
  const queryClient = useQueryClient()

  const { data: isDownloadEnabled, isLoading: isDownloadEnabledLoading } = useQuery(
    [QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }],
    async () => {
      const featureGroupList = await getClientFeatureGroups(clientId)
      return featureGroupList.some((g) => g.name === FEATURE_GROUPS.DATA_EXPORT_STANDARD)
    },
  )

  const { mutate: enableClientDownload } = useMutation(() => assignEnableDownload({ id: clientId }), {
    onMutate: async () => {
      await queryClient.cancelQueries([QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }])
      // previous value *should* be false (else we are changing true to true), but we don't want to assume
      const previousValue = queryClient.getQueryData([QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }])
      queryClient.setQueryData([QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }], true)
      return { previousValue }
    },
    onError: (err, _, context) => {
      showError()
      queryClient.setQueryData([QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }], context?.previousValue)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }] })
    },
  })

  const { mutate: disableClientDownload } = useMutation(() => deleteEnableDownload({ id: clientId }), {
    onMutate: async () => {
      await queryClient.cancelQueries([QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }])
      // previous value *should* be true (else we are changing false to false), but we don't want to assume
      const previousValue = queryClient.getQueryData([QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }])
      queryClient.setQueryData([QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }], false)
      return { previousValue }
    },
    onError: (err, _, context) => {
      showError()
      queryClient.setQueryData([QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }], context?.previousValue)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERIES.DATA_DOWNLOAD_ENABLED, { id: clientId }] })
    },
  })
  return {
    isDownloadEnabled,
    isDownloadEnabledLoading,
    enableClientDownload,
    disableClientDownload,
  }
}
