import { DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { useMessageBillingUsage } from '@community_dev/hooks'
import {
  AudioPlayer,
  COLORS,
  FONT_SIZE,
  MultimediaInput,
  SPACING,
  composeValidators,
  required,
} from '@community_dev/pixels'
import { Dropzone, Layout } from '@community_dev/pixels'
import { ApiError } from '@community_dev/requests'
import { OnboardingMessageTemplateType } from '@community_dev/types/lib/api/OnboardingMessages'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Descriptions,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import keyBy from 'lodash/keyBy'
import lowerCase from 'lodash/lowerCase'
import noop from 'lodash/noop'
import startCase from 'lodash/startCase'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { Field, Form, useForm } from 'react-final-form'
import styled from 'styled-components'

import { EditOnboardingMessagesList } from './components'
import { EDITABLE_MESSAGES, ONBOARDING_MESSAGE_TITLES } from './constants'

import {
  Client,
  deleteKeywordMessage,
  getKeywordMessages,
  getOnboardingMessageLabels,
  postImportOnboardingMessage,
  postKeywordMessage,
  putClient,
  putKeywordMessage,
  putVoicemail,
} from 'api/clients'
import { QUERIES } from 'constants/queries'
import { InlineEditMessage } from 'shared/components/InlineEditMessage'
import { Switch } from 'shared/components/Switch'
import { TemplateTypeSelect } from 'shared/components/TemplateTypeSelect/TemplateTypeSelect'

const StyledDetails = styled.div`
  padding: 20px;
`

const StyledMessages = styled.section`
  padding: 20px 0;
`

const StyledKeywords = styled.div`
  .ant-collapse-header {
    display: flex;
    align-items: center;
  }

  margin-bottom: 16px;
`

const StyledTitle = styled.div`
  flex-grow: 1;
`

function MessageField({
  name,
  validators,
  label,
  shouldShortenLinks,
  setShouldShortenLinks,
}: {
  name: string
  validators: any[]
  label: string
  shouldShortenLinks: boolean
  setShouldShortenLinks: Dispatch<SetStateAction<boolean>>
}): JSX.Element {
  const { getFieldState } = useForm()
  const message = getFieldState(name)?.value
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    message,
    shouldShortenLinks,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }
  return (
    <Field name="text" validate={composeValidators(...validators)}>
      {({ input, meta }) => (
        <>
          <label htmlFor="text" style={{ display: 'block', marginTop: SPACING[2] }}>
            {label}
            <MultimediaInput
              gallery={false}
              gif={false}
              onEmojiSelect={(_, text) => {
                input.onChange(text)
              }}
              placeholder=""
              usageCounterProps={usageCounterProps}
              {...input}
            />
          </label>
          {!meta.active && meta.touched && meta.modified && meta.error && (
            <Typography.Text type="danger">
              {label} {meta.error}
            </Typography.Text>
          )}
          <Checkbox
            checked={shouldShortenLinks}
            onChange={(e) => setShouldShortenLinks(e.target.checked)}
            style={{ marginTop: SPACING[3], fontSize: FONT_SIZE[2], color: COLORS.TEXT }}
          >
            Use Community Link Shortener (https://m.community.com, ~40 chars)
          </Checkbox>
        </>
      )}
    </Field>
  )
}

export function Messages({ client }: { client: Client }): JSX.Element {
  const queryClient = useQueryClient()
  const [isCreateKeywordMessageModalOpen, setIsCreateKeywordMessageModalOpen] = useState(false)
  const [isCreateImportOnboardingMessageModalOpen, setIsCreateImportOnboardingMessageModalOpen] = useState(false)
  const [createError, setCreateError] = useState<string | undefined>(undefined)
  const [shouldShortenLinks, setShouldShortenLinks] = useState(true)
  const { isLoading, isError, data } = useQuery([QUERIES.KEYWORDS, { id: client.id }], () =>
    getKeywordMessages(client.id),
  )
  function showError(message: any) {
    notification.error({
      message,
      placement: 'bottomRight',
      duration: 2,
    })
  }

  function showSuccess(message: any) {
    notification.success({
      message,
      placement: 'bottomRight',
      duration: 2,
    })
  }

  const clientMessageByLabel = useMemo(() => keyBy(client.onboardingMessages, 'label'), [client.onboardingMessages])

  const { data: onboardingMessageLabelOptions } = useQuery(
    [QUERIES.CLIENT_ONBOARDING_LABELS, { clientId: client.id }],
    {
      queryFn: () => getOnboardingMessageLabels(client.id),
      select: ({ data: labels }): DefaultOptionType[] => {
        return (
          labels
            .map(({ label }) => {
              const used = clientMessageByLabel[label] !== undefined
              return {
                label: (
                  <>
                    {ONBOARDING_MESSAGE_TITLES[label] || startCase(lowerCase(label))} {used && <i> — already exists</i>}
                  </>
                ),
                value: label,
                disabled: used,
              }
            })
            // disabled options at the bottom
            .sort((a) => (a.disabled ? 1 : -1))
        )
      },
    },
  )
  const { mutate: saveVoicemail } = useMutation(putVoicemail, {
    onSuccess() {
      showSuccess('Voicemail saved.')
      queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])
    },
    onError() {
      showError('Voicemail failed to upload. Please try again.')
    },
  })
  const { mutateAsync: createImportOnboardingMessage } = useMutation({
    mutationFn: ({
      text,
      label,
      templateType,
    }: {
      text: string
      label: string
      templateType?: OnboardingMessageTemplateType
    }) => {
      return postImportOnboardingMessage({
        clientId: client.id,
        text,
        label,
        templateType,
        shortenLinks: shouldShortenLinks,
      })
    },
    onError: () => {
      notification.error({
        message: 'Could not create import onboarding message',
      })
    },
    onSuccess: () => {
      notification.success({
        message: 'Import onboarding message created',
      })
      setIsCreateImportOnboardingMessageModalOpen(false)
      queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])
    },
  })
  const { mutate: createKeywordMessage } = useMutation(postKeywordMessage, {
    onError(err?: ApiError) {
      const error = err?.errors?.[0]?.message
      if (error) {
        setCreateError(`Keyword ${error}`)
        return
      }
      setCreateError('Sorry, something went wrong. Please try again.')
    },
    onSuccess() {
      showSuccess('Keyword saved.')
      queryClient.invalidateQueries([QUERIES.KEYWORDS, { id: client.id }])
      setIsCreateKeywordMessageModalOpen(false)
      setCreateError(undefined)
    },
  })
  const { mutate: removeKeyword } = useMutation(deleteKeywordMessage, {
    onError() {
      setCreateError('Sorry, something went wrong. Please try again.')
    },
    onSuccess() {
      showSuccess('Keyword deleted.')
      queryClient.invalidateQueries([QUERIES.KEYWORDS, { id: client.id }])
    },
  })

  const isWhatsAppMessageLabel = useCallback(
    (label: string | undefined) =>
      label?.toLocaleLowerCase().includes('whatsapp') && label.toLocaleLowerCase().includes('import'),
    [],
  )

  function invalidateClient() {
    queryClient.invalidateQueries([QUERIES.CLIENT, { id: client.id }])
  }

  function onFileAccepted(file: File) {
    const body = new FormData()
    body.append('voicemail', file)
    saveVoicemail({ id: client.id, body })
  }

  function handleCreateKeywordMessage(values: any) {
    setCreateError(undefined)
    return createKeywordMessage({ id: client.id, body: values })
  }

  function handleCreateImportOnboardingMessage({
    label,
    text,
    templateType,
  }: {
    label: string
    text: string
    templateType?: OnboardingMessageTemplateType
  }) {
    return createImportOnboardingMessage({
      label,
      text,
      // only include the template type when it’s required.
      templateType: isWhatsAppMessageLabel(label) ? templateType : undefined,
    })
  }

  function handleRemoveKeyword({ id }: any) {
    removeKeyword({ id: client.id, messageId: id })
  }

  return (
    <>
      <StyledDetails>
        <Row>
          <Col span={16}>
            <Descriptions bordered column={1} title="Client Info">
              <Descriptions.Item label="Auto Drip">
                <Switch
                  defaultChecked={client.autoDripEnabled}
                  name="Auto Drip"
                  onSuccess={invalidateClient}
                  prepare={(value) => ({
                    id: client.id,
                    body: { auto_drip_enabled: value },
                  })}
                  updateEntity={putClient}
                />
              </Descriptions.Item>
              <Descriptions.Item label="International">
                <Switch
                  defaultChecked={client.unsupportedCountryOnboardingEnabled}
                  name="International"
                  onSuccess={invalidateClient}
                  prepare={(value) => ({
                    id: client.id,
                    body: { unsupported_country_onboarding_enabled: value },
                  })}
                  updateEntity={putClient}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Voicemail">
                <Space direction="horizontal">
                  {client.voicemailUrl && <AudioPlayer src={client.voicemailUrl} />}
                  <Dropzone accept={{ 'audio/mp3': [], 'audio/mpeg': [] }} noDrag onFileAccepted={onFileAccepted}>
                    <Button block icon={<UploadOutlined />}>
                      Upload
                    </Button>
                  </Dropzone>
                </Space>
              </Descriptions.Item>
            </Descriptions>
            <EditOnboardingMessagesList
              client={client}
              messageLabels={EDITABLE_MESSAGES}
              title="Fan onboarding messages"
            />
            <StyledMessages>
              <h2 className="ant-descriptions-title">Keyword messages</h2>
              {isLoading && <Skeleton active loading={isLoading} paragraph={false} title={{ width: 50 }} />}
              {!isLoading && !isError && (
                <StyledKeywords>
                  <Collapse>
                    {data.map((message: any) => (
                      <Collapse.Panel
                        extra={
                          <Popconfirm
                            cancelText="No"
                            okText="Yes"
                            onCancel={noop}
                            onConfirm={() => {
                              handleRemoveKeyword(message)
                            }}
                            placement="bottomLeft"
                            title="Are you sure you want to remove this keyword message?"
                          >
                            <Button danger type="link">
                              <DeleteOutlined /> Remove
                            </Button>
                          </Popconfirm>
                        }
                        header={<StyledTitle>{message.keyword}</StyledTitle>}
                        key={message.id}
                      >
                        <InlineEditMessage
                          defaultValue={message.text}
                          name={message.keyword}
                          onSuccess={() => {
                            queryClient.invalidateQueries([QUERIES.KEYWORDS, { id: client.id }])
                          }}
                          prepare={(value) => ({
                            id: client.id,
                            messageId: message.id,
                            body: { text: value },
                          })}
                          updateEntity={putKeywordMessage}
                        />
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </StyledKeywords>
              )}
              <Button
                icon={<PlusOutlined />}
                onClick={() => setIsCreateKeywordMessageModalOpen((previous) => !previous)}
                size="large"
                type="primary"
              >
                New Keyword Message
              </Button>
            </StyledMessages>
            {client.onboardingMessages.some((m: any) => !EDITABLE_MESSAGES.includes(m.label)) && (
              <>
                <EditOnboardingMessagesList
                  client={client}
                  messageLabels={client.onboardingMessages
                    .map(({ label }: { label: string }) => label)
                    .filter((label: string) => !EDITABLE_MESSAGES.includes(label))}
                  title="Import onboarding messages"
                />
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => setIsCreateImportOnboardingMessageModalOpen(true)}
                  size="large"
                  type="primary"
                >
                  New Import Onboarding Message
                </Button>
              </>
            )}
          </Col>
        </Row>
      </StyledDetails>
      {isCreateImportOnboardingMessageModalOpen && (
        <Form
          initialValues={{
            label: undefined,
            text: '',
            templateType: undefined,
          }}
          onSubmit={handleCreateImportOnboardingMessage}
          render={({ handleSubmit, submitting, valid, values }) => (
            <Modal
              // the z-index must be less than 5 for the popover to show on top
              // of the modal.
              confirmLoading={submitting}
              okButtonProps={{ disabled: !valid }}
              okText="Add"
              onCancel={() => {
                setIsCreateImportOnboardingMessageModalOpen(false)
                setCreateError(undefined)
              }}
              onOk={handleSubmit}
              open={isCreateImportOnboardingMessageModalOpen}
              title="New Import Onboarding Message"
              width={800}
              zIndex={4}
            >
              <form onSubmit={handleSubmit}>
                {createError && <Alert message={createError} showIcon type="error" />}
                <Field name="label" validate={required}>
                  {({ input }) => (
                    <Layout width="100%">
                      <Select
                        aria-label="Select Onboarding Message Type"
                        onChange={(value: string) => input.onChange({ target: { value } })}
                        options={onboardingMessageLabelOptions}
                        placeholder="Import Message Label"
                        size="large"
                        style={{ width: '100%' }}
                        virtual={false}
                      />
                    </Layout>
                  )}
                </Field>
                {isWhatsAppMessageLabel(values.label) && (
                  <Field name="templateType" validate={required}>
                    {({ input }) => (
                      <Layout marginTop={SPACING[2]}>
                        <TemplateTypeSelect
                          onChange={(value) => input.onChange({ target: { value } })}
                          size="large"
                          value={input.value}
                        />
                      </Layout>
                    )}
                  </Field>
                )}
                <MessageField
                  label="Message"
                  name="text"
                  setShouldShortenLinks={setShouldShortenLinks}
                  shouldShortenLinks={shouldShortenLinks}
                  validators={[required]}
                />
              </form>
            </Modal>
          )}
        />
      )}
      {isCreateKeywordMessageModalOpen && (
        <Form
          onSubmit={handleCreateKeywordMessage}
          render={({ handleSubmit, submitting, valid }) => (
            <Modal
              // the z-index must be less than 5 for the popover to show on top
              // of the modal.
              confirmLoading={submitting}
              okButtonProps={{ disabled: !valid }}
              okText="Add"
              onCancel={() => {
                setIsCreateKeywordMessageModalOpen(false)
                setCreateError(undefined)
              }}
              onOk={handleSubmit}
              open={isCreateKeywordMessageModalOpen}
              title="New Keyword Message"
              zIndex={4}
            >
              <form onSubmit={handleSubmit}>
                {createError && <Alert message={createError} showIcon type="error" />}

                <Field name="keyword" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label htmlFor="keyword">
                        Keyword
                        <Input id="keyword" type="text" {...input} />
                      </label>
                      {!meta.active && meta.touched && meta.modified && meta.error && (
                        <div>
                          <Typography.Text type="danger">Keyword {meta.error}</Typography.Text>
                        </div>
                      )}
                    </>
                  )}
                </Field>

                <Field name="text">
                  {({ input, meta }) => (
                    <>
                      <label htmlFor="text">
                        Message
                        <MultimediaInput
                          gallery={false}
                          gif={false}
                          onEmojiSelect={(_, text) => {
                            input.onChange(text)
                          }}
                          placeholder=""
                          {...input}
                        />
                      </label>
                      {!meta.active && meta.touched && meta.modified && meta.error && (
                        <div>
                          <Typography.Text type="danger">Message {meta.error}</Typography.Text>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </form>
            </Modal>
          )}
        />
      )}
    </>
  )
}
