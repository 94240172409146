import * as launchDarkly from 'launchdarkly-js-client-sdk'
import { LDClient } from 'launchdarkly-js-client-sdk'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

import Sentry from 'integrations/Sentry'
import { Token } from 'utils/api'

export type LDCClientExtended = LDClient & { isReady?: boolean }

export const LaunchDarklyContext = createContext<LDCClientExtended | undefined>(undefined)

LaunchDarklyContext.displayName = 'LaunchDarklyContext'

export function useLaunchDarklyContext(): LDCClientExtended | undefined {
  const context = useContext(LaunchDarklyContext)

  return context
}

declare global {
  interface Window {
    ldcRef: LDCClientExtended
  }
}

type LaunchDarklyProviderProps = {
  token: Token
  children?: React.ReactNode
}

export function LaunchDarklyProvider({ children, token }: LaunchDarklyProviderProps): JSX.Element {
  const [isReady, setIsReady] = useState(false)
  const ldcRef = useRef<LDCClientExtended>()

  useEffect(() => {
    if (!import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID) {
      throw new Error('Launch darkly client id required')
    }

    if (token.sub) {
      const user = {
        kind: 'user',
        key: token.sub,
        appVersion: import.meta.env.VITE_VERSION,
      }

      const launchDarklyClient = launchDarkly.initialize(`${import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID}`, user, {
        sendEvents: import.meta.env.VITE_SEND_LAUNCH_DARKLY_EVENTS !== 'false',
      })

      ldcRef.current = launchDarklyClient

      const onReady = () => {
        setIsReady(true)
      }

      const onChange = () => {
        ldcRef.current = launchDarklyClient
      }

      launchDarklyClient.on('ready', onReady)

      if (!window.Cypress) {
        launchDarklyClient.on('change', onChange)
      }

      return () => {
        setIsReady(false)
        launchDarklyClient.off('ready', onReady)

        if (!window.Cypress) {
          launchDarklyClient.off('change', onChange)
        }
      }
    }
  }, [token?.sub]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    Sentry.setContext('feature flags', ldcRef.current?.allFlags() || null)
  }, [ldcRef])

  let value: LDCClientExtended | undefined

  if (ldcRef.current) {
    window.ldcRef = ldcRef.current

    value = {
      ...ldcRef.current,
      isReady,
    }
  }

  return <LaunchDarklyContext.Provider value={value}>{children}</LaunchDarklyContext.Provider>
}

export function userHasFlag<T = boolean>(key: string, defaultValue?: T): T {
  const _defaultValue = typeof defaultValue === 'undefined' ? false : defaultValue

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const launchDarklyClient = useLaunchDarklyContext()

  return launchDarklyClient?.variation(key, _defaultValue) || _defaultValue
}
