/* eslint-disable react/no-find-dom-node */

import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

import { SmileIcon } from 'shared/components/icon'
import ElasticInput from 'shared/components/input/ElasticInput'
import { EmojiPicker } from 'shared/components/picker'
import './SmsInput.scss'

class SmsInput extends React.PureComponent {
  input: any
  constructor(props: any) {
    super(props)
    this.state = {
      style: { height: '26px' },
      emojiOpen: false,
      selection: {
        start: 0,
        end: 0,
      },
    }
    this.input = React.createRef()
  }

  submitOnEnter = (e: any) => {
    if (e.which === 13) {
      e.preventDefault()
      if (e.target.form) {
        e.target.form.dispatchEvent(new Event('submit', { cancelable: true }))
      }
    }
  }

  openEmoji = () => {
    const inputNode = ReactDOM.findDOMNode(this.input.current)
    // @ts-expect-error TS(2339): Property 'selection' does not exist on type 'Reado... Remove this comment to see the full error message
    const { selection } = this.state
    this.setState({
      emojiOpen: true,
      selection: {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        start: inputNode.selectionStart || selection.start,
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        end: inputNode.selectionEnd || selection.end,
      },
    })
  }

  closeEmoji = () => this.setState({ emojiOpen: false })

  selectEmoji = (e: any) => {
    const emoji = e.native
    // @ts-expect-error TS(2339): Property 'selection' does not exist on type 'Reado... Remove this comment to see the full error message
    const { start, end } = this.state.selection
    // @ts-expect-error TS(2339): Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { value, name } = this.props
    let newValue = value.substring(0, start) + emoji
    newValue += value.substring(end)
    const pos = start + emoji.length
    const newSelection = { start: pos, end: pos }
    this.input.current.value = newValue
    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange({ target: { value: newValue, name } })
    this.setState({ selection: newSelection, emojiOpen: false })
  }

  onHeightChange = (height: any) => {
    // @ts-expect-error TS(2339): Property 'icons' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { icons } = this.props
    const padding = icons ? 40 : 0
    this.setState({ style: { height: `${height + padding + 6}px` } })
  }

  render() {
    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { name, value, minRows, maxRows, icons, maxLength, autoFocus, disabled, onChange, allowEmoji } = this.props
    // @ts-expect-error TS(2339): Property 'emojiOpen' does not exist on type 'Reado... Remove this comment to see the full error message
    const { emojiOpen } = this.state
    return (
      <div className={`SmsInput-main ${disabled ? ' disabled' : ''}`}>
        {/* @ts-expect-error TS(2339): Property 'style' this comment to see the full error message */}
        <div className="SmsInput-wrap" style={this.state.style}>
          <ElasticInput
            // @ts-expect-error TS(2769): No overload matches this call.
            autoFocus={autoFocus}
            disabled={disabled}
            maxLength={maxLength || Number.MAX_SAFE_INTEGER}
            maxRows={maxRows}
            minRows={minRows}
            name={name}
            onChange={onChange}
            onHeightChange={this.onHeightChange}
            ref={this.input}
            spellCheck={false}
            value={value}
          />
          {icons && (
            <div className="SmsInput-icons">
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              {allowEmoji && <SmileIcon className="SmsInput-icons-smile" onClick={this.openEmoji} size={20} />}
              {emojiOpen && (
                <EmojiPicker
                  // @ts-expect-error TS(2322): Type '{ rootStyle: { top: string; left: string; };... Remove this comment to see the full error message
                  onOuterClick={this.closeEmoji}
                  onSelect={this.selectEmoji}
                  rootStyle={{ top: '-25px', left: '25px' }}
                />
              )}
            </div>
          )}
        </div>
        {maxLength && <div className="SmsInput-remaining">{`Remaining: ${maxLength - value.length}`}</div>}
      </div>
    )
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
SmsInput.propTypes = {
  allowEmoji: PropTypes.bool,
  autoFocus: PropTypes.bool,
  icons: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
SmsInput.defaultProps = {
  autoFocus: false,
  icons: true,
  name: 'SmsInput',
  value: '',
  minRows: 3,
  maxRows: 6,
  maxLength: 255,
  disabled: false,
  onChange: noop,
}

export default SmsInput
