const validEmailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)

export const isPresent = {
  validator: (val: any) => typeof val === 'string' && val.trim().length > 0,
  message: 'is required',
}

export const isEmail = {
  validator: (val: any) => validEmailRegex.test(val),
  message: 'is invalid',
}

export const isBirthday = {
  validator: (val: any) => /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(val),
  message: 'is invalid',
}

export const minLength = (min: any) => ({
  validator: (val: any) => val && val.length < min,
  message: `must be at least ${min} characters`,
})

export const maxLength = (max: any) => ({
  validator: (val: any) => val && val.length > max,
  message: `must be less than ${max} characters`,
})

export const isRequired = {
  validator: (val: any) => !val || val.length === 0,
  message: 'is required',
}

export const isSame = {
  validator: (val: any, check: any) => val !== check,
  message: 'do not match',
}

export const isSID = {
  validator: (val: any) => val.length === 34 && val.startsWith('PN'),
  message: 'must be 34 characters and start with PN',
}
