import { ApiError } from '@community_dev/requests'
import { useQuery, useMutation, UseQueryResult, useQueryClient } from '@tanstack/react-query'
import { notification } from 'antd'

import { putSubscriptionDataFields, getSubscriptionDataFields, SubscriptionDataField } from 'api/subscriptionData'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES } from 'constants/queries'
import { useHasCapability } from 'hooks/useCapabilities'

export function useSubscriptionDataFields(clientId: string): UseQueryResult<SubscriptionDataField[], ApiError> {
  const canGetCustomMemberData = useHasCapability(CAPABILITIES.ADMIN.CLIENTS.READ)
  return useQuery([QUERIES.SUBSCRIPTION_DATA.FIELDS, clientId], () => getSubscriptionDataFields(clientId), {
    enabled: canGetCustomMemberData,
  })
}

type UseUpdateSubscriptionDataFieldsReturn = {
  update: (fields: SubscriptionDataField[]) => void
  isLoading: boolean
}

export function useUpdateSubscriptionDataFields(clientId: string): UseUpdateSubscriptionDataFieldsReturn {
  const queryClient = useQueryClient()
  const { mutate: update, isLoading } = useMutation(
    async (fields: SubscriptionDataField[]) => {
      return putSubscriptionDataFields(clientId, fields)
    },
    {
      onError() {
        notification.error({
          message: `Could not update fan registration custom fields`,
          placement: 'bottomRight',
          duration: 2,
        })
      },
      onSuccess() {
        notification.success({
          message: `Fan registration custom fields updated`,
          placement: 'bottomRight',
          duration: 2,
        })
        queryClient.invalidateQueries([QUERIES.SUBSCRIPTION_DATA.FIELDS, clientId])
      },
    },
  )
  return { update, isLoading }
}
