import React from 'react'

import Admins from 'screens/AdminsScreen/Admins'
import { SidebarLayout } from 'shared/layouts'

class AdminsScreen extends React.Component {
  render() {
    return (
      <SidebarLayout>
        <Admins />
      </SidebarLayout>
    )
  }
}

export default AdminsScreen
