import { DownArrowIcon } from '@community_dev/pixels'
import { Dropdown, Modal, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Dispatch, SetStateAction, useMemo } from 'react'
import styled from 'styled-components'

import { TeamMember } from './queries'
import { StyledTrigger } from './styles'

import { ClientSeat } from 'api/clients'
import { SeatRoles } from 'constants/roles'
import { getReadableRoles } from 'utils/roles'

const StyledMember = styled.div<{ $active?: boolean }>`
  // @ts-expect-error TS(2339): Property '$active' does not exist on type 'Pick<De... Remove this comment to see the full error message
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
`

const toNameOrStatus = (record: any) => {
  if (record?.isPendingDeployment) return 'Pending Deployment'
  if (record.firstName) return `${record.firstName} ${record.lastName}`
  return 'Invitation Sent'
}

export const useTeamColumnDef = (
  clientId: string,
  teamMembers: TeamMember[],
  isLoading: boolean,
  setEditMember: Dispatch<SetStateAction<ClientSeat | undefined>>,
  handleSendInvite: (r: any) => void,
  handleRemove: (seat: ClientSeat) => void,
  deleteInvite: any,
): ColumnsType<TeamMember> => {
  return useMemo(
    () => [
      {
        title: `Team Members${isLoading ? '' : ` (${teamMembers.length})`}`,
        dataIndex: 'firstName',
        key: 'firstName',
        width: 300,
        render: (_: any, record: TeamMember) => (
          <StyledMember $active={'firstName' in record}>
            <strong>{toNameOrStatus(record)}</strong>
            <br />
            <Typography.Text copyable type="secondary">
              {record.email}
            </Typography.Text>
          </StyledMember>
        ),
      },
      {
        key: 'id',
        dataIndex: 'id',
        title: 'ID',
        width: 200,
        render: (id: string) => (
          <Typography.Text code copyable>
            {id}
          </Typography.Text>
        ),
      },
      {
        key: 'action',
        width: 100,
        title: 'Role',
        render: (_: any, record: TeamMember) => {
          const isActive = 'firstName' in record
          const isOwner = isActive && record.roles?.some((role) => role.name === SeatRoles.OWNER)
          if ('isPendingDeployment' in record && record.isPendingDeployment) return null
          return (
            <Dropdown
              key={record.id}
              menu={{
                items: isActive
                  ? [
                      {
                        label: 'Edit',
                        onClick: () => setEditMember(record),
                        key: 'edit',
                      },
                      {
                        type: 'divider',
                        key: 'divider',
                      },
                      {
                        danger: true,
                        label: 'Remove',
                        key: 'remove-member',
                        disabled: isOwner,
                        onClick: () => {
                          const confirm = Modal.confirm({
                            title: `Are you sure you want to remove this member?`,
                            okType: 'danger',
                            onOk: () => {
                              handleRemove(record)
                              confirm.destroy()
                            },
                          })
                        },
                      },
                    ]
                  : [
                      {
                        label: 'Resend Invite',
                        onClick: () => handleSendInvite(record),
                        key: 'resend',
                      },
                      {
                        type: 'divider',
                        key: 'divider',
                      },
                      {
                        danger: true,
                        key: 'delete-invite',
                        label: 'Delete Invite',
                        onClick: () => {
                          const confirm = Modal.confirm({
                            title: `Are you sure you want to remove this member?`,
                            okType: 'danger',
                            onOk: () => {
                              deleteInvite({ id: clientId, inviteId: record.id })
                              confirm.destroy()
                            },
                          })
                        },
                      },
                    ],
              }}
              trigger={['click']}
            >
              <StyledTrigger style={{ justifyContent: 'right', textAlign: 'right' }}>
                {isActive ? getReadableRoles(record.roles) : 'Pending Invite'}
                <DownArrowIcon />
              </StyledTrigger>
            </Dropdown>
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId, teamMembers, isLoading, setEditMember, handleSendInvite, handleRemove, deleteInvite],
  )
}
