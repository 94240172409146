import React from 'react'

import { AdminGlyph, CommunityLogo } from 'shared/components/glyph'
import './SimpleLayout.scss'

type SimpleLayoutProps = {
  children?: React.ReactNode
}

class SimpleLayout extends React.PureComponent<SimpleLayoutProps> {
  render() {
    return (
      <div className="SimpleLayout-root">
        <div className="SimpleLayout-main">
          <div className="SimpleLayout-header">
            <div className="SimpleLayout-logo">
              <CommunityLogo />
              <AdminGlyph />
            </div>
          </div>
          <div className="SimpleLayout-body">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default SimpleLayout
