import styled from 'styled-components'

export const StyledTrigger = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-left: 8px;
    width: 12px;
  }
`
