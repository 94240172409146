import { SPACING, FONT_SIZE, FONT_WEIGHT, Layout, CloseIcon, CheckCircle } from '@community_dev/pixels'
import { Typography, Button, Tooltip, Input, Modal, Form } from 'antd'
import { FormListFieldData } from 'antd/lib/form/FormList'
import lowerCase from 'lodash/lowerCase'
import snakeCase from 'lodash/snakeCase'
import toArray from 'lodash/toArray'
import uniq from 'lodash/uniq'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import {
  BIRTHDAY_NAME,
  CITY_NAME,
  EMAIL_NAME,
  FIRST_NAME,
  GENDER_NAME,
  LAST_NAME,
  TYPE_LABELS,
  CUSTOM_FIELD_TYPE_LABELS,
  TEXT_TYPE,
  SELECT_TYPE,
  DATE_TYPE,
  EMAIL_TYPE,
  CUSTOM_FIELD_TYPES,
} from '../constants'
import { FormFieldValue } from '../types'

import { FieldCardLayout } from './FieldCardLayout'
import { FIELD_TYPE_ICON_MAP } from './FieldSelect'
import { GenderSelect } from './GenderSelect'
import CalendarIcon from './icons/calendar.svg?react'
import DropdownIcon from './icons/dropdown.svg?react'
import EmailIcon from './icons/email.svg?react'
import LocationIcon from './icons/location.svg?react'
import NameIcon from './icons/name.svg?react'
import TrashIcon from './icons/trash.svg?react'
import { StyledButtonContainer, StyledCardContainer, StyledIconButton, StyledIconWrapper } from './styled'

import { SubscriptionDataField } from 'api/subscriptionData'
import { CAPABILITIES } from 'constants/capabilities'
import { useHasCapability } from 'hooks/useCapabilities'

const ICON_NAME_MAP = {
  [FIRST_NAME]: NameIcon,
  [LAST_NAME]: NameIcon,
  [GENDER_NAME]: DropdownIcon,
  [BIRTHDAY_NAME]: CalendarIcon,
  [CITY_NAME]: LocationIcon,
  [EMAIL_NAME]: EmailIcon,
} as Record<string, any>

const COMPONENT_NAME_MAP = {
  [GENDER_NAME]: GenderSelect,
}

type BaseField = {
  id?: string
  enabled: boolean
  label: string
  name: string
  required?: boolean
}

type TextField = {
  type: typeof TEXT_TYPE
} & BaseField

export type SelectField = {
  options: string[]
  type: typeof SELECT_TYPE
} & BaseField

type DateField = {
  type: typeof DATE_TYPE
} & BaseField

type EmailField = {
  type: typeof EMAIL_TYPE
} & BaseField

export type Fields = TextField | SelectField | DateField | EmailField

const MAX_NAME_LENGTH = 30
const MAX_VALUE_OPTIONS = 80

const StyledInputWrapper = styled.div`
  width: 100%;
  margin-left: ${SPACING[3]};
  color: ${({ theme }) => theme?.COLORS?.TEXT};
`

const StyledInput = styled(Input)`
  font-size: ${FONT_SIZE[4]};
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme?.COLORS?.TEXT};
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;

  :hover,
  :focus {
    box-shadow: none !important;
  }
`

const StyledButtonWrapper = styled.div`
  margin-top: ${SPACING[4]};

  button {
    font-size: ${FONT_SIZE[2]};
  }
`

const StyledOptionContainer = styled.div`
  max-height: 432px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

const StyledCheckButton = styled(Button)`
  padding: 0 ${SPACING[2]} 0;
`

const isFieldCardDisabled = (canEditDobField: boolean, fieldName: string): boolean => {
  return !canEditDobField && fieldName === BIRTHDAY_NAME
}

const toFieldCardTooltip = (enabled: boolean, canEditDobField: boolean, fieldName: string): string => {
  if (isFieldCardDisabled(canEditDobField, fieldName))
    return 'You do not have the permissions to change this. If you need to change this field please reach out to the Legal team.'
  return enabled ? 'Hide field' : 'Show field'
}

export const FieldCard = ({ field, onChange }: { field: Fields; onChange: (field: Fields) => void }): JSX.Element => {
  const { name, label, enabled } = field
  const { COLORS } = useTheme() || {}
  const Icon = ICON_NAME_MAP[name] || NameIcon
  const canEditDobField = useHasCapability(CAPABILITIES.ADMIN.RESTRICTED_REGISTRATION_FIELD.WRITE)
  const isDisabled = isFieldCardDisabled(canEditDobField, name)
  const tooltipText = toFieldCardTooltip(enabled, canEditDobField, name)

  const Component = COMPONENT_NAME_MAP[name]

  if (Component) {
    return <Component field={field} onChange={onChange} />
  }

  return (
    <FieldCardLayout
      inputLabel={TYPE_LABELS[field.type]}
      isDisabled={isDisabled}
      isEnabled={enabled}
      onVisibleChange={() => onChange({ ...field, enabled: !enabled })}
      tooltipText={tooltipText}
    >
      <Layout alignItems="center" display="flex">
        <StyledIconWrapper>
          <Icon />
        </StyledIconWrapper>
        <Typography.Text
          style={{
            fontSize: FONT_SIZE[4],
            fontWeight: FONT_WEIGHT[5],
            marginLeft: SPACING[3],
            color: enabled ? COLORS?.TEXT : COLORS?.SUBTEXT,
          }}
        >
          {label}
          {enabled ? '' : ' (Hidden)'}
        </Typography.Text>
      </Layout>
    </FieldCardLayout>
  )
}

export const CustomFieldCard = ({
  field,
  onChange,
  onDelete,
}: {
  field: SubscriptionDataField
  onChange?: (field: SubscriptionDataField) => void
  onDelete?: (field: SubscriptionDataField) => void
}): JSX.Element => {
  const { name, valueType } = field
  const { COLORS } = useTheme() || {}
  const Icon = FIELD_TYPE_ICON_MAP[valueType]

  return (
    <StyledCardContainer $enabled>
      <Layout alignItems="center" display="flex" height="18px" justifyContent="space-between">
        <Typography.Text style={{ fontSize: FONT_SIZE[2], fontWeight: FONT_WEIGHT[5], color: COLORS?.SUBTEXT }}>
          {CUSTOM_FIELD_TYPE_LABELS[valueType]}
        </Typography.Text>
        <Layout alignItems="center" display="flex">
          <Typography.Text style={{ fontSize: FONT_SIZE[2], fontWeight: FONT_WEIGHT[5], color: COLORS?.TEXT }}>
            Required
          </Typography.Text>
          <StyledButtonContainer>
            <StyledCheckButton data-testid="require-custom-field" disabled={!onChange}>
              <CheckCircle
                checked={field.fanEnabledStatus === 'is_enabled_required'}
                color={COLORS.BUTTON_PRIMARY}
                onClick={() =>
                  onChange?.({
                    ...field,
                    fanEnabledStatus:
                      field.fanEnabledStatus !== 'is_enabled_required' ? 'is_enabled_required' : 'is_enabled_optional',
                  })
                }
                size={14}
              />
            </StyledCheckButton>
          </StyledButtonContainer>
          <StyledButtonContainer>
            <Tooltip
              align={{ offset: [0, 10] }}
              placement="top"
              title={
                onDelete ? (
                  <span>Delete Field</span>
                ) : (
                  <span>You do not have the capability to modify client fields</span>
                )
              }
            >
              <StyledIconButton
                data-testid="remove-custom-field"
                disabled={!onDelete}
                icon={<TrashIcon />}
                onClick={() => {
                  onDelete?.({
                    ...field,
                    fanEnabledStatus: 'not_enabled',
                  })
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Tooltip>
          </StyledButtonContainer>
        </Layout>
      </Layout>

      <Layout alignItems="center" display="flex">
        <StyledIconWrapper>
          <Icon />
        </StyledIconWrapper>
        <Typography.Text
          style={{
            fontSize: FONT_SIZE[4],
            fontWeight: FONT_WEIGHT[5],
            marginLeft: SPACING[3],
            color: COLORS?.TEXT,
          }}
        >
          {name}
        </Typography.Text>
      </Layout>
    </StyledCardContainer>
  )
}

export const CustomStringFieldCard = ({
  allFieldKeys,
  field,
  fieldValue,
  onChange,
  onRemove,
}: {
  allFieldKeys: string[]
  field: FormListFieldData
  fieldValue: FormFieldValue
  onChange?: (fieldValue: FormFieldValue) => void
  onRemove?: (index: number | number[]) => void
}): JSX.Element => {
  const { key, name, ...restField } = field
  const { COLORS } = useTheme() || {}
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const isDropdownField = !!fieldValue.options
  const isExistingField = !!fieldValue?.fieldKey
  const Icon = isDropdownField
    ? FIELD_TYPE_ICON_MAP[CUSTOM_FIELD_TYPES.SELECT]
    : FIELD_TYPE_ICON_MAP[CUSTOM_FIELD_TYPES.STRING]

  return (
    <StyledCardContainer $enabled>
      <Layout alignItems="center" display="flex" height="18px" justifyContent="space-between">
        <Typography.Text style={{ fontSize: FONT_SIZE[2], fontWeight: FONT_WEIGHT[5], color: COLORS?.SUBTEXT }}>
          {isDropdownField
            ? CUSTOM_FIELD_TYPE_LABELS[CUSTOM_FIELD_TYPES.SELECT]
            : CUSTOM_FIELD_TYPE_LABELS[CUSTOM_FIELD_TYPES.STRING]}
        </Typography.Text>
        <Layout alignItems="center" display="flex">
          <Typography.Text style={{ fontSize: FONT_SIZE[2], fontWeight: FONT_WEIGHT[5], color: COLORS?.TEXT }}>
            Required
          </Typography.Text>
          <StyledButtonContainer>
            <StyledCheckButton data-testid="require-custom-field">
              <CheckCircle
                checked={fieldValue?.fanEnabledStatus === 'is_enabled_required'}
                color={COLORS.BUTTON_PRIMARY}
                onClick={() => onChange?.(fieldValue)}
                size={14}
              />
            </StyledCheckButton>
          </StyledButtonContainer>
          <StyledButtonContainer>
            <Tooltip
              align={{ offset: [0, 10] }}
              placement="top"
              title={
                onRemove ? (
                  <span>Delete Field</span>
                ) : (
                  <span>You do not have the capability to modify client fields</span>
                )
              }
            >
              <StyledIconButton
                data-testid="remove-custom-field"
                disabled={!onRemove}
                icon={<TrashIcon />}
                onClick={() => {
                  if (isDropdownField) {
                    setShowConfirmationModal(true)
                  } else {
                    onRemove?.(field.name)
                  }
                }}
              />
            </Tooltip>
          </StyledButtonContainer>
        </Layout>
      </Layout>
      <Layout alignItems="baseline" display="flex">
        <StyledIconWrapper>
          <Icon />
        </StyledIconWrapper>
        <StyledInputWrapper>
          <Form.Item
            {...restField}
            name={[name, 'field']}
            rules={[
              {
                max: MAX_NAME_LENGTH,
                message: 'Maximum 30 characters',
                type: 'string',
                transform: (value) => value && value.trim(),
              },
              {
                message: 'Missing field name',
                required: true,
                type: 'string',
                whitespace: true,
              },
              {
                validator: async (_, field: string) =>
                  field?.length && !new RegExp(/^[\w\s]+$/).test(field)
                    ? Promise.reject(new Error('Can only contain letters, numbers and spaces'))
                    : Promise.resolve(),
              },
              {
                validator: async (_, field: string) =>
                  !isExistingField && field && allFieldKeys.includes(snakeCase(field.trim()))
                    ? Promise.reject(new Error('Cannot have duplicate field names'))
                    : Promise.resolve(),
              },
            ]}
          >
            <StyledInput data-testid="field-name" placeholder="Field Name" />
          </Form.Item>
        </StyledInputWrapper>
      </Layout>
      {isDropdownField && (
        <Form.List
          name={[field.name, 'options']}
          rules={[
            {
              validator: async (_, options: string[]) =>
                !options || options.length < 1
                  ? Promise.reject(new Error('Field must have at least 1 option'))
                  : Promise.resolve(),
            },
            {
              validator: async (_, options: string[]) => {
                const optionsWithValue = options.filter((o) => o?.length)
                return uniq(optionsWithValue.map((o) => lowerCase(o?.trim())))?.length !== optionsWithValue.length
                  ? Promise.reject(new Error('Cannot have duplicate option names'))
                  : Promise.resolve()
              },
            },
            {
              validator: async (_, options: string[]) =>
                options && options.length > MAX_VALUE_OPTIONS
                  ? Promise.reject(new Error(`Max ${MAX_VALUE_OPTIONS} options per field`))
                  : Promise.resolve(),
            },
          ]}
        >
          {(options, { add, remove }, { errors }) => {
            const maxOptionsExceeded = options.length >= MAX_VALUE_OPTIONS

            return (
              <Layout>
                <Layout marginLeft="26px">
                  <Form.ErrorList errors={errors} />
                </Layout>
                <StyledOptionContainer>
                  {options?.map((option, index) => {
                    return (
                      <Layout alignItems="baseline" display="flex" key={`${option}-${index}`} marginTop={SPACING[3]}>
                        <Layout
                          color={COLORS.SUBTEXT}
                          flexShrink={0}
                          fontSize={FONT_SIZE[2]}
                          textAlign="right"
                          width="14px"
                        >{`${index + 1}.`}</Layout>
                        <StyledInputWrapper>
                          <Form.Item
                            {...option}
                            rules={[
                              {
                                message: 'Missing option name',
                                required: true,
                                type: 'string',
                                whitespace: true,
                              },
                              {
                                validator: async (_, option: string) =>
                                  // we use toArray here so each emoji is represented as a single grapheme cluster
                                  // for example, '👨‍👩‍👧' should equal 1 even though '👨‍👩‍👧'.length equals 8
                                  option && toArray(option.trim()).length > MAX_NAME_LENGTH
                                    ? Promise.reject(new Error('Maximum 30 characters'))
                                    : Promise.resolve(),
                              },
                            ]}
                          >
                            <StyledInput placeholder="Option Name" />
                          </Form.Item>
                        </StyledInputWrapper>
                        <StyledButtonContainer $iconColor={COLORS.DIVIDERS}>
                          <Tooltip
                            align={{ offset: [0, 10] }}
                            placement="top"
                            title={
                              onRemove ? (
                                <span>Delete Option</span>
                              ) : (
                                <span>You do not have the capability to modify client fields</span>
                              )
                            }
                          >
                            <StyledIconButton icon={<CloseIcon size={10} />} onClick={() => remove(option.name)} />
                          </Tooltip>
                        </StyledButtonContainer>
                      </Layout>
                    )
                  })}
                </StyledOptionContainer>
                <StyledButtonWrapper>
                  <Tooltip
                    align={{ offset: [0, 10] }}
                    placement="top"
                    title={<span>Max {MAX_VALUE_OPTIONS} options per field</span>}
                    trigger={!maxOptionsExceeded ? [] : 'hover'}
                  >
                    <Button
                      disabled={maxOptionsExceeded}
                      onClick={() => {
                        if (!maxOptionsExceeded) {
                          add()
                        }
                      }}
                      size="small"
                    >
                      Add Option
                    </Button>
                  </Tooltip>
                </StyledButtonWrapper>
              </Layout>
            )
          }}
        </Form.List>
      )}
      <Modal
        footer={
          <>
            <Button onClick={() => setShowConfirmationModal(false)}>Cancel</Button>
            <Button
              danger
              onClick={() => {
                onRemove?.(field.name)
                setShowConfirmationModal(false)
              }}
              type="primary"
            >
              Delete
            </Button>
          </>
        }
        onCancel={() => setShowConfirmationModal(false)}
        open={showConfirmationModal}
        title="Delete field confirmation"
      >
        Are you sure you want to delete this dropdown field?
      </Modal>
    </StyledCardContainer>
  )
}
