import PropTypes from 'prop-types'
import React from 'react'
import './scss/index.css'

class BoxForm extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'header' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { children, header, title, footer, className, ...rest } = this.props
    return (
      <div className="BoxForm-root">
        {header && <div className="BoxForm-header">{header}</div>}
        <div className="BoxForm-main">
          {title && <div className="BoxForm-title">{title}</div>}
          <form {...rest} className={`BoxForm-form ${className}`}>
            {children}
          </form>
        </div>
        {footer && <div className="BoxForm-footer">{footer}</div>}
      </div>
    )
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
BoxForm.propTypes = {
  title: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
}

export default BoxForm
