import { Button, Input, Modal } from 'antd'
import { ButtonType } from 'antd/es/button/buttonHelpers'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'

type ButtonWithPromptType = {
  danger?: boolean
  buttonType?: ButtonType
  size?: SizeType
  buttonText: ReactNode
  modalText: string
  modalTitle: string
  onClick: () => void
  className?: string
  disabled?: boolean
  validationText?: string
}

const StyledInputContainer = styled.div`
  margin-top: 20px;
`

export const ButtonModalPrompt = ({
  buttonText,
  modalText,
  modalTitle,
  onClick,
  buttonType = 'default',
  danger,
  size,
  className,
  disabled,
  validationText,
}: ButtonWithPromptType): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const hideModal = () => {
    setInputValue('')
    setIsModalOpen(false)
  }

  const [inputValue, setInputValue] = useState('')
  const isModalButtonDisabled = Boolean(validationText && validationText !== inputValue)

  return (
    <>
      <Button
        className={className}
        danger={danger}
        disabled={disabled}
        onClick={showModal}
        size={size}
        type={buttonType}
      >
        {buttonText}
      </Button>
      <Modal
        okButtonProps={{ danger, type: buttonType, disabled: isModalButtonDisabled }}
        okText={buttonText}
        onCancel={hideModal}
        onOk={() => {
          hideModal()
          onClick()
        }}
        open={isModalOpen}
        title={modalTitle}
      >
        {modalText}
        {validationText && (
          <StyledInputContainer>
            Type "{validationText}" to confirm*
            <Input
              name="validation input"
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
              placeholder={validationText}
              value={inputValue}
            />
          </StyledInputContainer>
        )}
      </Modal>
    </>
  )
}
