import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { DATE_FORMAT } from 'constants/date-formats'

dayjs.extend(utc)

type FormatPhoneOptions = {
  intl?: boolean
  parens?: boolean
}

export const formatPhone = (val?: string, options: FormatPhoneOptions = {}): string => {
  if (!val) return ''
  const { intl = true, parens = true } = options
  const nums = `${val}`.replace(/\D/g, '')
  const found = nums.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (!found) return val // returns phone number value if not found as 10DLC
  const parts = [intl && found[1] ? '+1 ' : '']
  if (parens) {
    parts.push('(', found[2], ') ')
  } else {
    parts.push(found[2], '-')
  }
  parts.push(found[3], '-', found[4])
  return parts.join('')
}

export const formatDate = (date: any) => {
  if (date instanceof Date) {
    return dayjs(date).utc().format(DATE_FORMAT.DEFAULT)
  }
  if (typeof date === 'string' && date.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
    return dayjs(date, DATE_FORMAT.ISO_DATE_FORMAT).format(DATE_FORMAT.DEFAULT)
  }
  const formattedDate = dayjs(new Date(date)).utc().format(DATE_FORMAT.DEFAULT)
  return formattedDate === 'Invalid date' ? '' : formattedDate
}
