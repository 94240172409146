import { SPACING, Typography, Layout } from '@community_dev/pixels'
import { Button, Checkbox, Col, Divider, Input, Row, Switch } from 'antd'
import { stringify } from 'csv-stringify/browser/esm'
import uniq from 'lodash/uniq'
import { Field, Form } from 'react-final-form'
import saveAs from 'save-as'

export function CsvDownloader({ data, name }: { data: any[]; name: string }): JSX.Element {
  const buildAndDownloadCsv = async (fileName: string, data: any, includeHeaders: boolean, columns?: string[]) => {
    stringify(
      data,
      {
        header: includeHeaders,
        columns,
      },
      (err, csv) => {
        saveAs(new Blob([csv]), fileName)
      },
    )
  }
  return (
    <Form<{ columns: string[]; includeHeaders: boolean; name: string }>
      initialValues={{ columns: Object.keys(data[0]).map((key) => key), includeHeaders: true, name: name }}
      onSubmit={(values) => buildAndDownloadCsv(values.name, data, values.includeHeaders, values.columns)}
    >
      {({ handleSubmit, values }) => (
        <Layout width={'300px'}>
          <Divider style={{ marginTop: 0, marginBottom: SPACING[2] }}>
            <Typography variant="caption1">Options</Typography>
          </Divider>
          <Field name="name">
            {({ input }) => <Input addonBefore="Name" onChange={input.onChange} value={input.value} />}
          </Field>
          <Field name="includeHeaders">
            {({ input }) => (
              <label>
                <Row gutter={5} style={{ marginTop: SPACING[3] }}>
                  <Col>
                    <Switch
                      checked={values.includeHeaders}
                      onChange={(e) => input.onChange({ target: { value: e } })}
                      size="small"
                    />
                  </Col>
                  <Col>Include Headers</Col>
                </Row>
              </label>
            )}
          </Field>
          <Divider style={{ marginTop: SPACING[1], marginBottom: SPACING[1] }}>
            <Typography variant="caption1">Columns</Typography>
          </Divider>
          <Field name="columns">
            {({ input }) =>
              Object.keys(data[0]).map((key) => {
                return (
                  <Layout key={key}>
                    <Checkbox
                      checked={values.columns.includes(key)}
                      onChange={(e) => {
                        input.onChange({
                          target: {
                            value: e.target.checked
                              ? // add
                                uniq([...values.columns, key])
                              : // remove
                                values.columns.filter((c) => c !== key),
                          },
                        })
                      }}
                    >
                      {key}
                    </Checkbox>
                  </Layout>
                )
              })
            }
          </Field>
          <Button
            block
            disabled={values.columns.length === 0}
            onClick={handleSubmit}
            style={{ marginTop: SPACING[3] }}
            type="primary"
          >
            Download
          </Button>
        </Layout>
      )}
    </Form>
  )
}
