import { Layout, SPACING, pillColors, Badge, composeValidators, ColorGrid } from '@community_dev/pixels'
import { useMutation } from '@tanstack/react-query'
import { Button, Input, List, Skeleton, Typography, Popconfirm, Col, Row } from 'antd'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import flatten from 'lodash/flatten'
import { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import {
  Communities,
  Community,
  createClientCommunity,
  deleteClientCommunity,
  updateClientCommunity,
} from 'api/clients'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES } from 'constants/queries'
import { useHasCapability } from 'hooks/useCapabilities'
import { useCommunitiesQuery } from 'hooks/useCommunitiesQuery'
import { useToastMessage } from 'hooks/useNotification'
import { StyledModal } from 'shared/components/Modal'

dayjs.extend(advancedFormat)

export type AmbProps = {
  client: any
}

const AMB_TAG_TYPE = 'protected_amb'

const StyledCircle = styled.div<{ color: string }>`
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 0;
  display: inline-block;
  margin: 3px;
  background-color: ${({ color }) => color};
  border-radius: 100%;
`

const StyledBadge = styled(Badge)`
  left: ${SPACING[2]};
  top: -${SPACING[1]};
  position: relative;
`

type AllCommunities = Communities & { all: Community[] }
export function Amb({ client }: AmbProps): JSX.Element {
  const canEditAmbCommunities = useHasCapability(CAPABILITIES.ADMIN.CLIENT_FEATURE_GROUP.WRITE)
  const { showNotification } = useToastMessage()
  const {
    isLoading,
    data: communities,
    refetch: refetchCommunities,
  } = useCommunitiesQuery<AllCommunities>({
    clientId: client?.id,
    options: {
      select: (data) => ({ ...data.data, all: flatten(Object.values(data.data)) }),
    },
  })
  const { mutateAsync: updateCommunity } = useMutation([QUERIES.TAGS], {
    mutationFn: ({ clientId, community }: { clientId: string; community: Community }) =>
      updateClientCommunity(clientId, community),
    onSuccess: () => refetchCommunities(),
    onError: () => showNotification('Could not update community', false),
  })
  const { mutateAsync: createCommunity } = useMutation([QUERIES.TAGS], {
    mutationFn: ({ clientId, community }: { clientId: string; community: Community }) =>
      createClientCommunity(clientId, { ...community, tagType: AMB_TAG_TYPE }),
    onSuccess: () => refetchCommunities(),
    onError: () => showNotification('Could not create community', false),
  })
  const {
    mutateAsync: deleteCommunity,
    isLoading: isDeletingCommunity,
    variables: deletingVariables,
  } = useMutation([QUERIES.TAGS], {
    mutationFn: ({ clientId, communityId }: { clientId: string; communityId: string }) =>
      deleteClientCommunity(clientId, communityId),
    onSuccess: () => refetchCommunities(),
    onError: () => showNotification('Could not delete community', false),
  })
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedTag, setSelectedTag] = useState<Community | null>(null)
  const uniqueTagNamesRule = useCallback(
    (name: string) => {
      return communities?.all.some((community) => community.title.toLowerCase() === name.toLowerCase()) &&
        name.toLowerCase() !== selectedTag?.title.toLowerCase()
        ? 'Community name already exists'
        : undefined
    },
    [communities?.all, selectedTag?.title],
  )
  const onSubmit = useCallback(
    async (community: Community) => {
      if (selectedTag) {
        await updateCommunity({ clientId: client.id, community })
      } else {
        await createCommunity({ clientId: client.id, community })
      }
      setIsEditModalOpen(false)
      setSelectedTag(null)
    },
    [client.id, createCommunity, selectedTag, updateCommunity],
  )
  return (
    <div style={{ padding: `0 ${SPACING[8]} ${SPACING[8]} ${SPACING[8]}` }}>
      {isEditModalOpen && (
        <Form<Community>
          destroyOnUnregister={true}
          initialValues={selectedTag || { type: AMB_TAG_TYPE, color: Object.keys(pillColors)[0] }}
          keepDirtyOnReinitialize={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, submitting, hasValidationErrors, form }) => (
            <StyledModal
              footer={[
                <Button
                  key="cancel"
                  onClick={() => {
                    setSelectedTag(null)
                    setIsEditModalOpen(false)
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  disabled={hasValidationErrors}
                  htmlType="submit"
                  key="save"
                  loading={submitting}
                  onClick={async () => {
                    await handleSubmit()
                    form.reset()
                  }}
                  type="primary"
                >
                  Save
                </Button>,
              ]}
              onCancel={() => {
                setIsEditModalOpen(false)
                setSelectedTag(null)
                form.resetFieldState('title')
              }}
              open={true}
              style={{ paddingTop: SPACING[5] }}
              title={selectedTag === null ? 'Create Community' : `Edit Community “${selectedTag.title}”`}
            >
              <Field
                name="title"
                validate={composeValidators(
                  (value) => (!value ? 'this field is required' : undefined),
                  uniqueTagNamesRule,
                )}
              >
                {({ input, meta }) => {
                  return (
                    <>
                      <Typography>Community Name</Typography>
                      <Input
                        required
                        status={meta.error && meta.modified ? 'error' : undefined}
                        {...input}
                        placeholder="Community Name"
                      />
                      {meta.error && meta.modified && (
                        <Typography aria-live="polite" color="red">
                          {meta.error}
                        </Typography>
                      )}
                    </>
                  )
                }}
              </Field>
              <Field name="color">
                {({ input }) => (
                  <>
                    <Typography style={{ marginTop: SPACING[4] }}>Color</Typography>
                    <ColorGrid color={input.value} onChange={(color) => input.onChange({ target: { value: color } })} />
                  </>
                )}
              </Field>
            </StyledModal>
          )}
        </Form>
      )}
      <Row justify="space-between" style={{ marginTop: SPACING[7] }}>
        <Col>
          <h2 style={{ display: 'inline-block' }}>
            AMB Communities
            <StyledBadge>{(communities?.protectedAmb || []).length}</StyledBadge>
          </h2>
        </Col>
        <Col>
          <Button disabled={!canEditAmbCommunities} onClick={() => setIsEditModalOpen(true)}>
            Create AMB Community
          </Button>
        </Col>
      </Row>
      <Typography style={{ paddingBottom: SPACING[5] }}>
        AMB Communities can be chosen by Fans themselves, either during AMB onboarding or later. They cannot be created
        or edited by clients, and Fans cannot be added to or removed from them in the Dashboard.{' '}
        <i>To not overwhelm fans, we suggest a limit of 6 communities per client.</i>
      </Typography>
      <Layout padding={SPACING[3]}>
        <List
          dataSource={communities?.protectedAmb}
          itemLayout="horizontal"
          loading={isLoading}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Popconfirm
                  cancelText="No"
                  okText="Yes"
                  onCancel={(e) => e?.stopPropagation()}
                  onConfirm={async (e) => {
                    e?.stopPropagation()
                    deleteCommunity({ clientId: client.id, communityId: item.id })
                  }}
                  placement="bottomLeft"
                  title={
                    <>
                      Are you sure you want to remove the AMB Community <b>“{item.title}”</b>?
                    </>
                  }
                >
                  <Button
                    aria-label="delete AMB Community"
                    danger
                    disabled={!canEditAmbCommunities}
                    loading={isDeletingCommunity && deletingVariables?.communityId === item.id}
                    onClick={(e) => e.stopPropagation()}
                    shape="round"
                    size="small"
                  >
                    Remove
                  </Button>
                </Popconfirm>,
                <Button
                  aria-label="edit community"
                  disabled={!canEditAmbCommunities}
                  onClick={() => {
                    setSelectedTag(item)
                    setIsEditModalOpen(true)
                  }}
                  shape="round"
                  size="small"
                >
                  Edit
                </Button>,
              ]}
            >
              <Skeleton active avatar loading={isLoading} title={false}>
                <List.Item.Meta
                  avatar={<StyledCircle color={item.color} />}
                  description={
                    <>
                      <Typography.Text code copyable>
                        {item.id}
                      </Typography.Text>{' '}
                      &bull; Created {dayjs(item.insertedAt).format('Do MMM YYYY, HH:mm')}
                    </>
                  }
                  title={item.title}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Layout>
    </div>
  )
}
