import { route } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'

export type SystemicOnboardingResponse = CamelCasedPropertiesDeep<Api.SystemicOnboardingResponse>

export const getSystemicOnboarding = (clientId: string): Promise<SystemicOnboardingResponse> => {
  return request(route(ENDPOINTS.CLIENTS.SYSTEMIC_ONBOARDING, { clientId }), { reportErrors: false })
}
