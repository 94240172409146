import reverse from 'lodash/fp/reverse'
import lodashSortBy from 'lodash/fp/sortBy'
import identity from 'lodash/identity'

import { SORT_ORDER } from 'constants/sort'

export function sortBy(items: any, field: any, order: any) {
  const transform = order === SORT_ORDER.ASC ? identity : reverse
  // @ts-expect-error TS(2345): Argument of type 'LodashSortBy1x2<unknown>' is not... Remove this comment to see the full error message
  return transform(lodashSortBy(field, items))
}
