import { SPACING, COLORS, Layout, Typography, Badge } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { useMutation } from '@tanstack/react-query'
import { Button, Col, Row } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { CreateFeatureGroupFormResult, CreateFeatureGroupModal } from './CreateFeatureGroupModal'
import { FeatureGroupKind } from './FeatureGroup'
import { FeatureGroupList } from './FeatureGroupList'

import { postCapability, postCapabilityToFeatureGroup, postFeatureGroup } from 'api/capabilities'
import { ROUTES } from 'constants/routes'
import { useFeatureGroups } from 'hooks/useFeatureGroups'
import { useToastMessage } from 'hooks/useNotification'

const StyledBadge = styled(Badge)`
  left: ${SPACING[2]};
  top: -${SPACING[1]};
  position: relative;
`

export function FeatureGroups(): JSX.Element {
  const [isCreateFeatureGroupModalOpen, setIsCreateFeatureGroupModalOpen] = useState(false)
  const { allFeatureGroupsAndPlans, featureGroups, isLoading, refetch } = useFeatureGroups()
  const { showNotification } = useToastMessage()
  const { mutateAsync: createFeatureGroup } = useMutation({
    mutationFn: async (values: CreateFeatureGroupFormResult) => {
      // create the feature group
      await postFeatureGroup(values.name)
      // create new capabilities
      await Promise.all(values.newCapabilities.map(postCapability))
      // add capabilities to feature group.
      await Promise.all(
        [...values.capabilities, ...values.newCapabilities].map(async (capability) => {
          if (typeof capability === 'string') {
            return await postCapabilityToFeatureGroup({ featureGroupName: values.name, capabilityName: capability })
          } else {
            return await postCapabilityToFeatureGroup({
              featureGroupName: values.name,
              capabilityName: capability.name,
            })
          }
        }),
      )
    },
    onError: () => {
      showNotification(`There was an error creating the Feature Group`, false)
    },
    onSuccess: () => {
      refetch()
      setIsCreateFeatureGroupModalOpen(false)
    },
  })
  return (
    <Layout padding={SPACING[8]}>
      {isCreateFeatureGroupModalOpen && (
        <CreateFeatureGroupModal
          featureGroups={allFeatureGroupsAndPlans || []}
          kind={FeatureGroupKind.FEATURE_GROUP}
          onClose={() => setIsCreateFeatureGroupModalOpen(false)}
          onSubmit={createFeatureGroup}
        />
      )}

      <Row justify="space-between">
        <Col>
          <h1 style={{ display: 'inline-block' }}>Feature Groups</h1>
          <StyledBadge>{(featureGroups || []).length}</StyledBadge>
        </Col>
        <Col>
          <Button onClick={() => setIsCreateFeatureGroupModalOpen(true)} shape="round" size="small" type="primary">
            Create Feature Group
          </Button>
        </Col>
      </Row>
      <Typography color={COLORS.SUBTEXT} marginBottom={SPACING[5]} variant="body2">
        A Feature Group is a set of Capabilities that can be assigned to a Client{' '}
        <i>in addition to the Capabilities included in their Plan</i>. A Client can have multiple Feature Groups.
      </Typography>
      <FeatureGroupList
        actions={(featureGroup) => [
          <Link to={route(ROUTES.CAPABILITIES.FEATURE_GROUP, { name: featureGroup.name })}>View Details</Link>,
        ]}
        featureGroups={featureGroups}
        loading={isLoading}
      />
    </Layout>
  )
}
