import { CheckCircleOutlined, FileTextOutlined, HomeOutlined, StarOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { COLORS } from '@community_dev/pixels'
import { Breadcrumb, Menu } from 'antd'
// import PageHeader from 'antd/lib/page-header'
import { Route, Switch, useHistory } from 'react-router'
import styled from 'styled-components'

import { Capabilities } from './components/Capabilities'
import { Capability } from './components/Capability'
import { FeatureGroup, FeatureGroupKind } from './components/FeatureGroup'
import { FeatureGroups } from './components/FeatureGroups'
import { Plans } from './components/Plans'

import { ROUTES } from 'constants/routes'
import { SidebarLayout } from 'shared/layouts'

const StyledPage = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  .ant-page-header,
  .ant-page-header-content {
    padding: 0;
    margin: 0;
  }
`

const StyledBreadcrumbs = styled.div`
  padding: 15px 20px 5px;
`

export function CapabilitiesScreen(): JSX.Element {
  const history = useHistory()
  return (
    <SidebarLayout>
      <StyledPage>
        <PageHeader
          style={{ background: COLORS.APP_BACKGROUND_LEVEL_3, position: 'sticky', top: 0, zIndex: 1 }}
          title=""
        >
          <StyledBreadcrumbs>
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <HomeOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item href={ROUTES.CAPABILITIES.FEATURE_GROUPS}>Capabilities</Breadcrumb.Item>
            </Breadcrumb>
          </StyledBreadcrumbs>
          <Menu aria-label="Capabilities Sections" mode="horizontal" role="navigation">
            <Menu.Item
              icon={<FileTextOutlined />}
              key={ROUTES.CAPABILITIES.PLANS}
              onClick={() => history.push(ROUTES.CAPABILITIES.PLANS)}
            >
              Plans
            </Menu.Item>
            <Menu.Item
              icon={<StarOutlined />}
              key={ROUTES.CAPABILITIES.FEATURE_GROUPS}
              onClick={() => history.push(ROUTES.CAPABILITIES.FEATURE_GROUPS)}
            >
              Feature Groups
            </Menu.Item>
            {/* <Menu.Item
              disabled={true}
              onClick={() => history.push(ROUTES.CAPABILITIES.ROLES)}
              key={ROUTES.CAPABILITIES.ROLES}
              icon={<UserOutlined />}
            >
              Roles
            </Menu.Item> */}
            <Menu.Item
              icon={<CheckCircleOutlined />}
              key={ROUTES.CAPABILITIES.CAPABILITIES}
              onClick={() => history.push(ROUTES.CAPABILITIES.CAPABILITIES)}
            >
              Capabilities
            </Menu.Item>
          </Menu>
        </PageHeader>
        <Switch>
          <Route component={Plans} exact path={[ROUTES.CAPABILITIES.PLANS, ROUTES.CAPABILITIES.ROOT]} />
          <Route
            component={() => <FeatureGroup kind={FeatureGroupKind.PLAN} />}
            exact
            path={ROUTES.CAPABILITIES.PLAN}
          />
          <Route
            component={() => <FeatureGroup kind={FeatureGroupKind.FEATURE_GROUP} />}
            exact
            path={ROUTES.CAPABILITIES.FEATURE_GROUP}
          />
          <Route component={Capabilities} exact path={ROUTES.CAPABILITIES.CAPABILITIES} />
          <Route component={Capability} exact path={ROUTES.CAPABILITIES.CAPABILITY} />
          <Route component={FeatureGroups} exact path={ROUTES.CAPABILITIES.FEATURE_GROUPS} />
          {/* <Route path={ROUTES.CAPABILITIES.ROLES} exact component={() => <>Hi!</>} /> */}
        </Switch>
      </StyledPage>
    </SidebarLayout>
  )
}
