import { CommunicationChannel } from '@community_dev/types/lib/api/CommunicationChannel'
import { ImportActivationMode } from '@community_dev/types/lib/api/NumberImports'

// Source activation in client settings sourceActivationModes
export const SOURCE_ACTIVATION = 'WEBHOOK_SOURCE_ADMIN'

// onboarding labels for imports that have TCPA text
const IMPORT_SMS_TEXT_TCPA_LABEL = 'IMPORTED_ACTIVATION_MODE_TEXT_WEBHOOK_SOURCE_ADMIN_CUSTOM_TCPA_LANGUAGE'
const IMPORT_SMS_PREOPT_TCPA_LABEL = 'IMPORTED_ACTIVATION_MODE_PREOPT_WEBHOOK_SOURCE_ADMIN_CUSTOM_TCPA_LANGUAGE'

// Labels per channel and activation mode
export const IMPORT_ONBOARDING_MESSAGES_LABELS = {
  [CommunicationChannel.SMS]: {
    [ImportActivationMode.WEB]: ['IMPORTED_ACTIVATION_MODE_WEB_WEBHOOK_SOURCE_ADMIN'],
    [ImportActivationMode.TEXT]: ['IMPORTED_ACTIVATION_MODE_TEXT_WEBHOOK_SOURCE_ADMIN', IMPORT_SMS_TEXT_TCPA_LABEL],
    [ImportActivationMode.PREOPT]: [
      'IMPORTED_ACTIVATION_MODE_PREOPT_WEBHOOK_SOURCE_ADMIN',
      IMPORT_SMS_PREOPT_TCPA_LABEL,
    ],
  },
  [CommunicationChannel.WHATS_APP]: {
    [ImportActivationMode.PREOPT]: ['IMPORTED_ACTIVATION_MODE_PREOPT_WEBHOOK_SOURCE_ADMIN_WHATSAPP'],
  },
}

// The backend defaults TCPA text for SMS - TEXT and PREOPT
export const IMPORT_DEFAULT_ONBOARDING_TCPA_TEXT = {
  [IMPORT_SMS_TEXT_TCPA_LABEL]:
    'Reply Y to get recurring marketing and other texts from #{client_name} & agree to Community terms/privacy: community.com/terms. Freq varies, consent not a condition of purchase, msg & data rates apply.',
  [IMPORT_SMS_PREOPT_TCPA_LABEL]:
    "By texting with #{client_name} you agree to receive mkting/other texts and to Community's terms/privacy: https://community.com/terms. Freq varies, msg & data rates apply, consent not req'd for purch. Reply STOP to stop. Reply HELP for help.",
}
