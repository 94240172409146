import { defaultTheme } from '@ant-design/compatible'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import { ConnectedRouter } from 'connected-react-router'
import React, { useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router'

import { CAPABILITIES } from 'constants/capabilities'
import { ROUTES } from 'constants/routes'
import AppSnackbar from 'containers/AppSnackbar'
import { LaunchDarklyProvider } from 'contexts/LaunchDarklyProvider'
import { CapabilitiesProvider, useHasCapability } from 'hooks/useCapabilities'
import AdminsScreen from 'screens/AdminsScreen'
import { CapabilitiesScreen } from 'screens/CapabilitiesScreen'
import { Client } from 'screens/Clients/Client'
import { ClientList } from 'screens/Clients/ClientList'
import LoginScreen from 'screens/LoginScreen'
import { MemberScreen } from 'screens/MemberScreen'
import TokenScreen from 'screens/TokenScreen'
import { history } from 'store'
import analytics from 'utils/analytics'
import { parseAuthToken } from 'utils/api'
import { analyticsConfig } from 'utils/config'
import 'antd/dist/reset.css'
import './App.scss'
import 'react-quill/dist/quill.snow.css'

const AuthenticatedProviders = ({ children }) => {
  const [token] = useState(parseAuthToken)
  // If we get an old token, this line will still work on that token.
  if (!token.sub && token.login_state && token.login_state !== 'complete') return <Redirect to="/login" />

  // This will be the verification flow for the new external JWT
  if (!token.sub) return <Redirect to="/login" />
  return (
    <ConfigProvider theme={defaultTheme}>
      <LaunchDarklyProvider token={token}>
        <CapabilitiesProvider>{children}</CapabilitiesProvider>
      </LaunchDarklyProvider>
    </ConfigProvider>
  )
}
const Authenticated = () => {
  const canDeleteMember = useHasCapability(CAPABILITIES.ADMIN.FANS.WRITE)

  return (
    <Switch>
      <Route exact path={ROUTES.ADMINS}>
        <AdminsScreen />
      </Route>
      <Route path={ROUTES.ADMIN.EDIT}>
        <AdminsScreen />
      </Route>
      <Route component={ClientList} exact path={ROUTES.CLIENTS} />
      <Route component={Client} path={ROUTES.CLIENT.GENERAL} />
      <Route component={CapabilitiesScreen} path={ROUTES.CAPABILITIES.ROOT} />
      {canDeleteMember && <Route component={MemberScreen} path={ROUTES.MEMBER} />}
      <Redirect to="/login" />
    </Switch>
  )
}

const reqNoAuth = (screen: any) => {
  const token = parseAuthToken()
  // This will be the verification flow for the new external JWT
  if (token.sub && !token.login_state) return <Redirect to="/clients" />
  // If we get an old token, this line will still work on that token.
  if (token.sub && token.login_state === 'complete') return <Redirect to="/clients" />
  return screen
}

// This will all get moved to unAuthenticated app
const screens = {
  login: () => reqNoAuth(<LoginScreen />),
  token: () => reqNoAuth(<TokenScreen />),
}

const queries = {
  retry: (count: any, error: any) => count < 2 && ![418, 404].includes(error.status),
}

const queryClient = new QueryClient({
  defaultOptions: { queries },
})

const App = (): JSX.Element => {
  useEffect(() => {
    const { gaTrackingId } = analyticsConfig
    analytics.init({ gaTrackingId, history })
  }, [])

  return (
    <ConfigProvider theme={defaultTheme}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <div className="App-root">
            <Switch>
              <Route component={screens.login} exact path="/login" />
              <Route component={screens.token} exact path="/login/token" />
              <AuthenticatedProviders>
                <Authenticated />
              </AuthenticatedProviders>
            </Switch>
            <AppSnackbar />
          </div>
        </ConnectedRouter>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
