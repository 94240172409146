import { useQuery } from '@tanstack/react-query'
import { Descriptions } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { deleteMfaRequired, getMfaEnabled, putEnableMfa } from 'api/clients'
import { QUERIES } from 'constants/queries'
import { Switch } from 'shared/components/Switch'

const StyledMfa = styled.section`
  padding-left: 10px;
`

export function Mfa({ clientId }: any): JSX.Element | null {
  const mfa = useQuery([QUERIES.REQUIRE_MFA, { id: clientId }], () => getMfaEnabled(clientId))
  if (mfa.isLoading) return null
  return (
    <>
      <StyledMfa>
        <Descriptions extra>
          <Descriptions.Item label="Require MFA for all Team Members">
            <Switch
              defaultChecked={mfa.data.mfaEnabled}
              name="MFA settings"
              updateEntity={async (enableMFA) => {
                if (enableMFA) {
                  await putEnableMfa({ id: clientId })
                } else {
                  await deleteMfaRequired({ id: clientId })
                }
              }}
            />
          </Descriptions.Item>
        </Descriptions>
      </StyledMfa>
    </>
  )
}
