import { useQuery } from '@tanstack/react-query'
import identity from 'lodash/identity'
import T from 'prop-types'

export function DataProvider({ children, name, transformer, query }: any) {
  const { isLoading, isError, data: originalData, error, ...rest } = useQuery(name, query)
  let data = originalData

  if (data) data = transformer(data)

  return children({ isLoading, isError, data, error, ...rest })
}

DataProvider.defaultProps = {
  transformer: identity,
}

DataProvider.propTypes = {
  name: T.any,
  transformer: T.func,
  query: T.func.isRequired,
}
