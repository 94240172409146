/* eslint-disable max-len */

import PropTypes from 'prop-types'
import React from 'react'

class SmileIcon extends React.PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'size' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { size, ...rest } = this.props
    if (['string', 'number'].includes(typeof size)) {
      const sz = typeof size === 'number' ? `${size}px` : size
      // @ts-expect-error TS(2339): Property 'height' does not exist on type '{ childr... Remove this comment to see the full error message
      rest.height = sz
      // @ts-expect-error TS(2339): Property 'width' does not exist on type '{ childre... Remove this comment to see the full error message
      rest.width = sz
    }

    return (
      <svg {...rest} version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" id="01a.-client-web" stroke="none" strokeWidth="1">
          <g id="schedule-0" transform="translate(-327.000000, -454.000000)">
            <g id="Group-2" transform="translate(260.000000, 153.000000)">
              <g id="compose-icon-emoji" transform="translate(67.000000, 301.000000)">
                <circle cx="10" cy="10" id="Oval-2" r="9.31818182" stroke="currentColor" strokeWidth="1.36363636" />
                <circle cx="6.81818182" cy="8.63636364" fill="currentColor" id="Oval-4" r="1.36363636" />
                <circle cx="13.1818182" cy="8.63636364" fill="currentColor" id="Oval-4" r="1.36363636" />
                <path
                  d="M6.36363636,13.6363636 C8.78787879,15.7575758 11.2121212,15.7575758 13.6363636,13.6363636"
                  id="Path-2"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.81818182"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
SmileIcon.defaultProps = {
  size: 20,
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
SmileIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default SmileIcon
