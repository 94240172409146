import { Descriptions } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { Switch } from 'shared/components/Switch'

const StyledToggle = styled.section`
  padding-left: 10px;
`

type DataExportToggleProps = {
  client: any
  isDataDownloadEnabled?: boolean
  enable: () => void
  disable: () => void
}

export function DataExportToggle({
  client,
  isDataDownloadEnabled,
  enable,
  disable,
}: DataExportToggleProps): JSX.Element {
  return (
    <>
      <StyledToggle data-testid="client-data-export-toggle">
        <Descriptions extra>
          <Descriptions.Item label={`Allow Data Exports for ”${client.firstName} ${client.lastName}”`}>
            {
              <Switch
                defaultChecked={isDataDownloadEnabled}
                name="Data Export Settings"
                updateEntity={async (enableDownload) => {
                  if (enableDownload) {
                    await enable()
                  } else {
                    await disable()
                  }
                }}
              />
            }
          </Descriptions.Item>
        </Descriptions>
      </StyledToggle>
    </>
  )
}
