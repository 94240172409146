import { HTTP } from '@community_dev/requests'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { Capability } from './clients'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'
import { route } from 'utils/router'

export type FeatureGroup = {
  id: string
  name: string
  deprecated?: boolean
  description?: string
  capabilities: Capability[]
  createdAt: string
}

export type FeatureGroupClient = {
  firstName: string
  lastName: string
  id: string
  clientImage: string
  phoneNumber: string
}

export function getRefreshToken() {
  return request(route(ENDPOINTS.LOGIN.REFRESH_TOKEN))
}

export const getCapabilities = ({ prefix = 'feature' }: { prefix: string }): Promise<Capability[]> => {
  return request(route(ENDPOINTS.CAPABILITIES.CAPABILITIES, { prefix })).then((data) => data.data)
}

export const getFeatureGroups = (): Promise<FeatureGroup[]> => {
  return request(route(ENDPOINTS.CAPABILITIES.FEATURE_GROUPS)).then((data) => data.data)
}

export const postFeatureGroup = (name: string): Promise<any> => {
  return request(route(ENDPOINTS.CAPABILITIES.FEATURE_GROUP, { featureGroupName: name }), {
    method: HTTP.POST,
  })
}

export type PatchFeatureGroupResponse = CamelCasedPropertiesDeep<{
  data: {
    created_at: string
    id: string
    name: string
  }
}>

export const patchFeatureGroup = (
  featureGroupName: string,
  body: { name?: string; deprecated_at?: string },
): Promise<PatchFeatureGroupResponse> => {
  return request(route(ENDPOINTS.CAPABILITIES.FEATURE_GROUP, { featureGroupName: featureGroupName }), {
    method: HTTP.PATCH,
    body,
  })
}

export const getClientsByFeatureGroup = (name: string): Promise<FeatureGroupClient[]> => {
  return request(route(ENDPOINTS.CAPABILITIES.FEATURE_GROUP_CLIENTS, { featureGroupName: name })).then(
    (data) => data.data,
  )
}

export const getClientFeatureGroups = (id: string): Promise<FeatureGroup[]> => {
  return request(route(ENDPOINTS.CLIENTS.FEATURE_GROUPS, { clientId: id })).then((data) => data.data)
}

export function postCapability({ name, description }: { name: string; description: string }): Promise<Capability> {
  return request(route(ENDPOINTS.CAPABILITIES.CAPABILITY, { capabilityName: name }), {
    method: HTTP.POST,
    body: {
      description,
    },
  }).then((res) => res.data)
}

export function deleteFeatureGroup({ name }: { name: string }): Promise<any> {
  return request(route(ENDPOINTS.CAPABILITIES.FEATURE_GROUP, { featureGroupName: name }), {
    method: HTTP.DELETE,
  })
}

export function deleteCapability({ name }: { name: string }): Promise<any> {
  return request(route(ENDPOINTS.CAPABILITIES.CAPABILITY, { capabilityName: name }), {
    method: HTTP.DELETE,
  })
}

export type PostClientFeatureGroupResponse = CamelCasedPropertiesDeep<{
  data: {
    client_id: string
    feature_group_id: string
    feature_group_name: string
  }
}>

export function postClientFeatureGroup({
  id,
  featureGroupName,
}: {
  id: string
  featureGroupName: string
}): Promise<PostClientFeatureGroupResponse> {
  return request(
    route(ENDPOINTS.CLIENTS.FEATURE_GROUP, {
      clientId: id,
      featureGroupName,
    }),
    {
      method: HTTP.POST,
    },
  )
}

export function deleteClientFeatureGroup({
  id,
  featureGroupName,
}: {
  id: string
  featureGroupName: string
}): Promise<void> {
  return request(
    route(ENDPOINTS.CLIENTS.FEATURE_GROUP, {
      clientId: id,
      featureGroupName,
    }),
    {
      method: HTTP.DELETE,
    },
  )
}

export function postCapabilityToFeatureGroup({
  featureGroupName,
  capabilityName,
}: {
  featureGroupName: string
  capabilityName: string
}): Promise<FeatureGroup> {
  return request(route(ENDPOINTS.CAPABILITIES.FEATURE_GROUP_CAPABILITIES, { featureGroupName }), {
    method: HTTP.POST,
    body: {
      capabilities: [capabilityName],
    },
  })
}

export function deleteCapabilityFromFeatureGroup({
  featureGroupName,
  capabilityName,
}: {
  featureGroupName: string
  capabilityName: string
}): Promise<void> {
  return request(route(ENDPOINTS.CAPABILITIES.FEATURE_GROUP_CAPABILITY, { featureGroupName, capabilityName }), {
    method: HTTP.DELETE,
  })
}
