import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import sortBy from 'lodash/sortBy'
import { useTheme } from 'styled-components'

import { Role } from 'api/clients'
import { SeatRoles } from 'constants/roles'
import { getPrimaryRole } from 'utils/roles'

const roleToHeaderMap = {
  [SeatRoles.OWNER]: 'This seat has full access to all available features',
  [SeatRoles.VIEWER]: "This seat can read content but can't make any changes",
}

export function RoleDetails({
  roles,
  ...rest
}: {
  roles: Role[]
  rest?: React.HTMLAttributes<HTMLDivElement>
}): JSX.Element | null {
  const { COLORS } = useTheme() || {}
  if (roles === undefined || roles.length === 0) return null
  const roleHeader = getPrimaryRole(roles)?.description || roleToHeaderMap[roles[0].name] || ''
  return (
    <div {...rest}>
      <p
        css={`
          color: ${COLORS?.SUBTEXT};
        `}
      >
        {roleHeader}
      </p>
      <ul className="fa-ul">
        {roles.map((role: any) => {
          return sortBy(role.capabilities, (cap) => cap.description).map(
            (item) =>
              item.description !== null && (
                <li key={item.id}>
                  <FontAwesomeIcon
                    css={`
                      color: ${COLORS?.SUCCESS};
                      top: 4px;
                    `}
                    icon="check"
                    listItem
                  />{' '}
                  {item.description}
                </li>
              ),
          )
        })}
      </ul>
    </div>
  )
}
