import { Modal } from 'antd'

import { resetAuth } from 'redux/auth/actions'
import store from 'store'
import { baseConfig } from 'utils/config'

const { apiUrl } = baseConfig

export const logout = () => {
  return fetch({
    method: 'DELETE',
    url: `${apiUrl}/auth/v1/logout`,
    credentials: 'same-origin',
    headers: {
      // @ts-expect-error TS(2322): Type { Authorization: string; } is not assignabl... Remove this comment to see the full error message
      Authorization: `Bearer ${getAuthToken()}`,
    },
  })
}

const checkStatus = (resp: any, noAuth: any) => {
  if (resp.status >= 200 && resp.status < 300) return resp
  if (!noAuth && resp.status === 401) store.dispatch(resetAuth())
  return resp.text().then((text: any) => {
    let json = {}
    try {
      json = JSON.parse(text)
    } catch (e) {
      json = {}
    }
    const error = { ...json, status: resp.status, statusText: resp.statusText }
    return Promise.reject(error)
  })
}

const parseResponse = (resp: any, noAuth: any) =>
  resp
    .then((resp: any) => checkStatus(resp, noAuth))
    .then((resp: any) => resp.text())
    .then((text: any) => (text ? JSON.parse(text) : {}))
    .then((json: any) => json)
    .catch((error: any) => {
      if (
        error.status === 403 &&
        Array.isArray(error.errors) &&
        error.errors.some((e: any) => e.type === 'capability_missing')
      ) {
        Modal.error({
          title: `Your account is not allowed to perform this request.`,
          content: `Please contact amber@community.com for access to this operation`,
          className: 'capability-missing-modal',
        })
      }
      throw error
    })

export type Token = {
  aud?: string
  did?: string
  exp?: number
  iat?: number
  iss?: string
  jti?: string
  nbf?: number
  sub?: string
  login_state?: string
  okta_totp_type?: string
}

export const getAuthToken = (): string => {
  let token
  try {
    const { auth } = JSON.parse(localStorage.getItem('persist:root') || '')
    token = JSON.parse(auth).token
    return token || ''
  } catch (e) {
    return ''
  }
}

export const parseAuthToken = (): Token => {
  try {
    return JSON.parse(atob(getAuthToken().split('.')[1]))
  } catch (e) {
    return {}
  }
}

export const callApi = (path: any, { noAuth, noContentType, ...fetchOpts }: any) => {
  const opts = fetchOpts
  opts.credentials = 'same-origin'
  if (typeof opts.headers !== 'object') opts.headers = {}

  if (!noAuth) {
    opts.headers.Authorization = `Bearer ${getAuthToken()}`
  }

  const contentType = opts.headers['Content-Type'] || 'application/json'
  if (!noContentType) opts.headers['Content-Type'] = contentType

  const resp = fetch(`${apiUrl}${path}`, opts)
  return parseResponse(resp, noAuth)
}
