import { Modal } from 'antd'
import styled from 'styled-components'

export const StyledModal = styled(Modal).attrs({ zIndex: 4 })`
  .ant-modal-header {
    text-align: center;
    padding: 14px 18px;
    background-color: ${({ theme }) => theme?.COLORS?.BORDERS};
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .ant-modal-footer {
    background-color: ${({ theme }) => theme?.COLORS?.BORDERS};
    position: sticky;
    bottom: 0;
  }
  .ant-modal-title {
    color: ${({ theme }) => theme?.COLORS?.SUBTEXT};
    font-weight: 900;
    font-size: 16px;
    line-height: 128%;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Avenir', sans-serif;
  }
  .ant-input-group .ant-input:not(:hover):not(:focus) {
    border-right-color: transparent;
  }
  .ant-input-group-addon {
    background-color: transparent;
  }
  label {
    margin: 22px 0 8px;
    display: block;
  }
`
