import { COLORS } from '@community_dev/pixels'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Typography, Input, Button, List, notification } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

import { getCommunityPlusSubscriptions, refreshInviteLink } from 'api/communityPlus'
import { QUERIES } from 'constants/queries'

const CommunityPlusContainer = styled.div`
  padding: 20px 20px 16px;
`

const SectionContainer = styled.div`
  display: flex;
  background-color: ${COLORS.APP_BACKGROUND_LEVEL_2};
  padding: 20px;
  flex-direction: column;
`

const StyledUnorderedList = styled.ul`
  margin: 0;
`

export const CommunityPlus = ({ clientId }: { clientId: string }): JSX.Element => {
  const queryClient = useQueryClient()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [inviteLinks, setInviteLinks] = useState<Record<string, string>>({})
  const {
    data: subscriptions = [],
    isFetching,
    isFetched,
  } = useQuery({
    queryFn: () => getCommunityPlusSubscriptions(phoneNumber),
    queryKey: [QUERIES.COMMUNITY_PLUS_SUBSCRIPTIONS, phoneNumber],
    enabled: Boolean(phoneNumber),
    select: (data) => {
      return data.data.filter((subscription) => subscription.clientId === clientId)
    },
  })

  const { mutate: refreshInvite } = useMutation({
    mutationFn: (invitedId: string) => refreshInviteLink(invitedId),
    onError: () => {
      notification.error({
        message: 'Failed to generate new invite, please try again',
      })
    },
    onSuccess: (data, inviteId) => {
      notification.success({
        message: 'Link refreshed successfully. Please copy the link and share it with the member.',
        duration: 0,
      })
      setInviteLinks((prev) => ({
        ...prev,
        [inviteId]: data.inviteUrl,
      }))
      queryClient.invalidateQueries([QUERIES.COMMUNITY_PLUS_SUBSCRIPTIONS, phoneNumber])
    },
  })

  return (
    <CommunityPlusContainer>
      <Typography.Title level={5}>Community Plus</Typography.Title>
      <SectionContainer>
        <Typography>Lookup invite using member's phone number</Typography>
        <StyledUnorderedList>
          <li>
            <Typography.Paragraph>
              Phone number should be entered without any special characters. EX: 12223334444
            </Typography.Paragraph>
          </li>
        </StyledUnorderedList>
        <Input.Search
          loading={isFetching}
          name="phone number search"
          onSearch={setPhoneNumber}
          placeholder="12223334444"
        />
        {isFetched ? (
          <List
            bordered
            dataSource={subscriptions}
            loading={isFetching}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item.phoneNumber}</Typography.Text>
                <div>
                  <Typography.Text>{item.status}</Typography.Text>
                  {inviteLinks[item.inviteId] ? (
                    <Typography.Text copyable={{ text: inviteLinks[item.inviteId] }} style={{ marginLeft: '12px' }}>
                      {inviteLinks[item.inviteId]}
                    </Typography.Text>
                  ) : null}
                </div>
                <Button
                  onClick={() => {
                    refreshInvite(item.inviteId)
                  }}
                >
                  Refresh Invite Link
                </Button>
              </List.Item>
            )}
            style={{ backgroundColor: COLORS.APP_BACKGROUND_LEVEL_3, marginTop: '8px' }}
          />
        ) : null}
      </SectionContainer>
    </CommunityPlusContainer>
  )
}
