import {
  CheckCircleOutlined,
  CreditCardOutlined,
  HomeOutlined,
  IdcardOutlined,
  MessageOutlined,
  TeamOutlined,
  UserOutlined,
  StarOutlined,
  AppleOutlined,
  PlusOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useQuery } from '@tanstack/react-query'
import { Avatar, Breadcrumb, Menu, Space } from 'antd'
import { useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import { Amb } from './components/Amb'
import { Billing } from './components/Billing/Billing'
import { ClientFeatureGroups } from './components/ClientFeatureGroups'
import { CommunityPlus } from './components/CommunityPlus'
import { General } from './components/General'
import { Messages } from './components/Messages'
import { NumberImports } from './components/NumberImports'
import { SignUp } from './components/SignUp'
import { Team } from './components/Team'

import { getClientFeatureGroups } from 'api/capabilities'
import { Client as ClientType, getClient } from 'api/clients'
import { CAPABILITIES } from 'constants/capabilities'
import { QUERIES } from 'constants/queries'
import { ROUTES } from 'constants/routes'
import { useHasCapability } from 'hooks/useCapabilities'
import { BodySpinner } from 'shared/components/BodySpinner'
import { SidebarLayout } from 'shared/layouts'
import { route } from 'utils/router'

const StyledPage = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  .ant-page-header,
  .ant-page-header-content {
    padding: 0;
    margin: 0;
  }
`

const StyledBreadcrumbs = styled.div`
  padding: 15px 20px 5px;
`

enum STATES {
  LOADING = 'loading',
  SETTLED = 'settled',
  ERROR = 'error',
}

export function Client(): JSX.Element {
  const history = useHistory()
  const { pathname } = useLocation()
  const { id: clientId } = useParams<{ id: string }>()
  const canViewClientFeatureGroups = useHasCapability(CAPABILITIES.ADMIN.CLIENT_FEATURE_GROUP.READ)
  const { isLoading, isError, data: client } = useQuery([QUERIES.CLIENT, { id: clientId }], () => getClient(clientId))
  const [state, setState] = useState<STATES>(STATES.LOADING)
  const { data: clientHasAmb } = useQuery([QUERIES.FEATURE_GROUPS, clientId], () => getClientFeatureGroups(clientId), {
    select: (data) =>
      data.some((featureGroup) => featureGroup.capabilities.some((cap) => cap.name === CAPABILITIES.FEATURE.AMB.ALL)),
  })
  useEffect(() => {
    if (isLoading) {
      setState(STATES.LOADING)
      return
    }

    if (isError) {
      setState(STATES.ERROR)
      return
    }

    setState(STATES.SETTLED)
  }, [isLoading, isError])

  function handleNavigate({ key }: any) {
    history.push(key)
  }

  // This is only for Community Plus client
  // The only community plus client we have left is we're not really strangers
  const hasCommunityPlus = clientId === '052d9f6f-149f-48c7-be19-c53b018e8469'
  return (
    <SidebarLayout>
      <StyledPage>
        <PageHeader title="">
          <StyledBreadcrumbs>
            <Breadcrumb>
              <Breadcrumb.Item
                href="/"
                onClick={(event) => {
                  event.preventDefault()
                  handleNavigate({ key: route(ROUTES.CLIENTS) })
                }}
              >
                <HomeOutlined />
              </Breadcrumb.Item>
              {client && (
                <Breadcrumb.Item>
                  <Space>
                    <Avatar
                      alt={`${client.firstName} ${client.lastName}`}
                      icon={<UserOutlined />}
                      size={25}
                      src={client.profileImageSmall?.url}
                    />
                    {client.firstName} {client.lastName}
                  </Space>
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          </StyledBreadcrumbs>
          <Menu
            aria-label="Client Sections"
            mode="horizontal"
            onClick={handleNavigate}
            role="navigation"
            selectedKeys={[pathname]}
          >
            <Menu.Item icon={<IdcardOutlined />} key={route(ROUTES.CLIENT.GENERAL, { id: clientId })}>
              General
            </Menu.Item>
            <Menu.Item icon={<CheckCircleOutlined />} key={route(ROUTES.CLIENT.SIGN_UP, { id: clientId })}>
              Sign Up
            </Menu.Item>
            <Menu.Item icon={<TeamOutlined />} key={route(ROUTES.CLIENT.TEAM, { id: clientId })}>
              Team
            </Menu.Item>
            <Menu.Item icon={<MessageOutlined />} key={route(ROUTES.CLIENT.MESSAGES, { id: clientId })}>
              Messages
            </Menu.Item>
            <Menu.Item icon={<CreditCardOutlined />} key={route(ROUTES.CLIENT.BILLING, { id: clientId })}>
              Billing
            </Menu.Item>
            {canViewClientFeatureGroups && (
              <Menu.Item icon={<StarOutlined />} key={route(ROUTES.CLIENT.FEATURE_GROUPS, { id: clientId })}>
                Plans & Feature Groups
              </Menu.Item>
            )}
            {clientHasAmb && (
              <Menu.Item icon={<AppleOutlined />} key={route(ROUTES.CLIENT.AMB, { id: clientId })}>
                AMB
              </Menu.Item>
            )}
            {hasCommunityPlus && (
              <Menu.Item icon={<PlusOutlined />} key={route(ROUTES.CLIENT.COMMUNITY_PLUS, { id: clientId })}>
                Community Plus
              </Menu.Item>
            )}
            <Menu.Item icon={<PhoneOutlined />} key={route(ROUTES.CLIENT.NUMBER_IMPORT, { id: clientId })}>
              Number Import
            </Menu.Item>
          </Menu>
        </PageHeader>
        {state === STATES.LOADING && <BodySpinner />}
        {state === STATES.SETTLED && client && (
          <Switch>
            <Route
              exact
              path={route(ROUTES.CLIENT.GENERAL, { id: clientId })}
              render={() => <General client={client as ClientType} />}
            />
            <Route
              exact
              path={route(ROUTES.CLIENT.SIGN_UP, { id: clientId })}
              render={() => <SignUp client={client} />}
            />
            <Route exact path={route(ROUTES.CLIENT.TEAM, { id: clientId })} render={() => <Team client={client} />} />
            <Route
              exact
              path={route(ROUTES.CLIENT.MESSAGES, { id: clientId })}
              render={() => <Messages client={client} />}
            />
            <Route
              exact
              path={route(ROUTES.CLIENT.BILLING, { id: clientId })}
              render={() => <Billing client={client} />}
            />
            <Route
              exact
              path={route(ROUTES.CLIENT.FEATURE_GROUPS, { id: clientId })}
              render={() => <ClientFeatureGroups client={client} />}
            />
            <Route exact path={route(ROUTES.CLIENT.AMB, { id: clientId })} render={() => <Amb client={client} />} />
            {hasCommunityPlus && (
              <Route exact path={route(ROUTES.CLIENT.COMMUNITY_PLUS, { id: clientId })}>
                <CommunityPlus clientId={clientId} />
              </Route>
            )}
            <Route path={route(ROUTES.CLIENT.NUMBER_IMPORT, { id: clientId })}>
              <NumberImports client={client} />
            </Route>
          </Switch>
        )}
      </StyledPage>
    </SidebarLayout>
  )
}
