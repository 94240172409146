import { ApiError } from '@community_dev/requests'
import { useQuery, UseQueryResult, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { SubscriptionV2, getBillingClient, getSubscription } from 'api/billing'
import { QUERIES } from 'constants/queries'

export function useSubscription(clientId: any): UseQueryResult<SubscriptionV2, ApiError> {
  const [enabled, setEnabled] = useState(true)
  return useQuery([QUERIES.BILLING.SUBSCRIPTION, { clientId }], () => getSubscription(clientId), {
    enabled,
    onError(err) {
      if (err.status !== 404) return
      setEnabled(false)
    },
  })
}

export function useBillingClient(clientId: string) {
  const queryClient = useQueryClient()
  const [enabled, setEnabled] = useState(true)
  const reset = () => {
    setEnabled(true)
    queryClient.invalidateQueries([QUERIES.BILLING.CLIENT, { clientId }])
  }

  const client = useQuery([QUERIES.BILLING.CLIENT, { clientId }], () => getBillingClient(clientId), {
    enabled,
    onError(err) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (err.status !== 404) return
      setEnabled(false)
    },
  })

  return { ...client, reset }
}
