import { FileTextOutlined } from '@ant-design/icons'
import { Typography } from '@community_dev/pixels'
import { route } from '@community_dev/requests'
import { List, Popover, Skeleton, Tag } from 'antd'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { FeatureGroup } from 'api/capabilities'
import { ROUTES } from 'constants/routes'

export type PlanListProps = {
  plans?: FeatureGroup[]
  actions?: (fg: FeatureGroup) => ReactNode[]
  loading?: boolean
}

export function PlanList({ plans = [], actions = () => [], loading = false }: PlanListProps): JSX.Element {
  return (
    <List
      dataSource={plans}
      itemLayout="horizontal"
      loading={loading}
      renderItem={(item) => (
        <List.Item actions={actions ? actions(item) : []}>
          <Skeleton active avatar loading={loading} title={false}>
            <List.Item.Meta
              avatar={<FileTextOutlined />}
              description={item.description || '(no description)'}
              title={<Link to={route(ROUTES.CAPABILITIES.PLAN, { name: item.name })}>{item.name}</Link>}
            />
            {item.deprecated === true && <Tag>Deprecated</Tag>}
            <Popover
              content={
                <Typography variant="caption1">
                  <b>{item.name}</b> Capabilities:
                  <hr />
                  {Object.values(item.capabilities).map((capability) => (
                    <Link
                      key={capability.id}
                      style={{ display: 'block' }}
                      to={route(ROUTES.CAPABILITIES.CAPABILITY, { name: capability.name })}
                    >
                      {capability.name}
                    </Link>
                  ))}
                </Typography>
              }
            >
              <Tag>Capabilities: {Object.values(item.capabilities).length}</Tag>
            </Popover>
          </Skeleton>
        </List.Item>
      )}
    />
  )
}
