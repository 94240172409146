import { useMessageBillingUsage } from '@community_dev/hooks'
import { FONT_SIZE, Layout, MultimediaInput, TCPAMessage } from '@community_dev/pixels'
import { OnboardingMessageTemplateType } from '@community_dev/types/lib/api/OnboardingMessages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@tanstack/react-query'
import { Button, Spin, notification, Tooltip } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import { TemplateTypeSelect } from '../TemplateTypeSelect/TemplateTypeSelect'

enum States {
  INITIAL = 'initial',
  EDITING = 'editing',
  PROCESSING = 'processing',
}

const buttonDisabled = css`
  cursor: not-allowed;

  color: ${({ theme }) => theme?.COLORS?.BUTTON_DISABLED};
`
const button = css`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme?.COLORS?.LINKS};
  }
`
const StyledEditButton = styled.button<{ $disabled: boolean }>`
  border: none;
  background: none;
  ${({ $disabled }) => ($disabled ? buttonDisabled : button)}
`

const StyledAction = styled.footer`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;

  .ant-btn {
    margin-left: 8px;
  }
`

const StyledProcessing = styled.span`
  margin-right: 8px;
`

type InlineEditMessageProps = {
  defaultValue: string
  name?: string
  pattern?: RegExp
  prepare?: (value: string, shortenLinks?: boolean, templateType?: OnboardingMessageTemplateType) => any
  onSuccess?: (data: any) => void
  updateEntity: (data: any) => Promise<any>
  validation?: (value: string) => boolean
  maxLength?: number
  maxSegment?: number
  requiredSuffix?: string
  disabled?: boolean
  disabledTooltip?: string
  canShortenLinks?: boolean
  templateType?: OnboardingMessageTemplateType
  showTemplateTypeSelect?: boolean
}

export function InlineEditMessage({
  defaultValue,
  name,
  pattern,
  prepare,
  onSuccess,
  updateEntity,
  validation,
  maxLength,
  maxSegment,
  requiredSuffix = '',
  disabled = false,
  disabledTooltip = '',
  canShortenLinks = false,
  showTemplateTypeSelect = false,
  templateType,
}: InlineEditMessageProps): JSX.Element {
  const [state, setState] = useState<States>(States.INITIAL)
  const [value, setValue] = useState(defaultValue.toString())
  const [shouldShortenLinks, setShouldShortenLinks] = useState(true)
  const [selectedTemplateType, setSelectedTemplateType] = useState<OnboardingMessageTemplateType | undefined>(
    templateType,
  )
  const { mutate: save } = useMutation(updateEntity, {
    onError() {
      notification.error({
        message: name ? `${name} failed to save. Please try again.` : 'Sorry, something went wrong. Please try again.',
        placement: 'bottomRight',
        duration: 2,
      })
      setValue(defaultValue)
      setState(States.INITIAL)
    },
    onSuccess(data) {
      notification.success({
        message: name ? `${name} saved.` : 'Saved.',
        placement: 'bottomRight',
        duration: 2,
      })
      if (onSuccess) onSuccess(data)
      setState(States.INITIAL)
    },
  })

  function handleChange(s: string) {
    if (pattern && !s.match(pattern)) return
    setValue(s)
  }

  function handleKeyDown(evt: any) {
    if (evt.key !== 'Escape') return
    handleCancel()
  }

  function handleCancel() {
    setValue(defaultValue)
    setState(States.INITIAL)
  }

  function handleSave() {
    if (validation && !validation(value)) return
    setState(States.PROCESSING)
    const data = prepare ? prepare(value, shouldShortenLinks, selectedTemplateType) : value
    save(data)
    setState(States.PROCESSING)
  }

  const valueWithRequiredSuffix = `${value} ${requiredSuffix}`.trim()
  const { charCount, error, segmentCount, segmentMessage, tooltipContent } = useMessageBillingUsage({
    charMax: maxLength,
    segmentMax: maxSegment,
    message: value,
    messageSuffix: requiredSuffix ? ` ${requiredSuffix}` : '',
    shouldShortenLinks,
  })
  const usageCounterProps = {
    charCount,
    error,
    segmentCount,
    segmentMessage,
    tooltipContent,
  }
  const isError = Boolean(
    error?.character || error?.segment || (showTemplateTypeSelect && selectedTemplateType === undefined),
  )

  switch (state) {
    case States.EDITING:
      return (
        <>
          {showTemplateTypeSelect && (
            <TemplateTypeSelect onChange={setSelectedTemplateType} value={selectedTemplateType} />
          )}
          {requiredSuffix ? (
            <TCPAMessage
              defaultValue={value}
              onChange={handleChange}
              requiredSuffix={` ${requiredSuffix}`}
              usageCounterProps={usageCounterProps}
            />
          ) : (
            <MultimediaInput
              autoFocus
              gallery={false}
              gif={false}
              onChange={(e) => {
                handleChange(e.target.value)
              }}
              onEmojiSelect={(_, text) => {
                handleChange(text)
              }}
              onKeyDown={handleKeyDown}
              placeholder=""
              usageCounterProps={usageCounterProps}
              value={value}
            />
          )}

          <StyledAction>
            <Layout alignItems="center" display="flex">
              {canShortenLinks && (
                <Checkbox
                  checked={shouldShortenLinks}
                  onChange={(e) => setShouldShortenLinks(e.target.checked)}
                  style={{ fontSize: FONT_SIZE[2], alignItems: 'center' }}
                >
                  Use Community Link Shortener
                </Checkbox>
              )}
            </Layout>
            <Layout whiteSpace="nowrap">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button disabled={isError} onClick={handleSave} type="primary">
                Save
              </Button>
            </Layout>
          </StyledAction>
        </>
      )
    case States.PROCESSING:
      return (
        <div aria-busy aria-label="Saving" aria-live="polite">
          <StyledProcessing>{valueWithRequiredSuffix}</StyledProcessing> <Spin size="small" />
        </div>
      )
    default:
      return (
        <div>
          {valueWithRequiredSuffix}
          <Tooltip title={disabled ? disabledTooltip : ''}>
            {/* Seems like we need the span to show the tooltip */}
            <span>
              <StyledEditButton
                $disabled={disabled}
                aria-disabled={disabled}
                aria-label="Edit"
                data-testid="edit-inline-message"
                disabled={disabled}
                onClick={() => setState(States.EDITING)}
              >
                <FontAwesomeIcon icon="edit" />
              </StyledEditButton>
            </span>
          </Tooltip>
        </div>
      )
  }
}
