import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { COLORS, FONT_SIZE, Layout, SPACING } from '@community_dev/pixels'
import { Alert, AutoComplete, Col, Input, Row, Tag } from 'antd'
import compact from 'lodash/compact'
import uniqBy from 'lodash/fp/uniqBy'
import { useMemo } from 'react'
import { Field } from 'react-final-form'

export function StructuredCapabilityNameInput({
  value,
  existingNames,
  fields = ['kind', 'scope', 'action'],
}: {
  value: string
  existingNames: string[]
  fields?: ('kind' | 'scope' | 'action')[]
}): JSX.Element {
  const [kind, scope, action] = useMemo(() => value.split(':'), [value])

  const allowedCharacters = /[^a-z0-9_-]/g

  const validateName = (name: string) => {
    const [kind, scope, action] = name.split(':')
    if (existingNames.includes(name)) {
      return 'This name already exists.'
    } else if (fields.includes('kind') && !kind) {
      return 'Kind is a required field.'
    } else if (fields.includes('scope') && !scope) {
      return 'Scope is a required field.'
    } else if (fields.includes('action') && !action) {
      return 'Action is a required field.'
    } else if (allowedCharacters.test(`${kind}${scope}${action}`)) {
      return 'Invalid Characters. Only lowercase letters, numbers, dashes and underscores are allowed.'
    }
  }

  const scopes = useMemo(() => {
    return uniqBy(
      (v) => v.value,
      existingNames.map((name) => ({ value: name.split(':')[1] })),
    ).filter((v) => !!v.value)
  }, [existingNames])

  const actions = useMemo(() => {
    return uniqBy(
      (v) => v.value,
      existingNames.map((name) => ({ value: name.split(':')[2] })),
    ).filter((v) => !!v.value)
  }, [existingNames])

  const colspan = 24 / fields.length

  return (
    <Field name="name" validate={validateName}>
      {({ input, meta }) => (
        <>
          <Input.Group size="large">
            <Row gutter={10}>
              {fields.includes('kind') && (
                <Col span={colspan}>
                  Kind *
                  <Input disabled value={kind} />
                </Col>
              )}
              {fields.includes('scope') && (
                <Col span={colspan}>
                  Scope *
                  <AutoComplete
                    allowClear
                    aria-label="scope"
                    aria-required
                    filterOption={(inputValue, option) =>
                      option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(value) =>
                      input.onChange({ target: { value: compact([kind, value, action]).join(':') } })
                    }
                    options={scopes}
                    placeholder="e.g. campaigns"
                    size="large"
                    style={{ width: '100%' }}
                    value={scope}
                  />
                </Col>
              )}
              {fields.includes('action') && (
                <Col span={colspan}>
                  Action *
                  <AutoComplete
                    allowClear
                    aria-label="action"
                    filterOption={(inputValue, option) =>
                      option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(value) => input.onChange({ target: { value: compact([kind, scope, value]).join(':') } })}
                    options={actions}
                    placeholder="e.g. view"
                    size="large"
                    style={{ width: '100%' }}
                    value={action}
                  />
                </Col>
              )}
            </Row>
          </Input.Group>
          {!meta.pristine && meta.error && (
            <Alert message={meta.error} style={{ marginTop: SPACING[2], marginBottom: SPACING[2] }} type="error" />
          )}
          <Layout marginTop={SPACING[5]} textAlign="center">
            Name will appear as{' '}
            <Tag style={{ fontFamily: 'monospace', fontSize: FONT_SIZE[3] }}>
              {fields.includes('kind') && kind + ':'}
              {fields.includes('scope') && (scope || 'none')}
              {fields.includes('action') && ':' + (action || 'none')}
            </Tag>
            {meta.error === undefined ? (
              <CheckCircleFilled style={{ color: COLORS.SUCCESS }} />
            ) : (
              <CloseCircleFilled style={{ color: COLORS.ERRORS }} />
            )}
          </Layout>
        </>
      )}
    </Field>
  )
}
