import { Slider } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledOpacitySliderContainer = styled.div<{ $isDisabled?: boolean }>`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${({ $isDisabled }) => ($isDisabled ? '.2' : '1')};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')}; ;
`

const StyledSliderContainer = styled.div`
  width: 200px;
`

export const OpacitySlider = ({ onChange, value, isDisabled }: any) => {
  function handleOnChange(e: any) {
    onChange(100 - e)
  }

  return (
    <StyledOpacitySliderContainer $isDisabled={isDisabled}>
      <div>White background overlay</div>
      <StyledSliderContainer>
        <Slider defaultValue={value || 0} max={99} onChange={handleOnChange} />
      </StyledSliderContainer>
    </StyledOpacitySliderContainer>
  )
}
