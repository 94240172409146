import * as types from './constants'
import { normalizePhoneNumbers } from './normalizers'

const initialState = {
  numbers: [],
  loading: false,
  successful: false,
}

const phoneNumbers = (state = initialState, action: any) => {
  const { type, payload = {} } = action
  switch (type) {
    case types.LOOKUP_PHONE_NUMBERS:
      return {
        ...state,
        numbers: [],
        loading: true,
        successful: false,
      }
    case types.LOOKUP_PHONE_NUMBERS_SUCCESS:
      return {
        ...state,
        numbers: normalizePhoneNumbers(payload.data),
        loading: false,
        successful: true,
      }
    case types.LOOKUP_PHONE_NUMBERS_ERROR:
      return {
        ...state,
        loading: false,
        successful: false,
      }
    default:
      return state
  }
}

export default phoneNumbers
