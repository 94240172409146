import { HTTP } from '@community_dev/requests'
import { Api } from '@community_dev/types'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ENDPOINTS } from 'constants/endpoints'
import { request } from 'utils/request'
import { route } from 'utils/router'

export type SubscriptionV2 = CamelCasedPropertiesDeep<Api.Billing.Subscription>

export function getSubscription(clientId: string): Promise<SubscriptionV2> {
  return request(route(ENDPOINTS.BILLING.SUBSCRIPTION, { clientId }))
}

// Gets the account status and plan type
export type BillingClient = CamelCasedPropertiesDeep<Api.Billing.BillingClient>
export function getBillingClient(clientId: string): Promise<BillingClient> {
  return request(route(ENDPOINTS.BILLING.CLIENT, { clientId }))
}

export function patchBillingClient({ clientId, body }: any) {
  return request(route(ENDPOINTS.BILLING.CLIENT, { clientId }), {
    method: HTTP.PATCH,
    body,
  })
}

export function postBillingPaywall({ clientId }: { clientId: string }) {
  return request(route(ENDPOINTS.BILLING.BILLING_PAYWALL, { clientId }), {
    method: HTTP.POST,
  })
}

export function deleteSubscription({
  clientId,
  billingSubscriptionId,
}: {
  clientId: string
  billingSubscriptionId: string
}) {
  return request(route(ENDPOINTS.BILLING.CANCEL_SUBSCRIPTION, { clientId, subscriptionId: billingSubscriptionId }), {
    method: HTTP.DELETE,
  })
}

export const getTwilioSubaccountStatus = (clientId: string): Promise<Api.Twilio.SubaccountStatus> => {
  return request(route(ENDPOINTS.BILLING.TWILIO_SUBACCOUNT_STATUS, { clientId }))
}

export const putTwilioSubaccountStatus = ({
  clientId,
  status,
}: {
  clientId: string
  status: string
}): Promise<Api.Twilio.SubaccountStatus> => {
  return request(route(ENDPOINTS.BILLING.TWILIO_SUBACCOUNT_STATUS, { clientId }), {
    method: HTTP.PUT,
    body: { status },
  })
}
