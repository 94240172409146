import React from 'react'

import LoginForm from 'containers/LoginForm'
import BoxFormLayout from 'shared/layouts/BoxFormLayout'

class LoginScreen extends React.PureComponent {
  render() {
    return (
      <BoxFormLayout>
        <LoginForm />
      </BoxFormLayout>
    )
  }
}

export default LoginScreen
