export enum MediaProcessingStatus {
  UPLOAD_URL_ISSUED = 'upload_url_issued',
  PROCESSING = 'processing',
  ERRORED = 'errored',
  COMPLETED = 'completed',
}

export enum MediaDisposition {
  LINK = 'link',
  ATTACHMENT = 'attachment',
}
