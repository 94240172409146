import { CheckOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import partition from 'lodash/partition'
import { useMemo } from 'react'

import { FeatureGroup } from 'api/capabilities'

type PlanMenuProps = {
  plans: FeatureGroup[]
  onChange(p: FeatureGroup): any
  value: FeatureGroup[]
}

export function PlanMenu({ plans, onChange, value }: PlanMenuProps): JSX.Element {
  const [deprecatedPlans, selectablePlans] = useMemo(
    () =>
      partition(
        plans.sort((a, b) => a.name.localeCompare(b.name)),
        (p) => p.deprecated === true,
      ),
    [plans],
  )
  return (
    <Menu>
      {selectablePlans.map((plan) => {
        const isSelected = value.find((p) => p.id === plan.id)
        return (
          <Menu.Item
            icon={isSelected ? <CheckOutlined /> : <CheckOutlined style={{ visibility: 'hidden' }} />}
            key={plan.id}
            onClick={() => onChange(plan)}
          >
            {plan.name}
          </Menu.Item>
        )
      })}
      <Menu.Divider />
      {deprecatedPlans.map((plan) => {
        const isSelected = value.find((p) => p.id === plan.id)
        return (
          <Menu.Item
            disabled
            icon={<CheckOutlined style={{ visibility: isSelected ? 'visible' : 'hidden' }} />}
            key={plan.id}
          >
            {plan.name}
          </Menu.Item>
        )
      })}
    </Menu>
  )
}
